import React, { PureComponent, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Icons,
  ReminderDialog,
  Tabs,
  Table,
  Button,
  ConfirmationDialog,
} from 'spd-oa/components/common'
import SignupConfig from 'spd-oa/config'
import { API } from 'spd-oa/services'
import Utils from 'spd-oa/utils'
import { openLink } from 'spd-oa/helpers'

class OpenAccount extends PureComponent {
  state = {
    showReminder: false,
    reminder: '',
    under: '',
    type: '',
    starting: false,
    confirmMyInfo: false,
    confirmMyInfoBiz: false,
    redirectUrl: '',
    stateError: null,
    showStateError: false,
    ready: false,
    feature: {
      main: {
        residential: true,
        commercial: true,
        maintenance: {
          active: false,
          message: '',
        },
      },
    },
  }
  getListOfUserPremiseService = () => {
    API.getListUserOfPremise().then((data) => {
      if (data) {
        sessionStorage.setItem('userOfPremise', JSON.stringify(data))
      }
    })
  }
  checkFeaturesAvailability(cb) {
    const feature = { ...this.state.feature }
    const { maintenance } = this.props

    if (maintenance) {
      if (maintenance['maintenance'] && maintenance['maintenance'].active) {
        feature.main.maintenance = {
          active: maintenance['maintenance'].active,
          message: maintenance['maintenance'].message,
        }
      }
    }

    if (cb) {
      cb(feature)
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          feature,
        }),
        () => {
          SignupConfig.resetFlow()
          this._processOAMode(300)
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.under !== prevState.under) {
      this._processOAMode()
    }
    if (this.props.location !== prevProps.location) {
      this._processOAMode()
    }
    if (
      this.props.maintenance !== prevProps.maintenance ||
      this.props.maintenanceError !== prevProps.maintenanceError
    ) {
      this.checkFeaturesAvailability()
    }
  }

  componentDidMount() {
    const { maintenance, maintenanceError, isSPSInternalDomain } = this.props
    Utils.clearSession()
    if (maintenance || maintenanceError || isSPSInternalDomain) {
      this.checkFeaturesAvailability()
    }
    this.getListOfUserPremiseService()
  }

  render() {
    const {
      showReminder,
      starting,
      reminder,
      under,
      type,
      confirmMyInfo,
      confirmMyInfoBiz,
      stateError,
      showStateError,
      ready,
      feature,
    } = this.state

    const { mode: oaMode } = Utils.getOAMode()
    const inMaintenanceMode = ready && feature.main.maintenance.active
    if (!ready)
      return (
        <>
          <div className="oa-preloader">
            <Icons.spinloader width={50} height={50} color={['#9ba6aa']} />
            <span>Please wait..</span>
          </div>
        </>
      )
    if (inMaintenanceMode) {
      return (
        <div className="oa-status">
          <h1>Upgrading to serve you better</h1>
          <div className="oa-status-msg">
            <figure className="status-icon">
              <img src="/images/error-flag.svg" alt="Error" />
            </figure>
            {feature.main.maintenance.message ? (
              <p>{feature.main.maintenance.message}</p>
            ) : (
              <p>
                Thank you for visiting our website. We are sorry that this
                e-service is currently not available. We will resume services as
                soon as possible. Thank you for your understanding and patience.
              </p>
            )}
          </div>
        </div>
      )
    }
    return (
      <Fragment>
        <section className="open-account">
          <h1>
            {oaMode === 'mobile'
              ? 'Utilities Account Opening'
              : 'To Open Utilities Account'}
          </h1>

          <p>{`Tell us what is your premises type`}</p>
          <div className="choose-account">
            {Utils.getAvailableOADomain().residential && (
              <button
                className={`choice ${type === 'residential' && 'selected'}`}
                onClick={() => {
                  this._selectTypeHandler('residential')
                }}
              >
                <figure>
                  <Icons.residential
                    width={68}
                    height={67}
                    color={type === 'residential' ? ['#00b0b2'] : ['#D8D8D8']}
                  />
                </figure>
                <span>{`Residential Premises`}</span>
              </button>
            )}
            {Utils.getAvailableOADomain().commercial && (
              <button
                className={`choice ${type === 'commercial' && 'selected'}`}
                onClick={() => {
                  this._selectTypeHandler('commercial')
                }}
              >
                <figure>
                  <Icons.commercial
                    width={72}
                    height={70}
                    color={type === 'commercial' ? ['#00b0b2'] : ['#D8D8D8']}
                  />
                </figure>
                <span>{`Commercial Premises`}</span>
              </button>
            )}
          </div>

          {oaMode !== 'mobile' && (
            <p>{`Tell us how you want to register your account`}</p>
          )}

          <div className="choose-account">
            {Utils.getAvailableOATypes(type).personal && (
              <button
                className={`choice ${under === 'personal' && 'selected'}`}
                onClick={() => {
                  this._selectUnderHandler('personal')
                }}
              >
                <figure>
                  <Icons.avatar
                    width={32}
                    height={36}
                    color={under === 'personal' ? ['#00b0b2'] : ['#D8D8D8']}
                  />
                </figure>
                <span>{`Personal Name`}</span>
              </button>
            )}
            {Utils.getAvailableOATypes(type).company && (
              <button
                className={`choice ${under === 'company' && 'selected'}`}
                onClick={() => {
                  this._selectUnderHandler('company')
                }}
              >
                <figure>
                  <Icons.company
                    width={68}
                    height={67}
                    color={under === 'company' ? ['#00b0b2'] : ['#D8D8D8']}
                  />
                </figure>
                <span>{`Company Name`}</span>
              </button>
            )}
          </div>

          <div className="requirements">
            {this._renderRequirementList()}

            {confirmMyInfo && (
              <ConfirmationDialog
                confirmOkHandler={this._myInfoLinkConfirmHandler}
                confirmCancelHandler={this._myInfoLinkConfirmationCloseHandler}
                onCloseHandler={this._myInfoLinkConfirmationCloseHandler}
                onEscapeKeyDownHandler={
                  this._myInfoLinkConfirmationCloseHandler
                }
                content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
                actionConfig={{
                  cancel: {
                    label: 'Cancel',
                  },
                  ok: {
                    label: 'Continue',
                  },
                }}
              />
            )}
            {confirmMyInfoBiz && (
              <ConfirmationDialog
                confirmOkHandler={this._myInfoBizLinkConfirmHandler}
                confirmCancelHandler={
                  this._myInfoBizLinkConfirmationCloseHandler
                }
                onCloseHandler={this._myInfoBizLinkConfirmationCloseHandler}
                onEscapeKeyDownHandler={
                  this._myInfoBizLinkConfirmationCloseHandler
                }
                content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
                actionConfig={{
                  cancel: {
                    label: 'Cancel',
                  },
                  ok: {
                    label: 'Continue',
                  },
                }}
              />
            )}
          </div>

          <Button
            type={`button`}
            className="start-btn"
            disabled={type === '' || under === '' || starting}
            onClickHandler={() => this._startHandler(false)}
            loading={starting}
          >
            {`Start`}
          </Button>
          {
            <small className="note">
              {under !== '' && (
                <span>
                  {under === 'company'
                    ? `This process will take about 10 - 15 minutes`
                    : `This process will take about 5 minutes`}
                </span>
              )}
              {oaMode !== 'mobile' && oaMode !== 'kiosk' && (
                <span>
                  {`This site works best with latest versions of Chrome, Safari, Edge and Firefox.`}
                </span>
              )}
            </small>
          }
          {showReminder && (
            <ReminderDialog
              onContinueHandler={this._reminderContinueHandler}
              title={reminders[reminder].title}
              content={reminders[reminder] && reminders[reminder].component}
              actionConfig={{
                ok: {
                  label: 'Close',
                  handler: this._reminderContinueHandler,
                },
              }}
            />
          )}

          {showStateError && (
            <ConfirmationDialog
              confirmCancelHandler={this._stateErrorCloseHandler}
              onCloseHandler={this._stateErrorCloseHandler}
              onEscapeKeyDownHandler={this._stateErrorCloseHandler}
              content={stateError}
              actionConfig={{
                ok: false,
                cancel: {
                  label: 'Close and continue',
                },
              }}
            />
          )}
        </section>
      </Fragment>
    )
  }

  _renderRequirementList() {
    const { type, under } = this.state
    const { mode: oaMode } = Utils.getOAMode()
    return (
      <ul>
        {type !== '' && (
          <li>
            {type === 'residential' && (
              <span>
                {`Note this application is for `}{' '}
                <a onClick={this._linkHandler} data-type={`residential_use`}>
                  {`Residential use`}
                </a>{' '}
                {` only.`}
              </span>
            )}
            {type === 'commercial' && (
              <span>
                {`Note this application is for `}{' '}
                <a onClick={this._linkHandler} data-type={`commercial_use`}>
                  {`Commercial use`}
                </a>{' '}
                {` only.`}
              </span>
            )}
          </li>
        )}
        {type === 'residential' && (
          <li>
            <span>
              For premises used in connection to any trade or business (e.g.
              dormitory / serviced apartment / hostel), please submit a{' '}
              {oaMode !== 'kiosk' ? (
                <a
                  onClick={this._linkToHandler(
                    `${window.location.origin}/#/open-account/commercial`,
                    '_self'
                  )}
                  data-type={`residential_security_deposit`}
                >
                  commercial application
                </a>
              ) : (
                'commercial application'
              )}
              .
            </span>
          </li>
        )}
        <li>
          <span>
            {`To open a utilities account, you must be 18 years old and above.`}
          </span>
        </li>
        {type === 'residential' && (
          <li>
            <span>
              {`Your`}{' '}
              <a
                onClick={this._linkHandler}
                data-type={`residential_security_deposit`}
              >
                security deposit
              </a>{' '}
              {` will be billed in your first bill. Your `}{' '}
              <a
                onClick={this._linkHandler}
                data-type={`refuse_collection_charges`}
              >
                refuse collection charges
              </a>{' '}
              {`and`}{' '}
              <a onClick={this._linkHandler} data-type={`waterborne_tax`}>
                waterborne tax
              </a>{' '}
              will start with effect from the opening of your account.
            </span>
          </li>
        )}

        {type === 'commercial' && (
          <li>
            <span>
              You are required to make payment for{' '}
              <a
                onClick={this._linkHandler}
                data-type={`commercial_security_deposit`}
              >
                security deposit
              </a>{' '}
              {` via eNets or Credit Card during application. Your `}{' '}
              <a
                onClick={this._linkHandler}
                data-type={`refuse_collection_charges`}
              >
                refuse collection charges
              </a>{' '}
              {`and`}{' '}
              <a onClick={this._linkHandler} data-type={`waterborne_tax`}>
                waterborne tax
              </a>{' '}
              will start with effect from the opening of your account.
            </span>
          </li>
        )}

        {type === 'residential' && (
          <>
            <li>
              <span>
                For gas turn-on only, please call City Energy at{' '}
                {oaMode === 'kiosk' ? (
                  '1800-555-1661'
                ) : (
                  <a href="tel:18005551661">1800-555-1661</a>
                )}
                .
              </span>
            </li>
            <li>
              <span>
                {`You may need to attach documents such as property tax or proof of ownership.`}
              </span>
            </li>
          </>
        )}

        {type === 'commercial' && under !== '' && (
          <li>
            <span>
              {`You can check out what are the `}
              <a
                onClick={this._linkHandler}
                data-type={`commercial_${under}_documents`}
              >{`documents`}</a>{' '}
              {`required to support your account opening.`}
            </span>
          </li>
        )}
        {type === 'residential' && under === 'company' && (
          <>
            <li>
              <span>
                {`You can check out what are the `}
                <a
                  onClick={this._linkHandler}
                  data-type="company_documents"
                >{`documents`}</a>{' '}
                {`required to support your account opening.`}
              </span>
            </li>
          </>
        )}
        <li>
          Selection of dates are available up to 30 days in advance subjected to
          availability.
        </li>
        {type && under && (
          <>
            <li>
              To open a utilities account for non-standard premises,{' '}
              <Link to="#" onClick={this._linkToHandlerNanl}>
                click here
              </Link>
              .
            </li>
          </>
        )}
        {under === 'personal' && (
          <li className={`highlighted`}>
            {oaMode === 'kiosk' ? (
              <span>
                {`Login to Myinfo with your Singpass to retrieve your personal information and you will not be required to submit NRIC or FIN copies anymore.`}
              </span>
            ) : (
              <span>
                {`You are required to provide a copy of your NRIC or FIN.`}
                <br />
                <br />
                {`Alternatively, login to Myinfo with your Singpass to retrieve your personal information and you will not be required to submit NRIC or FIN copies anymore.`}
                <br />
                <br />
                <Link to="#" onClick={this._myInfoLinkClickHandler}>
                  What is Myinfo
                </Link>
              </span>
            )}
          </li>
        )}

        {under === 'company' && (
          <Fragment>
            <li className={`highlighted`}>
              <span>
                {`Login to Myinfo business with your Corppass to retrieve your company information and you will not be required to submit documents such as signed application form and ACRA.`}
                {Utils.getOAMode().mode !== 'kiosk' && (
                  <>
                    <br />
                    <br />
                    <Link to="#" onClick={this._myInfoBizLinkClickHandler}>
                      What is Myinfo business
                    </Link>
                  </>
                )}
              </span>
            </li>
          </Fragment>
        )}
      </ul>
    )
  }
  _selectTypeHandler = (domain) => {
    const {
      history,
      match: { params },
    } = this.props
    if (domain) {
      if (params.type) {
        history.push(`/open-account/${domain}/${params.type}`)
      } else {
        history.push(`/open-account/${domain}`)
      }
    }
  }

  _setType = (type) => {
    this.setState((prevState) => ({
      ...prevState,
      type,
    }))
  }

  _selectUnderHandler = (under) => {
    const {
      history,
      match: { params },
    } = this.props
    if (under) {
      let _domain = params.domain
      if (!_domain) {
        _domain = 'residential'
      }
      history.push(`/open-account/${_domain}/${under}`)
    }
  }

  _setUnder = (under) => {
    this.setState((prevState) => ({
      ...prevState,
      under,
    }))
  }

  _linkHandler = (e) => {
    const { target } = e
    const { type } = target.dataset
    this.setState((prevState) => ({
      ...prevState,
      reminder: type,
      showReminder: true,
    }))
  }

  _linkToHandler = (to, target = '_blank') => () => {
    if (to) {
      openLink(to, target)
    }
  }

  _linkToHandlerNanl = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyInfo: false,
    }))
    this._startHandler(true)
  }

  _startHandler = (signUpNanl = false) => {
    const { type, under } = this.state
    const mode = sessionStorage.getItem('oa_mode')
    Utils.clearSession()

    if (signUpNanl) {
      sessionStorage.setItem('isNanl', true)
    }
    if (mode) {
      sessionStorage.setItem('oa_mode', mode)
    }
    this.setState((prevState) => ({
      ...prevState,
      starting: true,
    }))

    this.checkFeaturesAvailability((availability) => {
      if (availability.main.maintenance.active) {
        return null
      }
      API.start({ application_domain: type, application_type: under })
        .then((res) => {
          sessionStorage.setItem('signupStart', true)
          sessionStorage.setItem('signupStateId', res.state_id)
          this.setState(
            (prevState) => ({
              ...prevState,
              starting: false,
            }),
            () => {
              this._proceed()
            }
          )
        })
        .catch((err) => {
          this.setState((prevState) => ({
            ...prevState,
            stateError: err.error_description,
            showStateError: true,
          }))
        })
    })
  }

  _proceed = () => {
    const { history } = this.props
    const { type, under } = this.state
    const signupFlow = SignupConfig.getFlow(type, under)
    history.push({
      pathname: signupFlow[0].route,
      state: {
        started: true,
      },
    })
  }

  _myInfoLinkClickHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyInfo: true,
    }))
  }
  _myInfoLinkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyInfo: false,
    }))
  }

  _myInfoLinkConfirmHandler = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmMyInfo: false,
      }),
      () => {
        openLink('https://www.singpass.gov.sg/main', '_blank')
      }
    )
  }

  _myInfoBizLinkClickHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyInfoBiz: true,
    }))
  }
  _myInfoBizLinkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyInfoBiz: false,
    }))
  }

  _myInfoBizLinkConfirmHandler = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmMyInfoBiz: false,
      }),
      () => {
        openLink('https://www.singpass.gov.sg/myinfobusiness', '_blank')
      }
    )
  }

  _reminderCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showReminder: false,
    }))
  }

  _reminderContinueHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      reminder: '',
      showReminder: false,
    }))
  }

  _stateErrorCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      stateError: null,
      showStateError: false,
      starting: false,
    }))
  }

  _processOAMode = (timeout = 0) => {
    setTimeout(() => {
      const {
        match: { params },
      } = this.props

      if (params) {
        if (params.domain) {
          this._setType(params.domain)
        }
        if (params.type) {
          this._setUnder(params.type)
        }
      }

      this.setState((prevState) => ({
        ...prevState,
        ready: true,
      }))
      // if (
      //   SignupConfig.modesFor['residential'].personal.some(
      //     m => m === Utils.getOAMode().mode
      //   )
      // ) {
      //   this._setUnder('personal')
      // }
    }, timeout)
  }
}

OpenAccount.defaultProps = {
  type: 'residential',
}

const CompanyDocumentsReminder = () => {
  return (
    <Fragment>
      <ul>
        <li>{`Signed and completed Utilities Application Form.`}</li>
        <li>{`Latest ACRA detailed Business Profile or Bizfile`}</li>
        <li>
          {`Letter of Authorisation`}
          <br />
          {`Notes: To be authorised by Director listed in the recent ACRA detailed Business Profile/Bizfile on the letterhead issued by company, indicating the company's representative's name and identification number i.e NRIC or FIN.`}
        </li>
      </ul>
    </Fragment>
  )
}

const CommercialPersonalDocumentsReminder = () => {
  const { mode: oaMode } = Utils.getOAMode()
  const [redirectUrl, setRedirectUrl] = useState('')

  const redirectLinkClickHandler = (url) => {
    setRedirectUrl(url)
  }

  const redirectLinkConfirmHandler = () => {
    openLink(redirectUrl, 'blank')
    setRedirectUrl('')
  }

  const redirectLinkCloseHandler = () => {
    setRedirectUrl('')
  }
  return (
    <Fragment>
      <ul>
        <li>
          {`Copy of NRIC or FIN (Front and Back)`}
          <br />
          {`(For Foreign Delegates, please provide a copy of Green Card / Employment Pass / copy of Exemption Order.)`}
        </li>
        <li>
          {`Documentary proof of occupancy of premises`}
          <br />
          {`(e.g Tenancy Agreement or property tax)`}
        </li>
        <li>
          {`Copy of completed and signed Acknowledgement of Electrical Installation License Requirement Form `}
          {oaMode !== 'kiosk' ? (
            <a
              href="https://www.spgroup.com.sg/resources"
              target="_blank"
            >{`EIL Form`}</a>
          ) : (
            `EIL Form`
          )}
          ,{' '}
          {`if the electricity load is above 45 kVA. For more information on the license, please refer to `}
          {oaMode !== 'kiosk' ? (
            <a href="https://www.ema.gov.sg" target="_blank">
              www.ema.gov.sg
            </a>
          ) : (
            `www.ema.gov.sg`
          )}
        </li>
        <li>
          {`For temporary supply of electricity, the Quotation for Electricity Supply Connection and LEI issued by EMA are required.`}
        </li>
        <li>
          For temporary supply of water, a Water Quotation and acknowledgement
          for{' '}
          {oaMode !== 'kiosk' ? (
            <Link
              to="#"
              onClick={() => {
                redirectLinkClickHandler(
                  'https://form.gov.sg/65adcc577d4e4a0012d46f80'
                )
              }}
            >
              Declaration on Temporary Sanitary Facilities/Toilets
            </Link>
          ) : (
            'Declaration on Temporary Sanitary Facilities/Toilets'
          )}{' '}
          from PUB is required.
        </li>
      </ul>

      {redirectUrl && (
        <ConfirmationDialog
          confirmOkHandler={redirectLinkConfirmHandler}
          confirmCancelHandler={redirectLinkCloseHandler}
          onCloseHandler={redirectLinkCloseHandler}
          onEscapeKeyDownHandler={redirectLinkCloseHandler}
          content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
          actionConfig={{
            cancel: {
              label: 'Cancel',
            },
            ok: {
              label: 'Continue',
            },
          }}
        />
      )}
    </Fragment>
  )
}

const CommercialCompanyDocumentsReminder = () => {
  const { mode: oaMode } = Utils.getOAMode()
  const [redirectUrl, setRedirectUrl] = useState('')

  const redirectLinkClickHandler = (url) => {
    setRedirectUrl(url)
  }

  const redirectLinkConfirmHandler = () => {
    openLink(redirectUrl, 'blank')
    setRedirectUrl('')
  }

  const redirectLinkCloseHandler = () => {
    setRedirectUrl('')
  }
  return (
    <Fragment>
      <ul>
        <li>{`Signed and completed Utilities Application Form.`}</li>
        <li>Latest ACRA detailed Business Profile or Bizfile</li>
        <li>
          {`Letter of Authorisation`}
          <br />
          {`Notes: To be authorised by Director listed in the recent ACRA detailed Business Profile/Bizfile on the letterhead issued by company, indicating the company's representative's name and identification number i.e NRIC or FIN.`}
        </li>
        <li>
          {`Documentary proof of occupancy of premises`}
          <br />
          {`(e.g Tenancy Agreement or property tax)`}
        </li>
        <li>
          {`An initial security deposit is payable at the point of application (Please click on "security deposit" link for more information.)`}
        </li>
        <li>
          {`Copy of completed and signed Acknowledgement of Electrical Installation License Requirement Form `}
          {oaMode !== 'kiosk' ? (
            <a
              href="https://www.spgroup.com.sg/resources"
              target="_blank"
            >{`EIL Form`}</a>
          ) : (
            `EIL Form`
          )}
          ,{' '}
          {`if the electricity load is above 45 kVA. For more information on the license, please refer to `}
          {oaMode !== 'kiosk' ? (
            <a href="https://www.ema.gov.sg" target="_blank">
              www.ema.gov.sg
            </a>
          ) : (
            `www.ema.gov.sg`
          )}
        </li>
        <li>
          {`For temporary supply of electricity, the Quotation for Electricity Supply Connection and LEI issued by EMA are required.`}
        </li>
        <li>
          For temporary supply of water, a Water Quotation and acknowledgement
          for{' '}
          {oaMode !== 'kiosk' ? (
            <Link
              to="#"
              onClick={() => {
                redirectLinkClickHandler(
                  'https://form.gov.sg/65adcc577d4e4a0012d46f80'
                )
              }}
            >
              Declaration on Temporary Sanitary Facilities/Toilets
            </Link>
          ) : (
            'Declaration on Temporary Sanitary Facilities/Toilets'
          )}{' '}
          from PUB is required.
        </li>
      </ul>

      {redirectUrl && (
        <ConfirmationDialog
          confirmOkHandler={redirectLinkConfirmHandler}
          confirmCancelHandler={redirectLinkCloseHandler}
          onCloseHandler={redirectLinkCloseHandler}
          onEscapeKeyDownHandler={redirectLinkCloseHandler}
          content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
          actionConfig={{
            cancel: {
              label: 'Cancel',
            },
            ok: {
              label: 'Continue',
            },
          }}
        />
      )}
    </Fragment>
  )
}

const WaterborneTaxReminder = () => {
  return (
    <Fragment>
      <ul>
        <li>
          {`Waterborne Tax is charged based on the volume of water used.`}
        </li>
        <li>
          {`WBT goes towards meeting the cost of treating used water and maintaining the used water network.`}
        </li>
        <li>
          {`For details on your water charges, please refer to PUB website.`}
        </li>
      </ul>
    </Fragment>
  )
}

const RefuseCollectionChargesReminder = () => {
  return (
    <Fragment>
      <ul>
        <li>
          {`Collected on behalf of the designated vendors for your estate.`}
        </li>
        <li>
          {`Colex Environmental Pte Ltd, SembWaste Pte Ltd, Veolia ES Singapore Pte Ltd, 800 Super Waste Management Pte Ltd, TEE Environmental Pte Ltd and Alba W&H Smart City Pte. Ltd. are appointed for different geographical locations in Singapore.`}
        </li>
        <li>
          {`Charges vary depending on the type of business and the geographical location.`}
        </li>
      </ul>
    </Fragment>
  )
}

const SecurityDepositForResidentialReminder = () => {
  return (
    <Fragment>
      <Tabs.Container defaultActive={`sd-tab-1`}>
        <Tabs.Item id={`sd-tab-1`} label={`Singaporean/PR/Company`}>
          <Table.Container
            data={[
              {
                premises_type: 'HDB 1 or 2 rooms',
                giro: '$40',
                non_giro: '$60',
              },
              {
                premises_type: 'HDB 3, 4 or 5 rooms',
                giro: '$70',
                non_giro: '$100',
              },
              {
                premises_type: 'HDB Executive / HUDC Flat / Terrace House',
                giro: '$100',
                non_giro: '$150',
              },
              {
                premises_type:
                  'Condominum / Semi-Detached House / Private Apartment',
                giro: '$150',
                non_giro: '$250',
              },
              {
                premises_type: 'Bungalow / Penthouse / Townhouse',
                giro: '$250',
                non_giro: '$400',
              },
            ]}
          />
        </Tabs.Item>
        <Tabs.Item id={`sd-tab-2`} label={`Foreigner`}>
          <Table.Container
            data={[
              {
                premises_type: 'HDB 1 or 2 rooms',
                giro: '$80',
                non_giro: '$120',
              },
              {
                premises_type: 'HDB 3, 4 or 5 rooms',
                giro: '$140',
                non_giro: '$200',
              },
              {
                premises_type: 'HDB Executive / HUDC Flat / Terrace House',
                giro: '$200',
                non_giro: '$300',
              },
              {
                premises_type:
                  'Condominum / Semi-Detached House / Private Apartment',
                giro: '$300',
                non_giro: '$500',
              },
              {
                premises_type: 'Bungalow / Penthouse / Townhouse',
                giro: '$500',
                non_giro: '$800',
              },
            ]}
          />
        </Tabs.Item>
      </Tabs.Container>
      <p>
        {`Note: Your initial security deposit will be billed under non-Giro rate in your first bill. Upon successful Giro application, it will be revised and the refunded amount will be used to offset your subsequent bills.`}
      </p>
    </Fragment>
  )
}

const SecurityDepositForCommercialReminder = () => {
  const { mode: oaMode } = Utils.getOAMode()
  return (
    <Fragment>
      <Tabs.Container defaultActive={`sd-tab-1`}>
        <Tabs.Item id={`sd-tab-1`} label={`Permanent Supply`}>
          <Table.Container
            respHoriz
            data={[
              {
                use_of_premises: 'HDB Shop Houses',
                water: '$100',
                electricity: '$200',
                gas: '$400',
              },
              {
                use_of_premises: 'Public Lighting',
                water: 'According to SP PowerGrid Quotation',
                electricity: 'According to SP PowerGrid Quotation',
                gas: 'According to SP PowerGrid Quotation',
              },
              {
                use_of_premises: 'All others',
                water: '$3.50 per Cu M or $300 whichever is higher',
                electricity: 'Based on *kVA',
                gas: '$400',
              },
            ]}
          />
          <Table.Container
            data={[
              {
                use_of_premises: 'Stalls - Cooking',
                deposit_required: '$600',
              },
              {
                use_of_premises: 'Stalls - Non Cooking',
                deposit_required: '$200',
              },
              {
                use_of_premises: 'Multi Storey Carpark',
                deposit_required: '$500',
              },
              {
                use_of_premises: 'ATM/Translink/Info Teller',
                deposit_required: '$200',
              },
              {
                use_of_premises: 'Bin Compound',
                deposit_required: '$100',
              },
            ]}
          />
          <small>
            For other premises, initial security deposit is based on the supply
            capacity / electricity load (kVA). If you are not sure of the
            premises electricity load, please check with your licensed
            electrical worker (LEW).
          </small>
          <Table.Container
            data={[
              {
                '*_supply_capacity_(kVA)': 'Up to 15',
                deposit_required: '$450',
              },
              {
                '*_supply_capacity_(kVA)': '16 to 45',
                deposit_required: '$1,350',
              },
              {
                '*_supply_capacity_(kVA)': '46 to 75',
                deposit_required: '$2,100',
              },
              {
                '*_supply_capacity_(kVA)': '76 to 140',
                deposit_required: '$4,050',
              },
              {
                '*_supply_capacity_(kVA)': '141 to 180',
                deposit_required: '$5,100',
              },
              {
                '*_supply_capacity_(kVA)': '181 to 230',
                deposit_required: '$6,600',
              },
              {
                '*_supply_capacity_(kVA)': '231 to 280',
                deposit_required: '$7,950',
              },
              {
                '*_supply_capacity_(kVA)': '281 to 460',
                deposit_required: '$13,050',
              },
              {
                '*_supply_capacity_(kVA)': '461 to 560',
                deposit_required: '$15,900',
              },
              {
                '*_supply_capacity_(kVA)': '561 to 1,000',
                deposit_required: '$28,500',
              },
              {
                '*_supply_capacity_(kVA)': '1,001 and above',
                deposit_required: '$57,000',
              },
            ]}
          />
          <small>Initial Deposit based on Contracted Capacity (kW)</small>
          <Table.Container
            data={[
              {
                '*_supply_capacity_(kW)':
                  'High Tension & Extra High Tension (Temporary & Permanent Supply)',
                deposit_required: '$40 per kW',
              },
            ]}
          />
        </Tabs.Item>
        <Tabs.Item id={`sd-tab-2`} label={`Temporary Supply`}>
          <Table.Container
            respHoriz
            data={[
              {
                use_of_premises: 'Construction Site',
                water: '$3.50 per Cu M or $300 whichever is higher',
                electricity: 'Based on *kVA',
              },
            ]}
          />
          <Table.Container
            data={[
              {
                use_of_premises: 'Site Office',
                deposit_required: '$1,300',
              },
              {
                use_of_premises: 'Wayang',
                deposit_required: '$150 per day',
              },
              {
                use_of_premises: 'Gondola',
                deposit_required: '$200',
              },
            ]}
          />
        </Tabs.Item>
      </Tabs.Container>
      <p>
        If you would like to buy electricity from the Open Electricity Market
        (OEM) via a licensed electricity Retailer or at the wholesale
        electricity price from SP Group after you have opened an utilities
        account with us, your security deposit will be reviewed accordingly and
        reflected in your subsequent utilities bills. Please visit{' '}
        {oaMode !== 'kiosk' ? (
          <a href="https://www.openelectricitymarket.sg/home" target="_blank">
            here
          </a>
        ) : (
          `here`
        )}{' '}
        for more information.
      </p>
    </Fragment>
  )
}

const UseReminder = ({ type }) => {
  let defaultActive = type === 'residential' ? `ru-tab-1` : 'ru-tab-2'
  const listUserOfPremise = JSON.parse(sessionStorage.getItem('userOfPremise'))
  const listUserPremiseDomResi = Utils.getListUserOfPremise(
    listUserOfPremise,
    'Dom',
    '01'
  )
  const listUserPremiseDomCom = Utils.getListUserOfPremise(
    listUserOfPremise,
    'Dom',
    '02'
  )
  const listUserPremiseNonDomResi = Utils.getListUserOfPremise(
    listUserOfPremise,
    'Non-Dom',
    '01'
  )
  const listUserPremiseNonDomCom = Utils.getListUserOfPremise(
    listUserOfPremise,
    'Non-Dom',
    '02'
  )
  return (
    <Tabs.Container defaultActive={defaultActive}>
      <Tabs.Item id={`ru-tab-1`} label={`Domestic Use`}>
        <p style={{ margin: '1rem 0' }}>
          {`For premises where water supplied are used exclusively for residential purposes including staff housing.`}
        </p>
        {type === 'residential' ? (
          <ul>
            {listUserPremiseDomResi.map((item) => (
              <li key={item.value}> {item.text}</li>
            ))}
          </ul>
        ) : (
          <ul>
            {listUserPremiseDomCom.map((item) => (
              <li key={item.value}> {item.text}</li>
            ))}
          </ul>
        )}
      </Tabs.Item>
      <Tabs.Item id={`ru-tab-2`} label={`Non Domestic Use`}>
        <p style={{ margin: '1rem 0' }}>
          {`For premises which is used for the purposes of or in connection with any trade, business or profession.`}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {type === 'residential' ? (
            <ul
              style={{
                flexBasis: '50%',
                width: '50%',
                paddingRight: '1rem',
                wordBreak: 'break-word',
              }}
            >
              {listUserPremiseNonDomResi.map((item) => (
                <li key={item.value}> {item.text}</li>
              ))}
            </ul>
          ) : (
            <>
              <ul
                style={{
                  flexBasis: '50%',
                  width: '50%',
                  paddingRight: '1rem',
                  wordBreak: 'break-word',
                }}
              >
                {listUserPremiseNonDomCom.map((item) => (
                  <li key={item.value}> {item.text}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Tabs.Item>
    </Tabs.Container>
  )
}

UseReminder.defaultProps = {
  type: 'residential',
}

const reminders = {
  residential_security_deposit: {
    title: 'Security Deposit',
    component: <SecurityDepositForResidentialReminder />,
  },
  commercial_security_deposit: {
    title:
      'Security Deposit <h5>This serves as a guide only. For other types of premises such as public lighting and ATM machines, please consult our Customer Service Officers for your specific requirements.</h5>',
    component: <SecurityDepositForCommercialReminder />,
  },
  refuse_collection_charges: {
    title: 'Refuse Collection Charges by Refuse Collection Vendor',
    component: <RefuseCollectionChargesReminder />,
  },
  waterborne_tax: {
    title: 'Waterborne Tax by PUB',
    component: <WaterborneTaxReminder />,
  },
  company_documents: {
    title: 'Documents required for account opening under company name',
    component: <CompanyDocumentsReminder />,
  },
  residential_use: {
    title: 'Use of Premises',
    component: <UseReminder type="residential" />,
  },

  commercial_use: {
    title: 'Use of Premises',
    component: <UseReminder type="commercial" />,
  },
  commercial_personal_documents: {
    title: 'Documents required for account opening under personal',
    component: <CommercialPersonalDocumentsReminder />,
  },
  commercial_company_documents: {
    title: 'Documents required for account opening under company',
    component: <CommercialCompanyDocumentsReminder />,
  },
}

export default OpenAccount

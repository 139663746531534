import React, { PureComponent, Fragment } from 'react'
import { Menu as MenuComp } from 'spd-oa/components/common'

export default class GridTooltip extends PureComponent {
  state = {
    menuOpen: false,
  }
  _toggleMenu = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: !prevState.menuOpen,
    }))
  }
  _optionCloseHandler = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: false,
    }))
  }

  _renderHandlers() {
    const { handlers, data } = this.props
    return handlers.map((item, itemIndex) => {
      return (
        <MenuComp.MenuItem
          key={`action-option_item_${itemIndex}`}
          to={``}
          method={`item.method`}
          type={'button'}
          bare={true}
          onClickHandler={() => {
            if (item.handler) {
              item.handler(data)
            }
          }}
        >
          <span>{`${item.label}`}</span>
        </MenuComp.MenuItem>
      )
    })
  }

  render() {
    const { menuOpen } = this.state
    const { icon } = this.props
    return (
      <Fragment>
        <button onClick={this._toggleMenu} ref={(el) => (this._button = el)}>
          {icon}
        </button>
        {menuOpen && (
          <MenuComp.Menu
            className="lm--alert contact"
            show={true}
            onCloseHandler={this._optionCloseHandler}
            onEscapeKeyDownHandler={this._optionCloseHandler}
            targetNode={this._button}
            offset={{
              x: 0,
              y: -20,
            }}
            arrow={false}
            disabled={true}
          >
            <MenuComp.MenuGroup>{this._renderHandlers()}</MenuComp.MenuGroup>
          </MenuComp.Menu>
        )}
      </Fragment>
    )
  }
}

import React from 'react'

function FormErrorBlock({ id, error }) {
  return error ? (
    <div id={id} className="error-block">
      {error}
    </div>
  ) : null
}

export default FormErrorBlock

/**
 * @typedef {{
 * postal_code: string,
 * block: string,
 * floor: string,
 * unit: string,
 * street: string,
 * country: string,
 * premise_no: string }} Address
 */

/**
 * @typedef { uen: string, company_name: string } CompanyInfo
 */

/**
 * @typedef {{
 * representative_name: string,
 * representative_nric_fin: string,
 * representative_designation: string,
 * representative_mobile_prefix: string,
 * representative_mobile_number: string,
 * representative_contact_prefix: string,
 * representative_contact_number: string,
 * representative_email: string,
 * representative_alternate_email: string }} Representative
 */

/**
 * @typedef {{ turn_on_datetime: Date }} PreferredDate
 */

/**
 * @typedef {{ services: string[] }} TurnOnServices
 */

/**
 * @typedef {{
 * application_number: string,
 * application_type: string,
 * file_id: string,
 * signed_at: Date,
 * payload: {
 *    premise_address: Address,
 *    mailing_address: Address,
 *    occupancy: string,
 *    use_of_premises: string,
 *    company_information: CompanyInfo,
 *    representative: Representative,
 *    preferred_date: PreferredDate,
 *    turn_on_services: TurnOnServices,
 *    account_holder_type: string,
 *    preTurn_on_flag: boolean,
 *  }
 * }} OAPDFPayload
 * */

import eSignatureGAServices from 'spd-oa/services/eSignature/eSignatureGAServices'
import {
  dataURItoBlob,
  preparePdfPayload,
} from 'spd-oa/services/eSignature/helpers'

const SIGNATURE_TYPE = Object.freeze({
  open_account_esig: 'OPEN_ACCOUNT_ESIG',
  open_account_esig_form: 'OPEN_ACCOUNT_ESIG_FORM',
  close_account_esig: 'CLOSE_ACCOUNT_ESIG',
  close_account_esig_form: 'CLOSE_ACCOUNT_ESIG_FORM',
})

const APP_TYPE = Object.freeze({
  open_account: 'open_account',
  close_account: 'close_account',
})

/**
 *
 * @param request {function({})}
 * @return {{uploadSignature: function}}
 */
export function eSignatureServices(request) {
  const BASE_ENDPOINT = `${process.env.REACT_APP_API_BASE}/v1/eSignature`
  const GA = eSignatureGAServices(request)

  /**
   *
   * @param signature {string} base64 string
   * @return {Promise<{fileId: string}>}
   * */
  function uploadSignature(signature) {
    const formData = new FormData()
    Object.entries({
      fileName: new File(
        [dataURItoBlob(signature)],
        `oa_esig_${new Date().getTime()}.png`
      ),
      type: SIGNATURE_TYPE.open_account_esig,
    }).forEach(([key, value]) => formData.append(key, value))

    return GA.promise(
      {
        method: 'POST',
        url: `${BASE_ENDPOINT}/upload`,
        data: formData,
      },
      GA.EVENT_ACTION.uploadEsig
    )
  }

  /**
   * @param payload {OAPDFPayload}
   * @return {Promise<{fileId: string}>}
   * */
  function generatePdfForm(payload) {
    return GA.promise(
      {
        method: 'POST',
        url: `${BASE_ENDPOINT}/generateOpenAccountPDF`,
        data: { ...payload, type: APP_TYPE.open_account },
      },
      GA.EVENT_ACTION.generatePdf
    )
  }

  /**
   *
   * @param contextData {{}}
   * @return {Promise<{fileId: string}> | undefined}
   */
  function attachSignatureToPdfForm(contextData) {
    return generatePdfForm(preparePdfPayload(contextData))
  }

  return {
    uploadSignature,
    attachSignatureToPdfForm,
  }
}

import React, { PureComponent, Fragment } from 'react'
import { Icons, Menu as MenuComp } from 'spd-oa/components/common'

export default class Options extends PureComponent {
  state = {
    menuOpen: false,
  }
  _toggleMenu = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: !prevState.menuOpen,
    }))
  }
  _optionCloseHandler = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: false,
    }))
  }

  _renderHandlers() {
    const { handlers, data, contextActions } = this.props
    return handlers.map((item, itemIndex) => {
      return (
        <MenuComp.MenuItem
          key={`action-option_item_${itemIndex}`}
          to={``}
          method={`${item.method}`}
          type={'button'}
          bare={true}
          confirm={item.confirm}
          confirmTitle={item.confirmTitle}
          confirmContent={item.confirmContent}
          onClickHandler={() => {
            if (item.handler) {
              item.handler(data, contextActions)
            }
          }}
        >
          <span>{`${item.label}`}</span>
        </MenuComp.MenuItem>
      )
    })
  }

  render() {
    const { menuOpen } = this.state
    return (
      <Fragment>
        <button onClick={this._toggleMenu} ref={(el) => (this._button = el)}>
          <Icons.options width={3} height={12} />
        </button>
        {menuOpen && (
          <MenuComp.Menu
            className="listaction-option"
            show={true}
            onCloseHandler={this._optionCloseHandler}
            onEscapeKeyDownHandler={this._optionCloseHandler}
            targetNode={this._button}
            offset={{
              x: -10,
            }}
            arrow={false}
          >
            <MenuComp.MenuGroup>
              {this._renderHandlers()}
              {/* <MenuComp.MenuItem
                  key={`action-option_item`}
                  to={``}
                  method={`item.method`}
                  type={'button'}
                  onClickHandler={() => { }}
                >
                  <span>{`View PDF`}</span>
                </MenuComp.MenuItem> */}
            </MenuComp.MenuGroup>
          </MenuComp.Menu>
        )}
      </Fragment>
    )
  }
}

Options.defaultProps = {
  contextActions: {},
}

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './choices.scss'

const Choices = ({
  id,
  classNames,
  children,
  defaultSelected,
  onChangeHandler,
  multiple,
  lengthwise,
  style,
}) => {
  const [chosen, setChosen] = useState(defaultSelected)

  const _Choices = classnames('cui-choices', classNames, {
    'is-lengthwise': lengthwise,
    'is-crosswise': !lengthwise,
  })

  const isByDefaultSelected = (id) => {
    return defaultSelected.some((itm) => itm === id)
  }

  const handleClick = (payload) => {
    let _chosen = [...chosen]
    if (!_chosen.some((c) => c === payload.id)) {
      if (multiple) {
        _chosen.push(payload.id)
      } else {
        _chosen = [payload.id]
      }
    } else {
      // Clicks the same Choice ID
      if (multiple) {
        const idx = _chosen.indexOf(payload.id)
        if (idx > -1) {
          _chosen.splice(idx, 1)
        }
      }
    }
    setChosen(_chosen)
    onChangeHandler({ id, chosen: _chosen })
  }

  const renderChildren = () => {
    if (!children) return null
    return React.Children.map(children, (child, childIdx) => {
      return React.cloneElement(child, {
        ...child.props,
        onClickHandler: handleClick,
        active: chosen.some((c) => c === child.props.id),
      })
    })
  }

  useEffect(() => {
    setChosen(defaultSelected)
  }, [defaultSelected])

  return (
    <div id={id} className={_Choices} style={style}>
      {renderChildren()}
    </div>
  )
}

Choices.propTypes = {
  id: PropTypes.string,
  children: (props, propName, componentName) => {
    const prop = props[propName]
    let error = null
    React.Children.forEach(prop, (child) => {
      if (child.type.name !== 'Choice') {
        error = new Error(
          `${componentName} children should be of type 'Choice'`
        )
      }
    })
    return error
  },
  defaultSelected: PropTypes.arrayOf(PropTypes.string),
  onChangeHandler: PropTypes.func,
  classNames: PropTypes.string,
  lengthwise: PropTypes.bool,
  style: PropTypes.object,
}

Choices.defaultProps = {
  defaultSelected: [''],
  multiple: false,
  classNames: '',
  lengthwise: false,
  style: {},
  onChangeHandler: () => {},
}

export default Choices

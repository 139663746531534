import React, { PureComponent, Fragment } from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { object, string, boolean } from 'yup'
import { FormGroup, FormCTA, FormUpload } from 'spd-oa/components/FormElement'
import {
  Button,
  Tooltip,
  Detail,
  ConfirmationDialog,
} from 'spd-oa/components/common'
import { isEmpty, openLink, getElectCapacityRangeIdx } from 'spd-oa/helpers'
import Utils from 'spd-oa/utils'
import KioskUpload from 'spd-oa/components/KioskUpload/KioskUpload'
import ApplicationFormField from 'spd-oa/pages/wiz/UploadDocs/ApplicationFormField'
import {
  kioskConfig,
  handleNext,
} from 'spd-oa/components/KioskUpload/kioskUploadHelpers'

const SESSION_STATES_KEY = kioskConfig.SESSION_STATES_KEY
const UPLOAD_STATUS = kioskConfig.UPLOAD_STATUS

const schema = object().shape({
  ...ApplicationFormField.validationSchema,
  bizfile: string()
    .required()
    .label('Business or Company Registration Certificate'),

  loa: string(),

  // isGstCertRequired: boolean(),
  // gst_reg_cert: string().when('isGstCertRequired', {
  //   is: true,
  //   then: string()
  //     .required()
  //     .label('GST Registration Certification'),
  //   otherwise: string().notRequired(),
  // }),

  meter_readings: boolean(),
  electric_meter_reading: string(),
  water_meter_reading: string(),
  gas_meter_reading: string(),

  isPooopRequired: boolean(),
  isEilRequired: boolean(),
  isSpPwrGridQuotRequired: boolean(),
  isPubWatQuotRequired: boolean(),
  isLoawrdRequired: boolean(),

  npop: string().when('nanl', {
    is: true,
    then: string()
      .required()
      .label('Proof of Premises'),
    otherwise: string().notRequired(),
  }),

  nasd: string(),

  pooop: string().when('isPooopRequired', {
    is: true,
    then: string()
      .required()
      .label('Proof of occupancy of premises document'),
    otherwise: string().notRequired(),
  }),

  eil: string().when('isEilRequired', {
    is: true,
    then: string()
      .required()
      .label('Signed Electrical Installation License Form'),
    otherwise: string().notRequired(),
  }),

  sp_pwrgrd_quot: string().when('isSpPwrGridQuotRequired', {
    is: true,
    then: string()
      .required()
      .label('SP PowerGrid Quotation document'),
    otherwise: string().notRequired(),
  }),

  pub_wat_quot: string().when('isPubWatQuotRequired', {
    is: true,
    then: string()
      .required()
      .label('PUB Water Quotation'),
    otherwise: string().notRequired(),
  }),

  loawrd: string().when('isLoawrdRequired', {
    is: true,
    then: string()
      .required()
      .label('Letter of Award'),
    otherwise: string().notRequired(),
  }),

  // check_i_confirm: boolean()
  //   .required()
  //   .oneOf([true], 'Must agree to the Terms and Conditions')
  // check_i_ack: boolean().required()
})

class CommCompUploadDocs extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      docs: {
        application_form: '',
        bizfile: '',
        loa: '',
        // gst_reg_cert: '',
        meter_readings: false,
        electric_meter_reading: '',
        water_meter_reading: '',
        gas_meter_reading: '',

        isPooopRequired: false,
        isEilRequired: false,
        isSpPwrGridQuotRequired: false,
        isPubWatQuotRequired: false,

        pooop: '',
        eil: '',
        sp_pwrgrd_quot: '',
        pub_wat_quot: '',
        loawrd: '',
      },
      // check_i_confirm: false,
      // check_i_ack: false
    })
    context.actions.setFields(data, false)
    this.state = {
      data,
      showTerms: false,
      submitting: false,
      confirmExtLink: false,
      extLink: {
        path: '',
        target: '',
      },
      requiredDocs: [],
    }
  }

  _termsOkHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: false,
    }))
  }

  _termsShowHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: true,
    }))
  }

  componentDidMount() {
    const currentPage = 'upload'
    Utils.getAttachments(
      this.props.context.data,
      this.props.context.config,
      this._form,
      this._preSave,
      currentPage
    ).then(() =>
      this.setState({
        requiredDocs: Utils.getRequiredDocs(
          {
            ...this.props.context.data,
            ...this.props.context.config,
            currentPage,
            isEilRequired: this._form && this._form.state.values.isEilRequired,
          },
          this._form.state.values
        ),
      })
    )
    if (this._form) {
      this._form.validateForm(this._form.state.values)
    }
  }

  render() {
    const { context } = this.props
    const { data: contextData, stateId } = context
    const { data, submitting, requiredDocs, confirmExtLink } = this.state

    const isPooopRequired = this._checkForRequiredDocs('pooop')
    const isEilRequired = this._checkForRequiredDocs('eil')
    const isSpPwrGridQuotRequired = this._checkForRequiredDocs('sp_pwrgrd_quot')
    const isPubWatQuotRequired = this._checkForRequiredDocs('pub_wat_quot')
    const isLoawrdRequired = this._checkForRequiredDocs('loawrd')

    return (
      <div className={`signup--upload signup--form`}>
        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          onSubmit={this._handleSubmit}
          isInitialValid={true}
          initialValues={{
            ...data.docs,
            check_i_ack: data.check_i_ack,
            isGstCertRequired: contextData.check_eeg,
            signature_file_id: contextData.signature_file_id,
            nanl: contextData.premises.nanl,
            isPooopRequired,
            isEilRequired,
            isSpPwrGridQuotRequired,
            isPubWatQuotRequired,
            isLoawrdRequired,
          }}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            handleChange,
            isValid,
          }) => {
            return (
              <form
                className="form-container"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                onSubmit={handleSubmit}
              >
                <h3>{`Upload Documents`}</h3>
                <p>
                  {Utils.getOAMode().mode !== 'kiosk'
                    ? `Now that you have reviewed and completed your application printout with signature and company stamp, you are ready to upload your documents.`
                    : `Now that you have reviewed and completed your application with signature, you are ready to upload your documents.`}
                </p>
                <small>
                  {`For faster processing, please keep each file size below 1MB.`}
                </small>

                {Utils.getOAMode().mode !== 'kiosk' ? (
                  <>
                    <ApplicationFormField transactionId={stateId} />
                    <FormGroup
                      groupTitle={`Business or Company Registration Certificate`}
                      groupHeaderContent={
                        <FormUpload.Doc
                          id="bizfile"
                          setFieldValue={setFieldValue}
                          value={
                            values['bizfile'] !== '' && [values['bizfile']]
                          }
                          upload={true}
                          params={{
                            transaction_id: stateId,
                            type: 'c_acra_manual',
                          }}
                          useFilename={true}
                          max={4000000}
                          errors={errors}
                          onlyElem={true}
                        />
                      }
                    />

                    {contextData.turnOnServices.type_of_usage ===
                      'permanent' && (
                      <FormGroup
                        groupTitle={
                          <>
                            Documentary Proof of Occupancy of Premises
                            <Tooltip>
                              <span>
                                e.g. Latest Property Tax or Tenancy Agreement
                              </span>
                            </Tooltip>
                          </>
                        }
                        groupHeaderContent={
                          <FormUpload.Doc
                            max={4000000}
                            id="pooop"
                            setFieldValue={setFieldValue}
                            value={
                              values['pooop'] && values['pooop'] !== ''
                                ? [values['pooop']]
                                : []
                            }
                            params={{
                              transaction_id: stateId,
                              type: 'proof_of_occupancy_of_premises',
                            }}
                            errors={errors}
                            upload
                            useFilename
                            onlyElem
                          />
                        }
                      />
                    )}

                    {contextData.premises.nanl && (
                      <FormGroup
                        groupTitle={
                          <Fragment>
                            {`Proof of Premises`}
                            <Tooltip>
                              <span>
                                {`e.g. Certificate of Numbering
                            from IRAS, Certificate of Factory Registration
                            from MOM`}
                              </span>
                            </Tooltip>
                          </Fragment>
                        }
                        groupHeaderContent={
                          <FormUpload.Doc
                            max={4000000}
                            id="npop"
                            label={null}
                            setFieldValue={setFieldValue}
                            value={
                              values['npop'] && values['npop'] !== ''
                                ? [values['npop']]
                                : []
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'nanl_proof_of_premise',
                            }}
                            useFilename={true}
                            errors={errors}
                          />
                        }
                      />
                    )}

                    {contextData.turnOnServices.type_of_usage === 'permanent' &&
                      contextData.turnOnServices.services &&
                      contextData.turnOnServices.services.indexOf(
                        'electricity'
                      ) !== -1 && (
                        <FormGroup
                          groupTitle={
                            <>
                              <div>
                                Signed Electrical Installation License Form
                                (EIL){' '}
                                {!values['isEilRequired'] && ' (Optional)'}
                                <br />
                                <small
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                  Download from{' '}
                                  <Button
                                    link
                                    onClickHandler={this._linkHandler(
                                      'https://www.spgroup.com.sg/resources',
                                      '_blank',
                                      false
                                    )}
                                  >
                                    HERE
                                  </Button>
                                </small>
                              </div>
                            </>
                          }
                          groupHeaderContent={
                            <FormUpload.Doc
                              max={4000000}
                              id="eil"
                              setFieldValue={setFieldValue}
                              value={
                                values['eil'] && values['eil'] !== ''
                                  ? [values['eil']]
                                  : []
                              }
                              params={{
                                transaction_id: stateId,
                                type: 'electrical_installation_license_form',
                              }}
                              errors={errors}
                              upload
                              useFilename
                              onlyElem
                            />
                          }
                        />
                      )}

                    {contextData.premises.nanl && (
                      <FormGroup
                        groupTitle={
                          <Fragment>
                            {`Additional Supporting Document (Optional)`}
                            <Tooltip>
                              <span>
                                {`Any other supporting documents for this premises`}
                              </span>
                            </Tooltip>
                          </Fragment>
                        }
                        groupHeaderContent={
                          <FormUpload.Doc
                            max={4000000}
                            id="nasd"
                            label={null}
                            setFieldValue={setFieldValue}
                            value={
                              values['nasd'] && values['nasd'] !== ''
                                ? [values['nasd']]
                                : []
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'nanl_additional_supporting_documents',
                            }}
                            useFilename={true}
                            errors={errors}
                          />
                        }
                      />
                    )}
                    {contextData.turnOnServices.type_of_usage ===
                      'temporary' && (
                      <>
                        <FormGroup
                          groupTitle={`Letter of Award`}
                          groupHeaderContent={
                            <FormUpload.Doc
                              max={4000000}
                              id="loawrd"
                              setFieldValue={setFieldValue}
                              value={
                                values['loawrd'] && values['loawrd'] !== ''
                                  ? [values['loawrd']]
                                  : []
                              }
                              params={{
                                transaction_id: stateId,
                                type: 'letter_of_award',
                              }}
                              errors={errors}
                              upload
                              useFilename
                              onlyElem
                            />
                          }
                        />
                        {contextData.turnOnServices.services &&
                          contextData.turnOnServices.services.length > 0 && (
                            <>
                              {contextData.turnOnServices.services.indexOf(
                                'electricity'
                              ) !== -1 && (
                                <FormGroup
                                  groupTitle={`SP PowerGrid Quotation (for temporary electricity supply)`}
                                  groupHeaderContent={
                                    <FormUpload.Doc
                                      max={4000000}
                                      id="sp_pwrgrd_quot"
                                      setFieldValue={setFieldValue}
                                      value={
                                        values['sp_pwrgrd_quot'] &&
                                        values['sp_pwrgrd_quot'] !== ''
                                          ? [values['sp_pwrgrd_quot']]
                                          : []
                                      }
                                      params={{
                                        transaction_id: stateId,
                                        type: 'sp_powergrid_quotation',
                                      }}
                                      errors={errors}
                                      upload
                                      useFilename
                                      onlyElem
                                    />
                                  }
                                />
                              )}
                              {contextData.turnOnServices.services.indexOf(
                                'water'
                              ) !== -1 && (
                                <FormGroup
                                  groupTitle={
                                    <Fragment>
                                      <div>
                                        Water Quotation and Acknowledgement for
                                        Declaration on Temporary Sanitary
                                        Facilities/Toilets from PUB (for
                                        temporary water supply)
                                      </div>
                                      <Tooltip>
                                        <span>
                                          Email acknowledgement from PUB for the
                                          Declaration on Temporary Sanitary
                                          Facilities/Toilets. Refer to PUB{' '}
                                          <Link
                                            to="#"
                                            onClick={this._linkHandler(
                                              'https://form.gov.sg/65adcc577d4e4a0012d46f80',
                                              '_blank'
                                            )}
                                          >
                                            website
                                          </Link>{' '}
                                          for more information.
                                        </span>
                                      </Tooltip>
                                    </Fragment>
                                  }
                                  groupHeaderContent={
                                    <FormUpload.Doc
                                      max={4000000}
                                      id="pub_wat_quot"
                                      setFieldValue={setFieldValue}
                                      value={
                                        values['pub_wat_quot'] &&
                                        values['pub_wat_quot'] !== ''
                                          ? [values['pub_wat_quot']]
                                          : []
                                      }
                                      params={{
                                        transaction_id: stateId,
                                        type: 'pub_water_quotation',
                                      }}
                                      errors={errors}
                                      upload
                                      useFilename
                                      onlyElem
                                    />
                                  }
                                />
                              )}
                            </>
                          )}
                      </>
                    )}

                    {/* {contextData.check_eeg && (
                      <FormGroup
                        groupTitle={`GST Reg Certification`}
                        groupHeaderContent={
                          <FormUpload.Doc
                            id="gst_reg_cert"
                            setFieldValue={setFieldValue}
                            value={
                              values['gst_reg_cert'] !== '' && [
                                values['gst_reg_cert'],
                              ]
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'gst_registration_certification',
                            }}
                            useFilename={true}
                            max={4000000}
                            errors={errors}
                            onlyElem={true}
                          />
                        }
                      />
                    )} */}

                    <FormGroup
                      groupTitle={
                        <Fragment>
                          <div>Letter of Authorisation (Optional)</div>
                          <Tooltip>
                            <span>
                              The letter is to be endorsed by your Director
                              (listed in the recent ACRA documents/Bizfile) and
                              printed on your company’s letterhead, indicating
                              your full name, designation and NRIC/FIN number.
                            </span>
                          </Tooltip>
                        </Fragment>
                      }
                      groupHeaderContent={
                        <Fragment>
                          <FormUpload.Doc
                            id="loa"
                            setFieldValue={setFieldValue}
                            value={values['loa'] !== '' && [values['loa']]}
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'c_letter_of_auth',
                            }}
                            useFilename={true}
                            errors={errors}
                            max={4000000}
                            onlyElem={true}
                          />
                        </Fragment>
                      }
                    />
                  </>
                ) : (
                  requiredDocs.length > 0 && (
                    <Detail.DetailNode>
                      <KioskUpload
                        stateId={stateId}
                        requiredDocs={requiredDocs}
                        preSave={this._preSave}
                        setFormValue={setFieldValue}
                      />
                    </Detail.DetailNode>
                  )
                )}

                <FormCTA>
                  <Button
                    variant="secondary"
                    className="lm--switch u-ph0"
                    onClickHandler={this._handleBackBtn}
                    type="button"
                    disabled={submitting}
                  >
                    {`Back`}
                  </Button>

                  <Button
                    variant="primary"
                    className="lm--switch u-ph0"
                    type="submit"
                    disabled={
                      !isValid ||
                      !Utils.isMeterReadingsOk(
                        values['meter_readings'],
                        contextData.turnOnServices.services,
                        values
                      ) ||
                      !isEmpty(errors) ||
                      submitting
                    }
                    loading={submitting}
                  >
                    {`Proceed to Payment`}
                  </Button>
                </FormCTA>
              </form>
            )
          }}
        />

        {confirmExtLink && (
          <ConfirmationDialog
            confirmOkHandler={this._linkConfirmHandler}
            confirmCancelHandler={this._linkConfirmationCloseHandler}
            onCloseHandler={this._linkConfirmationCloseHandler}
            onEscapeKeyDownHandler={this._linkConfirmationCloseHandler}
            content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
            actionConfig={{
              cancel: {
                label: 'Cancel',
              },
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}
      </div>
    )
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (Utils.getOAMode().mode === 'kiosk') {
      sessionStorage.removeItem(SESSION_STATES_KEY.uploadStatus)
    } else {
      if (this._form.state && this._form.state.values) {
        this._preSave(this._form.state.values)
      }
    }
    actions.prev()
  }

  _handleSubmit = (values) => {
    const { context } = this.props
    const {
      actions,
      data: contextData,
      config: contextConfig,
      stateId,
    } = context
    const uploadStatus = sessionStorage.getItem(
      kioskConfig.SESSION_STATES_KEY.uploadStatus
    )
    this.setState((prevState) => ({
      ...prevState,
      submitting: true,
    }))
    if (actions.next) {
      const actionNext = () => {
        actions.next(
          values,
          () => {
            this.setState((prevState) => ({
              ...prevState,
              submitting: false,
            }))
          },
          {
            contact: {
              email: `${contextData.representative.representative_email}`,
              number: `${contextData.representative.representative_mobile_number}`,
            },
            application: {
              transaction_id: stateId,
              domain: contextConfig.application_domain,
              type: contextConfig.application_type,
              occupancy: contextData.premises.premise_occupancy,
            },
          }
        )
      }
      if (
        Utils.getOAMode().mode === 'kiosk' &&
        uploadStatus !== UPLOAD_STATUS.DONE
      ) {
        handleNext().then(() => {
          sessionStorage.setItem(
            SESSION_STATES_KEY.uploadStatus,
            UPLOAD_STATUS.DONE
          )
          actionNext()
        })
      } else {
        actionNext()
      }
    }

    this._preSave(values)
  }

  _linkHandler = (path, target, external = true) => () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmExtLink: external,
        extLink: {
          path,
          target,
        },
      }),
      () => {
        if (!external) {
          this._linkConfirmHandler()
        }
      }
    )
  }

  _linkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmExtLink: false,
      extLink: {
        path: '',
        target: '',
      },
    }))
  }

  _linkConfirmHandler = () => {
    const { extLink } = this.state
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmExtLink: false,
        extLink: {
          path: '',
          target: '',
        },
      }),
      () => {
        openLink(extLink.path, extLink.target)
      }
    )
  }

  _preSave = (values) => {
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {
        docs: {
          pooop: values.pooop || '',
          npop: values.npop || '',
          nasd: values.nasd || '',
          application_form: values.application_form || '',
          bizfile: values.bizfile || '',
          eil: values.eil || '',
          loa: values.loa || '',
          meter_readings: values.meter_readings || false,
          electric_meter_reading: values.electric_meter_reading || '',
          water_meter_reading: values.water_meter_reading || '',
          gas_meter_reading: values.gas_meter_reading || '',
          isPooopRequired: values.isPooopRequired || false,
          isEilRequired: values.isEilRequired || false,
          isSpPwrGridQuotRequired: values.isSpPwrGridQuotRequired || false,
          isPubWatQuotRequired: values.isPubWatQuotRequired || false,
          isLoawrdRequired: values.isLoawrdRequired || false,
          sp_pwrgrd_quot: values.sp_pwrgrd_quot || '',
          pub_wat_quot: values.pub_wat_quot || '',
          loawrd: values.loawrd || '',
        },
      }
      actions.presave(payload)
    }
  }

  _checkForRequiredDocs = (doc = '') => {
    if (doc === '') return false

    const { context } = this.props
    const { data: contextData, config: contextConfig } = context

    let formProps
    if (doc === 'eil') {
      if (contextData.security_deposit_calculation) {
        if (contextData.security_deposit_calculation['ELECT_CAPACITY']) {
          formProps = {
            values: {
              ELECT_CAPACITY:
                contextData.security_deposit_calculation['ELECT_CAPACITY'],
            },
          }
        }
      }
    }

    if (contextConfig.application_domain === 'commercial') {
      if (doc === 'pooop') {
        if (contextData.turnOnServices.type_of_usage === 'permanent') {
          return true
        }
      } else if (doc === 'eil') {
        if (formProps) {
          if (
            contextData.turnOnServices &&
            contextData.turnOnServices.services &&
            contextData.turnOnServices.services.indexOf('electricity') !== -1 &&
            contextData.turnOnServices.type_of_usage === 'permanent'
          ) {
            const eCRangeIdx = getElectCapacityRangeIdx({
              ELECT_CAPACITY: formProps.values.ELECT_CAPACITY,
            })
            const isEilRequired = eCRangeIdx && eCRangeIdx > 2
            if (!isEilRequired) {
              if (this._form) {
                Utils.deleteAttachments(
                  ['electrical_installation_license_form'],
                  this._form
                )
              }
            }
            return isEilRequired
          }
        }
      } else {
        if (
          contextData.turnOnServices &&
          contextData.turnOnServices.type_of_usage === 'temporary'
        ) {
          if (doc === 'loawrd') {
            return true
          }
          if (
            contextData.turnOnServices.services &&
            contextData.turnOnServices.services.length > 0
          ) {
            if (doc === 'sp_pwrgrd_quot') {
              return (
                contextData.turnOnServices.services.indexOf('electricity') !==
                -1
              )
            } else if (doc === 'pub_wat_quot') {
              return contextData.turnOnServices.services.indexOf('water') !== -1
            }
          }
        }
      }
    }
    return false
  }
}

export default CommCompUploadDocs

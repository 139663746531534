import React, { Fragment } from 'react'
import Utils from 'spd-oa/utils'
import { parseDate } from 'spd-oa/helpers'

const fields = {
  residential: {
    /**
     * RESIDENTIAL UNDER PERSONAL
     */
    personal: {
      premises: {
        label: 'Your Premises Information',
        fields: [
          {
            label: 'Premises Address',
            parent: 'premise_address',
            field: [
              'premise_block',
              'premise_street_name',
              'premise_unit',
              'premise_floor',
              'premise_country',
              'premise_postal_code',
              'premise_description',
            ],
            resolve: (values) => {
              if (values.premise_description) {
                return values.premise_description.toUpperCase()
              }
              return (
                <Fragment>
                  <span>{`${values.premise_block} ${values.premise_street_name}`}</span>
                  {values.premise_floor &&
                    values.premise_floor !== '' &&
                    values.premise_unit &&
                    values.premise_unit !== '' && (
                      <span>{`#${values.premise_floor}-${values.premise_unit}`}</span>
                    )}
                  {values.premise_floor &&
                    values.premise_floor !== '' &&
                    (!values.premise_unit || values.premise_unit === '') && (
                      <span>{`#${values.premise_floor}`}</span>
                    )}
                  {values.premise_unit &&
                    values.premise_unit !== '' &&
                    (!values.premise_floor || values.premise_floor === '') && (
                      <span>{`${values.premise_unit}`}</span>
                    )}
                  <span>{`Singapore ${values.premise_postal_code}`}</span>
                </Fragment>
              )
            },
          },
          {
            label: 'Mailing Address',
            parent: 'mail_address',
            field: [
              'check_same_address',
              'mail_block',
              'mail_unit',
              'mail_floor',
              'mail_street_name',
              'mail_country',
              'mail_postal_code',
            ],
            resolve: (values) => {
              if (values.check_same_address) {
                return `Same as premises address`
              }
              return (
                <Fragment>
                  <span>{`${values.mail_block} ${values.mail_street_name}`}</span>
                  {values.mail_floor &&
                    values.mail_floor !== '' &&
                    values.mail_unit &&
                    values.mail_unit !== '' && (
                      <span>{`#${values.mail_floor}-${values.mail_unit}`}</span>
                    )}
                  {values.mail_floor &&
                    values.mail_floor !== '' &&
                    (!values.mail_unit || values.mail_unit === '') && (
                      <span>{`#${values.mail_floor}`}</span>
                    )}
                  {values.mail_unit &&
                    values.mail_unit !== '' &&
                    (!values.mail_floor || values.mail_floor === '') && (
                      <span>{`${values.mail_unit}`}</span>
                    )}
                  <span>{`Singapore ${values.mail_postal_code}`}</span>
                </Fragment>
              )
            },
          },
          {
            label: 'Type of Occupancy',
            field: 'premise_occupancy',
            resolve: (data) => {
              return Utils.capitalize(data)
            },
          },
          {
            label: 'Use of Premises',
            field: 'use_of_premises',
            resolve: (data) => {
              return Utils.getItemFromUserOfPremise(data, '01')
            },
          },
          {
            label: 'Embedded Electricity Generator',
            parent: 'eeg',
            field: 'check_eeg',
            resolve: (eeg) => {
              return `Applicable`
            },
          },
          {
            label: 'GST Information',
            parent: 'eeg',
            field: 'gst_reg_no',
          },
          {
            label: 'GST Validity',
            parent: 'eeg',
            field: ['check_eeg', 'gst_reg_date'],
            show: (eeg) => {
              return eeg.check_eeg
            },
            resolve: (eeg) => {
              if (eeg.gst_reg_date) {
                return `${parseDate(eeg.gst_reg_date, 'DD MMM YYYY', null)} `
              }
              return '-'
            },
          },
        ],
      },
      personal_information: {
        label: 'Your Personal Information',
        fields: [
          {
            label: 'Salutation',
            field: 'salutation',
            resolve: (data) => {
              return Utils.capitalize(data)
            },
          },
          {
            label: 'Name as per NRIC or FIN Card',
            field: 'name',
          },
          {
            label: 'NRIC No or FIN',
            field: 'nric_fin',
          },
          {
            label: 'NRIC or FIN Date of Issue',
            field: 'nric_fin_doi',
            resolve: (date) => {
              return <span>{parseDate(date, 'DD MMM YYYY', null)}</span>
            },
          },
          {
            label: 'Work Permit Status',
            field: 'work_permit_status',
            resolve: (data) => {
              return Utils.capitalize(data)
            },
          },
          {
            label: 'Date of Birth',
            field: 'dob',
            resolve: (date) => {
              return <span>{parseDate(date, 'DD MMM YYYY', null)}</span>
            },
          },
          {
            label: 'Race',
            field: 'race',
            resolve: (data) => {
              return Utils.capitalize(data)
            },
          },
          {
            label: 'Email',
            parent: 'personal_contact_information',
            field: 'email',
          },
          {
            label: 'Mobile No',
            parent: 'personal_contact_information',
            field: ['mobile_prefix', 'mobile_number'],
            resolve: (data) => {
              return `${data.mobile_number}`
            },
          },
          {
            label: 'Alternate Contact No (Optional)',
            parent: 'personal_contact_information',
            field: ['alternate_contact_prefix', 'alternate_contact_number'],
            show: (alt) => {
              return alt.alternate_contact_number !== ''
            },
            resolve: (data) => {
              return `${data.alternate_contact_number}`
            },
          },
          {
            label: 'NRIC or FIN (Front)',
            parent: 'personal_supporting_docs',
            field: 'nric_fin_front',
            resolve: (front) => {
              return (
                <span>
                  <span className="upload-doc-type">
                    {front && typeof front === 'string' && `${front}`}
                    {front && typeof front === 'object' && `${front.name}`}
                  </span>
                </span>
              )
            },
          },
          {
            label: 'NRIC or FIN (Back)',
            parent: 'personal_supporting_docs',
            field: 'nric_fin_back',
            resolve: (back) => {
              return (
                <span>
                  <span className="upload-doc-type">
                    {back && typeof back === 'string' && `${back}`}
                    {back && typeof back === 'object' && `${back.name}`}
                  </span>
                </span>
              )
            },
          },
          {
            label: 'Bankruptcy',
            parent: 'bankruptcy',
            field: 'bankruptcy_no',
          },
        ],
      },
    },
    /**
     * RESIDENTIAL UNDER COMPANY
     */
    company: {
      premises: {
        label: 'Your Premises Information',
        fields: [
          {
            label: 'Premises Address',
            parent: 'premise_address',
            field: [
              'premise_block',
              'premise_street_name',
              'premise_unit',
              'premise_floor',
              'premise_country',
              'premise_postal_code',
              'premise_description',
            ],
            resolve: (values) => {
              if (values.premise_description) {
                return values.premise_description.toUpperCase()
              }
              return (
                <Fragment>
                  <span>{`${values.premise_block} ${values.premise_street_name}`}</span>
                  {values.premise_floor &&
                    values.premise_floor !== '' &&
                    values.premise_unit &&
                    values.premise_unit !== '' && (
                      <span>{`#${values.premise_floor}-${values.premise_unit}`}</span>
                    )}
                  {values.premise_floor &&
                    values.premise_floor !== '' &&
                    (!values.premise_unit || values.premise_unit === '') && (
                      <span>{`#${values.premise_floor}`}</span>
                    )}
                  {values.premise_unit &&
                    values.premise_unit !== '' &&
                    (!values.premise_floor || values.premise_floor === '') && (
                      <span>{`${values.premise_unit}`}</span>
                    )}
                  <span>{`Singapore ${values.premise_postal_code}`}</span>
                </Fragment>
              )
            },
          },
          {
            label: 'Mailing Address',
            parent: 'mail_address',
            field: [
              'check_same_address',
              'mail_block',
              'mail_unit',
              'mail_floor',
              'mail_street_name',
              'mail_country',
              'mail_postal_code',
            ],
            resolve: (values) => {
              if (values.check_same_address) {
                return `Same as premises address`
              }
              return (
                <Fragment>
                  <span>{`${values.mail_block} ${values.mail_street_name}`}</span>
                  {values.mail_floor &&
                    values.mail_floor !== '' &&
                    values.mail_unit &&
                    values.mail_unit !== '' && (
                      <span>{`#${values.mail_floor}-${values.mail_unit}`}</span>
                    )}
                  {values.mail_floor &&
                    values.mail_floor !== '' &&
                    (!values.mail_unit || values.mail_unit === '') && (
                      <span>{`#${values.mail_floor}`}</span>
                    )}
                  {values.mail_unit &&
                    values.mail_unit !== '' &&
                    (!values.mail_floor || values.mail_floor === '') && (
                      <span>{`${values.mail_unit}`}</span>
                    )}
                  <span>{`Singapore ${values.mail_postal_code}`}</span>
                </Fragment>
              )
            },
          },
          {
            label: 'Type of Occupancy',
            field: 'premise_occupancy',
            resolve: (data) => {
              return Utils.capitalize(data)
            },
          },
          {
            label: 'Use of Premises',
            field: 'use_of_premises',
            resolve: (data) => {
              return Utils.getItemFromUserOfPremise(data, '01')
            },
          },
          {
            label: 'Embedded Electricity Generator',
            parent: 'eeg',
            field: 'check_eeg',
            resolve: (eeg) => {
              return `Applicable`
            },
          },
          {
            label: 'GST Information',
            parent: 'eeg',
            field: 'gst_reg_no',
          },
          {
            label: 'GST Validity',
            parent: 'eeg',
            field: ['check_eeg', 'gst_reg_date'],
            show: (eeg) => {
              return eeg.check_eeg
            },
            resolve: (eeg) => {
              if (eeg.gst_reg_date) {
                return `${parseDate(eeg.gst_reg_date, 'DD MMM YYYY', null)} `
              }
              return '-'
            },
          },
        ],
      },
      company_information: {
        label: 'Your Company',
        fields: [
          {
            label: 'UEN',
            field: 'uen',
          },
          {
            label: 'Company Name',
            field: 'company_name',
          },
        ],
      },
      representative: {
        label: 'Your Company Representative',
        fields: [
          {
            label: 'Name',
            field: 'representative_name',
          },
          {
            label: 'Designation',
            field: 'representative_designation',
          },
          {
            label: 'Mobile No',
            field: [
              'representative_mobile_prefix',
              'representative_mobile_number',
            ],
            resolve: (data) => {
              return `${data.representative_mobile_number}`
            },
          },
          {
            label: 'Alternate Contact No',
            field: [
              'representative_contact_prefix',
              'representative_contact_number',
            ],
            show: (contact) => {
              return contact.representative_contact_number !== ''
            },
            resolve: (data) => {
              return `${data.representative_contact_number}`
            },
          },
          {
            label: 'Email',
            field: 'representative_email',
          },
          {
            label: 'Alternate Email',
            field: 'representative_alternate_email',
            show: (email) => {
              return email !== ''
            },
          },
        ],
      },
    },
  },
}

export default fields

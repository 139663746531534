import React, { useEffect, useRef, useState } from 'react'
import FormErrorBlock from './FormErrorBlock'

const FormTextArea = ({
  id,
  label,
  error,
  value,
  name,
  minLength,
  maxLength,
  handleChange,
  handleBlur,
  setFieldError,
  children,
  capsOnChange,
  setFieldValue,
  ...props
}) => {
  const [remaining, setRemaining] = useState(
    value ? maxLength - value.length : maxLength
  )

  const checkValueForMinMax = (value) => {
    if (maxLength > 0) {
      if (maxLength - value.length > 0) {
        setRemaining(maxLength - value.length)
      } else {
        setRemaining(0)
      }
    }
    if (minLength > 0) {
      if (value.length < minLength) {
        setFieldError(
          name,
          `Textarea should have a minimum characters of ${minLength}`
        )
      }
    }
  }

  const handleTextareaChange = (e) => {
    let value = e.target.value
    checkValueForMinMax(value)
    if (setFieldValue) {
      setFieldValue(id, value)
      if (handleChange) {
        handleChange(e)
      }
    } else {
      if (handleChange) {
        handleChange(e)
      }
    }
  }

  const blurHandler = (e) => {
    if (capsOnChange) {
      capsAllVals()
    }
    if (handleBlur) {
      handleBlur(e)
    }
  }

  const capsAllVals = () => {
    if (setFieldValue) {
      const val = value && value.toUpperCase()
      setFieldValue(id, val)
    }
  }
  return (
    <div
      className={
        'lm--formItem lm--formItem--inline ' + (error ? 'is-error' : '')
      }
    >
      <div className="lm--formItem-label">{label}</div>
      <div className="lm--formItem-control">
        {maxLength > 0 && (
          <div className="control-counter">{`[${remaining}]`}</div>
        )}
        <textarea
          id={id}
          className={capsOnChange ? 'lm--formItem-uppercase' : ''}
          value={value}
          onBlur={blurHandler}
          onChange={handleTextareaChange}
          cols={35}
          rows={5}
          maxLength={maxLength}
          minLength={minLength}
          {...props}
        />
        <FormErrorBlock id={`${id}-field_error`} error={error} />
        {children && <div className="control-content">{children}</div>}
      </div>
    </div>
  )
}

export default FormTextArea

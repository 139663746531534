import React, { PureComponent } from 'react'

import { trailWithZero } from 'spd-oa/helpers'
import FormErrorBlock from './FormErrorBlock'

class FormInputSplit extends PureComponent {
  state = {
    errorInput1: false,
    errorInput2: false,
  }
  render() {
    const {
      id,
      name,
      label,
      value,
      error,
      handleChange,
      handleBlur,
      placeholder,
      setFieldValue,
      trailZero,
      capsOnChange,
      params,
      ...props
    } = this.props
    const { errorInput1, errorInput2 } = this.state
    return (
      <div
        className={
          'lm--formItem lm--formItem--inline split' +
          (errorInput1 || errorInput2 ? ' is-error' : '')
        }
      >
        <div className="lm--formItem-label">{label}</div>
        <div className="lm--formItem-control">
          <input
            id={id[0]}
            className="split-input first"
            type="text"
            value={value[0]}
            onChange={this._inputFirstChangeHandler}
            placeholder={placeholder[0]}
            onBlur={this._blurHandler}
            {...props}
          />
          <input
            id={id[1]}
            className="split-input second"
            type="text"
            value={value[1]}
            onChange={this._inputSecondChangeHandler}
            placeholder={placeholder[1]}
            onBlur={this._blurHandler}
            {...props}
          />
          <FormErrorBlock id={`${id[0]}-field_error`} error={errorInput1} />
          <FormErrorBlock id={`${id[1]}-field_error`} error={errorInput2} />
        </div>
      </div>
    )
  }
  _inputFirstChangeHandler = (e) => {
    const {
      handleChange,
      setFieldValue,
      capsOnChange,
      params,
      trailZero,
      id,
    } = this.props
    if (setFieldValue) {
      let v = e.target.value
      if (capsOnChange) {
        if (typeof v === 'string') {
          v = v.toUpperCase()
        }
      }
      setFieldValue(id[0], v)
      handleChange(id[0], v, params.type)
    } else {
      handleChange(e, params.type)
    }
  }

  _inputSecondChangeHandler = (e) => {
    const {
      handleChange,
      setFieldValue,
      capsOnChange,
      params,
      trailZero,
      id,
    } = this.props
    if (setFieldValue) {
      let v = e.target.value
      if (capsOnChange) {
        if (typeof v === 'string') {
          v = v.toUpperCase()
        }
      }
      setFieldValue(id[1], v)
      handleChange(id[1], v, params.type)
    } else {
      handleChange(e, params.type)
    }
  }

  _blurHandler = (e) => {
    const {
      handleBlur,
      error,
      trailZero,
      setFieldValue,
      handleChange,
      id,
      params,
    } = this.props
    if (handleBlur) {
      handleBlur(e)
    }
    if (trailZero && trailZero.length > 0) {
      const tId = e.target.id
      let v = e.target.value
      trailZero.forEach((tz, i) => {
        if (tz) {
          if (tId === id[i]) {
            v = trailWithZero(v)
            setFieldValue(id[i], v)
            handleChange(id[i], v, params.type)
          }
        }
      })
    }
    if (error[0] || error[1]) {
      this.setState((prevState) => ({
        ...prevState,
        errorInput1: error[0],
        errorInput2: error[1],
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorInput1: null,
        errorInput2: null,
      }))
    }
  }
}

FormInputSplit.defaultProps = {
  id: '',
  label: '',
  value: '',
  error: '',
  handleChange: () => {},
  placeholder: '',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
}

export default FormInputSplit

import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import moment from 'moment'

import { Modal, Icons } from 'spd-oa/components/common'
import { parseDate } from 'spd-oa/helpers'

require('moment-timezone')

let currentYear = new Date().getFullYear()
let fromMonth = new Date(1901, 0)

class YearMonthForm extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.date !== state.date) {
      return {
        date: props.date,
      }
    }
    return null
  }
  state = {
    toMonth: new Date(currentYear + 10, 11),
    date: this.props.date,
  }
  _handleChange = (e) => {
    const { onChange } = this.props
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  componentDidMount() {
    const { offset, date } = this.props
    let toMonth = new Date(currentYear + 10, 11)
    let currentDate = date
    if (offset) {
      if (offset.years && offset.months) {
        toMonth = new Date(currentYear - offset.years, 11 - offset.months)
        currentDate = toMonth
      } else if (offset.years) {
        toMonth = new Date(currentYear - offset.years, 11)
        currentDate = toMonth
      } else if (offset.months) {
        toMonth = new Date(currentYear, 11 - offset.months)
        currentDate = toMonth
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      toMonth,
      date: currentDate,
    }))
  }

  render() {
    const { localeUtils } = this.props
    const { date: currentDate, toMonth } = this.state
    const months = localeUtils.getMonths()
    const years = []

    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i)
    }

    return (
      <form className="DayPicker-Caption">
        <select
          name="month"
          onChange={this._handleChange}
          value={currentDate.getMonth()}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          onChange={this._handleChange}
          value={currentDate.getFullYear()}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    )
  }
}

class DatePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.state = this.getInitialState()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state._value) {
      if (props.value === '') {
        return {
          from: null,
          to: null,
          enteredTo: null,
          selectedDay: null,
          _value: '',
        }
      }
      return {
        _value: props.value,
      }
    }
    return null
  }

  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null,
      selectedDay: null,
      _value: '',
    }
  }
  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
    const isRangeSelected = from && to
    return !from || isBeforeFirstDay || isRangeSelected
  }
  handleSingleDay = (day, { selected, disabled }) => {
    if (disabled) {
      return null
    }
    const { setFieldValue, id } = this.props
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedDay: selected ? null : day,
      }),
      () => {
        // setFieldValue(id, this.state.selectedDay)
        this.handleClosePicker()
      }
    )
  }
  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return null
    }
    const { from, to } = this.state
    if (from && to && day >= from && day <= to) {
      this.handleResetClick()
      return
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      })
    } else {
      this.setState({
        to: day,
        enteredTo: day,
      })
    }
  }
  handleDayMouseEnter(day) {
    const { from, to } = this.state
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      })
    }
  }
  handleResetClick() {
    this.setState(this.getInitialState())
  }

  handleClosePicker = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPicker: false,
    }))
  }

  handleOpenPicker = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPicker: true,
    }))
  }

  handleYearMonthChange = (month) => {
    this.setState((prevState) => ({
      ...prevState,
      month,
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { setFieldValue, onSelectHandler, id, value } = this.props

    if (this.state.selectedDay !== prevState.selectedDay) {
      onSelectHandler(id, this.state.selectedDay)
    }
    if (this.state.from !== prevState.from || this.state.to !== prevState.to) {
      const payload = {
        from: this.state.from,
        to: this.state.to,
      }
      onSelectHandler(id, payload)
    }
  }

  componentDidMount() {
    const { offset, id } = this.props
    if (offset) {
      let toMonth = new Date(currentYear + 10, 11)
      if (offset.years && offset.months) {
        toMonth = new Date(currentYear - offset.years, 11 - offset.months)
      } else if (offset.years) {
        toMonth = new Date(currentYear - offset.years, 11)
      } else if (offset.months) {
        toMonth = new Date(currentYear, 11 - offset.months)
      }
      this.setState((prevState) => ({
        ...prevState,
        month: toMonth,
      }))
    }
  }

  render() {
    const {
      children,
      isRange,
      availableDays,
      publicHolidays,
      disablePrev,
      disableNext,
      disableBefore,
      disableAfter,
      disabled,
      offset,
      value,
      showLegend,
      onBlurHandler,
      onChangeHandler,
      isInput,
      id,
      timezone,
    } = this.props
    const {
      from,
      to,
      enteredTo,
      showPicker,
      selectedDay: sd,
      month,
    } = this.state
    const modifiers = {
      start: from,
      end: enteredTo,
      nonWorkingDays: {
        daysOfWeek: [0],
      },
    }
    const modifiersStyles = {
      nonWorkingDays: {
        color: '#FF0000',
      },
      today: {
        color: '#00b0b2',
        fontWeight: '400',
      },
      selected: {
        color: '#00b0b2',
        backgroundColor: 'transparent',
        border: '1px solid #00b0b2',
        fontWeight: 'bold',
      },
      outside: {
        border: 'none',
      },
      publicHolidays: {
        color: '#FF0000',
      },
    }
    let minDate = disableBefore
      ? moment(disableBefore)
          .tz(timezone)
          .add(1, 'days')
      : disableBefore
    let maxDate = disableAfter
      ? moment(disableAfter)
          .tz(timezone)
          .add(1, 'days')
      : disableAfter

    const disabledDays = this._getDisabledDays(minDate, maxDate)

    const multiDisabledDays = [disabledDays]
    if (publicHolidays) {
      publicHolidays.map((day) => {
        multiDisabledDays.push(new Date(day))
      })
    }

    const selectedDays = [from, { from, to: enteredTo }]
    let selectedDay = sd
    const formatOption = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Asia/Singapore',
    }

    if (value) {
      if (typeof value === 'string') {
        let _selected = moment(value).tz('Asia/Singapore')
        selectedDay = _selected.toDate()
      }
      if (typeof value === 'object') {
        selectedDay = value
      }
    }

    return (
      <Fragment>
        {isInput && (
          <div className="datepicker">
            <input
              id={`${id}-input`}
              type="text"
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              value={this._getFormattedSelectedDay(value)}
            />
            <button
              disabled={disabled}
              type={'button'}
              ref={(el) => (this._targetElem = el)}
              onClick={this.handleOpenPicker}
              className="btn--oa lm--button lm--button-picker"
              onBlur={onBlurHandler}
            >
              <Icons.calendar width={16} height={16} />
            </button>
          </div>
        )}
        {!isInput && (
          <button
            id={`${id}-btn`}
            disabled={disabled}
            type={'button'}
            ref={(el) => (this._targetElem = el)}
            onClick={this.handleOpenPicker}
            className="btn--oa lm--button lm--button-picker"
            onBlur={onBlurHandler}
          >
            <Icons.calendar width={16} height={16} />
            {children && typeof children === 'function'
              ? children({
                  to,
                  from,
                  selectedDay,
                })
              : isRange
              ? to && to !== 'Today'
                ? `${from &&
                    from.toLocaleDateString('en-SG', formatOption)} - ${to &&
                    to.toLocaleDateString('en-SG', formatOption)}`
                : `Today`
              : `${
                  selectedDay
                    ? parseDate(selectedDay, 'DD/MM/YYYY', '')
                    : 'Date'
                }`}
          </button>
        )}
        {showPicker && (
          <Modal
            id={`${id}-dp`}
            className="app-datepicker"
            onClose={this.handleClosePicker}
            target={this._targetElem}
            withBackdrop={false}
          >
            {isRange ? (
              <Fragment>
                <DayPicker
                  className="datepicker-range"
                  numberOfMonths={1}
                  fromMonth={from}
                  selectedDays={selectedDays}
                  disabledDays={multiDisabledDays}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  onDayClick={this.handleDayClick}
                  onDayMouseEnter={this.handleDayMouseEnter}
                  captionElement={({ date, localeUtils }) => (
                    <YearMonthForm
                      offset={offset}
                      date={date}
                      localeUtils={localeUtils}
                      onChange={this.handleYearMonthChange}
                    />
                  )}
                  month={month}
                />
                <div>
                  {!from && !to && 'Please select the first day.'}
                  {from && !to && 'Please select the last day.'}
                  {from &&
                    to &&
                    `Selected from ${from.toLocaleDateString()} to
                            ${to.toLocaleDateString()}`}{' '}
                  {from && to && (
                    <button className="link" onClick={this.handleResetClick}>
                      Reset
                    </button>
                  )}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <DayPicker
                  disabledDays={multiDisabledDays}
                  selectedDays={selectedDay}
                  modifiers={{
                    nonWorkingDays: {
                      daysOfWeek: [0],
                    },
                    publicHolidays: publicHolidays
                      ? publicHolidays.map((date) => new Date(date))
                      : publicHolidays,
                  }}
                  modifiersStyles={modifiersStyles}
                  onDayClick={this.handleSingleDay}
                  captionElement={({ date, localeUtils }) => (
                    <YearMonthForm
                      offset={offset}
                      date={date}
                      localeUtils={localeUtils}
                      onChange={this.handleYearMonthChange}
                    />
                  )}
                  month={month}
                />
              </Fragment>
            )}
            {showLegend && (
              <div className="modal-footnote">
                <span className="fn-item">
                  <span
                    className="circle"
                    style={{
                      backgroundColor: '#00b0b2',
                    }}
                  ></span>
                  <span>{`Today`}</span>
                </span>
                <span className="fn-item">
                  <span
                    className="circle"
                    style={{
                      backgroundColor: '#FF0000',
                    }}
                  ></span>
                  <span>{`Sun / PH day`}</span>
                </span>
              </div>
            )}
          </Modal>
        )}
      </Fragment>
    )
  }

  _getDisabledDays(minDate, maxDate) {
    const {
      availableDays,
      disableToday,
      disablePrev,
      disableNext,
      timezone,
    } = this.props
    if (availableDays && availableDays.length > 0) {
      return (day) => {
        const today = moment().startOf('day')
        const today_date = today.format('YYYY-MM-DD')
        const c_day = moment(day).startOf('day')
        const c_day_date = c_day.format('YYYY-MM-DD')
        if (disableToday) {
          if (today_date === c_day_date) {
            return true
          }
        }
        return !availableDays.some((dDay) => {
          const a_day = moment(dDay)
            .tz(timezone)
            .startOf('day')
          return a_day.format('YYYY-MM-DD') === c_day_date
        })
      }
    } else {
      if (disablePrev) {
        return { before: new Date() }
      }
      if (disableNext) {
        return { after: new Date() }
      }
      if (minDate) {
        return { before: minDate.toDate() }
      } else if (maxDate) {
        return { after: maxDate.toDate() }
      }
    }
    return null
  }
  _getFormattedSelectedDay(value) {
    if (Object.prototype.toString.call(value) === '[object Date]') {
      if (isNaN(value)) {
        // d.valueOf() could also work
        return value
      } else {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
        let selectedDay = new Date(value)
        return selectedDay.toLocaleDateString('en-SG', options)
      }
    }
    return value
  }
}

DatePicker.defaultProps = {
  isRange: false,
  availableDays: [],
  showLegend: true,
  timezone: 'Asia/Singapore',
  disableToday: false,
}

DatePicker.propTypes = {
  isRange: PropTypes.bool,
  showLegend: PropTypes.bool,
  availableDays: PropTypes.array,
  disableToday: PropTypes.bool,
}

export default DatePicker

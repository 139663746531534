import React, { PureComponent } from 'react'
import { TweenLite, TweenMax, Back, Power1 } from 'gsap'
import { Icons } from 'spd-oa/components/common'

class Item extends PureComponent {
  state = {
    active: this.props.defaultActive || false,
  }
  componentDidMount() {
    // TweenLite.set(this._item, {visibility:'visible'})
    const { active } = this.state
    this._open(active)
  }
  render() {
    const { title, children } = this.props
    const { active } = this.state
    return (
      <div
        className={`accordion--item ${active ? 'is-active' : ''}`}
        ref={(el) => {
          this._item = el
        }}
      >
        <div
          className="accordion--item-head"
          onClick={this._toggleHandler}
          ref={(el) => {
            this._head = el
          }}
        >
          <h3 className="accordion--item-title">{title}</h3>
          <figure className="accordion--item-icon">
            <Icons.chevDown width={24} height={24} />
          </figure>
        </div>
        <div
          className="accordion--item-body"
          ref={(el) => {
            this._body = el
          }}
        >
          <div className="accordion--item-content">{children}</div>
        </div>
      </div>
    )
  }
  _toggleHandler = () => {
    const { active } = this.state
    if (!active) {
      this.setState(
        (prevState) => ({
          ...prevState,
          active: true,
        }),
        () => {
          // TweenLite.set(this._body, { height: 'auto' })
          // TweenLite.from(this._body, 0.6,
          //   {
          //     height: 0,
          //     immediateRender: false,
          //     ease: Back.easeOut
          //   })

          this._open(true)
        }
      )
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          active: false,
        }),
        () => {
          // TweenLite.to(
          //   this._body,
          //   0.3, {
          //     height: 0,
          //     immediateRender: false,
          //     ease: Power1.easeOut
          //   })
          this._open(false)
        }
      )
    }
  }

  _open(active) {
    if (active) {
      TweenLite.set(this._body, { height: 'auto' })
      TweenLite.from(this._body, 0.6, {
        height: 0,
        immediateRender: false,
        ease: Back.easeOut,
      })
    } else {
      TweenLite.to(this._body, 0.3, {
        height: 0,
        immediateRender: false,
        ease: Power1.easeOut,
      })
    }
  }
}

Item.displayName = 'AccordionItem'

class List extends PureComponent {
  render() {
    const { children } = this.props
    return <section className="app-accordion">{children}</section>
  }
}

List.displayName = 'AccordionList'

export default {
  Item,
  List,
}

import React, { Fragment, useState } from 'react'

import {
  FormCheckBox,
  FormGroup,
  FormInput,
  FormSplitInput,
  FormTextArea,
} from 'spd-oa/components/FormElement'

import { ConfirmationDialog, Tooltip } from 'spd-oa/components/common'

const PremiseForm = ({ props, isPremise, title, streetName, domain }) => {
  const [isShowPopup, setIsShowPopup] = useState(false)

  const handleOpenPopup = () => {
    setIsShowPopup(true)
  }

  const handleClosePopup = () => {
    setIsShowPopup(false)
  }

  if (isPremise) {
    return (
      <FormGroup groupTitle={title}>
        {!props.values.nanl ? (
          <>
            <FormInput
              label="House / Block No."
              id="premise_block"
              error={props.touched.premise_block && props.errors.premise_block}
              value={props.values.premise_block}
              handleChange={(e) => {
                props.handleChange('premise_block', e.target.value, 'premise')
                props.setFieldValue('premise_block', e.target.value)
              }}
              handleBlur={(e) => {
                if (streetName.handleBlur) {
                  streetName.handleBlur('premise', e)
                }
              }}
              capsOnBlur={true}
              setFieldValue={props.setFieldValue}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('premise', ev) && ev.preventDefault()
                  }
                }
              }}
            />
            <FormSplitInput
              label="Unit"
              id={['premise_floor', 'premise_unit']}
              params={{
                type: 'premise',
              }}
              error={[
                (props.touched.premise_floor && props.errors.premise_floor) ||
                  props.errors.premise_floor,
                (props.touched.premise_unit && props.errors.premise_unit) ||
                  props.errors.premise_unit,
              ]}
              value={[props.values.premise_floor, props.values.premise_unit]}
              handleChange={props.handleChange}
              capsOnChange={true}
              handleBlur={(e) => {
                if (streetName.handleBlur) {
                  streetName.handleBlur('premise', e)
                }
              }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('premise', ev) && ev.preventDefault()
                  }
                }
              }}
              setFieldValue={props.setFieldValue}
              trailZero={[true, true]}
              placeholder={['Floor number', 'Unit number']}
            />
            <FormInput
              label="Postal Code"
              id="premise_postal_code"
              error={
                props.touched.premise_postal_code &&
                props.errors.premise_postal_code
              }
              value={props.values.premise_postal_code}
              handleChange={(e) => {
                props.handleChange(
                  'premise_postal_code',
                  e.target.value,
                  'premise'
                )
                props.setFieldValue('premise_postal_code', e.target.value)
              }}
              handleBlur={(e) => {
                if (streetName.handleBlur) {
                  streetName.handleBlur('premise', e)
                }
              }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('premise', ev) && ev.preventDefault()
                  }
                }
              }}
              onlyNumber
              setFieldValue={props.setFieldValue}
              maxLength={6}
            />
            {!streetName.premise ? (
              <FormInput
                label="Street Name"
                id="premise_street_name"
                error={
                  (props.touched.premise_street_name &&
                    props.errors.premise_street_name) ||
                  props.streetNameError
                }
                value={props.values.premise_street_name}
                handleChange={(e) => {
                  props.setFieldValue('premise_street_name', e.target.value)
                }}
                autoComplete={'off'}
                autoCorrect={'off'}
                autoCapitalize={'off'}
                disabled={true}
                maxLength={60}
                loading={streetName.fetching['premise']}
                showError={
                  streetName.status['premise'] &&
                  streetName.status['premise'] === 'error'
                }
              />
            ) : (
              <FormInput
                label="Street Name"
                id="premise_street_name"
                error={
                  (props.touched.premise_street_name &&
                    props.errors.premise_street_name) ||
                  props.streetNameError
                }
                value={props.values.premise_street_name}
                capsOnChange={true}
                setFieldValue={props.setFieldValue}
                handleBlur={(e) => {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('premise_street_name', e)
                  }
                }}
                autoComplete={'off'}
                autoCorrect={'off'}
                autoCapitalize={'off'}
                maxLength={60}
                loading={streetName.fetching['premise']}
                showError={
                  streetName.status['premise'] &&
                  streetName.status['premise'] === 'error'
                }
              />
            )}
          </>
        ) : (
          <>
            <FormTextArea
              label={
                <>
                  Description of the <br />
                  address / premises
                  <Tooltip>
                    Please indicate the premises address given by the relevant
                    government organisation.
                  </Tooltip>
                </>
              }
              id="premise_description"
              error={
                props.touched.premise_description &&
                props.errors.premise_description
              }
              value={props.values.premise_description}
              maxLength="500"
              capsOnChange={true}
              setFieldError={props.setFieldError}
              setFieldValue={props.setFieldValue}
              handleBlur={(e) => {
                if (streetName.handleBlur) {
                  streetName.handleBlur('premise', e)
                }
              }}
            >
              {/* <p>
                Message here with <a onClick={handleOpenPopup}>pop up</a>
              </p> */}
            </FormTextArea>
            {isShowPopup && (
              <ConfirmationDialog
                confirmOkHandler={handleClosePopup}
                onCloseHandler={handleClosePopup}
                onEscapeKeyDownHandler={handleClosePopup}
                content={
                  <>
                    <picture>
                      <source
                        media="(max-width: 767px)"
                        srcSet="/images/hla-banner-mobile.jpg"
                      />
                      <img src="/images/hla-banner.jpg" alt="HLA banner" />
                    </picture>
                  </>
                }
                actionConfig={{
                  cancel: {
                    label: '',
                  },
                  ok: {
                    label: 'OK',
                  },
                }}
              />
            )}
          </>
        )}
      </FormGroup>
    )
  } else {
    return (
      <FormGroup
        groupTitle={title}
        groupField={
          !props.values.nanl && (
            <FormCheckBox
              label={false}
              onlyElem={true}
              id="check_same_address"
              name="check_same_address"
              error={
                props.touched.check_same_address &&
                props.errors.check_same_address
              }
              text={`Same as premises address`}
              // value={`same_address`}
              checked={props.values.check_same_address}
              handleChange={(e) => {
                props.setFieldValue('check_same_address', e.target.checked)
                if (!e.target.checked) {
                  props.setFieldValue('mail_block', '')
                  props.setFieldValue('mail_floor', '')
                  props.setFieldValue('mail_unit', '')
                  props.setFieldValue('mail_postal_code', '')
                  props.setFieldValue('mail_street_name', '')
                  streetName.reset()
                }
              }}
              disabled={props.values.nanl}
            />
          )
        }
      >
        {!props.values.check_same_address && (
          <Fragment>
            <FormInput
              label="House / Block No."
              id="mail_block"
              error={props.touched.mail_block && props.errors.mail_block}
              value={props.values.mail_block}
              handleChange={(e) => {
                props.handleChange('mail_block', e.target.value, 'mail')
                props.setFieldValue('mail_block', e.target.value)
              }}
              handleBlur={(e) => {
                if (streetName.handleBlur) {
                  streetName.handleBlur('mail', e)
                }
              }}
              capsOnBlur={true}
              setFieldValue={props.setFieldValue}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('mail', ev) && ev.preventDefault()
                  }
                }
              }}
            />
            <FormSplitInput
              label="Unit"
              id={['mail_floor', 'mail_unit']}
              params={{
                type: 'mail',
              }}
              error={[
                (props.touched.mail_floor && props.errors.mail_floor) ||
                  props.errors.mail_floor,
                (props.touched.mail_unit && props.errors.mail_unit) ||
                  props.errors.mail_unit,
              ]}
              value={[props.values.mail_floor, props.values.mail_unit]}
              handleChange={props.handleChange}
              capsOnChange={true}
              setFieldValue={props.setFieldValue}
              trailZero={[true, true]}
              placeholder={['Floor number', 'Unit number']}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('mail', ev) && ev.preventDefault()
                  }
                }
              }}
            />
            <FormInput
              label="Postal Code"
              id="mail_postal_code"
              error={
                props.touched.mail_postal_code && props.errors.mail_postal_code
              }
              value={props.values.mail_postal_code}
              handleChange={(e) => {
                props.handleChange('mail_postal_code', e.target.value, 'mail')
                props.setFieldValue('mail_postal_code', e.target.value)
              }}
              handleBlur={(e) => {
                if (streetName.handleBlur) {
                  streetName.handleBlur('mail', e)
                }
              }}
              pattern="[0-9]{6}"
              maxLength={6}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('mail', ev) && ev.preventDefault()
                  }
                }
              }}
            />
            {!streetName.mail ? (
              <FormInput
                label="Street Name"
                id="mail_street_name"
                error={
                  (props.touched.mail_street_name &&
                    props.errors.mail_street_name) ||
                  props.streetNameError
                }
                value={props.values.mail_street_name}
                capsOnChange={true}
                handleChange={(e) => {
                  props.setFieldValue('mail_street_name', e.target.value)
                }}
                autoComplete={'off'}
                autoCorrect={'off'}
                autoCapitalize={'off'}
                disabled={true}
                maxLength={60}
                loading={streetName.fetching['mail']}
                showError={
                  streetName.status['mail'] &&
                  streetName.status['mail'] !== 'success'
                }
              />
            ) : (
              <FormInput
                label="Street Name"
                id="mail_street_name"
                error={
                  (props.touched.mail_street_name &&
                    props.errors.mail_street_name) ||
                  props.streetNameError
                }
                value={props.values.mail_street_name}
                capsOnChange={true}
                setFieldValue={props.setFieldValue}
                handleBlur={(e) => {
                  if (streetName.handleBlur) {
                    streetName.handleBlur('mail_street_name', e)
                  }
                }}
                autoComplete={'off'}
                autoCorrect={'off'}
                autoCapitalize={'off'}
                loading={streetName.fetching['mail']}
                showError={
                  streetName.status['mail'] &&
                  streetName.status['mail'] !== 'success'
                }
              />
            )}
          </Fragment>
        )}
      </FormGroup>
    )
  }
}

PremiseForm.defaultProps = {
  streetName: {
    mail: false,
    premise: false,
  },
}

export default PremiseForm

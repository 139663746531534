import React from 'react'

class FormSwitchRadioGroup extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }
  onChange(event) {
    this.props.onChange(this.props.id, event.target.value)
  }
  render() {
    const { labelGroupTitle, children } = this.props
    return (
      <div className="lm--formItem lm--formItem--inline">
        <div className="lm--formItem-label">{labelGroupTitle}</div>
        {children(this.onChange, this.props.selected)}
      </div>
    )
  }
}

export default FormSwitchRadioGroup

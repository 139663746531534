import React, { Fragment } from 'react'

import Utils from 'spd-oa/utils'
import { parseDate } from 'spd-oa/helpers'
import moment from 'moment'

const printFields = {
  residential: {
    company: {
      premises: {
        label: 'Your Premises Information',
        fields: [
          {
            label: 'Premises Address',
            parent: 'premise_address',
            field: [
              'premise_block',
              'premise_street_name',
              'premise_floor',
              'premise_unit',
              'premise_country',
              'premise_postal_code',
              'premise_description',
            ],
            resolve: (values) => {
              if (values.premise_description) {
                return values.premise_description.toUpperCase()
              }
              return (
                <Fragment>
                  <span>{`${values.premise_block} ${values.premise_street_name}`}</span>
                  {values.premise_floor &&
                    values.premise_floor !== '' &&
                    values.premise_unit &&
                    values.premise_unit !== '' && (
                      <span>{`#${values.premise_floor}-${values.premise_unit}`}</span>
                    )}
                  {values.premise_floor &&
                    values.premise_floor !== '' &&
                    (!values.premise_unit || values.premise_unit === '') && (
                      <span>{`#${values.premise_floor}`}</span>
                    )}
                  {values.premise_unit &&
                    values.premise_unit !== '' &&
                    (!values.premise_floor || values.premise_floor === '') && (
                      <span>{`${values.premise_unit}`}</span>
                    )}
                  <span>{`${values.premise_country} ${values.premise_postal_code}`}</span>
                </Fragment>
              )
            },
          },
          {
            label: 'Mailing Address',
            parent: 'mail_address',
            field: [
              'check_same_address',
              'mail_block',
              'mail_street_name',
              'mail_floor',
              'mail_unit',
              'mail_country',
              'mail_postal_code',
            ],
            resolve: (values) => {
              if (values.check_same_address) {
                return `Same as premises address`
              }
              return (
                <Fragment>
                  <span>{`${values.mail_block} ${values.mail_street_name}`}</span>
                  {values.mail_floor &&
                    values.mail_floor !== '' &&
                    values.mail_unit &&
                    values.mail_unit !== '' && (
                      <span>{`#${values.mail_floor}-${values.mail_unit}`}</span>
                    )}
                  {values.mail_floor &&
                    values.mail_floor !== '' &&
                    (!values.mail_unit || values.mail_unit === '') && (
                      <span>{`#${values.mail_floor}`}</span>
                    )}
                  {values.mail_unit &&
                    values.mail_unit !== '' &&
                    (!values.mail_floor || values.mail_floor === '') && (
                      <span>{`${values.mail_unit}`}</span>
                    )}
                  <span>{`${values.mail_country} ${values.mail_postal_code}`}</span>
                </Fragment>
              )
            },
          },
          {
            label: 'Type of Occupancy',
            field: 'premise_occupancy',
            resolve: (data) => {
              return Utils.capitalize(data)
            },
          },
          {
            label: 'Use of Premises',
            field: 'use_of_premises',
            resolve: (data) => {
              return Utils.getItemFromUserOfPremise(data, '01')
            },
          },
          {
            label: 'Embedded Electricity Generator',
            parent: 'root',
            field: 'gst_reg_no',
            resolve: (eeg) => {
              return `Applicable`
            },
          },
          {
            label: 'GST Information',
            parent: 'root',
            field: 'gst_reg_no',
          },
          {
            label: 'GST Validity',
            parent: 'root',
            field: ['gst_reg_date'],
            skip_empty: true,
            resolve: (eeg) => {
              if (eeg.gst_reg_date) {
                return `${parseDate(eeg.gst_reg_date, 'DD MMM YYYY', null)} `
              }
              return '-'
            },
          },
        ],
      },
      company_information: {
        label: 'Your Company',
        fields: [
          {
            label: 'UEN',
            field: 'uen',
          },
          {
            label: 'Company Name',
            field: 'company_name',
          },
        ],
      },
      representative: {
        label: 'Your Company Representative',
        fields: [
          {
            label: 'Name',
            field: 'representative_name',
          },
          {
            label: 'NRIC No or FIN',
            field: 'representative_nric_fin',
          },
          {
            label: 'Designation',
            field: 'representative_designation',
          },
          {
            label: 'Mobile No',
            field: 'representative_mobile_number',
          },
          {
            label: 'Alternate Contact No',
            field: 'representative_contact_number',
          },
          {
            label: 'Email',
            field: 'representative_email',
          },
          {
            label: 'Alternate Email',
            field: 'representative_alternate_email',
          },
        ],
      },
      turnOnServices: {
        label: 'Your Utilities',
        fields: [
          {
            label: 'Selected Supplies',
            field: ['services', 'chilled_water'],
            resolve: (values) => {
              const printArr = [...values.services]
              if (values.chilled_water)
                printArr.push('Centralised Cooling Services')
              return <span>{Utils.printArray(printArr)}</span>
            },
          },
          {
            label: 'Preferred Turn on Date',
            parent: 'preferred_date',
            field: 'turn_on_datetime',
            resolve: (date) => {
              if (date.start && date.end) {
                return (
                  <span>{`${moment(date.start).format('DD MMM YYYY')}, ${moment(
                    date.start
                  )
                    .tz('Asia/Singapore')
                    .format('HH:mm')} - ${moment(date.end)
                    .tz('Asia/Singapore')
                    .format('HH:mm')}`}</span>
                )
              }
              return <span>{moment(date).format('DD MMM YYYY')}</span>
            },
          },
        ],
      },
    },
  },
}

export default printFields

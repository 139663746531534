import { has, isEmpty } from 'lodash'
import Utils from 'spd-oa/utils'

const docLabels = {
  nric_fin_back: 'NRIC or FIN (Back)',
  nric_fin_front: 'NRIC or FIN (Front)',
  poo: 'Proof of Ownership',
  gst_reg_cert: 'GST Registration Certification',
  electric_meter_reading: 'Electricity meter reading',
  water_meter_reading: 'Water meter reading',
  gas_meter_reading: 'Gas meter reading',
  application_form: 'Signed Application Form',
  bizfile: 'Business or Company Registration Certificate',
  loa: 'Letter of Authorisation (Optional)',
  npop:
    'Proof of Premise (e.g. Certificate of Numbering from IRAS, Certificate of Factory Registration from MOM)',
  nasd:
    'Additional Supporting Document (Any other supporting documents for this premises)',
  pooop:
    'Documentary Proof of Occupancy of Premises (e.g.Latest Property Tax or Tenancy Agreement)',
  eil: 'Signed Electrical Installation License Form (EIL)',
  loawrd: 'Letter of Award',
  sp_pwrgrd_quot: 'SP PowerGrid Quotation (for temporary electricity supply)',
  pub_wat_quot:
    'Water Quotation and Acknowledgement for Declaration on Temporary Sanitary Facilities/Toilets from PUB (for temporary water supply)',
}

const formats = ['.pdf', '.jpg', '.jpeg', '.png']
const max_size = 8000000 // 8MB

const personalDocsSpecs = {
  nric_fin_back: {
    id: 'nric_fin_back',
    type: 'nric_back',
    label: docLabels.nric_fin_back,
    formats,
    is_required: true,
    max_size,
  },
  nric_fin_front: {
    id: 'nric_fin_front',
    type: 'nric_front',
    label: docLabels.nric_fin_front,
    formats,
    is_required: true,
    max_size,
  },
}

const meterDocsSpecs = {
  electric_meter_reading: {
    id: 'electric_meter_reading',
    type: 'meter_electric',
    label: docLabels.electric_meter_reading,
    formats,
    is_required: true,
    max_size,
  },
  water_meter_reading: {
    id: 'water_meter_reading',
    type: 'meter_water',
    label: docLabels.water_meter_reading,
    formats,
    is_required: true,
    max_size,
  },
  gas_meter_reading: {
    id: 'gas_meter_reading',
    type: 'meter_gas',
    label: docLabels.gas_meter_reading,
    formats,
    is_required: true,
    max_size,
  },
}

const commercialDocsSpecs = {
  npop: {
    id: 'npop',
    type: 'nanl_proof_of_premise',
    label: docLabels.npop,
    formats,
    is_required: true,
    max_size,
  },
  nasd: {
    id: 'nasd',
    type: 'nanl_additional_supporting_documents',
    label: docLabels.nasd,
    formats,
    is_required: false,
    max_size,
  },
  pooop: {
    id: 'pooop',
    type: 'proof_of_occupancy_of_premises',
    label: docLabels.pooop,
    formats,
    is_required: true,
    max_size: 30000000,
    is_multiple: true,
  },
  eil: {
    id: 'eil',
    type: 'electrical_installation_license_form',
    label: docLabels.eil,
    formats,
    is_required: true,
    max_size,
  },
  loawrd: {
    id: 'loawrd',
    type: 'letter_of_award',
    label: docLabels.loawrd,
    formats,
    is_required: true,
    max_size,
  },
  sp_pwrgrd_quot: {
    id: 'sp_pwrgrd_quot',
    type: 'sp_powergrid_quotation',
    label: docLabels.sp_pwrgrd_quot,
    formats,
    is_required: true,
    max_size,
  },
  pub_wat_quot: {
    id: 'pub_wat_quot',
    type: 'pub_water_quotation',
    label: docLabels.pub_wat_quot,
    formats,
    is_required: true,
    max_size,
  },
}

const specs = {
  ...personalDocsSpecs,
  poo: {
    id: 'poo',
    type: 'proof_of_ownership',
    label: docLabels.poo,
    formats,
    is_required: true,
    max_size,
  },

  ...meterDocsSpecs,
  application_form: {
    id: 'application_form',
    type: 'c_signed_utility_application_form',
    label: docLabels.application_form,
    formats,
    is_required: true,
    max_size,
  },
  bizfile: {
    id: 'bizfile',
    type: 'c_acra_manual',
    label: docLabels.bizfile,
    formats,
    is_required: true,
    max_size,
    is_multiple: true,
  },
  loa: {
    id: 'loa',
    type: 'c_letter_of_auth',
    label: docLabels.loa,
    formats,
    is_required: false,
    max_size,
  },

  ...commercialDocsSpecs,
}

const specObjToDocs = (specObj) => {
  return Object.values(specObj).map(({ id, type }) => ({
    id,
    type,
  }))
}

const meterDocs = specObjToDocs(meterDocsSpecs)

const commercialDocs = specObjToDocs(commercialDocsSpecs)

const personalDocs = specObjToDocs(personalDocsSpecs)

const deletingDocs = [
  ...meterDocs,

  { id: 'gst_reg_cert', type: 'gst_registration_certification' },
  { id: 'eil', type: 'electrical_installation_license_form' },
]

function getNricTypes() {
  return Object.values(personalDocsSpecs).map((doc) => doc.type)
}
function getNricIds() {
  return Object.values(personalDocsSpecs).map((doc) => doc.id)
}

function getMultiFileTypes() {
  const { mode } = Utils.getOAMode()
  if (mode !== 'kiosk') {
    return []
  }
  return Object.values(specs)
    .filter((doc) => doc.is_multiple)
    .map((doc) => doc.type)
}

export {
  deletingDocs,
  commercialDocs,
  meterDocs,
  docLabels,
  specs,
  personalDocsSpecs,
  personalDocs,
  getNricTypes,
  getNricIds,
  getMultiFileTypes,
}

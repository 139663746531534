import React from 'react'
import classnames from 'classnames'

const FormSwitchRadio = ({
  id,
  name,
  value,
  checked,
  onChange,
  label,
  ...props
}) => {
  const _Switch = classnames('lm--switch', {
    'is-disabled': props.disabled,
  })
  return (
    <div className={_Switch}>
      <input
        type="radio"
        id={id}
        name={name}
        className="btn--oa lm--switch-input"
        onChange={onChange}
        value={value}
        checked={checked}
        {...props}
      />
      <label htmlFor={id} className="lm--switch-label">
        {label}
      </label>
    </div>
  )
}

export default FormSwitchRadio

import React, { PureComponent, Fragment } from 'react'
import { Icons, Display, Button } from 'spd-oa/components/common'

import { API as ApiService } from 'spd-oa/services'

class Plans extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      plan_name: '',
      plan_id: '',
    })

    context.actions.setFields(data)
    this.state = {
      data,
      fetching: false,
      error: null,
      plans: null,
    }
  }

  _fetchPlans = () => {
    this.setState({
      fetching: true,
    })
    ApiService.GET({
      url: '/api/v1/oa/plans',
    })
      .then((result) => {
        // console.log('# result ', result)
        this.setState({ plans: result.data, fetching: false, error: null })
      })
      .catch((err) => {
        this.setState({
          error: {
            err,
            message:
              "Oops, there's something wrong with the service. Please try again.",
          },
        })
      })
  }

  _selectHandler = (data) => {
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {
        plan_name: data.plan_name,
        plan_id: data.plan_code,
      }
      actions.presave(payload)
      actions.next()
    }
  }
  componentDidMount() {
    const { data } = this.state
    const { context } = this.props
    context.actions.setFields(data)
    this._fetchPlans()
  }

  render() {
    const { plans, fetching } = this.state
    return (
      <div className={`oa_signup--plans`}>
        {(plans &&
          plans.discounted_from_tariff &&
          plans.discounted_from_tariff.length > 0) ||
        (plans &&
          plans.fixed_price_plans &&
          plans.fixed_price_plans.length > 0) ? (
          <Fragment>
            <div className="plans_wrapper">
              <section className="plans_list">
                {this._renderPlans(plans.fixed_price_plans, 'Fixed Plans')}
              </section>
              <section className="plans_list">
                {this._renderPlans(
                  plans.discounted_from_tariff,
                  'Discounted Off Tariff'
                )}
              </section>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="plans_wrapper">
              {fetching ? (
                <Display
                  icon={<Icons.blank width={204} height={153} />}
                  title="Please wait.."
                  text="Currently loading plans."
                >
                  <Button
                    content="Back"
                    variant="primary"
                    onClickHandler={() => {
                      window.location.href = '/oa/dashboard'
                    }}
                  />
                </Display>
              ) : (
                <Display
                  icon={<Icons.blank width={204} height={153} />}
                  title="No plan available"
                  text="There's no plan available, kindly contact your team for more details."
                >
                  <Button
                    content="Back"
                    variant="primary"
                    onClickHandler={() => {
                      window.location.href = '/oa/dashboard'
                    }}
                  />
                </Display>
              )}
            </div>
          </Fragment>
        )}
      </div>
    )
  }

  _renderPlans(plans, type) {
    if (!plans || plans.length === 0) {
      return null
    }
    return plans.map((plan, i) => {
      return (
        <Plan
          key={plan.id}
          data={plan}
          type={type}
          onSelect={this._selectHandler}
        />
      )
    })
  }
}

const Plan = ({ data, type, onSelect }) => {
  return (
    <div className="plans_list--item">
      <h4 className="item-title col-md-12">{`${data.plan_name}`}</h4>
      <div className="item-details u-grid u-justify-center">
        <div className="col-sm-12 col-md-4 u-pt4 u-pr2">
          <p>
            {`${
              data.plan_description !== '' ? `${data.plan_description}` : '-'
            }`}
          </p>
        </div>
        <div className="col-sm-12 col-md-4 u-pt4 u-pl2 u-pr2">
          <ul>
            <li>{`${data.contract_period} months contract`}</li>
            {type === 'Fixed Plan' && (
              <li>{`$${data.fixed_unit_price / 10000} / kWh`}</li>
            )}
            {type === 'Discounted Off Tariff' && (
              <li>{`$${data.tariff_discount_percent / 100}% off SP Tariff`}</li>
            )}
            {/* <li>{`Constant Pricing`}</li> */}
          </ul>
        </div>
        <div className="col-sm-12 col-md-4 u-pl2 u-pt1">
          <div>
            <button
              className="lm--button lm--button--primary u-block"
              onClick={() => {
                onSelect(data)
              }}
            >
              Choose
            </button>
          </div>
          <div className="u-mt2">
            <a href={`${data.factsheet_url}`} target="_blank">
              Fact Sheet
            </a>
          </div>
          <div className="u-mt2">
            <a href={`${data.tnc_url}`} target="_blank">
              Terms &amp; Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plans

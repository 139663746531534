import React from 'react'
import PropTypes from 'prop-types'
import SignaturePad from 'react-signature-canvas'
import classNames from 'classnames'

function ESignature({ className, assignRef, ...rest }) {
  const canvasProps = React.useMemo(() => {
    return { className: classNames('esignature', className) }
  }, [className])

  return <SignaturePad canvasProps={canvasProps} ref={assignRef} {...rest} />
}

ESignature.propTypes = {
  className: PropTypes.string,
  assignRef: PropTypes.func,
}

export default ESignature

import React, { PureComponent } from 'react'
import { Detail } from 'spd-oa/components/common'
import SignupConfig from 'spd-oa/config'
import Utils from 'spd-oa/utils'

class Summary extends PureComponent {
  render() {
    const { contextConfig } = this.props
    const fields =
      (SignupConfig.confirmationFields &&
        SignupConfig.confirmationFields[contextConfig['application_domain']] &&
        Object.keys(
          SignupConfig.confirmationFields[contextConfig['application_domain']][
            contextConfig['application_type']
          ]
        )) ||
      null
    return <div className="signup-summary">{this._renderFields(fields)}</div>
  }
  _renderFields(fields) {
    const { contextConfig } = this.props
    const data = { ...this.props.data }

    const { mode } = Utils.getOAMode()
    if (mode === 'kiosk') {
      data.personal_supporting_docs = {}
    }

    if (!fields) {
      return null
    }
    if (
      !contextConfig['application_domain'] ||
      !contextConfig['application_type']
    ) {
      return null
    }

    return fields.map((groupField, index) => {
      const group =
        SignupConfig.confirmationFields[contextConfig['application_domain']][
          contextConfig['application_type']
        ][groupField]

      return (
        <Detail.DetailNode
          id={`${group.id ? group.id : groupField}`}
          key={`${groupField}--${index}`}
          label={group.label}
        >
          {group.fields &&
            group.fields.map((f, i) => {
              let d
              if (f.parent && f.parent !== '') {
                if (data[groupField]) {
                  d = data[groupField][f.parent]
                  if (Array.isArray(f.field) && f.field.length > 0) {
                    d = {}
                    f.field.forEach((key) => {
                      if (data[groupField] && data[groupField][f.parent]) {
                        d[key] = data[groupField][f.parent][key]
                      } else if (data[f.parent]) {
                        d[key] = data[f.parent][key]
                      }
                    })
                  } else {
                    if (data[f.parent]) {
                      d = data[f.parent][f.field]
                    }
                  }
                }
              } else {
                if (data[groupField]) {
                  if (Array.isArray(f.field) && f.field.length > 0) {
                    d = {}
                    f.field.forEach((key) => {
                      d[key] = data[groupField][key]
                    })
                  } else {
                    d = data[groupField][f.field]
                  }
                }
              }
              if (d) {
                if (f.hasOwnProperty('show')) {
                  if (typeof f.show === 'boolean') {
                    if (f.show) {
                      return (
                        <Detail.DetailNodeItem
                          id={this.getNodeId(groupField, f)}
                          key={`${groupField}-${f.field}--${i}`}
                          label={f.label}
                          data={d}
                          render={(data) => {
                            if (f.resolve && typeof f.resolve === 'function') {
                              return f.resolve(data)
                            }

                            return <span>{d}</span>
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                  if (typeof f.show === 'function') {
                    if (f.show(d)) {
                      return (
                        <Detail.DetailNodeItem
                          id={this.getNodeId(groupField, f)}
                          key={`${groupField}-${f.field}--${i}`}
                          label={f.label}
                          data={d}
                          render={(data) => {
                            if (f.resolve && typeof f.resolve === 'function') {
                              return f.resolve(data)
                            }
                            return <span>{d}</span>
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                }
                return (
                  <Detail.DetailNodeItem
                    id={this.getNodeId(groupField, f)}
                    key={`${groupField}-${f.field}--${i}`}
                    label={f.label}
                    data={d}
                    render={(data) => {
                      if (f.resolve && typeof f.resolve === 'function') {
                        return f.resolve(data)
                      }

                      return <span>{d}</span>
                    }}
                  />
                )
              }
              return null
            })}
        </Detail.DetailNode>
      )
    })
  }
  getNodeId = (g, n) => {
    let id = `${g}`
    if (n.id) {
      id += `-${n.id}`
    } else if (n.field && typeof n.field !== 'string') {
      if (n.parent) {
        id += `-${n.parent}`
      }
    } else {
      id += `-${n.field}`
    }

    return id
  }
}

Summary.defaultProps = {
  contextConfig: {},
  data: {},
}

export default Summary

import React, { PureComponent } from 'react'
import { DatePicker } from 'spd-oa/components/common'
import FormErrorBlock from './FormErrorBlock'
import moment from 'moment'

class FormDate extends PureComponent {
  state = {
    error: null,
  }

  componentDidMount() {
    const { id, value, setFieldValue } = this.props
    let date = moment(value, moment.ISO_8601, true)

    if (date.isValid()) {
      setTimeout(() => {
        setFieldValue(id, date.toDate())
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { value, error } = this.props

    if (
      value !== prevProps.value &&
      Object.prototype.toString.call(value) === '[object Date]'
    ) {
      this._checkForError()
    }

    if (value === prevProps.value && value !== '') {
      this._checkForError()
    }
  }

  render() {
    const {
      id,
      label,
      error,
      value,
      name,
      formatDate,
      isInline,
      withTime,
      isRange,
      disablePrev,
      disableNext,
      availableDays,
      offset,
      showLegend,
      isInput,
      disabled,
      disableBefore,
      disableAfter,
    } = this.props
    const { error: stateError } = this.state
    return (
      <div
        className={
          'lm--formItem lm--formItem--inline form-date' +
          (stateError ? ' is-error' : '')
        }
      >
        <div className="lm--formItem-label">{label}</div>
        <div className="lm--formItem-control">
          <DatePicker
            id={id}
            onSelectHandler={this._onSelectHandler}
            isRange={isRange}
            disablePrev={disablePrev}
            disableNext={disableNext}
            disableBefore={disableBefore}
            disableAfter={disableAfter}
            availableDays={availableDays}
            offset={offset}
            value={value}
            showLegend={showLegend}
            onBlurHandler={this._blurHandler}
            onChangeHandler={this._onChangeHandler}
            isInput={isInput}
            disabled={disabled}
          />
          {stateError && (
            <FormErrorBlock id={`${id}-field_error`} error={stateError} />
          )}
        </div>
      </div>
    )
  }
  _onChangeHandler = (e) => {
    const { handleChange, setFieldValue, id } = this.props
    if (setFieldValue) {
      let value = e.target.value
      var date = moment(value, 'DD/MM/YYYY', true)

      setFieldValue(id, date.isValid() ? date.toDate() : value)
      if (handleChange) {
        handleChange(e)
      }
    }
  }

  _onSelectHandler = (id, value) => {
    const { setFieldValue } = this.props
    if (setFieldValue) {
      setFieldValue(id, value === null ? '' : value)
    }
    this._checkForError()
  }

  _blurHandler = (e) => {
    const { handleBlur, error } = this.props
    if (handleBlur) {
      handleBlur(e)
    }
    this._checkForError()
  }

  _checkForError = () => {
    const { error } = this.props

    if (error) {
      this.setState((prevState) => ({
        ...prevState,
        error: error,
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: null,
      }))
    }
  }
}

export default FormDate

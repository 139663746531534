import React, { PureComponent } from 'react'

import { FormCheckBox } from 'spd-oa/components/FormElement'

class TermsControl extends PureComponent {
  render() {
    const {
      id,
      name,
      value,
      byClickMessage,
      handleChange,
      children,
    } = this.props
    return (
      <div className="terms-node">
        <div className="terms-control">
          <FormCheckBox
            onlyElem
            id={id}
            name={name}
            handleChange={handleChange}
            checked={value}
          />
        </div>
        <div className="terms-text">
          <span className="by-clicking">{byClickMessage}</span>
          {children}
        </div>
      </div>
    )
  }
}

TermsControl.defaultProps = {
  byClickMessage: 'By clicking Submit, I:',
  id: '',
  name: '',
  value: '',
  handleChange: () => {},
}

export default TermsControl

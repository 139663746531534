import React from 'react'
import SignaturePopup from './SignaturePopup'
import { API } from 'spd-oa/services'
import PropTypes from 'prop-types'
import { FormErrorBlock } from 'spd-oa/components/FormElement'

function Signature(props) {
  const [openPopup, setOpenPopup] = React.useState()
  const [signature, setSignature] = React.useState({
    trimmedSign: undefined,
    rawSign: undefined,
  })

  const handleClick = () => {
    if (!props.eSignature) {
      return
    }
    setOpenPopup(true)
  }

  const handleClose = () => {
    setOpenPopup(false)
    props.setTouched && props.setTouched(props.name, true)
  }

  const handleSaveSignature = (trimmedSign, rawSign) => {
    return API.eSignatureServices
      .uploadSignature(trimmedSign)
      .then((data) => {
        setSignature({ trimmedSign, rawSign })
        props.onSignatureUploaded(props.name, data)
      })
      .catch(() => {
        props.setError(props.name, 'Upload signature failed.')
      })
  }

  return (
    <div className="signature-node">
      {props.eSignature && (
        <SignaturePopup
          open={openPopup}
          handleClose={handleClose}
          handleSave={handleSaveSignature}
          value={signature.rawSign}
        />
      )}
      <div className="sn-signed" onClick={handleClick}>
        {signature.trimmedSign && (
          <img src={signature.trimmedSign} alt="Signed" />
        )}
        {props.displayValue && (
          <p className="display-value">{props.displayValue}</p>
        )}
        {!signature.trimmedSign && !props.displayValue && props.helperText && (
          <p className="helper-text" aria-disabled={!props.eSignature}>
            {props.helperText}
          </p>
        )}
      </div>
      <div className="sn-line" />
      <div className="sn-by">
        {props.by.map((o) => (
          <span key={o}>{o}</span>
        ))}
      </div>
      <FormErrorBlock error={props.error} />
    </div>
  )
}

Signature.propTypes = {
  name: PropTypes.string,
  displayValue: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  by: PropTypes.arrayOf(PropTypes.string),
  eSignature: PropTypes.bool,
  onSignatureUploaded: PropTypes.func,
  setError: PropTypes.func,
  setTouched: PropTypes.func,
}

Signature.defaultProps = {
  by: ['Signature'],
}

export default Signature

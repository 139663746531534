import React, { Fragment, PureComponent } from 'react'
import { ThirdPartySiteDialog } from 'spd-oa/components'
import { FormChoose, FormGroup } from 'spd-oa/components/FormElement'
import { Tooltip } from 'spd-oa/components/common'
import Utils from 'spd-oa/utils'
import { openLink } from 'spd-oa/helpers'

class Utilities extends PureComponent {
  state = {
    timeslot: [],
  }
  render() {
    const { domain } = this.props

    return (
      <FormGroup
        groupTitle={
          <Fragment>
            {'Choose Your Utilities'}
            <Tooltip>
              <span>
                The registered account holder will be liable for the usage of
                any utility supply used without an active account.
              </span>
            </Tooltip>
          </Fragment>
        }
        groupHeaderContent={
          domain === 'residential' && (
            <>
              <p>
                {`For Residential Premises, Electricity and Water are selected by default.`}
              </p>
            </>
          )
        }
        groupField={
          domain === 'commercial' && (
            <UtilitiesFormChoose {...this.props} onlyElem />
          )
        }
      >
        {domain === 'residential' && (
          <UtilitiesFormChoose {...this.props} onlyElem={false} />
        )}
      </FormGroup>
    )
  }

  // _turnOnDateTimeHandler = (group, value) => {
  //   const { setFieldValue } = this.props
  //   setFieldValue(group, value)
  //   if (group === 'turn_on_date') {
  //     this._fetchTimeslot(value)
  //   }
  // }
  // _fetchTimeslot = (date) => {
  //   API.retrieveTimeslot().then((data) => {
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       timeslot: data.timeslot,
  //     }))
  //   })
  // }
}

const UtilitiesFormChoose = ({ onlyElem, ...props }) => {
  const [cityGasLinkPrompt, setCityGasLinkPrompt] = React.useState(false)

  const handleCityGasLinkContinue = () => {
    setCityGasLinkPrompt(false)
    const cityGasLink = 'https://www.cityenergy.com.sg'
    openLink(cityGasLink, '_blank')
  }

  const handleCityGasLinkCancel = () => {
    setCityGasLinkPrompt(false)
  }

  const handleCityGasLink = (e) => {
    e.preventDefault()
    setCityGasLinkPrompt(true)
  }

  const townGasMsgMapping = {
    1: {
      gas_preselected: {
        has_gas: '',
        has_no_gas:
          'If you are not taking over the gas utility, the proof of ownership or tenancy agreement will be required for this application. City Energy Fees applies for future gas Turn-on requests.',
      },
      no_gas_preselected: {
        has_gas: (
          <span>
            Please contact City Energy at{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              '1800-555-1661'
            ) : (
              <a href="tel:1800-555-1661">1800-555-1661</a>
            )}{' '}
            for further assistance. City Energy fees applies for gas Turn-on
            requests.
          </span>
        ),
        has_no_gas: (
          <span>
            City Energy fees applies for future gas Turn-On requests. For more
            information,{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              'please contact City Energy at 1800-555-1661 for an appointment.'
            ) : (
              <>
                please click{' '}
                <a
                  href="https://www.cityenergy.com.sg"
                  onClick={handleCityGasLink}
                >
                  here
                </a>
                .
              </>
            )}
          </span>
        ),
      },
    },
    0: {
      gas_preselected: {
        has_gas: '',
        has_no_gas:
          'If you are not taking over the gas utility, the proof of ownership or tenancy agreement will be required for this application. City Energy Fees applies for future gas Turn-on requests.',
      },
      no_gas_preselected: {
        has_gas:
          'City Energy is not available at the premises, please seek alternatives.',
        has_no_gas:
          'City Energy is not available at the premises, please seek alternatives.',
      },
    },
    3: {
      gas_preselected: {
        has_gas: '',
        has_no_gas:
          'If you are not taking over the gas utility, the proof of ownership or tenancy agreement will be required for this application. City Energy Fees applies for future gas Turn-on requests.',
      },
      no_gas_preselected: {
        has_gas: (
          <span>
            Please contact City Energy at{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              '1800-555-1661'
            ) : (
              <a href="tel:1800-555-1661">1800-555-1661</a>
            )}{' '}
            to check for town gas availability and charges.
          </span>
        ),
        has_no_gas: (
          <span>
            Please contact City Energy at{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              '1800-555-1661'
            ) : (
              <a href="tel:1800-555-1661">1800-555-1661</a>
            )}{' '}
            to check for town gas availability and charges.
          </span>
        ),
      },
    },
  }

  const renderNotes = () => {
    let notes = [
      {
        values: ['water', 'gas'],
        notes: [
          <span>
            {props.domain === 'residential' && (
              <>
                For gas turn-on, please call City Energy at{' '}
                {Utils.getOAMode().mode === 'kiosk' ? (
                  '1800-555-1661'
                ) : (
                  <a href="tel:1800-555-1661">1800-555-1661</a>
                )}{' '}
                for an appointment after receiving email confirmation on your
                account opening.
              </>
            )}
            Note: There may be{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              'gas supply installation fee'
            ) : (
              <a
                href="https://www.cityenergy.com.sg"
                onClick={handleCityGasLink}
              >
                gas supply installation fee
              </a>
            )}{' '}
            chargeable by City Energy.
          </span>,
        ],
      },
      {
        values: ['electricity', 'gas'],
        notes: [
          <span>
            {props.domain === 'residential' && (
              <>
                For gas turn-on, please call City Energy at{' '}
                {Utils.getOAMode().mode === 'kiosk' ? (
                  '1800-555-1661'
                ) : (
                  <a href="tel:1800-555-1661">1800-555-1661</a>
                )}{' '}
                for an appointment after receiving email confirmation on your
                account opening.
              </>
            )}
            Note: There may be{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              'gas supply installation fee'
            ) : (
              <a
                href="https://www.cityenergy.com.sg"
                onClick={handleCityGasLink}
              >
                gas supply installation fee
              </a>
            )}{' '}
            chargeable by City Energy.
          </span>,
        ],
      },
      {
        values: ['electricity', 'water', 'gas'],
        notes: [
          <span>
            {props.domain === 'residential' && (
              <>
                For gas turn-on, please call City Energy at{' '}
                {Utils.getOAMode().mode === 'kiosk' ? (
                  '1800-555-1661'
                ) : (
                  <a href="tel:1800-555-1661">1800-555-1661</a>
                )}{' '}
                for an appointment after receiving email confirmation on your
                account opening.
              </>
            )}
            Note: There may be{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              'gas supply installation fee'
            ) : (
              <a
                href="https://www.cityenergy.com.sg"
                onClick={handleCityGasLink}
              >
                gas supply installation fee
              </a>
            )}{' '}
            chargeable by City Energy.
          </span>,
        ],
      },
      {
        values: ['gas'],
        notes: [
          <span>
            {props.domain === 'residential' && (
              <>
                For gas turn-on, please call City Energy at{' '}
                {Utils.getOAMode().mode === 'kiosk' ? (
                  '1800-555-1661'
                ) : (
                  <a href="tel:1800-555-1661">1800-555-1661</a>
                )}{' '}
                for an appointment after receiving email confirmation on your
                account opening.
              </>
            )}
            Note: There may be{' '}
            {Utils.getOAMode().mode === 'kiosk' ? (
              'gas supply installation fee'
            ) : (
              <a
                href="https://www.cityenergy.com.sg"
                onClick={handleCityGasLink}
              >
                gas supply installation fee
              </a>
            )}{' '}
            chargeable by City Energy.
          </span>,
        ],
      },
    ]

    if (props.domain === 'residential' && props.townGas !== undefined) {
      const {
        townGas,
        values: { services },
        gasPreSelected,
      } = props
      const hasGas = services.find((serv) => serv === 'gas')
      const gasStatus = hasGas ? 'has_gas' : 'has_no_gas'
      const gasPreSelectedStatus = gasPreSelected
        ? 'gas_preselected'
        : 'no_gas_preselected'

      const townGasNote = {
        values: services,
        notes: [townGasMsgMapping[townGas][gasPreSelectedStatus][gasStatus]],
      }

      return notes.map((note) =>
        services.every((r) => note.values.indexOf(r) >= 0) ? townGasNote : note
      )
    }

    return notes
  }

  return (
    <>
      <FormChoose
        id={'services'}
        title=""
        onlyElem={onlyElem}
        selected={props.values['services']}
        setFieldValue={props.setFieldValue}
        setFieldTouched={props.setFieldTouched}
        multiple={true}
        error={props.errors['services']}
        choices={[
          {
            label: 'Electricity',
            id: 'services_electricity',
            name: 'services',
            value: 'electricity',
            disabled: props.domain === 'residential',
          },
          {
            label: 'Water',
            id: 'services_water',
            name: 'services',
            value: 'water',
            disabled: props.domain === 'residential',
          },
          {
            label: 'Gas',
            id: 'services_gas',
            name: 'services',
            value: 'gas',
            disabled: props.townGas === '0' && !props.gasPreSelected,
          },
        ]}
        notes={renderNotes()}
      />
      {cityGasLinkPrompt && (
        <ThirdPartySiteDialog
          confirmHandler={handleCityGasLinkContinue}
          cancelHandler={handleCityGasLinkCancel}
        />
      )}
    </>
  )
}

export default Utilities

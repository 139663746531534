import React, { PureComponent } from 'react'
import moment from 'moment'
import { DatePicker, TimePicker, Icons } from 'spd-oa/components/common'
import { parseDate } from 'spd-oa/helpers'
import { FormErrorBlock } from 'spd-oa/components/FormElement'

require('moment-timezone')

const transformDate = (date) => {
  if (typeof date === 'string') {
    if (date !== '') {
      return new Date(date)
    }
  } else {
    if (date) {
      if (date.start && date.end) {
        const _dt = {
          start: date.start,
          end: date.end,
        }

        if (typeof date.start === 'string') {
          if (date.start !== '') {
            _dt.start = new Date(date.start)
          }
        }
        if (typeof date.end === 'string') {
          if (date.end !== '') {
            _dt.end = new Date(date.end)
          }
        }
        return _dt
      }
    }
  }
  return date
}

class FormDateTime extends PureComponent {
  state = {
    dateTime: transformDate(this.props.dateTimeValue),
    timeslotLoading: false,
  }

  dt = transformDate(this.props.dateTimeValue)

  componentDidMount() {
    const { id, setFieldValue } = this.props
    const { dateTime } = this.state
    if (dateTime) {
      setTimeout(() => {
        setFieldValue(id, dateTime)
        this._fetchTimeslot()
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { retrieveTimeslotAgain } = this.props
    if (retrieveTimeslotAgain !== prevProps.retrieveTimeslotAgain) {
      if (retrieveTimeslotAgain) {
        this._fetchTimeslot()
      }
    }
  }

  render() {
    const {
      id,
      dateId,
      timeId,
      dateRange,
      timeslotProps,
      dateProps,
      label,
      error,
      value,
      name,
      formatDate,
      isInline,
      withTime,
      setFieldValue,
      isRenderNote,
      disablePrev,
      availableDays,
      offset,
      publicHolidays,
      children,
    } = this.props
    const { timeslotLoading } = this.state
    return (
      <div className="lm--formItem lm--formItem--inline form-datetime form-date form-time">
        <div className="lm--formItem-label">{label}</div>
        <div className="lm--formItem-control">
          <div className="form-datetime_container">
            <DatePicker
              id={dateId}
              onSelectHandler={this._onChangeHandler}
              isRange={dateRange}
              disablePrev={disablePrev}
              availableDays={availableDays}
              publicHolidays={publicHolidays}
              offset={offset}
              {...dateProps}
              onChangeHandler={this._onChangeHandler}
              disableToday
            />
            {timeslotProps.timeslot && timeslotProps.timeslot.length > 0 && (
              <TimePicker
                id={timeId}
                onSelectHandler={this._onChangeHandler}
                loading={timeslotLoading}
                {...timeslotProps}
              />
            )}
            {timeslotProps.fetching && (
              <figure className="datetime-loader">
                <Icons.spinloader width={25} height={25} color={['#333333']} />
              </figure>
            )}
          </div>
          {isRenderNote &&
            timeslotProps.timeslot &&
            timeslotProps.timeslot.length > 0 && (
              <small>
                Please arrange for someone to be present for the appointment.
              </small>
            )}
          {children && <small>{children}</small>}
          {timeslotProps.error && (
            <FormErrorBlock
              id={`${id}-field_error`}
              error={timeslotProps.error}
            />
          )}
        </div>
      </div>
    )
  }
  _onChangeHandler = (group, value) => {
    const {
      dateId,
      timeId,
      id,
      setFieldValue,
      timeslotProps,
      dateProps,
      useHash,
      hashConfig,
    } = this.props
    if (!this._dt && dateProps.value) {
      this.dt = moment(dateProps.value)
        .tz('Asia/Singapore')
        .format()
    }
    if (dateId === group) {
      if (value) {
        if (useHash) {
          let hash = this._getHash(value, false)
          setFieldValue(hashConfig.dateId, hash)
        }
        value.setHours(0, 0, 0)
        let dateVal = moment(value)

        this.dt = dateVal
          .utcOffset(0, Math.sign(value.getTimezoneOffset()) !== 1)
          .tz('Asia/Singapore')
          .format()

        this._fetchTimeslot()
        setFieldValue(group, this.dt)
        setFieldValue(id, this.dt)
      }
    }

    if (timeId === group) {
      if (this.dt && value) {
        this.dt = this._setDateWithTime(this.dt, value)
      }
      if (timeslotProps.apiHandler) {
        // Try to confirm if apihandler is being passed,
        // if success then fire callback (setFieldValue)
        this.setState((prevState) => ({
          ...prevState,
          timeslotLoading: true,
        }))

        timeslotProps.apiHandler(
          this.dt,
          timeId,
          `${useHash ? value : ''}`,
          () => {
            setFieldValue(group, this.dt)
            setFieldValue(id, this.dt)
            if (useHash) {
              setFieldValue(hashConfig.timeId, value)
            }
            this.setState((prevState) => ({
              ...prevState,
              timeslotLoading: false,
            }))
          },
          () => {
            // Err callback
            this.setState((prevState) => ({
              ...prevState,
              timeslotLoading: false,
            }))
          }
        )
      } else {
        if (useHash) {
          setFieldValue(hashConfig.timeId, value)
        }
        setFieldValue(group, this.dt)
        setFieldValue(id, this.dt)
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      dateTime: this.dt,
    }))
  }

  // _onTimeSelectHandler = (value, group) => {
  //   const { setFieldValue } = this.props
  //   setFieldValue(group, value)
  // }

  _setDateWithTime(date, t) {
    if (typeof t !== 'string') return

    const { useHash, timeslotProps } = this.props
    let d = date

    if (d.start) {
      d = d.start
    }
    if (!useHash) {
      let timeSplit = t.split('-')
      if (timeSplit.length > 1) {
        let sParts = timeSplit[0].match(/(\d+):(\d+)/)
        let sHours = parseInt(sParts[1], 10)
        let sMinutes = parseInt(sParts[2], 10)

        let eParts = timeSplit[1].match(/(\d+):(\d+)/)
        let eHours = parseInt(eParts[1], 10)
        let eMinutes = parseInt(eParts[2], 10)

        let start = new Date(d)
        start.setHours(sHours)
        start.setMinutes(sMinutes)
        let end = new Date(d)
        end.setHours(eHours)
        end.setMinutes(eMinutes)

        let dt = {
          start: moment(start)
            .tz('Asia/Singapore')
            .format(),
          end: moment(end)
            .tz('Asia/Singapore')
            .format(),
        }
        return dt
      } else {
        let parts = t.match(/(\d+):(\d+) (\w+)/),
          hours = /am/i.test(parts[3])
            ? parseInt(parts[1], 10)
            : parseInt(parts[1], 10) + 12,
          minutes = parseInt(parts[2], 10)

        d.setHours(hours)
        d.setMinutes(minutes)
      }
    } else {
      if (timeslotProps.timeslot.length > 0) {
        let timeSplit = timeslotProps.timeslot.find(
          (time) => time.time_hash === t
        )
        if (timeSplit) {
          let dt = {
            start: moment(timeSplit.start)
              .tz('Asia/Singapore')
              .format(),
            end: moment(timeSplit.end)
              .tz('Asia/Singapore')
              .format(),
          }
          return dt
        }
      }
    }
    let res = moment(d)
    return res.tz('Asia/Singapore').format()
  }

  _fetchTimeslot() {
    const { retrieveTimeslotHandler } = this.props
    const hash = this._getHash(this.dt, false)
    if (retrieveTimeslotHandler) {
      const date = parseDate(this.dt, 'YYYYMMDD')
      retrieveTimeslotHandler(date, this.dt, hash)
    }
  }

  _getHash(value) {
    const { dateslot } = this.props
    // get hash for date
    if (dateslot.length > 0) {
      let dHash = ''
      let d1 = moment(value)
      let date = dateslot.find((ds) => {
        let d2 = moment(ds.date).tz('Asia/Singapore')
        if (d1.format('YYYY MM DD') === d2.format('YYYY MM DD')) {
          return true
        }
        return false
      })
      if (date && date.date_hash) {
        dHash = date.date_hash
      }
      return dHash
    }
    return null
  }
}

FormDateTime.defaultProps = {
  timeslotProps: {
    timeslot: [],
  },
  useHash: false,
  hashConfig: {
    id: '',
    dateId: '',
    timeId: '',
  },
}

export default FormDateTime

import React, { PureComponent } from 'react'
import { Icons, Button } from 'spd-oa/components/common'
import { API } from 'spd-oa/services'
import FormErrorBlock from './FormErrorBlock'
import Utils from 'spd-oa/utils'

const MESSAGE = {
  moreThanLimit: 'Please upload attachment with size no more than %d MB.',
  errorMessage: 'There was a problem parsing your file.',
}

const MAX_NAME_CHAR = 40

class Upload {
  static getAll({ transaction_id }) {
    let result = {}
    API.getAttachments({
      transaction_id,
    })
      .then((res) => {
        result = {
          ...res,
          success: true,
        }
      })
      .catch((err) => {
        result.success = false
        result.err = err
      })
    return result
  }

  static remove({ transaction_id, type }) {
    return new Promise((resolve, reject) => {
      let result = {}
      API.deleteAttachment({
        transaction_id,
        type,
      })
        .then((res) => {
          result = {
            ...res,
            success: true,
          }
          resolve(result)
        })
        .catch((err) => {
          result.success = false
          result.err = err
          resolve(result)
        })
    })
  }

  constructor(file, max = 5000000, params = {}) {
    this._reader = new FileReader()
    this._file = file
    this._max = max
    this._params = params
  }

  upload() {
    return new Promise((resolve, reject) => {
      let result = {}
      if (this._file.length === 1) {
        API.attachDoc({
          transaction_id: this._params.transaction_id,
          type: this._params.type,
          file: this._file[0],
        })
          .then((res) => {
            result = {
              ...res,
              success: true,
            }
            resolve(result)
          })
          .catch((err) => {
            result.success = false
            result.err = err
            resolve(result)
          })
      }
    })
  }

  process(upload = false) {
    if (!this._file) return
    if (Array.isArray(this._file) && this._file.length === 0) return
    if (Array.isArray(this._file) && this._file.length > 0) {
      return new Promise(async (resolve, reject) => {
        const files = []
        const errors = []
        let file
        let fileIndex
        let uploadRes

        this._reader.onload = async () => {
          if (file.size <= this._max) {
            if (upload) {
              uploadRes = await this.upload()
            }
            if (uploadRes && !uploadRes.success) {
              reject({
                err: new Error(
                  'There was an error when uploading. Please try again.'
                ),
              })
            }
            let filePayload

            if (upload) {
              filePayload = {
                index: fileIndex,
                name: file.name,
                type: file.type,
              }
            } else {
              filePayload = {
                index: fileIndex,
                name: file.name,
                type: file.type,
                data: this._reader.result,
              }
            }

            files.push(filePayload)
            if (uploadRes) {
              resolve(files, {
                ...uploadRes,
              })
            } else {
              resolve(files)
            }
          } else {
            let errMsg = MESSAGE.moreThanLimit.replace(
              /%d/g,
              this._max / 1000000
            )
            errors.push({
              index: fileIndex,
              name: file.name,
              error: new Error(errMsg),
            })
            if (uploadRes) {
              reject({
                err: errors,
                upload: {
                  ...uploadRes,
                },
              })
            } else {
              reject({
                err: errors,
              })
            }
          }
        }
        this._file.forEach((f, fIn) => {
          file = f
          fileIndex = fIn
          this._reader.readAsDataURL(f)
        })
      })
    }
    return new Promise(async (resolve, reject) => {
      let uploadRes
      if (upload) {
        uploadRes = await this.upload()
      }
      this._reader.onload = () => {
        if (this._file.size <= this._max) {
          if (uploadRes) {
            resolve(
              {
                name: this._file.name,
                data: this._reader.result,
              },
              {
                ...uploadRes,
              }
            )
          } else {
            resolve({
              name: this._file.name,
              data: this._reader.result,
            })
          }
        } else {
          let errMsg = MESSAGE.moreThanLimit.replace(/%d/g, this._max / 1000000)
          if (uploadRes) {
            reject({
              err: {
                name: this._file.name,
                error: new Error(errMsg),
              },
              upload: {
                ...uploadRes,
              },
            })
          } else {
            reject({
              name: this._file.name,
              error: new Error(errMsg),
            })
          }
        }
      }

      this._reader.onerror = () => {
        this._reader.abort()
        let errMsg = MESSAGE.errorMessage
        reject({
          name: this._file.name,
          error: new Error(errMsg),
        })
      }

      this._reader.readAsDataURL(this._file)
    })
  }
}

class Photo extends PureComponent {
  state = {
    thumb: null,
    thumbName: '',
    errMsg: '',
  }
  _onChangeHandler = (e) => {
    e.preventDefault()
    const { setFieldValue, id } = this.props
    const file = e.target.files[0]
    this.uploader = new Upload(file)
    this.uploader
      .process()
      .then((res) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            thumb: res.data,
            thumbName: res.name,
          }),
          () => {
            setFieldValue(id, this.state.thumb)
          }
        )
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          errMsg: err,
        }))
      })
  }

  render() {
    const { thumb, thumbName } = this.state
    const { id, errors, label } = this.props
    return (
      <div
        className={`upload-input upload-img img-placeholder ${
          thumb ? '--uploaded' : ''
        }`}
      >
        <input
          type="text"
          name={`${id}`}
          id={`${id}`}
          value={label}
          disabled
          // onChange={setFieldValue}
        />
        <input
          type="file"
          accept="image/jpg, image/jpeg, image/png"
          error={errors[0]}
          onChange={this._onChangeHandler}
        />
        <div className="upload_field">
          {label !== '' && <span>{label}</span>}
          {thumb && (
            <figure className="upload_field--thumb">
              <img src={thumb} alt={thumbName} />
            </figure>
          )}
        </div>
      </div>
    )
  }
}

Photo.defaultProps = {
  id: '',
  error: [],
  label: '',
  setFieldValue: () => {},
}

class Doc extends PureComponent {
  checkedForVal = false
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      errMsg: null,
    }
    // this._checkForValue()
  }

  componentDidMount() {
    if (!this.checkedForVal) {
      this._checkForValue()
    }
  }

  componentDidUpdate() {
    if (!this.checkedForVal) {
      this._checkForValue()
    }
  }

  render() {
    const { files, errMsg, processing, deleting } = this.state

    const { mode: oaMode } = Utils.getOAMode()
    const unavailable = oaMode === 'kiosk' && 'To be provided on confirmation'
    const {
      id,
      errors,
      label,
      max,
      format,
      multiple,
      onlyElem,
      useFilename,
    } = this.props
    return (
      <div
        id={`upload_doc--${id}`}
        className={`upload-input upload-doc ${
          files.length > 0 ? '--uploaded' : ''
        } ${processing ? 'processing' : ''} ${
          multiple ? 'multiple' : 'single'
        }`}
      >
        {label && !onlyElem && <div className="upload-label">{label}</div>}
        <div className="upload_field">
          {unavailable && <div> {unavailable} </div>}
          {!unavailable && files.length === 0 && !multiple && (
            <div className="mock_file">
              <input
                type="file"
                name={`${id}`}
                id={`${id}`}
                accept={`${format.join(', ')}`}
                error={errors[0]}
                onChange={this._onChangeHandler}
                multiple={multiple}
                disabled={processing || (!multiple && files.length > 0)}
                ref={(el) => {
                  this._file = el
                }}
              />
              <div className="mock_file--btn">
                {processing ? (
                  <Icons.spinloader
                    width={25}
                    height={25}
                    color={['#333333']}
                  />
                ) : (
                  `Choose File`
                )}
              </div>
              <div className="mock_file--msg">
                {`${max ? `Max ${max / 1000000}MB` : ''}${
                  format !== '' ? `, file format ${format.join(', ')}` : ''
                }`}
              </div>
            </div>
          )}
          {!unavailable && files.length > 0 && (
            <div className="upload_field-results">
              <ul>
                {files.map((file, i) => {
                  return (
                    <li
                      key={`doc-${i}--${file.name}`}
                      className={`${deleting ? 'deleting' : ''}`}
                    >
                      <i>
                        <Icons.plans
                          width={14}
                          height={16}
                          color={['#818a91']}
                        />
                      </i>
                      <span>
                        {useFilename
                          ? `${file.name}`
                          : `${id}.${file.name.split('.').pop()}`}
                      </span>

                      <Button
                        variant="secondary"
                        bare={true}
                        onClickHandler={(e) => {
                          this._deleteDocHandler(i)
                        }}
                        disabled={deleting}
                      >
                        {deleting ? `Deleting..` : `Delete`}
                      </Button>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {errMsg && errMsg.message !== '' && (
            <FormErrorBlock
              id={`${id}-upload-field_error`}
              error={errMsg.message}
            />
          )}
          {/* {
            errors && (<FormErrorBlock id={`${id}-field_error`} error={error} />)
          } */}
        </div>
      </div>
    )
  }

  _sendFiles() {
    const { setFieldValue, id, multiple } = this.props
    const { files } = this.state
    let value = files
    if (!multiple) {
      value = files[0]
    }
    setFieldValue(id, value)
  }

  _onChangeHandler = (e) => {
    e.preventDefault()
    const { params, upload, max, multiple } = this.props
    const files = e.target.files
    this.setState((prevState) => ({
      ...prevState,
      errMsg: null,
    }))
    if (files && files.length > 0) {
      const _files = [...files]
      let maxCharErrMsg = 'Please rename your filename to 40 characters'
      let maxCharErr = false
      if (multiple) {
        maxCharErrMsg =
          "Please rename one/more of your files' name to 40 characters"
      }
      _files.forEach((file) => {
        if (file.name) {
          let name = file.name
            .split('.')
            .slice(0, -1)
            .join('.')
          if (name.length > MAX_NAME_CHAR) {
            maxCharErr = true
          }
        }
      })
      if (maxCharErr) {
        this.setState((prevState) => ({
          ...prevState,
          errMsg: new Error(maxCharErrMsg),
        }))
        return null
      }
      if (params) {
        this.uploader = new Upload([...files], max, {
          transaction_id: params.transaction_id,
          type: params.type,
        })
      } else {
        this.uploader = new Upload([...files], max)
      }
      this.setState((prevState) => ({
        ...prevState,
        processing: true,
      }))
      this.uploader
        .process(upload)
        .then((res) => {
          this.setState(
            (prevState) => ({
              ...prevState,
              errMsg: null,
              processing: false,
              files: res,
            }),
            () => {
              this._sendFiles()
            }
          )
        })
        .catch((res) => {
          let errMsg = ''
          if (res.err) {
            errMsg = (res.err[0] && res.err[0].error) || res.err
          } else {
            errMsg = res.error
          }
          this.setState((prevState) => ({
            ...prevState,
            processing: false,
            errMsg: errMsg,
          }))
        })
    }
  }

  _deleteDocHandler = async (docIndex) => {
    const { params, upload } = this.props
    const { files } = this.state
    if (files[docIndex]) {
      if (upload) {
        this.setState((prevState) => ({
          ...prevState,
          deleting: true,
        }))
        const res = await Upload.remove({
          transaction_id: params.transaction_id,
          type: params.type,
        })
        if (res && res.success) {
          this.setState(
            (prevState) => {
              const _files = [...prevState.files]
              _files.splice(docIndex, 1)
              return {
                ...prevState,
                deleting: false,
                files: [..._files],
              }
            },
            () => {
              this._sendFiles()
            }
          )
        } else {
          this.setState((prevState) => ({
            ...prevState,
            deleting: false,
            errMsg: new Error(
              'There was a problem on deleting the attachment. Please try again.'
            ),
          }))
        }
      } else {
        this.setState(
          (prevState) => {
            const _files = [...prevState.files]
            _files.splice(docIndex, 1)
            return {
              ...prevState,
              files: [..._files],
            }
          },
          () => {
            this._sendFiles()
          }
        )
      }
    }
    this._file.value = ''
    this.uploader = null
  }

  _checkForValue() {
    const { value, id } = this.props
    if (value && value.length > 0) {
      this.setState(
        (prevState) => ({
          ...prevState,
          files: value.map((v, i) => {
            let name = ''
            if (typeof v === 'string') {
              name = v
            } else {
              name = v.name
            }
            return {
              name: `${name}`,
              // name: `${id}.${v.split('/').pop()}`,
              // name: v.filename,
              type: v,
            }
          }),
        }),
        () => {
          this.checkedForVal = true
        }
      )
    }
  }
}

Doc.defaultProps = {
  label: null,
  max: 4000000,
  format: ['.pdf', '.jpg', '.jpeg', '.png'],
  errors: [],
  id: '',
  multiple: false,
  useFilename: false,
  setFieldValue: () => {},
  value: [],
}

const FormWrap = (Component) => {
  return class extends PureComponent {
    static defaultProps = {
      label: '',
    }
    render() {
      const { label, ...rest } = this.props
      return (
        <div className={'lm--formItem lm--formItem--inline '}>
          <div className="lm--formItem-label">{`${label}`}</div>
          <div className="lm--formItem-control">
            <Component {...rest} />
          </div>
        </div>
      )
    }
  }
}

export default {
  Photo: Photo,
  Doc: Doc,
}

import React from 'react'

import Premises from '../pages/wiz/_Premises'
import Particulars from '../pages/wiz/_Particulars'
import Utilities from '../pages/wiz/_Utilities'
import ResiCompUploadDocs from '../pages/wiz/_UploadDocs.resi.comp'
import CommCompUploadDocs from '../pages/wiz/_UploadDocs.comm.comp'
import Company from '../pages/wiz/_Company'
import Review from '../pages/wiz/_Review'
import Confirmation from '../pages/wiz/_Confirmation'
import CommReview from '../pages/wiz/_CommReview'
import Payment from '../pages/wiz/_Payment'

import { withContext } from 'spd-oa/context'
import { getESignatureConfig } from 'spd-oa/pages/wiz/Signature/helpers'
import { withKioskSideBarContext } from 'spd-oa/kioskContext'

const indexPages = {
  residential: '/open-account/residential',
  commercial: '/open-account/commercial',
}

const esigConfig = getESignatureConfig()

const pages = {
  residential: {
    start: '/open-account/residential',
    personal: {
      '/open-account/residential/personal/premises': {
        title: 'Premises',
        component: withContext(Premises),
      },
      '/open-account/residential/personal/particulars': {
        title: 'Personal Information',
        component: withContext(Particulars),
      },
      '/open-account/residential/personal/utilities': {
        title: 'Utilities',
        component: withContext(Utilities),
      },
      '/open-account/residential/personal/confirmation': {
        title: 'Review',
        component: withContext(Confirmation),
      },
    },
    company: {
      '/open-account/residential/company/premises': {
        title: 'Premises',
        component: withContext(Premises),
      },
      '/open-account/residential/company/particulars': {
        title: 'Company Information',
        component: withContext(Company),
      },
      '/open-account/residential/company/review': {
        title: esigConfig.reviewStepTitle,
        component: withContext(Review),
      },
      '/open-account/residential/company/upload': {
        title: 'Upload and Submit',
        component: withContext(ResiCompUploadDocs),
      },
      '/open-account/residential/company/confirmation': {
        title: 'Review',
        component: withContext(Confirmation),
      },
    },
  },

  commercial: {
    start: '/open-account/commercial',
    personal: {
      '/open-account/commercial/personal/premises': {
        title: 'Premises',
        component: withContext(Premises),
      },
      '/open-account/commercial/personal/particulars': {
        title: 'Personal Information',
        component: withContext(Particulars),
      },
      '/open-account/commercial/personal/review': {
        title: 'Review',
        component: withContext(CommReview),
      },
      '/open-account/commercial/personal/payment': {
        title: 'Payment',
        component: withKioskSideBarContext(withContext(Payment)),
      },
    },
    company: {
      '/open-account/commercial/company/premises': {
        title: 'Premises',
        component: withContext(Premises),
      },
      '/open-account/commercial/company/particulars': {
        title: 'Company Information',
        component: withContext(Company),
      },
      '/open-account/commercial/company/review': {
        title: esigConfig.reviewStepTitle,
        component: withContext(Review),
      },
      '/open-account/commercial/company/upload': {
        title: 'Upload',
        component: withContext(CommCompUploadDocs),
      },
      '/open-account/commercial/company/confirmation': {
        title: 'Review',
        component: withContext(CommReview),
      },
      '/open-account/commercial/company/payment': {
        title: 'Payment',
        component: withKioskSideBarContext(withContext(Payment)),
      },
    },
  },
}

const defaultFlow = {
  residential: {
    start: pages['residential'].start,
    personal: [
      '/open-account/residential/personal/premises',
      '/open-account/residential/personal/particulars',
      '/open-account/residential/personal/confirmation',
    ],
    company: [
      '/open-account/residential/company/premises',
      '/open-account/residential/company/particulars',
      '/open-account/residential/company/review',
      '/open-account/residential/company/upload',
    ],
  },

  commercial: {
    start: pages['commercial'].start,
    personal: [
      '/open-account/commercial/personal/premises',
      '/open-account/commercial/personal/particulars',
      '/open-account/commercial/personal/review',
      '/open-account/commercial/personal/payment',
    ],
    company: [
      '/open-account/commercial/company/premises',
      '/open-account/commercial/company/particulars',
      '/open-account/commercial/company/review',
      '/open-account/commercial/company/upload',
      '/open-account/commercial/company/payment',
    ],
  },
}

let flow = getFlowFromSession() || {
  residential: {
    start: pages['residential'].start,
    personal: [
      '/open-account/residential/personal/premises',
      '/open-account/residential/personal/particulars',
      '/open-account/residential/personal/confirmation',
    ],
    company: [
      '/open-account/residential/company/premises',
      '/open-account/residential/company/particulars',
      '/open-account/residential/company/review',
      '/open-account/residential/company/upload',
    ],
  },

  commercial: {
    start: pages['commercial'].start,
    personal: [
      '/open-account/commercial/personal/premises',
      '/open-account/commercial/personal/particulars',
      '/open-account/commercial/personal/review',
      '/open-account/commercial/personal/payment',
    ],
    company: [
      '/open-account/commercial/company/premises',
      '/open-account/commercial/company/particulars',
      '/open-account/commercial/company/review',
      '/open-account/commercial/company/upload',
      '/open-account/commercial/company/payment',
    ],
  },
}

const updateFlow = ({
  type,
  under,
  params = {
    removeId: [],
    addId: [],
  },
}) => {
  if (flow[type]) {
    if (flow[type][under]) {
      if (params.removeId.length > 0) {
        params.removeId.forEach((id) => {
          let idx = flow[type][under].findIndex((v) => v === id)
          if (idx !== -1) {
            flow[type][under].splice(idx, 1)
          }
        })
      }

      if (params.addId.length > 0) {
        let paymentPath = ''
        if (type === 'commercial') {
          const _flow = flow[type][under]
          const paymentIdx = _flow.length - 1
          paymentPath = _flow[paymentIdx]
          flow[type][under].splice(paymentIdx, 1)
        }
        params.addId.forEach((id) => {
          let idx = flow[type][under].findIndex((v) => v === id)
          if (idx === -1) {
            flow[type][under].push(id)
          }
        })
        if (type === 'commercial') {
          flow[type][under].push(paymentPath)
        }
      }
    }
  }
  let currentFlow = sessionStorage.getItem('signupFlow')
  let newFlow = JSON.stringify(flow)
  if (currentFlow !== newFlow) {
    sessionStorage.setItem('signupFlow', JSON.stringify(flow))
  }
}

const getFlow = (type, under) => {
  let result = []
  if (flow[type]) {
    if (flow[type][under]) {
      result = getItems(flow[type][under], type, under)
    }
  }
  return result
}

const resetFlow = () => {
  flow = Object.assign({}, defaultFlow)
}

const myInfoUsage = [
  'residential.personal',
  'residential.company',
  'commercial.personal',
  'commercial.company',
]

const modesFor = {
  domain: {
    residential: ['normal', 'kiosk', 'mobile'],
    commercial: ['normal', 'kiosk', 'mobile'],
  },
  residential: {
    personal: ['normal'],
    company: ['normal'],
  },
  commercial: {
    personal: ['normal'],
    company: ['normal'],
  },
}

const externalAppConfig = {
  mobile: {
    app: 'infinity',
    type: 'residential',
    under: 'personal',
  },
}

function getItems(paths = [], root = '', parent = '') {
  if (paths.length === 0) return []
  if (root !== '' && parent === '') {
    return paths.map((path) => ({
      ...pages[root][path],
      route: path,
    }))
  } else if (root !== '' && parent !== '') {
    return paths.map((path) => ({
      ...pages[root][parent][path],
      route: path,
    }))
  }
  return paths.map((path) => ({
    ...pages[path],
    route: path,
  }))
}

function getFlowFromSession() {
  let result = sessionStorage.getItem('signupFlow')
  if (!result) {
    return null
  }
  return JSON.parse(result)
}

export {
  flow,
  getFlow,
  updateFlow,
  resetFlow,
  myInfoUsage,
  indexPages,
  modesFor,
  externalAppConfig,
}

import React, { useState, useEffect, useRef } from 'react'
import { Button, Icons } from 'spd-oa/components/common'
import FormErrorBlock from './FormErrorBlock'

const FormInput = (props) => {
  const {
    value: valueProps,
    error: errorProps,
    handleChange,
    setFieldValue,
    id,
    onlyNumber,
    capsOnChange,
    handleBlur,
    capsOnBlur,
    handleKeyPress,
    handleKeyDown,
    label,
    name,
    maxLength,
    isInline,
    ctaHandler,
    ctaLabel,
    note,
    loading,
    showError,
    children,
    disabled,
    trailZero,
    showModified,
  } = props
  const [value, setValue] = useState(valueProps)
  const [error, setError] = useState(errorProps)
  const [focused, setFocused] = useState(false)
  const [cursorPos, setCursorPos] = useState(0)

  const inputRef = useRef()

  useEffect(() => {
    if (capsOnBlur) {
      capsAllVals()
    }
  }, [])

  useEffect(() => {
    if (focused) {
      inputRef.current.setSelectionRange(cursorPos, cursorPos)
    }
  }, [focused, value])

  useEffect(() => {
    setValue(valueProps)
    setError(errorProps)
  }, [errorProps, valueProps])

  const fieldChange = (e) => {
    setCursorPos(e.target.selectionStart)
    if (setFieldValue) {
      let value = e.target.value
      if (onlyNumber) {
        value = value.replace(/[a-zA-Z]/g, '')
      }
      setFieldValue(id, value)
      if (handleChange) {
        handleChange(e)
      }
    } else {
      if (handleChange) {
        handleChange(e)
      }
    }
  }

  const changeHandler = (e) => {
    fieldChange(e)
  }

  const focusHandler = () => {
    setFocused(true)
  }

  const blurHandler = (e) => {
    if (capsOnBlur || capsOnChange) {
      capsAllVals()
    }
    if (handleBlur) {
      handleBlur(e)
    }
    if (error) {
      setError(error)
      setFocused(false)
    } else {
      setError(null)
      setFocused(false)
    }
  }

  const keypressHandler = (e) => {
    if (handleKeyPress) {
      handleKeyPress(e)
    }
  }

  const keydownHandler = (e) => {
    if (handleKeyDown) {
      handleKeyDown(e)
    }
  }

  const capsAllVals = () => {
    if (setFieldValue) {
      const val = value && value.toUpperCase()
      setFieldValue(id, val)
    }
  }

  return (
    <div
      className={`lm--formItem ${isInline ? 'lm--formItem--inline' : ''} ${
        error || showError ? 'is-error' : ''
      }${ctaHandler ? 'with-cta' : ''} ${note ? 'with-note' : ''}`}
    >
      {label && label !== '' && (
        <div className="lm--formItem-label">{label}</div>
      )}
      <div className="lm--formItem-control">
        {note && <span className="field-note">{note}</span>}
        <input
          ref={inputRef}
          className={capsOnChange ? 'lm--formItem-uppercase' : ''}
          aria-label={label}
          id={id}
          type="text"
          value={value}
          name={name}
          maxLength={maxLength}
          onBlur={blurHandler}
          onFocus={focusHandler}
          onChange={changeHandler}
          onKeyPress={keypressHandler}
          onKeyDown={keydownHandler}
          disabled={disabled}
        />
        {ctaHandler && (
          <div className="field-cta">
            <Button
              link={true}
              onClickHandler={ctaHandler}
              variant={'secondary'}
            >
              {ctaLabel || 'Continue'}
            </Button>
          </div>
        )}
        {loading && (
          <figure className="field-loader">
            <Icons.spinloader width={25} height={25} color={['#333333']} />
          </figure>
        )}
        {children && <div className="control-content">{children}</div>}
        <FormErrorBlock
          id={`${id}-field_error`}
          error={error || (showError && error)}
        />
      </div>
    </div>
  )
}

FormInput.defaultProps = {
  isInline: true,
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
}

export default FormInput

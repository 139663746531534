import React, { PureComponent } from 'react'
import { Icons } from '@casper-ui/components'

class AdBanner extends PureComponent {
  render() {
    const { to, type, image, title, children } = this.props

    return (
      <div
        className={`ad-banner${
          type === 'separate' ? '-separate' : type === 'single' ? '-single' : ''
        }`}
      >
        {type === 'separate' ? (
          <>
            <div className="ad-banner-img">
              <figure>{children}</figure>
            </div>
            <div className="ad-banner-title">
              {title && <h3>{title}</h3>}
              <a href={to} target="_blank">
                Learn More <Icons.NextArrow width={16} height={16} />
              </a>
            </div>
          </>
        ) : type === 'single' ? (
          <>
            <div className="ad-banner-img">
              <figure>{children}</figure>
            </div>
            <div className="ad-banner-title">
              {title && <h5>{title}</h5>}
              <a href={to} target="_blank">
                Learn More
              </a>
            </div>
          </>
        ) : (
          <a href={to} target="_blank">
            <figure>{children}</figure>
          </a>
        )}
      </div>
    )
  }
}

AdBanner.defaultProps = {
  to: '#',
  separate: false,
  image: null,
  title: '',
}

export default AdBanner

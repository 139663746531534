import React, { useState, useEffect, useRef } from 'react'
import OtpInput from 'react-otp-input'
import classnames from 'classnames'

import FormErrorBlock from './FormErrorBlock'
import Button from '../common/Button'
import Icons from '../common/Icons'

const FormOtpInput = ({
  numberOfInputs,
  isInline,
  error,
  note,
  label,
  preOtp,
  autoFocus,
  text,
  requesting,
  autoVerify,
  verifying,
  verified,
  verifyHandler,
  withRequestBtn,
  requestTimeout: requestTime,
  expiredTime,
  requestHandler,
  handleChange: propHandleChange,
  setFieldValue,
  children,
  ready,
  preRender,
  otpLabel,
  ...props
}) => {
  const [timeRemaining, setTimeRemaining] = useState(requestTime)
  const [timeExpireRemaining, setTimeExpireRemaining] = useState(expiredTime)

  let otpTimer = useRef(null)
  let otpExpiredTimer = useRef(null)

  const isRequestTimeout = timeRemaining === 0
  const isOTPExpired = timeExpireRemaining === 0

  const _OtpInput = classnames('lm--formItem form-item field-otp', {
    'lm--formItem--inline': isInline,
    'is-error': error,
    'with-note': note,
    'is-verified': verified,
  })

  const _RequestBtn = classnames(`field-otp__request`, {
    'is-timeout': isRequestTimeout,
  })

  const handleVerify = (val) => {
    verifyHandler(val)
  }

  const handleAutoVerify = (val) => {
    if (autoVerify) {
      if (numberOfInputs === val.length) {
        handleVerify(val)
      }
    }
  }

  const handleRequest = (e) => {
    requestHandler(e)
    setTimeRemaining(requestTime)
    setTimeExpireRemaining(expiredTime)
    if (otpExpiredTimer.current) {
      clearInterval(otpExpiredTimer.current)
    }
  }

  const handleChange = (val) => {
    handleAutoVerify(val)
    if (setFieldValue) {
      setFieldValue(props.id, val)
      return
    }
    propHandleChange(val)
  }

  const startRequestCountdown = () => {
    otpTimer.current = setInterval(() => {
      setTimeRemaining((prevState) => prevState - 1)
    }, 1000)
  }

  const startRequestExpireCountdown = () => {
    otpExpiredTimer.current = setInterval(() => {
      setTimeExpireRemaining((prevState) => prevState - 1)
    }, 1000)
  }

  const clearRequestCountdown = () => {
    clearInterval(otpTimer.current)
    otpTimer.current = null
  }

  const clearRequestExpireCountdown = () => {
    clearInterval(otpExpiredTimer.current)
    otpExpiredTimer.current = null
  }

  function renderField() {
    if (preRender) {
      if (typeof preRender === 'function') {
        preRender()
      } else {
        return preRender
      }
    }

    return (
      <>
        {/* <OTPTimer shouldCalculate={otpCalculateTimes} onExpired={onExpired} /> */}
        {text && text !== '' && <div className="control-prefield">{text}</div>}
        <div className="control-container">
          {preOtp && <span>{`${preOtp} -`}</span>}
          {withRequestBtn && requestHandler && (
            <Button
              className={_RequestBtn}
              onClickHandler={handleRequest}
              disabled={verified || ready || verifying || requesting}
            >
              {requesting ? `Requesting..` : `Request OTP`}
            </Button>
          )}
          {otpLabel && <div className="field-otp__label">{otpLabel}</div>}
          {ready && !verified && timeRemaining > 0 && (
            <>
              <small>
                You can request for new OTP in <b>{timeRemaining}</b> seconds.
                <br />
              </small>
            </>
          )}
          {ready && !verified && !timeRemaining && (
            <small>
              You can request for new OTP.{' '}
              <Button link onClickHandler={handleRequest}>
                Resend OTP
              </Button>
            </small>
          )}
          {ready && (
            <div className="field-otp__wrap">
              <OtpInput
                numInputs={numberOfInputs}
                inputStyle="field-otp__input"
                containerStyle="field-otp__cont"
                onChange={handleChange}
                shouldAutoFocus={autoFocus}
                isDisabled={
                  verifying ||
                  verified ||
                  !ready ||
                  isOTPExpired ||
                  props.disabled
                }
                {...props}
              />
              {verifying && (
                <Icons.spinloader
                  color={['#8c969d']}
                  className="btn-loader otp-loader"
                  width={30}
                  height={30}
                />
              )}
            </div>
          )}
        </div>
        {children && <div className="control-content">{children}</div>}
      </>
    )
  }

  useEffect(() => {
    if (ready) {
      if (requestTime && requestTime > 0) {
        startRequestCountdown()
        startRequestExpireCountdown()
      }
    }
  }, [ready, requestTime])

  useEffect(() => {
    return () => {
      clearRequestCountdown()
      clearRequestExpireCountdown()
    }
  }, [])

  useEffect(() => {
    if (timeRemaining === 0 || verified) {
      clearRequestCountdown()
    }
    if (timeExpireRemaining === 0 || verified) {
      clearRequestExpireCountdown()
    }
  }, [timeRemaining, timeExpireRemaining, verified])

  return (
    <div id={`${props.id}-form-item`} className={_OtpInput}>
      {label && <div className="lm--formItem-label label">{label}</div>}
      <div className="lm--formItem-control control">
        {renderField()}
        {note && <span className="field-note">{note}</span>}
        <FormErrorBlock
          id={`${props.id}-field_error`}
          error={
            (isOTPExpired &&
              'Your OTP has expired. Please request for a new OTP.') ||
            error
          }
        />
      </div>
    </div>
  )
}

FormOtpInput.defaultProps = {
  isInline: true,
  numberOfInputs: 4,
  id: '',
  autoFocus: false,
  autoVerify: false,
  requesting: false,
  verifying: false,
  verified: false,
  ready: true,
  withRequestBtn: false,
  requestTimeout: 0,
  expiredTime: 0, // in seconds
  requestHandler: () => {},
  verifyHandler: () => {},
}

export default FormOtpInput

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { Header, WizSteps } from 'spd-oa/components/wiz'
import { ConfirmationDialog } from 'spd-oa/components/common'
import { SignupProvider, withContext } from 'spd-oa/context'
import { API } from 'spd-oa/services'
import SignupConfig from 'spd-oa/config'
import Utils from 'spd-oa/utils'
import { isEmpty } from 'lodash'

const getCurrentStep = (type, path, under) => {
  return SignupConfig.getFlow(type, under).findIndex((o) => o.route === path)
}

const getMaxStep = (type, under) => {
  return SignupConfig.getFlow(type, under).length > 0
    ? SignupConfig.getFlow(type, under).length - 1
    : 0
}

const getPreSavedData = () => {
  const nanl = JSON.parse(sessionStorage.getItem('isNanl'))
  if (sessionStorage.getItem('signupData')) {
    return JSON.parse(sessionStorage.getItem('signupData'))
  }
  return {
    promptMyInfo: true,
    eligible: true,
    is3rd: false,
    isPooRequired: false,
    timeslot_allowed: true,
    showManualEntryPrompt: true,
    isNewCustomer: true,
    hasMissedAppointment: false,
    hasActiveAcctUnderName: false,
    isDisconnected: false,
    terminateDate: '',
    isNanl: nanl,
  }
}

class SignupWiz extends PureComponent {
  constructor(props) {
    super(props)
    this._preSavedData = getPreSavedData()
    this.state = {
      signupFlow: SignupConfig.getFlow(props.type, props.under),
      signupData: this._preSavedData,
      shared: {
        refetch_calendar: false,
        refetch_timeslot: false,
      },
      error: null,
      maxStep: getMaxStep(this.props.type, this.props.under),
      currentStep: getCurrentStep(
        this.props.type,
        this.props.location.pathname,
        this.props.under
      ),
      path: '',
      errType: null,
      errDuplicate: null,
      errSubmission: null,
      errSubmissionSysMsg: null,
      errInvalidDate: null,
      errPayment: null,
      errGeneral: null,
      errEndSession: null,
      is3rdErr: false,
      hasMissedAppointmentErr: false,
      isDisconnectedErr: false,
      hasActiveAcctUnderNameErr: false,
      addingInGasErr: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.path) {
      return {
        ...state,
        currentStep: getCurrentStep(
          props.type,
          props.location.pathname,
          props.under
        ),
        maxStep: getMaxStep(props.type, props.under),
        path: props.location.pathname,
      }
    }
    return null
  }

  _getPreSavedData() {
    this._preSavedData = getPreSavedData()
  }

  _getFieldsNonExisting(fields) {
    let existing = []
    if (typeof fields !== 'object') return existing
    const _fields = Object.keys(fields)
    _fields.forEach((field) => {
      if (!this._preSavedData[field]) {
        existing.push(field)
      }
    })
    return existing
  }

  _setFields = (fields, saveToLS = true) => {
    const _fields = this._getFieldsNonExisting(fields)
    const fieldsToBeAdded = {}
    _fields.forEach((field) => {
      fieldsToBeAdded[field] = fields[field]
    })
    this.setState(
      (prevState) => {
        const newState = {
          ...prevState,
          signupData: {
            ...this._preSavedData,
            ...prevState.signupData,
            ...fieldsToBeAdded,
          },
        }
        return newState
      },
      () => {
        if (saveToLS) {
          sessionStorage.setItem(
            'signupData',
            JSON.stringify(this.state.signupData)
          )
        }
      }
    )
  }

  _hydrateData = (data) => {
    let result = {}
    if (!data) return result
    result = data
    const fields = Object.keys(data)
    fields.forEach((field) => {
      if (this._preSavedData.hasOwnProperty(field)) {
        result[field] = this._preSavedData[field]
      }
    })
    return result
  }

  _preSaveData = (data, saveToLS = true, cb) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          signupData: {
            ...prevState.signupData,
            ...data,
          },
        }
      },
      () => {
        if (saveToLS) {
          sessionStorage.removeItem('signupData')
          sessionStorage.setItem(
            'signupData',
            JSON.stringify(this.state.signupData)
          )
        }
        if (cb) {
          cb()
        } else {
          this._getPreSavedData()
        }
      }
    )
  }

  _navigateToFlow = () => {
    const { history, type, under } = this.props
    const { signupFlow, currentStep } = this.state
    const flow = signupFlow
    const route = flow[currentStep]
    if (route) {
      history.push(route.route)
    }
  }

  _setGeneralError = () => {
    this.setState((prevState) => ({
      ...prevState,
      errGeneral: 'Sorry, there was a problem occured. Please try again later.',
    }))
  }

  _setEndSessionError = (err) => {
    let errMsg =
      err ||
      'We are unable to proceed as there is no available appointment date for your application. Please chat with our officers via our SP App for assistance.'
    this.setState((prevState) => ({
      ...prevState,
      errEndSession: errMsg,
    }))
  }

  _setPaymentError = (errMsg) => {
    this.setState((prevState) => ({
      ...prevState,
      errPayment:
        errMsg ||
        'We are unable to process your application as the payment transaction was not successful. <br /> Please try again or visit our Customer Service Centre to open a utilities account.',
    }))
  }

  _submit(payload) {
    const { type, under } = this.props
    const { signupData, signupStateId, signupNotificationNo } = this.state
    const submitPayload = Utils.prepareSubmissionData(
      {
        transaction_id: signupStateId,
        notification_no: signupNotificationNo,
        source: Utils.getSource(),
        ...signupData,
        ...payload,
        payment_redirect: window.location.href || '',
      },
      type,
      under
    )

    if (submitPayload) {
      const oaPromise = API.submit(submitPayload, signupData.use_myinfo)
      if (isEmpty(signupData.signature_file_id)) {
        return oaPromise
      }

      return new Promise((resolve, reject) => {
        oaPromise
          .then((oaRes) => {
            if (oaRes.status === 'FAIL') {
              reject({ error: 'Attach signature failed' })
              return
            }
            let nanlData = {}
            if (submitPayload.nanl) {
              nanlData = {
                ...nanlData,
                nanl: submitPayload.nanl,
                nanl_additional_info: submitPayload.nanl_additional_info,
              }
            }
            API.eSignatureServices
              .attachSignatureToPdfForm({
                ...signupData,
                appId: oaRes.app_id,
                ...nanlData,
              })
              .then((esigRes) => {
                resolve({ ...oaRes, ...esigRes })
              })
              .catch(reject)
          })
          .catch(reject)
      })
    }
  }

  next = (payload = {}, cb, params = {}, redirectToThankYou = true) => {
    const { history } = this.props
    const { currentStep, maxStep, signupData } = this.state
    const signupStep = sessionStorage.getItem('signupStep') || 0
    if (currentStep < maxStep) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            currentStep: prevState.currentStep + 1,
          }
        },
        () => {
          this._navigateToFlow()
          if (currentStep >= signupStep) {
            sessionStorage.setItem('signupStep', this.state.currentStep)
          }
        }
      )
    } else if (currentStep === maxStep) {
      this._submit(payload)
        .then((res) => {
          this.setState(
            (prevState) => ({
              ...prevState,
              errType: null,
              errDuplicate: null,
              errInvalidDate: null,
              errSubmission: null,
              errSubmissionSysMsg: null,
              shared: {
                ...prevState.shared,
                refetch_calendar: false,
                refetch_timeslot: false,
              },
            }),
            () => {
              if (cb) {
                cb(res)
              }
              if (redirectToThankYou) {
                history.push({
                  pathname: `/thank-you`,
                  state: {
                    success: true,
                    ebill: payload.ebill && payload.ebill_verified,
                    paper_bill: payload.enable_paper_bill,
                    contact: {
                      ...params.contact,
                      email: res.email,
                    },
                    nanl: params.nanl,
                    application: {
                      ...params.application,
                    },
                    appId: res.app_id,
                  },
                })
              }
            }
          )
        })
        .catch((err) => {
          this.setState((prevState) => ({
            ...prevState,
            signupData: {
              ...prevState.signupData,
              showPreTurnOnPrompt: false,
            },
          }))

          if (err.error === 'duplicate') {
            this.setState((prevState) => ({
              ...prevState,
              errDuplicate: <span>{err.error_description}</span>,
            }))
          } else if (
            err.error === 'date_invalid_ncc' ||
            err.error === 'date_invalid_pool' ||
            err.error === 'date_invalid_retailer' ||
            err.error === 'date_invalid_ncc_12pm' ||
            err.error === 'date_invalid_pool_12pm' ||
            err.error === 'date_invalid_retailer_12pm' ||
            err.status === 'OA_CALENDAR_ERROR'
          ) {
            this.setState((prevState) => ({
              ...prevState,
              errInvalidDate: err.error_description || err.message,
              terminateDate: null,
              zcGasDate: null,
              zczzDate: null,
            }))
          } else {
            if (err.error !== 'pending') {
              this.setState((prevState) => ({
                ...prevState,
                errType: err.error || 'generic',
                errSubmission: err.message || `An error occured`,
                errSubmissionSysMsg: err.error_description,
              }))
            }
          }

          if (cb) {
            cb(err)
          }
        })
    }
  }

  prev = () => {
    const { currentStep } = this.state
    if (currentStep > 0) {
      this.setState(
        (prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep - 1,
        }),
        () => {
          this._navigateToFlow()
        }
      )
    }
  }

  cancel = () => {
    const { history } = this.props
    const { mode } = Utils.getOAMode({ forQueryParam: true })
    if (mode && mode !== 'normal') {
      history.push(`/open-account/?mode=${mode}`)
      return
    }
    history.push(`/open-account/`)
  }

  _updateFlow = ({ type = 'residential', under = 'personal', params = {} }) => {
    SignupConfig.updateFlow({
      type,
      under,
      params,
    })
    this.setState({
      signupFlow: SignupConfig.getFlow(type, under),
    })
  }

  _checkSignupFlow() {
    const { history, type, under, followFlow } = this.props
    const signupDomainType = sessionStorage.getItem('signupDomainType')
    const signupStart = sessionStorage.getItem('signupStart')
    const domainType = `${type}.${under}`
    if (followFlow) {
      const { currentStep } = this.state
      const signupStep = sessionStorage.getItem('signupStep') || 0
      if (currentStep > signupStep) {
        history.push(SignupConfig.getFlow(type, under)[signupStep].route)
        if (currentStep === signupStep) {
          sessionStorage.setItem('signupStep', currentStep)
        }
      }
      if (currentStep === signupStep) {
        sessionStorage.setItem('signupStep', currentStep)
      }
    }
    if ((!signupStart && !signupDomainType) || !domainType) {
      sessionStorage.removeItem('signupDomainType')
      sessionStorage.removeItem('signupStep')
      // sessionStorage.setItem('signupDomainType', domainType)
      history.push(SignupConfig.flow[type]['start'])
      if (domainType !== signupDomainType) {
      }
    }
  }

  _checkIfEligible = (payload, cb) => {
    let result = true
    const { type, under } = this.props
    const { signupData, signupStateId } = this.state
    const isItForResi = type === 'residential'
    const isItUnderPersonal = under === 'personal'
    const { nric_roc, bp_type, occupancy } = payload
    if (!nric_roc) return null

    API.checkEligibility({
      nric_roc,
      bp_type,
      premise_no: signupData.premises.premise_address.premise_premise_no,
      transaction_id: signupStateId,
    })
      .then((res) => {
        const checkPremisesValidations = () => {
          if (isItForResi) {
            if (res.has_missed_appointment) {
              // CHECKS FOR MISSED APPOINTMENT
              this.setState((prevState) => ({
                ...prevState,
                signupData: {
                  ...prevState.signupData,
                  eligible: false,
                  hasMissedAppointment: true,
                },
                addingInGasErr: false,
                hasActiveAcctUnderNameErr: false,
                hasMissedAppointmentErr: true,
              }))
              result = false
            } else if (res.is_disconnected) {
              // CHECKS IF ITS DISCONNECTED
              this.setState((prevState) => ({
                ...prevState,
                signupData: {
                  ...prevState.signupData,
                  eligible: false,
                  isDisconnected: true,
                },
                addingInGasErr: false,
                hasActiveAcctUnderNameErr: false,
                isDisconnectedErr: true,
              }))
              result = false
            }
          }
        }

        const checkAccountValidations = () => {
          if (!res.is_new_customer) {
            if (isItForResi) {
              // CHECKS FOR GAS
              if (res.hasOwnProperty('available_gas')) {
                if (res.available_gas === null) {
                  // available_gas is null its not a duplicate but check for premises validation
                  this.setState((prevState) => ({
                    ...prevState,
                    addingInGasErr: false,
                    hasActiveAcctUnderNameErr: false,
                  }))
                  checkPremisesValidations()
                } else if (res.available_gas === 'N') {
                  // if available_gas is N check if there's Gas in services
                  if (signupData.turnOnServices.services.includes('gas')) {
                    this.setState((prevState) => ({
                      ...prevState,
                      addingInGasErr: true,
                      hasActiveAcctUnderNameErr: true,
                    }))
                  } else {
                    // If there's no Gas in services show duplicate prompt but check for premises validation
                    this.setState((prevState) => ({
                      ...prevState,
                      addingInGasErr: false,
                      hasActiveAcctUnderNameErr: true,
                    }))
                    checkPremisesValidations()
                  }
                } else if (res.available_gas === 'Y') {
                  // If available_gas is Y show duplicate prompt but check for premises validation
                  this.setState((prevState) => ({
                    ...prevState,
                    addingInGasErr: false,
                    hasActiveAcctUnderNameErr: true,
                  }))
                  checkPremisesValidations()
                }
              } else {
                // If there's no available_gas dont show prompt but check for premises validation
                this.setState((prevState) => ({
                  ...prevState,
                  addingInGasErr: false,
                  hasActiveAcctUnderNameErr: false,
                }))
                checkPremisesValidations()
              }
            }
          } else {
            checkPremisesValidations()
          }
        }

        if (isItForResi) {
          // CHECKS FOR ELIGIBILITY (eg. 3rd time account opening)
          if (!res.eligible) {
            // If not eligible, check for type of occupancy
            if (occupancy && occupancy === 'tenant') {
              // If tenant, cannot proceed
              this.setState((prevState) => ({
                ...prevState,
                signupData: {
                  ...prevState.signupData,
                  timeslot_allowed: res.timeslot_allowed,
                  eligible: false,
                  is3rd: isItForResi && isItUnderPersonal,
                  isPooRequired: prevState.signupData.isPooRequired || false,
                },
                is3rdErr: isItForResi && isItUnderPersonal,
              }))
              result = false
            } else if (occupancy && occupancy === 'owner') {
              checkAccountValidations()
              // If owner, need to upload POO
              this.setState((prevState) => ({
                ...prevState,
                signupData: {
                  ...prevState.signupData,
                  timeslot_allowed: res.timeslot_allowed,
                  eligible: true,
                  is3rd: isItForResi && isItUnderPersonal,
                  isPooRequired: isItUnderPersonal
                    ? true
                    : prevState.signupData.isPooRequired || false,
                },
                is3rdErr: false,
              }))
            }

            if (!isItUnderPersonal) {
              // For company
              checkAccountValidations()
            }
          } else {
            checkAccountValidations()

            this.setState((prevState) => ({
              ...prevState,
              signupData: {
                ...prevState.signupData,
                timeslot_allowed: res.timeslot_allowed,
                eligible: true,
                isPooRequired: prevState.signupData.isPooRequired || false,
                is3rd: false,
              },
              is3rdErr: false,
            }))
          }
        } else {
          // For Commercial premises
          checkAccountValidations()
        }

        this.setState((prevState) => ({
          ...prevState,
          timeslot_allowed: res.timeslot_allowed,
          signupData: {
            ...prevState.signupData,
            linked_email: res.linked_email,
            checkEligibleSuccess: true,
            isNewCustomer: res.is_new_customer,
          },
        }))
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          _error: err.message,
          isNewCustomer: false,
        }))
      })
      .finally(() => {
        if (cb) {
          cb()
        }
      })
    return result
  }

  _getStateId() {
    const stateId = Utils.getStateId()
    if (stateId) {
      this.setState((prevState) => ({
        ...prevState,
        signupStateId: stateId,
      }))
    }
  }

  _generateNotificationNo = (force = false) => {
    if (force) {
      const notifNo = Utils.generateNotifcationNumber(Date.now())
      if (notifNo) {
        this.setState((prevState) => ({
          ...prevState,
          signupNotificationNo: notifNo,
        }))
      }
    } else {
      const { signupNotificationNo } = this.state
      if (!signupNotificationNo || signupNotificationNo === '') {
        const notifNo = Utils.generateNotifcationNumber(Date.now())
        if (notifNo) {
          this.setState((prevState) => ({
            ...prevState,
            signupNotificationNo: notifNo,
          }))
        }
      }
    }
  }

  _processAcctType() {
    const { type, under } = this.props
    this.setState((prevState) => ({
      ...prevState,
      signupData: {
        ...prevState.signupData,
        account_type: type === 'residential' ? '01' : '02',
        account_holder_type: under === 'personal' ? 'P' : 'C',
      },
    }))
  }

  componentDidMount() {
    this._checkSignupFlow()
    this._processAcctType()
    this._getStateId()
    this._generateNotificationNo()
    this._checkMyInfoUsage()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._checkSignupFlow()
    }
  }

  render() {
    const {
      signupFlow,
      signupData,
      shared,
      signupStateId,
      signupNotificationNo,
      currentStep,
      errDuplicate,
      errInvalidDate,
      errSubmission,
      errSubmissionSysMsg,
      errPayment,
      errGeneral,
      errEndSession,
      is3rdErr,
      hasMissedAppointmentErr,
      isDisconnectedErr,
      hasActiveAcctUnderNameErr,
      addingInGasErr,
    } = this.state
    const { location, type, under, shouldHideWizSteps } = this.props

    return (
      <SignupProvider
        value={{
          stateId: signupStateId,
          data: {
            transaction_id: signupStateId,
            notification_no: signupNotificationNo,
            ...signupData,
          },
          shared: shared,
          actions: {
            setFields: this._setFields,
            hydrate: this._hydrateData,
            presave: this._preSaveData,
            next: this.next,
            prev: this.prev,
            cancel: this.cancel,
            generateNotification: this._generateNotificationNo,
            checkEligibility: this._checkIfEligible,
            showError: this._setGeneralError,
            showEndSessionError: this._setEndSessionError,
            showPaymentError: this._setPaymentError,
            updateFlow: this._updateFlow,
          },
          config: {
            application_domain: type,
            application_type: under,
          },
        }}
      >
        <div className="signup">
          {location.pathname && !location.pathname.match(/thank-you/g) && (
            <Header>
              {
                <h2 className="lm--card-item h2">
                  {type === 'residential' &&
                  signupData.premises &&
                  signupData.premises.nanl
                    ? 'Open Utilities Account for Non-Standard Residential Premises'
                    : type === 'residential' &&
                      `Open Utilities Account for Residential Premises`}
                  {type === 'commercial' &&
                  signupData.premises &&
                  signupData.premises.nanl
                    ? `Open Utilities Account for Non-Standard Commercial Premises`
                    : type === 'commercial' &&
                      `Open Utilities Account for Commercial Premises`}
                  <br />
                  {under === 'personal' && 'Under Personal Name'}
                  {under === 'company' && 'Under Company Name'}
                </h2>
              }
              {Utils.getOAMode().mode === 'kiosk' &&
              shouldHideWizSteps ? null : (
                <WizSteps
                  id="signup-steps-tl"
                  items={signupFlow}
                  currentStep={currentStep}
                />
              )}
            </Header>
          )}

          <Switch>
            {signupFlow.length > 0 &&
              signupFlow.map((item, index) => {
                return (
                  <Route
                    key={`${item.route}_${index}`}
                    path={item.route}
                    render={(props) => {
                      const Component = item.component
                      return <Component under={under} {...props} />
                    }}
                  />
                )
              })}

            <Route
              path={`/open-account/test`}
              render={(props) => <SampleWithContext {...props} />}
            />
            <Route
              path={`/open-account/test-again`}
              render={(props) => <AnotherSampleWithContext {...props} />}
            />
          </Switch>
          {errDuplicate && (
            <ConfirmationDialog
              id="submit-err-prompt"
              confirmCancelHandler={this._duplicateErrorHandler}
              content={errDuplicate}
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            />
          )}
          {errInvalidDate && (
            <ConfirmationDialog
              id="submit-err-prompt"
              confirmOkHandler={this._invalidDateErrorHandler}
              content={errInvalidDate}
              actionConfig={{
                cancel: false,
                ok: {
                  label: 'Continue',
                },
              }}
              processing={false}
            />
          )}
          {errSubmission && (
            <ConfirmationDialog
              id="submit-err-prompt"
              confirmOkHandler={this._submitErrorHandler}
              content={
                <div className="err-content">
                  {errSubmission}
                  {errSubmissionSysMsg && (
                    <code className="sys-msg">{errSubmissionSysMsg}</code>
                  )}
                </div>
              }
              actionConfig={{
                cancel: false,
                ok: {
                  label: 'Continue',
                },
              }}
              processing={false}
            />
          )}
          {!signupData.eligible && is3rdErr && (
            <ConfirmationDialog
              id="eligible-err-prompt"
              confirmCancelHandler={this._thirdErrHandler}
              title={`Eligibility`}
              content={
                <div>
                  We are unable to proceed with your application request as this
                  is your 3rd account with us within a period of 6 months.
                  Please proceed to our Customer Service Centres together with
                  your NRIC/FIN, initial security deposit and duly signed
                  tenancy agreement. For more information, please contact us at{' '}
                  {Utils.getOAMode().mode === 'kiosk' ? (
                    '1800 2222 333'
                  ) : (
                    <a href="tel:1800-2222-333">1800 2222 333</a>
                  )}
                  .
                </div>
              }
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            />
          )}
          {hasActiveAcctUnderNameErr && (
            <ConfirmationDialog
              id="active-acc-err-prompt"
              confirmCancelHandler={this._activeAcctErrHandler}
              content={
                <div>
                  {`The premises has an active utilities account under your ${
                    under === 'company' ? 'company name' : 'name'
                  }. `}
                  <>
                    {addingInGasErr ? (
                      <>
                        For adding on of gas supply, please call City Energy at{' '}
                        {Utils.getOAMode().mode === 'kiosk' ? (
                          '1800 555 1661'
                        ) : (
                          <a href="tel:1800-555-1661">1800 555 1661</a>
                        )}
                        .
                      </>
                    ) : (
                      <>
                        If you are intending to apply for an e-services account,
                        click{' '}
                        {Utils.getOAMode().mode === 'kiosk' ? (
                          'You may visit this url, https://services.spservices.sg/#/login'
                        ) : (
                          <a
                            href="https://services.spservices.sg/#/login"
                            target="_blank"
                          >
                            here
                          </a>
                        )}
                        .
                      </>
                    )}
                  </>
                </div>
              }
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            />
          )}
          {hasMissedAppointmentErr && (
            <ConfirmationDialog
              id="missed-appointment-err-prompt"
              confirmCancelHandler={this._missedApptErrHandler}
              content={
                <div>
                  {under === 'company'
                    ? 'The premises has an active utilities account under your company name. For reschedule request under company name, please download the '
                    : 'The premises has an active utilities account under your name. If you would like to reschedule your appointment date, click '}
                  {Utils.getOAMode().mode === 'kiosk' ? (
                    'You may visit this url, https://services.spservices.sg/#/rescheduleAppointment'
                  ) : (
                    <a
                      href="https://www.spgroup.com.sg/resources?page=2&category=Billing"
                      target="_blank"
                    >
                      {`${under === 'company' ? 'Reschedule Form ' : 'here'}`}
                    </a>
                  )}
                  {`${
                    under === 'company'
                      ? 'and email the completed form to us.'
                      : '.'
                  }`}
                  <br />
                  <br />
                  If you are intending to apply for an e-services account, click{' '}
                  <a
                    href="https://services.spservices.sg/#/login"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </div>
              }
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            />
          )}
          {isDisconnectedErr && (
            <ConfirmationDialog
              id="disconnected-err-prompt"
              confirmCancelHandler={this._isDisconnectedErrHandler}
              content={
                <div>
                  {`This premises has an active utilities account under your ${
                    under === 'company' ? 'company name' : 'name'
                  }. `}
                  If you require assistance, please call us at{' '}
                  {Utils.getOAMode().mode === 'kiosk' ? (
                    '6671 7100'
                  ) : (
                    <a href="tel:66717100">6671 7100</a>
                  )}
                  .
                </div>
              }
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            />
          )}
          {errPayment && (
            <ConfirmationDialog
              id="payment-err-prompt"
              confirmCancelHandler={this._paymentErrHandler}
              title={`Payment Failed`}
              processing={false}
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: errPayment }} />
            </ConfirmationDialog>
          )}
          {errGeneral && (
            <ConfirmationDialog
              id="general-err-prompt"
              confirmOkHandler={this._generalErrHandler}
              title={`Error`}
              content={errGeneral}
              processing={false}
              actionConfig={{
                cancel: false,
                ok: {
                  label: 'Continue',
                },
              }}
            />
          )}
          {errEndSession && (
            <ConfirmationDialog
              id="endsession-err-prompt"
              confirmCancelHandler={this._endSessionErrHandler}
              processing={false}
              actionConfig={{
                cancel: {
                  label: 'Go back to open account screen',
                },
                ok: false,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: errEndSession }} />
            </ConfirmationDialog>
          )}
        </div>
      </SignupProvider>
    )
  }

  _generalErrHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      errGeneral: null,
    }))
  }

  _endSessionErrHandler = () => {
    const { history, type } = this.props
    this.setState(
      (prevState) => ({
        ...prevState,
        errEndSession: null,
      }),
      () => {
        history.push(`/open-account/${type}`)
      }
    )
  }

  _paymentErrHandler = () => {
    const { history, type } = this.props
    this.setState(
      (prevState) => ({
        ...prevState,
        errPayment: null,
      }),
      () => {
        history.push(`/open-account/${type}`)
      }
    )
  }

  _duplicateErrorHandler = () => {
    const { history, type } = this.props
    this.setState(
      (prevState) => ({
        ...prevState,
        errType: null,
        errDuplicate: null,
      }),
      () => {
        history.push(`/open-account/${type}`)
      }
    )
  }

  _submitErrorHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      errType: null,
      errSubmission: null,
      shared: {
        refetch_timeslot:
          prevState.errType && prevState.errType === 'timeslot_not_available',
      },
    }))
  }

  _invalidDateErrorHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      errType: null,
      errInvalidDate: null,
      shared: {
        ...prevState.shared,
        refetch_calendar: true,
      },
    }))
  }

  _thirdErrHandler = () => {
    this._goBackToOALanding()
  }

  _missedApptErrHandler = () => {
    this._goBackToOALanding()
  }

  _isDisconnectedErrHandler = () => {
    this._goBackToOALanding()
  }

  _activeAcctErrHandler = () => {
    this._goBackToOALanding()
  }

  _goBackToOALanding = () => {
    const { history, type, under } = this.props
    history.push(`/open-account/${type}`)
  }

  _pushPageToDataLayer = (path, title = '') => {
    window.dataLayer.push({
      pageDataLayer: path,
    })
  }

  _checkMyInfoUsage = () => {
    const { signupData } = this.state
    const { type, under } = this.props
    let typeUnder = ''
    if (signupData.use_myinfo) {
      SignupConfig.myInfoUsage.forEach((u) => {
        let checker = `${type}.${under}`
        if (checker === u) {
          typeUnder = checker
        }
      })
    }
    if (typeUnder !== '') {
      switch (typeUnder) {
        case 'residential.company': {
          this._updateFlow({
            type: 'residential',
            under: 'company',
            params: {
              removeId: [
                '/open-account/residential/company/review',
                '/open-account/residential/company/upload',
              ],
              addId: ['/open-account/residential/company/confirmation'],
            },
          })
          break
        }
        case 'residential.personal':
        // falls through
        default:
          break
      }
    }
  }
}

SignupWiz.defaultProps = {
  type: 'residential',
  under: 'personal',
}

SignupWiz.propTypes = {
  type: PropTypes.string.isRequired,
  under: PropTypes.string.isRequired,
}

class Sample extends PureComponent {
  state = {
    data: {
      name: '',
      email: '',
    },
  }
  componentDidMount() {
    const { data } = this.state
    const { context } = this.props
    context.actions.setFields(data)
  }
  render() {
    return <div>Test Sample</div>
  }
}

const SampleWithContext = withContext(Sample)

class AnotherSample extends PureComponent {
  state = {
    data: {
      premise: '',
      mailing: '',
    },
  }
  componentDidMount() {
    const { data } = this.state
    const { context } = this.props
    context.actions.setFields(data)
  }
  render() {
    return <div>Test Another Sample</div>
  }
}

const AnotherSampleWithContext = withContext(AnotherSample)

export default SignupWiz

import moment from 'moment'

const ISO_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss'

/**
 *
 * @return {OAPDFPayload}
 * @param rawData
 */
export function preparePdfPayload(rawData) {
  let payload = {
    mailing_address: toAddress(rawData.premises.mail_address, 'mail'),
    occupancy: rawData.premises.premise_occupancy,
    use_of_premises: rawData.premises.use_of_premises,
    company_information: rawData.company_information,
    representative: rawData.representative,
    preferred_date: {
      ...rawData.preferred_date,
      turn_on_datetime: toDateTime(rawData.turn_on_datetime),
    },
    turn_on_services: rawData.turnOnServices,
    account_holder_type: rawData.account_holder_type,
    preTurn_on_flag: rawData.preTurnOnFlag,
    nanl: rawData.nanl,
  }
  if (rawData.nanl) {
    payload = {
      ...payload,
      nanl_additional_info: rawData.nanl_additional_info,
    }
  } else {
    payload = {
      ...payload,
      premise_address: toAddress(rawData.premises.premise_address, 'premise'),
    }
  }
  return {
    application_number: rawData.appId,
    application_type: rawData.account_type,
    file_id: rawData.signature_file_id,
    signed_at: toDateTime(rawData.signed_at),
    payload,
  }
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i)
  }

  return new Blob([arrayBuffer], { type: mimeString })
}

/**
 *
 * @param data
 * @param prefix {'premise' | 'mail'}
 * @return {{ block: string, floor: string, unit: string, street: string, postal_code: string }}
 */
function toAddress(data, prefix) {
  return {
    block: data[`${prefix}_block`],
    floor: data[`${prefix}_floor`],
    unit: data[`${prefix}_unit`],
    street: data[`${prefix}_street_name`],
    postal_code: data[`${prefix}_postal_code`],
    premise_no: data[`${prefix}_premise_no`],
    country: data[`${prefix}_country`],
  }
}

export function toDateTime(inputDate, format = ISO_DATE_TIME) {
  return moment(inputDate).format(format)
}

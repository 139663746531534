import React, { createContext } from 'react'

const SignupContext = createContext({})

export const SignupProvider = SignupContext.Provider
export const SignupConsumer = SignupContext.Consumer

export const withContext = (Component, otherProps) => {
  return (props) => (
    <SignupConsumer>
      {(context) => (
        <div className="page-content">
          <Component {...props} {...otherProps} context={context} />
        </div>
      )}
    </SignupConsumer>
  )
}

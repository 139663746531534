import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ title, children }) => {
  return (
    <header className="lm--card page-title">
      {title !== '' && <h2 className="lm--card-item h2"> {title} </h2>}
      <div className="lm--card-item u-grid u-grid-center@md col-sm-12">
        {children}
      </div>
    </header>
  )
}

Header.defaultProps = {
  title: '',
}

Header.propTypes = {
  title: PropTypes.string,
}

export default Header

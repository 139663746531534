import React, { Component, Fragment } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { OpenAccount } from 'spd-oa/pages'
import { SignupWiz, ThankYou } from 'spd-oa/pages/wiz'
import Utils from 'spd-oa/utils'
import { openLink } from 'spd-oa/helpers'
import { API } from 'spd-oa/services'
import { Logo, LiveChat } from '@casper-ui/components'
import { signatureFlag } from 'spd-oa/pages/wiz/Signature/helpers'
import { KioskSideBar } from 'spd-oa/components'
import { KioskSideBarProvider } from 'spd-oa/kioskContext'

const CHAT_URL = process.env.REACT_APP_CHAT_URL
const ENV = process.env.REACT_APP_ENV

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'normal',
      liveChatStatus: false,
      liveChatOffsetX: null,
      liveChatOffsetY: null,
      maintenance: null,
      maintenanceError: null,
      shouldHideEndSession: false,
    }
    this.props.history.listen((location) => {
      window.dataLayer.push({
        pageDataLayer: location.pathname,
      })
    })
    this._isSPSInternalDomain =
      window.location.hostname === 'openaccount.spdigital.sg'
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mode !== prevState.mode) {
      this.checkMode()
    }

    const _page =
      document.querySelector('.open-account') ||
      document.querySelector('.signup') ||
      document.querySelector('#oa-thank-you')

    if (
      this.state.mode === 'normal' &&
      _page &&
      (_page.offsetLeft !== prevState.liveChatOffsetX ||
        _page.offsetTop !== prevState.liveChatOffsetY)
    ) {
      this._updateLiveChatOffset()
    }
  }

  componentDidMount() {
    let { liveChatStatus } = this.state
    const ieVersion = Utils.detectIE()
    if (ieVersion && ieVersion < 12) {
      if (document.body) {
        document.body.classList.add('ie-legacy')
      }
    } else {
      if (document.body) {
        document.body.classList.add('non-ie')
      }
    }
    this.checkMode()

    if (!this._isSPSInternalDomain) {
      const esignatureFactory = signatureFlag()
      API.getFeaturesAvailability()
        .then((res) => {
          if (res) {
            this.setState({
              maintenance: res,
            })
          }
          if (res['oa-livechat']) {
            liveChatStatus = res['oa-livechat'].active
            this.setState((prevState) => ({
              ...prevState,
              liveChatStatus,
            }))
          }

          esignatureFactory.setFlag(res)
        })
        .catch((err) => {
          this.setState({
            maintenanceError: err,
          })
          esignatureFactory.clear()
        })
    }

    if (this.state.mode === 'normal') {
      window.addEventListener('resize', this._updateLiveChatOffset)
      this._updateLiveChatOffset()
    }

    this._configureKiosk()
  }

  componentWillUnmount() {
    Utils.clearSession()
    if (this.state.mode === 'normal') {
      window.removeEventListener('resize', this._updateLiveChatOffset)
    }
  }

  render() {
    const {
      mode,
      liveChatStatus,
      liveChatOffsetX,
      liveChatOffsetY,
      shouldHideEndSession,
    } = this.state
    if (Utils.getOAMode().mode === 'kiosk') {
      return (
        <KioskSideBarProvider
          value={{
            shouldHideEndSession: shouldHideEndSession,
            updateShouldHideEndSession: this._updateShouldHideEndSession,
          }}
        >
          <KioskSideBar>{this.renderOA()}</KioskSideBar>
        </KioskSideBarProvider>
      )
    }
    return (
      <Fragment>
        <header className="app-header">
          <Logo.SPgroup />
        </header>
        {this.renderOA()}
        {(mode === 'normal' || (mode === 'mobile' && ENV === 'qa')) &&
          liveChatStatus &&
          liveChatOffsetX !== null &&
          liveChatOffsetY !== null && (
            <LiveChat
              mode="popup"
              url={CHAT_URL}
              onToggle={mode === 'mobile' ? this._handleToggleLiveChat : null}
              title="SP Services::Live Chat"
              offsetX={liveChatOffsetX}
              offsetY={liveChatOffsetY}
            />
          )}
      </Fragment>
    )
  }

  renderOA() {
    const { maintenance, maintenanceError, shouldHideEndSession } = this.state
    return (
      <main className="app-main oa" id="main">
        <Switch>
          {/* ROUTES TO OPEN ACCOUNT LANDING */}
          <Route
            exact
            path={process.env.PUBLIC_URL || '/'}
            render={() => <Redirect to={'/open-account/'} />}
          />
          <Route
            exact
            path={'/open-account/'}
            render={(props) => (
              <OpenAccount
                maintenance={maintenance}
                maintenanceError={maintenanceError}
                isSPSInternalDomain={this._isSPSInternalDomain}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={'/open-account/:domain'}
            render={(props) => (
              <OpenAccount
                maintenance={maintenance}
                maintenanceError={maintenanceError}
                isSPSInternalDomain={this._isSPSInternalDomain}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={'/open-account/:domain/:type'}
            render={(props) => (
              <OpenAccount
                maintenance={maintenance}
                maintenanceError={maintenanceError}
                isSPSInternalDomain={this._isSPSInternalDomain}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`/thank-you`}
            render={(props) => <ThankYou {...props} />}
          />

          {/* RESIDENTIAL ROUTES */}
          <Route
            path={'/open-account/residential/personal/:path'}
            render={(props) => (
              <SignupWiz
                type="residential"
                under="personal"
                followFlow={true}
                shouldHideWizSteps={shouldHideEndSession}
                {...props}
              />
            )}
          />

          <Route
            path={'/open-account/residential/company/:path'}
            render={(props) => (
              <SignupWiz
                type="residential"
                under="company"
                followFlow={true}
                shouldHideWizSteps={shouldHideEndSession}
                {...props}
              />
            )}
          />

          {/* COMMERCIAL ROUTES */}
          <Route
            path={'/open-account/commercial/personal/:path'}
            render={(props) => (
              <SignupWiz
                type="commercial"
                under="personal"
                followFlow={true}
                shouldHideWizSteps={shouldHideEndSession}
                {...props}
              />
            )}
          />

          <Route
            path={'/open-account/commercial/company/:path'}
            render={(props) => (
              <SignupWiz
                type="commercial"
                under="company"
                followFlow={true}
                shouldHideWizSteps={shouldHideEndSession}
                {...props}
              />
            )}
          />
        </Switch>
      </main>
    )
  }

  checkMode() {
    let mode = this.processQueryParams('mode')
    if (!mode) {
      mode = Utils.getOAMode().mode
    }
    if (mode) {
      if (document.body) {
        document.body.classList.add(`oa-${mode}`)
      }
      this.setState((prevState) => ({
        ...prevState,
        mode,
      }))
    } else if (this.state.mode === 'normal') {
      this.setState((prevState) => ({
        ...prevState,
        mode: 'normal',
      }))
    }
  }

  processQueryParams(key) {
    if (this.props.location.search) {
      let queries = Utils.parseQueryParams(this.props.location.search)
      let values = {}
      // Query for Kiosk
      if (queries && queries['mode']) {
        values.mode = 'normal'
        if (queries['redirect']) {
          window.sessionStorage.setItem('redirect', queries['redirect'])
        }
        if (queries['mode'] === 'kiosk') {
          values.mode = 'kiosk'
          window.sessionStorage.setItem('oa_mode', 'kiosk')
          window.sessionStorage.setItem('token', queries['token'])
        } else if (queries['mode'] === 'mobile') {
          values.mode = 'mobile'
          window.sessionStorage.setItem('oa_mode', 'mobile')
        } else {
          window.sessionStorage.removeItem('oa_mode')
          window.sessionStorage.removeItem('redirect')
        }
      }
      if (key === 'mode') {
        return values[key]
      }
    }
  }

  _handleToggleLiveChat = () => {
    openLink(CHAT_URL, '_self')
  }

  _updateLiveChatOffset = () => {
    const { location } = this.props
    const _page =
      document.querySelector('.open-account') ||
      document.querySelector('.signup') ||
      document.querySelector('#oa-thank-you')

    if (_page) {
      this.setState((prevState) => ({
        ...prevState,
        liveChatOffsetX: _page.offsetLeft,
        liveChatOffsetY: _page.offsetTop,
      }))
    } else if (!location.pathname.match(/^\/$/g)) {
      const offsetX =
        window.innerWidth < 768 ? 0 : (window.innerWidth - 400) / 2
      this.setState((prevState) => ({
        ...prevState,
        liveChatOffsetX: offsetX,
        liveChatOffsetY: 0,
      }))
    }
  }

  _updateShouldHideEndSession = (opt) => {
    this.setState((prevState) => ({
      ...prevState,
      shouldHideEndSession: opt,
    }))
  }

  _configureKiosk = () => {
    const _html = document.querySelector('html')
    const _body = document.querySelector('body')
    if (Utils.getOAMode().mode === 'kiosk' && _html && _body) {
      _html.style.overscrollBehavior = 'none'
      _body.style.overscrollBehavior = 'none'
    }
  }
}

export default withRouter(App)

import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import { Button } from 'spd-oa/components/common'

/**
 * Cache menu default props, useful for merging default and passed in props
 */
const MENU_DEFAULT_PROPS = {
  disableEscapeKey: false,
  className: '',
  withBackdrop: false,
  arrow: 'relative',
  offset: {
    x: 0,
    y: 8,
  },
  id: '',
}
/**
 * Component for menu group
 */
const MenuGroup = (props) => {
  const { children, title } = props
  return (
    <ul>
      {title && (
        <li className="menu-subhead">
          <span>{title}</span>
        </li>
      )}
      {children}
    </ul>
  )
}
/**
 * Component for menu item
 */
const MenuItem = (props) => {
  const {
    id,
    value,
    label,
    group,
    type,
    icon,
    to,
    method,
    onClickHandler,
    children,
    disabled,
    className,
    bare,
    confirm,
    confirmTitle,
    confirmContent,
    active,
  } = props
  let __menuItem__ = className
  let _to = to
  if (disabled) {
    _to = '#'
    __menuItem__ += ` disabled`
  }
  if (active) {
    __menuItem__ += ` active`
  }
  if (type === 'button') {
    // return (
    //   <li className={__menuItem__}>
    //     <button onClick={onClickHandler} disabled={disabled}>
    //       {
    //         icon && <i>{icon}</i>
    //       }
    //       <span>
    //         {children}
    //       </span>
    //     </button>
    //   </li>
    // )
    return (
      <li className={__menuItem__}>
        <Button
          onClickHandler={() => {
            if (onClickHandler) {
              onClickHandler({ id, value, label, group })
            }
          }}
          disabled={disabled}
          bare={bare}
          confirm={confirm}
          confirmTitle={confirm ? confirmTitle : null}
          confirmContent={confirm ? confirmContent : null}
        >
          {icon && <i>{icon}</i>}
          <span>{children}</span>
        </Button>
      </li>
    )
  }
  return (
    <li className={__menuItem__}>
      <a
        href={_to}
        data-method={method}
        onClick={onClickHandler}
        disabled={disabled}
      >
        {icon && <i>{icon}</i>}
        {children}
      </a>
    </li>
  )
}

MenuItem.defaultProps = {
  className: '',
  disabled: false,
  id: '',
  group: '',
  value: '',
}

class Menu extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    /**
     * Handles whether to show/hide the Menu
     */
    if (props.show !== state.show) {
      return {
        ...state,
        show: props.show,
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  _menuButtonHandler = (e) => {
    /**
     * Handler for the button/target when clicked
     */
    this.setState((prevState) => ({
      show: !prevState.show,
    }))
  }

  _menuOnEscKeyDownHandler = (e) => {
    /**
     * Handler for the esc key event
     */
    const { onEscapeKeyDownHandler, disableEscapeKey } = this.props
    if (!disableEscapeKey) {
      if (onEscapeKeyDownHandler) {
        onEscapeKeyDownHandler()
      }
    }
  }

  _menuOnCloseHandler = (e) => {
    /**
     * Triggers onCloseHandler if available from props
     */
    const { onCloseHandler } = this.props
    if (onCloseHandler) {
      onCloseHandler()
    }
  }

  _closeMenu(cb) {
    /**
     * Closes the menu
     */
    this.setState(
      (prevState) => ({
        show: false,
      }),
      () => {
        if (cb) {
          cb()
        }
      }
    )
  }

  render() {
    const {
      className,
      title,
      targetNode,
      arrow,
      offset,
      withBackdrop,
      children,
      id,
    } = this.props

    const { show } = this.state
    return (
      <Fragment>
        {show && (
          <Modal
            id={`${id}-menu`}
            target={targetNode}
            className={`app-menu ${className}`}
            onClose={this._menuOnCloseHandler}
            onEscapeKeyDown={this._menuOnEscKeyDownHandler}
            withBackdrop={withBackdrop}
            arrow={arrow}
            offset={{
              ...MENU_DEFAULT_PROPS.offset,
              ...offset,
            }}
          >
            <div>
              {title && title !== '' && <h2 className="menu-title">{title}</h2>}
              {children}
            </div>
          </Modal>
        )}
      </Fragment>
    )
  }
}

Menu.propTypes = {
  /**
   * Main title of the menu, will be displayed on top
   */
  title: PropTypes.string,
  /**
   * CSS class names as string
   */
  className: PropTypes.string,
  /**
   * If `false` will render transparent backdrop
   */
  withBackdrop: PropTypes.bool,
}

/**
 * Use the assigned MENU_DEFAULT_PROPS to Menu's defaultProps
 */
Menu.defaultProps = MENU_DEFAULT_PROPS

export default {
  Menu,
  MenuItem,
  MenuGroup,
}

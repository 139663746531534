import React, { PureComponent } from 'react'
import { Modal, Button } from 'spd-oa/components/common'

const ACTION_CONFIG_DEFAULT = {
  ok: {
    label: 'Continue',
  },
  cancel: {
    label: 'No',
  },
}

class ReminderDialog extends PureComponent {
  render() {
    const {
      className,
      onCloseHandler,
      onEscapeKeyDownHandler,
      title,
      description,
      content,
      actionConfig,
    } = this.props
    const config = {
      ...ACTION_CONFIG_DEFAULT,
      ...actionConfig,
    }
    return (
      <Modal
        className={`app-reminder_dialog ${className}`}
        onClose={onCloseHandler}
        onEscapeKeyDown={onEscapeKeyDownHandler}
      >
        {title !== '' && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
        {description &&
          description.length > 0 &&
          description.map((d, i) => <p key={`reminder_desc--${i}`}>{d}</p>)}

        {content && <div className="content">{content}</div>}

        <div className={`modal-ctas`}>
          <Button
            variant="primary"
            onClickHandler={this._okHandler}
            className={`lm--button--small`}
          >
            {config.ok.label}
          </Button>
        </div>
      </Modal>
    )
  }
  _okHandler = () => {
    const { onContinueHandler, actionTimeout } = this.props
    if (onContinueHandler) {
      if (actionTimeout) {
        setTimeout(() => {
          onContinueHandler()
        }, 1000)
        return null
      }
      onContinueHandler()
      return null
    }
  }
}

ReminderDialog.defaultProps = {
  title: 'Take note:',
  content: null,
  description: [],
  actionConfig: ACTION_CONFIG_DEFAULT,
}

export default ReminderDialog

import React from 'react'
import QRCode from 'qrcode.react'
import { Button, Detail } from 'spd-oa/components/common'
import { API } from 'spd-oa/services'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { docLabels, getNricIds, getNricTypes } from 'spd-oa/attachmentHelpers'
import {
  buildSpec,
  getKioskSessionId,
  normalizeFileInfoList,
  kioskConfig,
} from 'spd-oa/components/KioskUpload/kioskUploadHelpers'

const SESSION_STATES_KEY = kioskConfig.SESSION_STATES_KEY
const UPLOAD_STATUS = kioskConfig.UPLOAD_STATUS

export default class KioskUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadUrl: null,
      uploadSessionId: null,
      error: false,
      /**
       * @type {{ [docId: string]: FileInfoMap }}
       */
      docs: {},
      uploadIsDone: false,
    }
    this._subscribe = this._subscribe.bind(this)
    this._handleSseError = this._handleSseError.bind(this)
    this._initQrUpload = this._initQrUpload.bind(this)
    this._updateUrl = this._updateUrl.bind(this)
    this._tearDown = this._tearDown.bind(this)
    this._updateReceivedDocs = this._updateReceivedDocs.bind(this)
    this._handleRemoveDoc = this._handleRemoveDoc.bind(this)
    this._setDocsState = this._setDocsState.bind(this)
    this._checkRequiredDocsChange = this._checkRequiredDocsChange.bind(this)
    this._updateUploadStatus = this._updateUploadStatus.bind(this)
    this._initUploadStatus = this._initUploadStatus.bind(this)
    this._resetDocsValue = this._resetDocsValue.bind(this)
    this._resetUploadedFiles = this._resetUploadedFiles.bind(this)
    this.initFn = this.initFn.bind(this)
    this.initGenerator = null
  }

  *initFn(stateId, requiredDocs) {
    yield
    const initRequiredDocs =
      sessionStorage.getItem(SESSION_STATES_KEY.uploadStatus) ===
      UPLOAD_STATUS.DONE
        ? [...requiredDocs]
        : this._resetDocsValue([...requiredDocs])
    this._setDocsState(initRequiredDocs)
    this.eventSrc = this._subscribe(buildSpec(stateId, initRequiredDocs))
    this._updateUploadStatus(UPLOAD_STATUS.INIT)
  }

  componentDidMount() {
    this._initQrUpload(this.props.stateId, this.props.requiredDocs)
  }

  componentWillUnmount() {
    if (this.eventSrc && this.eventSrc.readyState !== 2) {
      API.closeUploadSession(this.state.uploadSessionId)
      this.eventSrc.close()
    }
    if (sessionStorage.getItem(SESSION_STATES_KEY.kioskStreamId)) {
      sessionStorage.removeItem(SESSION_STATES_KEY.kioskStreamId)
    }
  }

  _resetUploadedFiles(sessionId) {
    const { docs } = this.state
    const shouldReset =
      sessionId &&
      Object.keys(docs).length > 0 &&
      sessionStorage.getItem(SESSION_STATES_KEY.uploadStatus) !==
        UPLOAD_STATUS.DONE
    if (shouldReset) {
      return API.resetUploadedFiles(sessionId)
    }
  }

  _resetDocsValue(docs) {
    return docs.map((doc) => {
      if (getNricIds().includes(doc.id)) {
        this.props.preSave()
      } else {
        this.props.setFormValue(doc.id, '')
      }
      return {
        id: doc.id,
        value: '',
        ...(doc.is_required === false && { is_required: doc.is_required }),
      }
    })
  }

  _initUploadStatus() {
    const uploadStatus = sessionStorage.getItem(SESSION_STATES_KEY.uploadStatus)
    if (uploadStatus) {
      this.setState({
        uploadIsDone: uploadStatus === UPLOAD_STATUS.DONE,
      })
      if (this.props.setUploadIsDone) {
        this.props.setUploadIsDone(uploadStatus === UPLOAD_STATUS.DONE)
      }
    } else {
      sessionStorage.setItem(
        SESSION_STATES_KEY.uploadStatus,
        UPLOAD_STATUS.INIT
      )
      if (this.props.setUploadIsDone) {
        this.props.setUploadIsDone(false)
      }
    }
  }

  _updateUploadStatus(stage) {
    switch (stage) {
      case UPLOAD_STATUS.INIT:
        this._initUploadStatus()
        break
      case UPLOAD_STATUS.DONE:
        this.setState({
          uploadIsDone: true,
        })
        sessionStorage.setItem(
          SESSION_STATES_KEY.uploadStatus,
          UPLOAD_STATUS.DONE
        )
        if (this.props.setUploadIsDone) {
          this.props.setUploadIsDone(true)
        }
        break
    }
  }

  _checkRequiredDocsChange(prevProps) {
    const prevEil = prevProps.requiredDocs.find((doc) => doc.id === 'eil')
    const currentEil = this.props.requiredDocs.find((doc) => doc.id === 'eil')
    if (prevEil && currentEil) {
      return prevEil.is_required !== currentEil.is_required
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { docs } = this.state
    const kioskId = sessionStorage.getItem(SESSION_STATES_KEY.kioskStreamId)
    if (this._checkRequiredDocsChange(prevProps)) {
      if (kioskId) {
        const eilDocs = docs['eil']
        const eilRequiredDocs = this.props.requiredDocs.find(
          (doc) => doc.id === 'eil'
        )
        const is_required = eilRequiredDocs.is_required || false
        this._updateReceivedDocs(
          eilDocs.id,
          eilDocs.type,
          eilDocs.value,
          eilDocs.fileId,
          is_required
        )
        const msg = encodeURIComponent(JSON.stringify({ eil: { is_required } }))
        API.sendMsgToKiosk(kioskId, msg)
      } else {
        this._initQrUpload(this.props.stateId, this.props.requiredDocs)
      }
    }
  }

  render() {
    const { uploadUrl, error, docs, uploadIsDone } = this.state
    const docValues = Object.values(docs)

    return (
      <div className="detail_section--group-box">
        <div className="node-item--block qr-upload">
          <h4>Required Documents</h4>
          {uploadUrl &&
            sessionStorage.getItem(SESSION_STATES_KEY.uploadStatus) !==
              UPLOAD_STATUS.DONE && (
              <div>
                <div>
                  You are required to upload these documents to complete your
                  account opening
                </div>
                <div>Scan QR code to upload the documents</div>
                <div className="u-text-muted">
                  Please approach our service ambassador if you need any
                  assistance.
                </div>
                <div className="qr-container">
                  <div className={`qr-wrapper ${error ? 'error' : ''}`}>
                    <QRCode size={180} value={uploadUrl} />
                  </div>
                  {error && (
                    <div className="qr-error">
                      QR code
                      <br />
                      has expired
                    </div>
                  )}
                </div>
              </div>
            )}
          {error &&
            sessionStorage.getItem(SESSION_STATES_KEY.uploadStatus) !==
              UPLOAD_STATUS.DONE && (
              <div className="refresh-qr">
                <Button
                  variant="primary"
                  onClickHandler={() =>
                    this._initQrUpload(
                      this.props.stateId,
                      this.props.requiredDocs
                    )
                  }
                >
                  Refresh QR code
                </Button>
              </div>
            )}
          {docValues.map((doc) => (
            <Detail.DetailNodeItem
              key={doc.id}
              id={doc.id}
              label={`${docLabels[doc.id]}${
                doc.hasOwnProperty('is_required') && !doc.is_required
                  ? ' (Optional)'
                  : ''
              }`}
              render={(d) => this._renderDocItem(d, doc.id, doc.fileId)}
              data={doc.value}
            />
          ))}
          {docValues.some((doc) => !isEmpty(doc.value)) && !uploadIsDone && (
            <Detail.DetailNodeItem
              label=""
              render={() => (
                <div className="u-text-muted">
                  Wrong file? You may delete and upload again
                </div>
              )}
            />
          )}
        </div>
      </div>
    )
  }
  _renderDocItem = (value, docId, fileId) => {
    if (isEmpty(value)) {
      return this.state.uploadIsDone ? '' : 'This document is not uploaded yet'
    }

    value = Array.isArray(value) ? value : [value]
    const fileDetails = value.map((val, idx) => {
      return {
        fileName: val,
        fileId: fileId && fileId[idx],
      }
    })
    return fileDetails.map((file) => {
      return (
        <div key={file.fileName}>
          <span className="upload-doc-type">{file.fileName}</span>
          {this.state.uploadIsDone ? null : (
            <Button
              variant="secondary"
              bare={true}
              onClickHandler={() =>
                this._handleRemoveDoc(docId, file.fileName, file.fileId)
              }
            >
              Delete
            </Button>
          )}
        </div>
      )
    })
  }

  _initQrUpload(stateId, requiredDocs) {
    this.initGenerator = this.initFn(stateId, requiredDocs)
    const initFn = this.initGenerator
    initFn.next()

    // if connecting, teardown
    if (this.eventSrc && this.eventSrc.readyState !== 2) {
      return API.closeUploadSession(this.state.uploadSessionId)
    } else {
      initFn.next()
    }
  }

  _setDocsState(requiredDocs) {
    this.setState({
      docs: requiredDocs.reduce((obj, doc) => ({ ...obj, [doc.id]: doc }), {}),
    })
  }

  /**
   * @typedef {{id: string, label: string}} Field
   * @typedef {{app_id: string, app_state: string, fields: Array<Field>}} Spec
   * */
  _subscribe(specs) {
    const updateReceivedDocs = this._updateReceivedDocs
    const updateUrl = this._updateUrl
    const tearDown = this._tearDown
    const preSave = this.props.preSave
    const resetUploadedFiles = this._resetUploadedFiles

    const nricIds = getNricIds()
    let personal_supporting_docs = this.props.requiredDocs
      .filter((doc) => nricIds.includes(doc.id))
      .reduce((obj, doc) => ({ ...obj, [doc.id]: doc.value || '' }), {})

    const saveForm = (key, value) => {
      this.props.setFormValue(key, value)
    }

    if (Array.isArray(specs.fields) && specs.fields.length > 0) {
      const token = window.sessionStorage.getItem('token')
      /** @type {EventSource} */
      const eventSrc = API.subscribeUploadEvent(specs, token)

      eventSrc.onmessage = (event) => {
        if (event.data.match(/^http/)) {
          const gaTrackingIdParam =
            window.ga && window.ga.getAll
              ? window.ga.getAll()[0].get('linkerParam')
              : null
          updateUrl(
            gaTrackingIdParam
              ? `${event.data}&${gaTrackingIdParam}`
              : event.data
          )
          resetUploadedFiles(this.state.uploadSessionId)
        } else if (event.data === '__EOF__') {
          if (sessionStorage.getItem(SESSION_STATES_KEY.kioskStreamId)) {
            this._updateUploadStatus(UPLOAD_STATUS.DONE)
          }
          tearDown()
        } else {
          const attachmentInfo = JSON.parse(event.data)

          if (attachmentInfo.message) {
            sessionStorage.setItem(
              SESSION_STATES_KEY.kioskStreamId,
              attachmentInfo.message
            )
            return
          }

          const fileInfoMap = normalizeFileInfoList(attachmentInfo.data)

          if (getNricTypes().includes(fileInfoMap.type)) {
            personal_supporting_docs = {
              ...personal_supporting_docs,
              [fileInfoMap.id]: fileInfoMap.value,
            }
            preSave({
              personal_supporting_docs,
            })
          } else if (fileInfoMap.type && fileInfoMap.id) {
            saveForm(fileInfoMap.id, fileInfoMap.value)
          }

          updateReceivedDocs(
            fileInfoMap.id,
            fileInfoMap.type,
            fileInfoMap.value,
            fileInfoMap.fileId
          )

          if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({
              event: 'Upload Document',
              eventCategory: 'Upload Document',
              eventAction: 'Upload',
              eventLabel: fileInfoMap.type,
            })
          }
        }
      }

      eventSrc.onerror = this._handleSseError
      return eventSrc
    } else {
      this.initGenerator = null
      tearDown()
    }
  }

  _handleRemoveDoc(docId, filename, fileId) {
    const { setFormValue, preSave, stateId } = this.props
    const { docs, uploadSessionId } = this.state
    const kioskStreamId = sessionStorage.getItem(
      SESSION_STATES_KEY.kioskStreamId
    )
    const docValue = docs[docId].value
    const docValueAfterRm = Array.isArray(docValue)
      ? docValue.filter((name) => name !== filename)
      : ''
    const docFileId = docs[docId].fileId
    const docFileIdAfterRm = docFileId.filter((id) => id !== fileId)
    const nricDocIds = getNricIds()
    API.sendDeletedFileDetails(kioskStreamId, {
      stateId: uploadSessionId,
      type: docs[docId].type,
      fileId: fileId,
      docId: docId,
      fileDetails: [
        {
          index: docs[docId].value.indexOf(filename),
          fileName: filename,
          lastModified: '',
        },
      ],
    }).then(() => {
      if (nricDocIds.includes(docId)) {
        const personal_supporting_docs = {}
        nricDocIds.forEach((id) => {
          personal_supporting_docs[id] = id === docId ? '' : docValue
        })
        preSave({ personal_supporting_docs })
      } else {
        setFormValue(docId, docValueAfterRm)
      }
      this._setDocsState(
        Object.values(docs).map((stateDoc) => {
          const doc = { ...stateDoc }
          if (doc.id === docId) {
            doc.value = docValueAfterRm
            doc.fileId = docFileIdAfterRm
          }

          return doc
        })
      )
    })
  }

  _updateReceivedDocs(docId, type, value, fileId, is_required) {
    this.setState((state) => ({
      ...state,
      docs: {
        ...state.docs,
        [docId]: {
          ...state.docs[docId],
          type,
          value,
          fileId,
          ...(is_required !== undefined && { is_required }),
        },
      },
    }))
  }

  _tearDown() {
    this._updateUrl(null)
    if (this.eventSrc) {
      this.eventSrc.close()
    }
    if (this.initGenerator) {
      this.initGenerator.next()
      this.initGenerator = null
    }
  }

  _updateUrl(uploadUrl) {
    this.setState({
      error: false,
      uploadUrl,
      uploadSessionId: uploadUrl ? getKioskSessionId(uploadUrl) : uploadUrl,
    })
  }

  _handleSseError() {
    if (this.eventSrc) {
      this.eventSrc.close()
    }
    this.setState({
      error: true,
    })
  }
}

KioskUpload.propTypes = {
  stateId: PropTypes.string,
  requiredDocs: PropTypes.array,
  preSave: PropTypes.func,
  setFormValue: PropTypes.func,
  setUploadIsDone: PropTypes.func,
}

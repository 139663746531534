import React from 'react'

const TermsCoolingService = ({ ...rest }) => {
  return (
    <div className={`terms-and-conditions ${rest.className}`}>
      <div className="extTitle">
        <h5>
          TERMS & CONDITIONS FOR THE PROVISION OF CENTRALISED COOLING SERVICES
        </h5>
      </div>
      <div>
        1. Capitalised terms used in these Terms & Conditions shall have the
        meanings set out below:
      </div>
      <div className="extContents">
        <div>
          {' '}
          1.1 “Agreement” means the agreement between SP Home and the Customer
          on these Terms & Conditions.
        </div>
        <div>
          {' '}
          1.2 "Centralised Cooling System" means all plant, apparatus, equipment
          and facilities designed, engineered, constructed, installed, tested,
          commissioned, operated, maintained and/or used by SP Home for or in
          connection with the provision of the Supply by SP Home.
        </div>
        <div>
          {' '}
          1.3 “Customer” with respect to any premises, means the person who
          requested or contracted with SP Home for the supply of chilled water
          to the premises (including an occupier of the premises).
        </div>
        <div>
          {' '}
          1.4 “Supply Commencement Date” means the date of commencement of the
          Supply to the Premises, as determined and notified by SP Home to the
          Customer.
        </div>
        <div>
          {' '}
          1.5 “FCU” means a chilled water fan coil unit installed within the
          Premises pursuant to the Installation Agreement; and "FCUs" means all
          such fan coil units.
        </div>
        <div>
          {' '}
          1.6 “Force Majeure Event” means any event or circumstance, the
          occurrence and the effect of which SP Home is unable to prevent or
          avoid notwithstanding the exercise of reasonable foresight, diligence
          and care on the part of SP Home, and includes acts of God, lightning
          strikes, earthquakes, floods, storms, typhoons, explosions, fires,
          pandemics, epidemics and any natural disaster; acts of civil or
          military authority, acts of war, acts of government authorities or
          competent authorities, acts of public enemies, terrorism, riots, civil
          commotion, malicious damage, sabotage, outbreaks of hostilities,
          labour disturbances, insurrection, revolution, strikes, lockouts and
          labour disputes.
        </div>
        <div>
          {' '}
          1.7 “HDB” means the Housing and Development Board, a body corporate
          established under the Housing and Development Act 1959.
        </div>
        <div>
          {' '}
          1.8 “Installation Agreement” means the Agreement for Installation of
          Equipment for Centralised Cooling Services made or to be made between
          the Customer and SP Home (for the installation, testing and
          commissioning of the Last Mile Piping & Cabling and the FCUs).
        </div>
        <div> 1.9 “Last Mile Piping & Cabling” means the:</div>
        <div>
          <div className="extContents">
            <div>
              (i) chilled water pipes within the Premises which connect each FCU
              to the main chilled water pipes of the Centralised Cooling System
              which serves the building in which the Premises are located;
            </div>
            <div>
              (ii) condensate drain pipes from each FCU to the nearest drain
              point within the Premises;
            </div>
            <div>
              (iii) power cable from the electrical isolator at the air-con
              ledge of the Premises to all the FCUs within the Premises; and
            </div>
            <div>
              (iv) insulated polyvinyl chloride (PVC) piping and trunking
              enclosing the items set out in Clauses 1.9(i) to 1.9(iii).
            </div>
          </div>
        </div>
        <div>
          {' '}
          1.10 “Meter” means the British Thermal Unit meter installed within the
          pipe riser outside the Premises in the HDB block for the Supply.
        </div>
        <div>
          {' '}
          1.11 “No Tampering Obligation” has the meaning given to it in Clause
          18.
        </div>
        <div>
          {' '}
          1.12 “Personal Data” has the meaning given to it in the Personal Data
          Protection Act 2012.
        </div>
        <div>
          {' '}
          1.13 “Premises” means the premises of the Customer as identified in
          the Schedule to the Installation Agreement.{' '}
        </div>
        <div>
          {' '}
          1.14 “SP Home” means SP Home Cooling Pte. Ltd. (UEN No. 201924654M), a
          company incorporated in Singapore and having its registered office at
          2 Kallang Sector SP Group Building Singapore 349277, and includes any
          successors or assigns thereof.
        </div>
        <div>
          1.15 “Supply” means the supply of chilled water through the Last Mile
          Piping & Cabling to the FCUs at the Premises for space cooling.
        </div>
        <div>
          1.16 “Supply Period” means the period commencing on the Supply
          Commencement Date and continuing until terminated in accordance with
          the terms of this Agreement.
        </div>
        <div>
          1.17 “Usage Charge” in relation to any billing period, means the
          charges for the Supply during that billing period, calculated in
          accordance with the formula set out in Clause 4.
        </div>
        <div>
          Any reference to appointed agents and/or contractors in this Agreement
          shall refer to Daikin Airconditioning (Singapore) Pte Ltd and/or such
          other agents or contractors engaged by SP Home from time to time.
        </div>
      </div>
      <div>
        2. The rights and obligations of SP Home and the Customer under this
        Agreement shall be conditional on the Customer having entered into an
        Installation Agreement. In the event of any conflict or inconsistency
        between the terms of the Installation Agreement and this Agreement, the
        terms of this Agreement shall prevail to the extent of such conflict or
        inconsistency.
      </div>
      <div>
        3. SP Home shall provide the Supply during the Supply Period. The Supply
        may be suspended or interrupted by SP Home at its absolute discretion
        without any notice or incurring any liability whatsoever, to the
        Customer in any of the following circumstances (and for such period as
        SP Home considers appropriate):
      </div>
      <div className="extContents">
        <div>
          3.1 the Customer commits any breach of the Customer’s obligations
          under the Installation Agreement;
        </div>
        <div>
          3.2 the Customer commits any breach of the Customer’s obligations
          under this Agreement (other than the No Tampering Obligation) and the
          Customer does not remedy such breach within 30 days following a notice
          given by SP Home to the Customer to remedy such breach; or
        </div>
        <div>
          3.3 the Customer commits any breach of the No Tampering Obligation.
        </div>
        <div>
          3.4 during any period of servicing, maintenance or repair to SP Home’s
          Centralised Cooling System;
        </div>
        <div>
          3.5 if there is any irregularity relating to the Supply to the
          Premises, and/or any damage to the Last Mile Piping & Cabling or FCUs
          is suspected or detected;
        </div>
        <div>
          3.6 to protect the health and safety of any person or the environment;
        </div>
        <div>3.7 due to any Force Majeure Event; and/or</div>
        <div>
          3.8 if there is any disruption in the supply of electricity and/or
          water (other than the Supply) to the Premises.
        </div>
        <div>
          The Customer shall be solely responsible (at the Customer’s own cost)
          to arrange for such alternative cooling systems as the Customer may
          consider suitable for the Customer’s needs, if the Customer is unable
          to tolerate any failure, reduction, interruption or inconsistency in
          the Supply.
        </div>
      </div>
      <div>4. The Customer shall pay the Usage Charge for the Supply.</div>
      <div>
        The Usage Charge for each billing period shall be calculated based on
        the following:
      </div>
      <div>
        Usage Charge = Metered quantities of Supply for that billing period in
        units of kilowatt-hours refrigeration (kWrh) x prevailing consumption
        Usage Charge Rate (S$/kWrh)
      </div>
      <div>where:</div>
      <div>
        “Usage Charge Rate” is the prevailing dollar per kilowatt-hours
        refrigeration (kWrh) of Supply or S$/kWrh rate published by SP Home at
        https://mytengah.sg/centralised-cooling/chilled-water-usage-rate.
      </div>
      <div>
        5. The Usage Charge Rate shall be updated quarterly to reflect the
        actual cost of electricity based on the prevailing residential
        electricity energy charge in S$ per kWeh as published quarterly on
        https://www.spgroup.com.sg, the prevailing domestic potable water
        prices, water borne fees, water conservation taxes as published on the
        PUB website, and the consumer price index (CPI) published by the
        Department of Statistics, Singapore.
      </div>
      <div>
        6. SP Home shall bill the Customer for the Supply based on the metered
        consumption data recorded by the Meter. The Meter shall be periodically
        read by SP Home or SP Home’s appointed contractor. If the Meter does
        not, in the reasonable opinion of SP Home, correctly register such
        Supply (e.g. due to the Meter being damaged or faulty), SP Home shall be
        entitled to make adjustments to any bill(s) based on the estimated
        consumption of the Supply as determined by SP Home, and/or charge such
        sum as SP Home may assess (and the Customer shall pay the sum so
        assessed by SP Home).
      </div>
      <div>
        7. SP Home shall own, install, calibrate and maintain the accuracy of
        the Meter. The Customer shall not remove, modify, cover up, damage, or
        tamper or otherwise interfere with or do any act which may affect the
        accuracy of the Meter. The Customer shall bear the costs of repairing or
        replacing any Meter if the damage to or inaccuracy or loss of the Meter
        is caused by any act or omission of the Customer.
      </div>
      <div>
        8. The Customer may request for a Meter accuracy test at an additional
        payment of S$500, which shall be payable by the Customer in advance. If
        the test result confirms that the accuracy of the Meter is not within
        the limits prescribed by the relevant manufacturer or supplier of the
        Meter, such additional payment will be refunded to the Customer and the
        Meter will be replaced free of charge.
      </div>
      <div>
        9. The Usage Charges set out in the Customer’s bill issued by SP
        Services Pte Ltd shall be final, binding and conclusive evidence of the
        amount payable by the Customer. The Customer shall pay SP Home the Usage
        Charges for the metered quantities of chilled water supplied to the
        Customer during the billing period using the various payment options
        prescribed by SP Home from time to time (as published on
        https://mytengah.sg/centralised-cooling/chilled-water-usage-rate),
        within 14 days following the date of the bill. Post-dated cheques are
        not acceptable. The Customer shall make payment for the Usage Charges in
        full, notwithstanding any dispute. Upon resolution of such dispute, any
        amount which the Customer may have overpaid or underpaid (as determined
        by such resolution) will be credited or debited to the Customer (and
        reflected in the following month’s bill).
      </div>
      <div>
        10. The Customer shall pay late payment interest (before as well as
        after any judgement) at the rate of 1% per month on any outstanding
        amount owed by the Customer, from the date when such amount becomes due
        and payable to the date of actual payment. Without prejudice to Clause
        23, the Customer shall indemnify and hold harmless SP Home against all
        costs and expenses incurred by SP Home in recovering such payment
        (including without limitation legal costs (on a full indemnity basis)).
      </div>
      <div>
        11. The amounts payable to SP Home under this Agreement exclude the
        charges for all electricity, water, sewerage, gas, cable,
        telecommunications, and any other utilities at the Premises. The
        Customer must settle payment for such charges directly with the relevant
        service providers.
      </div>
      <div>
        12. SP Home shall be entitled to terminate the Supply Period with
        immediate effect by written notice to the Customer, if:
      </div>
      <div className="extContents">
        <div>
          12.1 SP Home ceases for any reason to be able to provide the Supply
          (including any termination of the arrangement with HDB which enables
          SP Home to provide the Supply);
        </div>
        <div>
          12.2 the Customer commits any breach of the Customer’s obligations
          under the Installation Agreement or this Agreement, or any sum payable
          to SP Home remains outstanding for more than 45 days; or
        </div>
        <div>
          12.3 the Customer becomes bankrupt or insolvent, or makes any
          composition or arrangement with the Customer’s creditors.
        </div>
      </div>
      <div>
        13. SP Home shall be entitled to terminate the Supply Period at any time
        after the Supply Commencement Date by giving the Customer at least 1
        month’s prior written notice.
      </div>
      <div>
        14. The Customer shall be entitled to terminate the Supply Period at any
        time after the Supply Commencement Date by giving SP Home at least 1
        month’s prior written notice, provided that the Customer shall not be
        permitted to give any notice of termination unless the Customer has duly
        paid all sums due and payable by the Customer under this Agreement and
        the Installation Agreement to SP Home.
      </div>
      <div>
        15. The termination of the Supply Period shall not affect the Customer’s
        liability to SP Home or SP Home’s rights and remedies. Any and all sums
        payable by the Customer to SP Home pursuant to this Agreement and the
        Installation Agreement shall immediately fall due for payment on the
        date of termination of the Supply Period.
      </div>
      <div>
        16. If the Supply Period is terminated pursuant to Clause 12.1 or 13, SP
        Home shall pay the Customer an amount equal to the value of the
        remaining useful lifespan of each FCU (amortised on a straight line
        basis of a 7-year useful lifespan period commencing on the date of key
        collection.
      </div>
      <div>
        17. If the Supply is suspended or interrupted under Clause 3.1, 3.2 or
        3.3, or the Supply Period is terminated under Clause 12.2 or 12.3, and
        the Customer has remedied all outstanding breaches to the satisfaction
        of SP Home, the Customer may request that SP Home reconnect the Supply,
        and resume the Supply Period. Any such reconnection shall be at SP
        Home’s sole discretion, and the Customer shall pay SP Home a
        reconnection fee of S$60. SP Home shall be entitled to review and revise
        the reconnection fee from time to time at its sole discretion. Any
        revised reconnection fee (and the date such revised fee takes effect)
        will be published on https://mytengah.sg or otherwise notified by SP
        Home to Customer.
      </div>
      <div>
        18. The Customer shall ensure that the Centralised Cooling System is not
        modified, tampered or otherwise interfered with, and the Last Mile
        Piping & Cabling and FCUs installed at the Premises is not encased in
        any other equipment, cabling or boxing, and is not closed-off, removed,
        modified, covered up, tampered or otherwise interfered with except by SP
        Home or SP Home’s appointed contractors (collectively the “No Tampering
        Obligation”). Failing which, SP Home may, at the Customer’s cost and
        expense (and without any liability to the Customer for any loss, damage
        and/or cost of reinstatement), remove and/or modify such arrangements
        and/or encasements, and/or repair or replace any component of the
        Centralised Cooling System, the Last Mile Piping & Cabling or the FCUs.
        This Clause 18 shall remain in force even if the Supply Period is
        terminated.
      </div>
      <div>
        19. In the event that the Supply Period is terminated for any reason,
        and if the Customer wishes to dismantle and remove the FCUs, the
        Customer shall notify SP Home in writing of the same and shall comply
        with the following procedure at the Customer’s own cost and expense:
      </div>
      <div className="extContents">
        <div>
          19.1 The Customer shall schedule an appointment with SP Home to turn
          off the chilled water supply valve outside the Premises.
        </div>
        <div>
          19.2 SP Home will turn off the chilled water supply valve, disconnect
          the piping at the entrance of the Premises, and cover up the
          Centralised Cooling System’s pipe sleeve openings at such entrance.
        </div>
        <div>
          19.3 Without prejudice to Clause 20, the Customer shall provide SP
          Home and/or its appointed contractors access to the Premises to carry
          out any and all works referred to in Clause 19.2. SP Home shall not be
          liable to the Customer for any loss or damage arising from or in
          connection with such works (including any loss or damage to the
          Premises).
        </div>
        <div>
          19.4 The Customer shall be solely responsible to carry out any
          dismantling and/or removal of the FCUs and trunking within the
          Premises (after the completion of the works referred to in Clause
          19.2).
        </div>
      </div>
      <div>
        20. The Customer shall ensure that SP Home and/or its appointed
        contractors be given access to the Premises at all reasonable times to
        install, inspect and carry out any such work as SP Home considers
        appropriate in connection with the provision of the Supply (including on
        the FCUs, the Last Mile Piping & Cabling, the Meter and any other
        equipment). SP Home may appoint such contractors as it considers fit to
        perform any of its obligations under this Agreement.
      </div>
      <div>21. The Customer hereby represents and warrants that:</div>
      <div className="extContents">
        <div>
          21.1 the Customer has the legal capacity to enter into this Agreement;
        </div>
        <div>
          21.2 the Customer is the registered owner or tenant of the Premises;
          and
        </div>
        <div>21.3 the Customer is not an undischarged bankrupt.</div>
      </div>
      <div>
        22. The Customer shall immediately notify SP Home in writing if there
        are any changes to the particulars and/or information given to SP Home
        by the Customer prior to entry into this Agreement.
      </div>
      <div>
        23. The Customer shall indemnify and hold harmless SP Home against any
        losses, damages, claims, actions, judgements, suits, costs, expenses,
        disbursements, injuries or other liabilities whatsoever incurred or
        suffered by, or brought against, SP Home arising out of or in connection
        with any act, omission, default or negligence of the Customer, or any
        breach or default whatsoever of the Customer’s obligations under this
        Agreement.
      </div>
      <div>
        24. Without prejudice to Clause 3 above, SP Home shall not be liable for
        any losses, damages, claims, actions, judgements, suits, costs,
        expenses, disbursements, injuries or other liabilities whatsoever
        suffered by or caused to the Customer, the Premises or any other
        property of the Customer arising from or in connection with any
        interruption, suspension, outage, failure, reduction, inconsistency or
        other disruption of the Supply (whether planned or unplanned) whether
        due to faults in the electricity and/or water supply to the Premises or
        otherwise. SP Home shall not in any circumstances whatsoever be liable
        to the Customer for any loss of profit, loss of revenue, loss of use,
        loss of contract or loss of goodwill, or any indirect or consequential
        loss howsoever arising (including due to any default, negligence or
        breach by SP Home). Any statutory or implied conditions and warranties
        are expressly excluded. In any case, SP Home’s aggregate liability to
        the Customer for any loss or damage arising out of or in connection with
        this Agreement shall be limited to S$1,800.
      </div>
      <div>
        25. All amounts specified in this Agreement shall be exclusive of goods
        and services tax. The Customer shall be solely liable to pay all goods
        and services tax imposed or levied under applicable law on any and all
        amounts payable by the Customer under this Agreement.
      </div>
      <div>
        26. The Customer hereby consents and agrees to SP Home’s collecting,
        using and/or disclosing data (including Personal Data) relating to the
        Customer at any time, for the following purposes:
      </div>
      <div className="extContents">
        <div>
          26.1 providing the Customer with access to and/or use of the “SP
          utilities” mobile application (or any other mobile application made
          available by SP Home or its related corporations);
        </div>
        <div>
          26.2 billing and account management (including debt collection and
          recovery);
        </div>
        <div>26.3 communications with the Customer;</div>
        <div>26.4 verifying the identity and authority of the Customer;</div>
        <div>26.5 developing new services and products;</div>
        <div>
          26.6 carrying out analytics, research, planning and statistical
          assessment; and/or
        </div>
        <div>
          26.7 facilitating SP Home’s compliance with applicable laws,
          regulations, guidelines and/or business requirements (including
          providing any public agency with Personal Data in response to requests
          from such public agency).
        </div>
      </div>
      <div>
        27. The Customer warrants that where the Customer has disclosed Personal
        Data of third parties (e.g. other tenants of the Premises) to SP Home in
        connection with the abovementioned purposes, the Customer has obtained
        the prior consent of such third parties for SP Home to collect, use and
        disclose such Personal Data for such purposes, in accordance with any
        applicable laws, regulations and/or guidelines.
      </div>
      <div>
        28. The Customer acknowledges and consents to SP Home’s disclosure of
        any data (including any Personal Data) relating to the Customer, to SP
        Home’s insurers, bankers, sub-contractors, service providers, partners
        and/or any government or regulatory authority and/or any other third
        party designated by SP Home for any of the purposes described in Clause
        26.
      </div>
      <div>
        29. The Customer shall not assign, transfer or otherwise deal with any
        of the Customer’s rights, interests or obligations under this Agreement
        except with SP Home’s prior written consent, which consent may be given
        subject to such conditions as SP Home considers fit. SP Home may assign,
        transfer or otherwise deal with any or all of its rights, interest and
        or obligations under this Agreement by written notice to the Customer.
      </div>
      <div>
        30. SP Home may at any time and from time to time by written notice to
        the Customer amend, supplement or modify the terms and conditions of
        this Agreement (including, without limitation, to address any change in
        any applicable law, regulation, rule or directive). The Customer shall
        observe and comply with the same with effect from the date stated in SP
        Home’s notice.
      </div>
      <div>
        31. The invalidity, illegality or unenforceability of any provision of
        this Agreement shall not in any way affect or impair any other provision
        of this Agreement and this Agreement shall be construed as if such
        invalid or illegal or unenforceable provision had never been contained
        herein.
      </div>
      <div>
        32. A person who or which is not a party to this Agreement shall not
        have any right under the Contracts (Rights of Third Parties) Act 2001 to
        enforce any provision of this Agreement.
      </div>
      <div>
        33. Unless otherwise specified, all notices, demands or communications
        required or permitted to be given or made under this Agreement:
      </div>
      <div className="extContents">
        <div>
          33.1 to the Customer, may be given or made by courier, prepaid
          registered post or electronic mail to any of the mailing addresses or
          electronic mail addresses of the Customer in the records of SP Home
          (or to such other address as the Customer may from time to time notify
          SP Home in writing);
        </div>
        <div>
          33.2 to SP Home, may be given or made by courier, prepaid registered
          post or electronic mail to the following address or electronic mail
          address (or to such other address as SP Home may from time to time
          notify the Customer in writing):
        </div>
        <div>Address: SP Group</div>
        <div>2 Kallang Sector Singapore 349277</div>
        <div>Attention: SP Home Cooling Pte. Ltd.</div>
        <div>Email: tengahsupport@spgroup.com.sg</div>
      </div>
      <div>34. This Agreement shall be governed by the laws of Singapore.</div>
      <div>
        35. Any dispute arising out of or in connection with this Agreement
        shall be submitted for mediation at the Singapore Mediation Centre in
        accordance with the Singapore Mediation Centre’s Mediation Procedure in
        force for the time being. Either party may submit a request to mediate
        to the Singapore Mediation Centre upon which the other party will be
        bound to participate in the mediation within 30 days thereof. Unless
        otherwise agreed by the parties, the Mediator(s) will be appointed by
        the Singapore Mediation Centre. The mediation will take place in
        Singapore in the English language and the parties agree to be bound by
        any settlement agreement reached. Each party shall bear its own costs
        for any mediation commenced in the Singapore Mediation Centre. If,
        notwithstanding any mediation commenced pursuant to this Clause 35, the
        parties are unable to reach a mutually satisfactory resolution of any
        dispute arising out of or in connection with this Agreement no later
        than 30 days after the date the dispute is referred for mediation, then
        either party may refer such dispute for resolution exclusively to the
        Courts of Singapore.
      </div>
    </div>
  )
}

export default TermsCoolingService

import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import 'atlas'
import 'atlas/dist/atlas.bundle.css'
import './index.scss'
import { ScrollToTop } from 'spd-oa/components/common'

import App from './App'

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <Router>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>,
  document.getElementById('root')
)

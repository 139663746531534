import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import { boolean, date, mixed, object, ref, string } from 'yup'
import classnames from 'classnames'

import SignupConfig from 'spd-oa/config'
import { TurnOnServices } from 'spd-oa/components'
import {
  Button,
  ConfirmationDialog,
  Detail,
  Icons,
  Summary,
  Terms,
} from 'spd-oa/components/common'
import { FormCTA, FormErrorBlock } from 'spd-oa/components/FormElement'
import { allEmpty, isEmpty } from 'spd-oa/helpers'
import Utils from 'spd-oa/utils'
import Signature from 'spd-oa/pages/wiz/Signature/Signature'
import { toDateTime } from 'spd-oa/services/eSignature/helpers'
import { getESignatureConfig } from 'spd-oa/pages/wiz/Signature/helpers'

const schema = object().shape({
  application_domain: string(),
  application_type: string(),

  USE_OF_PREMISES_LIST: string()
    .when('application_domain', {
      is: 'commercial',
      then: string().required(),
    })
    .label('Type of Premises'),
  ELECT_CAPACITY: string()
    .when(['USE_OF_PREMISES_LIST', 'services'], {
      is: (use_premises, services) => {
        const criteria = [
          'PREMISES_PUBLIC_LIGHTING',
          'PREMISES_OTHERS',
          'PREMISES_CONSTRUCTION_SITE',
        ]
        return (
          services.some((s) => s === 'electricity') &&
          criteria.some((c) => c === use_premises)
        )
      },
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .label('Electricity Supply Capacity (kVA)'),
  WATER_CAPACITY: string()
    .when(['USE_OF_PREMISES_LIST', 'services'], {
      is: (use_premises, services) => {
        const criteria = ['PREMISES_OTHERS', 'PREMISES_CONSTRUCTION_SITE']
        return (
          services.some((s) => s === 'water') &&
          criteria.some((c) => c === use_premises)
        )
      },
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .matches(/(^[0-9]+$)/, 'Water Supply Capacity must be numeric')
    .label('Water Supply Capacity'),
  NO_DAYS: string()
    .when(['USE_OF_PREMISES_LIST', 'services'], {
      is: (use_premises, services) => {
        const criteria = ['PREMISES_WAYANG']
        return criteria.some((c) => c === use_premises)
      },
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .label('No Of Days'),
  meter_readings: boolean(),
  electric_meter_reading: string(),
  water_meter_reading: string(),
  gas_meter_reading: string(),

  use_myinfo: boolean(),
  isPooRequired: boolean(),

  poo: string()
    .nullable()
    .when('isPooRequired', {
      is: true,
      then: mixed()
        .required()
        .label('Proof of Ownership'),
      otherwise: string().notRequired(),
    }),

  isESignatureRequired: boolean(),
  signature_file_id: string()
    .nullable()
    .when(['isESignatureRequired'], {
      is: true,
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .label('e-Signature'),
  turn_on_date: string(),
  turn_on_time: mixed(),

  timeslot_required: boolean(),
})

const signedBy = {
  customer: ['Signature'],
  sp: ['Company Stamp (If available)'],
}

class Review extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      meter_readings: false,
      electric_meter_reading: '',
      water_meter_reading: '',
      gas_meter_reading: '',
      turn_on_date: '',
      turn_on_time: '',
      turn_on_datetime: '',
      notification_type: '',
      meter_reading_required: false,
      poo: null,
      // isGstCertRequired: false,
      check_eeg: false,
      gst_reg_no: '',
      gst_reg_date: '',
      security_deposit_calculation: {
        security_deposit: 0,
        USE_OF_PREMISES_LIST: '',
        ELECT_CAPACITY: '',
        WATER_CAPACITY: '',
        NO_DAYS: '',
      },
    })

    context.actions.setFields(data)
    this.state = {
      data,
      errPrint: null,
      showPreTurnOnPrompt: context.data.preTurnOn,
      requiredDocs: [],
    }
  }

  componentDidMount() {
    const {
      context: {
        actions: contextActions,
        config: contextConfig,
        data: contextData,
      },
    } = this.props

    this._renderToFrame()
    Utils.getAttachments(
      this.props.context.data,
      this.props.context.config,
      this._form,
      this._preSave
    ).then(() =>
      this.setState({
        requiredDocs: Utils.getRequiredDocs(
          {
            ...contextData,
            ...contextConfig,
          },
          this._form.state.values
        ),
      })
    )

    if (contextConfig.application_domain !== 'commercial') {
      contextActions.presave({
        preTurnOn: false,
        showPreTurnOnPrompt: false,
      })
    }
  }

  componentDidUpdate() {
    this._renderToFrame()
  }

  _preTurnOnCloseHandle = () => {
    this.setState({
      showPreTurnOnPrompt: false,
    })
  }

  render() {
    const { context } = this.props
    const { data, errPrint, requiredDocs, showPreTurnOnPrompt } = this.state
    const {
      data: contextData,
      config: contextConfig,
      shared: contextShared,
      actions: contextActions,
      stateId,
    } = context
    const fields =
      (SignupConfig.printFields[contextConfig.application_domain] &&
        Object.keys(
          SignupConfig.printFields[contextConfig.application_domain][
            contextConfig.application_type
          ]
        )) ||
      null

    const formConfig = getESignatureConfig()
    return (
      <div className={`signup--print signup--form`}>
        <header className="signup--head">
          <h3>{formConfig.reviewStepTitle}</h3>
          <p>{formConfig.reviewStepInstruction}</p>
        </header>

        <iframe
          id="print-frame"
          name="print-frame"
          style={{
            width: '100%',
          }}
        />
        <section
          style={{
            opacity: '0',
            visibility: 'hidden',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '-1',
            width: '100%',
          }}
        >
          <small>{`This is a preview of your application`}</small>
          <div
            id="printable"
            ref={(el) => {
              this._wrap = el
            }}
          >
            <figure className="logo for-print">
              <img src="https://assets.spdigital.sg/img/spgroup/logo_tagline_horizontal_v1_0.svg" />
            </figure>
            <div className="Xprintable-border">
              <h3 className="page-title">
                {contextConfig.application_domain === 'commercial' &&
                contextData.premises.nanl
                  ? 'Open Utilities Account for Non-Standard Commercial Premises'
                  : contextConfig.application_domain === 'commercial' &&
                    'Open Utilities Account for Commercial Premises'}
                {contextConfig.application_domain === 'residential' &&
                contextData.premises.nanl
                  ? 'Open Utilities Account for Non-Standard Residential Premises'
                  : contextConfig.application_domain === 'residential' &&
                    'Open Utilities Account for Residential Premises'}
                <br />
                {contextConfig.application_type === 'personal'
                  ? 'Under Personal Name'
                  : 'Under Company Name'}
              </h3>
              {this._renderFields(
                fields,
                contextData,
                contextConfig.application_domain,
                contextConfig.application_type
              )}
            </div>

            <div className="signature-nodes">
              <Signature by={signedBy.customer} />
              <Signature by={signedBy.sp} />
            </div>

            <div className="print-pagebreak" />

            <Terms className="for-print" />
          </div>
        </section>

        <Summary contextConfig={contextConfig} data={contextData} />

        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          enableReinitialize={true}
          initialValues={{
            ...data,
            application_domain: contextConfig.application_domain,
            application_type: contextConfig.application_type,
            use_myinfo: contextData.use_myinfo,
            ...data.security_deposit_calculation,
            turn_on_date: '',
            turn_on_time: '',
            turn_on_datetime: '',
            timeslot_required: false,
            isPooRequired: contextData.isPooRequired,
            isESignatureRequired: formConfig.enableESignature,
            services:
              (contextData.turnOnServices &&
                contextData.turnOnServices.services) ||
              [],
          }}
          onSubmit={this._handleSubmit}
          isInitialValid={true}
          render={({
            values,
            touched,
            errors,
            isValid,
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            handleBlur,
          }) => (
            <form
              className="form-container"
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              onSubmit={handleSubmit}
            >
              <Detail.DetailNode>
                <TurnOnServices
                  stateId={stateId}
                  contextData={contextData}
                  contextShared={contextShared}
                  contextActions={contextActions}
                  contextConfig={contextConfig}
                  handleSubmitMeterCheck={this._handleSubmitMeterCheck}
                  handleEegCheck={this._handleEegCheck}
                  handleEegFields={this._handleEegFields}
                  requiredDocs={requiredDocs}
                  formProps={{
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    setFieldError,
                    handleChange,
                    setFieldTouched,
                    handleBlur,
                  }}
                  homeContent={false}
                  fetchTimeslotCallBack={this._errPrintOkHandler}
                />
              </Detail.DetailNode>

              <div className="signature-nodes">
                <Signature
                  name="signature_file_id"
                  error={touched.signature_file_id && errors.signature_file_id}
                  setError={setFieldError}
                  setTouched={setFieldTouched}
                  by={formConfig.signedByCustomer}
                  eSignature={formConfig.enableESignature}
                  onSignatureUploaded={(name, data) => {
                    setFieldValue(name, data.fileId)
                    setFieldValue('signed_at', new Date())
                  }}
                  helperText={formConfig.customerHelperText}
                />
                <Signature
                  by={formConfig.signedBySP}
                  displayValue={
                    formConfig.enableESignature &&
                    values.signed_at &&
                    toDateTime(values.signed_at, 'DD/MM/YYYY')
                  }
                  helperText={formConfig.spHelperText}
                />
              </div>

              {!formConfig.enableESignature && (
                <>
                  <Button
                    variant="secondary"
                    link={true}
                    className={`btn-print`}
                    onClickHandler={this._printHandler}
                    data-oa={{
                      turn_on_datetime: values.turn_on_datetime,
                      timeslot_required: values.timeslot_required,
                    }}
                  >
                    <Icons.print width={24} height={24} /> <span>Print</span>
                  </Button>
                  <small
                    style={{
                      display: 'block',
                    }}
                  >
                    {`You can click on the Printer icon to print out your application to sign.`}
                  </small>

                  {errPrint && (
                    <FormErrorBlock id="print-field_error" error={errPrint} />
                  )}
                </>
              )}

              <FormCTA>
                <Button
                  variant="secondary"
                  onClickHandler={this._handleBackBtn}
                >
                  {`Back`}
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !isValid ||
                    values['turn_on_datetime'] === '' ||
                    (values['timeslot_required'] &&
                      (!values['turn_on_datetime'].start ||
                        !values['turn_on_datetime'].end)) ||
                    (values['isPooRequired'] && !values['poo']) ||
                    (values['check_eeg'] &&
                      (!values['gst_reg_no'] || !values['gst_reg_date'])) ||
                    !isEmpty(errors)
                  }
                >
                  {`Next`}
                </Button>
              </FormCTA>
            </form>
          )}
        />
        {contextData.showPreTurnOnPrompt && (
          <ConfirmationDialog
            confirmOkHandler={this._preTurnOnContinueHandler}
            actionConfig={{
              cancel: {
                label: '',
              },
              ok: {
                label: 'Continue',
              },
            }}
          >
            <span style={{ textAlign: 'center' }}>
              New HDB Build-To-Order (BTO) premises have ongoing supplies. You
              do not need to be present during the turn-on date
            </span>
          </ConfirmationDialog>
        )}
        {/* {
          errPrint && (
            <ConfirmationDialog
              confirmOkHandler={this._errPrintOkHandler}
              title={`Print Application Form`}
              content={errPrint}
              processing={false}
              actionConfig={{
                cancel: false,
                ok: {
                  label: 'Continue'
                }
              }}
            />
          )
        } */}
      </div>
    )
  }

  _renderFields(fields, data, domain, type) {
    if (!fields) return null
    return fields.map((groupField, index) => {
      const group = SignupConfig.printFields[domain][type][groupField]
      return (
        <Detail.DetailNode
          className={classnames('detail_section', {
            'detail-selection-sd':
              groupField === 'security_deposit_calculation',
            'detail-section-empty': groupField === 'emptySection',
          })}
          key={`${groupField}--${index}`}
          label={group.label}
        >
          {group.fields &&
            group.fields.map((f, i) => {
              let d
              if (f.parent && f.parent !== '') {
                if (data[groupField]) {
                  d = data[groupField][f.parent]
                  if (Array.isArray(f.field) && f.field.length > 0) {
                    d = {}
                    f.field.forEach((key) => {
                      if (data[groupField] && data[groupField][f.parent]) {
                        d[key] = data[groupField][f.parent][key]
                      } else if (data[f.parent]) {
                        d[key] = data[f.parent][key]
                      } else {
                        d[key] = data[key]
                      }
                    })
                    if (f.skip_empty) {
                      d = allEmpty(d) ? null : d
                    }
                  } else {
                    if (data[f.parent]) {
                      d = data[f.parent][f.field]
                    } else {
                      d = data[f.field]
                    }
                  }
                }
              } else {
                if (data[groupField]) {
                  if (Array.isArray(f.field) && f.field.length > 0) {
                    d = {}
                    f.field.forEach((key) => {
                      d[key] = data[groupField][key]
                    })
                  } else {
                    d = data[groupField][f.field]
                  }
                }
              }
              if (d) {
                if (f.hasOwnProperty('show')) {
                  if (typeof f.show === 'boolean') {
                    if (f.show) {
                      return (
                        <Detail.DetailNodeItem
                          key={`${groupField}-${f.field}--${i}`}
                          label={f.label}
                          data={d}
                          render={(data) => {
                            if (f.resolve && typeof f.resolve === 'function') {
                              return f.resolve(data)
                            }

                            return <span>{d}</span>
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                  if (typeof f.show === 'function') {
                    if (f.show(d)) {
                      return (
                        <Detail.DetailNodeItem
                          key={`${groupField}-${f.field}--${i}`}
                          label={f.label}
                          data={d}
                          render={(data) => {
                            if (f.resolve && typeof f.resolve === 'function') {
                              return f.resolve(data)
                            }
                            return <span>{d}</span>
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                }
                return (
                  <Detail.DetailNodeItem
                    key={`${groupField}-${f.field}--${i}`}
                    label={f.label}
                    data={d}
                    render={(data) => {
                      if (f.resolve && typeof f.resolve === 'function') {
                        return f.resolve(data)
                      }

                      return <span>{d}</span>
                    }}
                  />
                )
              }
              return null
            })}
        </Detail.DetailNode>
      )
    })
  }

  _renderToFrame() {
    const contHtml = this._wrap.innerHTML
    let styles = [...document.getElementsByTagName('style')]
    let link =
      process.env.NODE_ENV === 'production'
        ? [...document.getElementsByTagName('link')]
        : []
    if (styles.length === 0) {
      styles = [...document.getElementsByTagName('link')]
      styles = styles.map((s) => {
        return s.outerHTML
      })
      styles = styles.join(' ')
    } else {
      styles = styles.map((s) => {
        return s.innerHTML
      })
      link = link
        .map((s) => {
          return s.outerHTML
        })
        .filter((l) => l.includes('stylesheet'))
      link = link.join(' ')
      styles = '<style>' + styles.join(' ') + '</style>'
    }

    const frm = window.frames['print-frame']
    frm.document.write(`
      <!doctype html>
      <html>
        <head>
          ${link}
          ${styles}
        </head>
        <body>
          <div class='print-container'>
            <div id='printable'>
              ${contHtml}
            </div>
          </div>
        </body>
      </html>
    `)
    frm.document.close()
  }

  _printHandler = () => {
    const { context } = this.props
    const { config: contextConfig } = context
    const {
      turn_on_datetime,
      timeslot_required,
      check_eeg,
      gst_reg_no,
      gst_reg_date,
      security_deposit,
    } = this._form.state.values
    if (
      (turn_on_datetime === '' && !context.data.premises.nanl) ||
      (timeslot_required && (!turn_on_datetime.start || !turn_on_datetime.end))
    ) {
      let errPrint = `Please select your preferred turn on date`
      if (
        timeslot_required &&
        (!turn_on_datetime.start || !turn_on_datetime.end)
      ) {
        errPrint = `Please select your preferred turn on timeslot`
      }
      this.setState((prevState) => ({
        ...prevState,
        errPrint,
      }))
      return null
    }
    if (
      security_deposit === 0 &&
      contextConfig.application_domain !== 'residential'
    ) {
      let errPrint = `Please fill all your security deposit fields`
      this.setState((prevState) => ({
        ...prevState,
        errPrint,
      }))
      return null
    }
    if (check_eeg && (gst_reg_no === '' || gst_reg_date === '')) {
      let errPrint = `Please fill in your GST information`
      this.setState((prevState) => ({
        ...prevState,
        errPrint,
      }))
      return null
    }

    this.setState((prevState) => ({
      ...prevState,
      errPrint: null,
    }))
    const frm = window.frames['print-frame']
    frm.print()
  }

  _preTurnOnContinueHandler = () => {
    const {
      state: { values },
    } = this._form
    this._handleSubmit(values)
  }

  _handleSubmit = (values) => {
    const { context } = this.props
    const { actions, data: contextData, config: contextConfig } = context

    if (
      contextData.preTurnOn &&
      !contextData.showPreTurnOnPrompt &&
      contextConfig.application_domain !== 'commercial'
    ) {
      actions.presave({ showPreTurnOnPrompt: true })
    } else {
      if (!values.meter_readings) {
        values.gas_meter_reading = ''
        values.electric_meter_reading = ''
        values.water_meter_reading = ''
      }

      if (!values.check_eeg) {
        values.gst_reg_no = ''
        values.gst_reg_date = ''
      }

      this._preSave(values)
      actions.next()
    }
  }

  _handleNext = () => {
    const { actions } = this.props.context
    actions.next()
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (this._form.state && this._form.state.values) {
      this._preSave(this._form.state.values)
    }
    actions.prev()
  }

  _preSave = (values) => {
    const { actions, data } = this.props.context
    if (actions.presave) {
      const payload = {
        meter_readings: values.meter_readings,
        electric_meter_reading: values.electric_meter_reading,
        water_meter_reading: values.water_meter_reading,
        gas_meter_reading: values.gas_meter_reading,
        notification_type: values.notification_type,
        check_eeg: values.check_eeg,
        poo: values.poo,
        gst_reg_no: values.gst_reg_no,
        gst_reg_date: values.gst_reg_date,
        docs: values.docs || data.docs,
        turnOnServices: {
          ...data.turnOnServices,
          bypassZCGas: false,
        },
        zcGasDate: '',
        zczzDate: '',
      }

      if (values.isESignatureRequired) {
        payload.signature_file_id = values.signature_file_id
        payload.signed_at = values.signed_at
      }

      actions.presave(payload)
    }
  }

  _errPrintOkHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      errPrint: null,
    }))
  }

  _handleSubmitMeterCheck = (e) => {
    this._form.setFieldValue('meter_readings', e.target.checked)
    if (!e.target.checked) {
      let attachments = []
      if (this._form.state.values.electric_meter_reading)
        attachments.push('meter_electric')
      if (this._form.state.values.water_meter_reading)
        attachments.push('meter_water')
      if (this._form.state.values.gas_meter_reading)
        attachments.push('meter_gas')

      Utils.deleteAttachments(attachments, this._form)
    }
  }

  _handleEegCheck = (e) => {
    const { data: contextData } = this.props.context
    this._form.setFieldValue('check_eeg', e.target.checked)
    const newFormState = {
      ...this._form.state.values,
      check_eeg: e.target.checked,
    }
    this._preSave(newFormState)
    if (!e.target.checked) {
      let attachments = []
      const { docs } = contextData
      this._form.setFieldValue('gst_reg_no', '')
      this._form.setFieldValue('gst_reg_date', '')
      Utils.deleteAttachments(attachments, this._form)
      this._preSave({
        ...this._form.state.values,
        check_eeg: false,
        gst_reg_no: '',
        gst_reg_date: '',
        docs: {
          ...docs,
        },
      })
    }
  }
}

export default Review

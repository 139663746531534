import React, { PureComponent } from 'react'

class FormField extends PureComponent {
  render() {
    const { label, field, children } = this.props
    return (
      <div className={`lm--formItem lm--formItem--inline ${field}`}>
        <div className="lm--formItem-label">{label}</div>
        <div className="lm--formItem-control">{children}</div>
      </div>
    )
  }
}

FormField.defaultProps = {
  label: '',
  field: '',
}

export default FormField

import React, { PureComponent as Component, Fragment } from 'react'
// import Utils from 'spd-oa/utils'
// import { API } from 'spd-oa/services'

class IndexPage extends Component {
  state = {
    retailerKey: null,
    retailer: null,
    sessionId: null,
    proceed: false,
  }
  render() {
    const { proceed, error } = this.state
    return (
      <Fragment>
        {proceed && <div>{`Open Account`}</div>}
        {error && (
          <section className="card-error">
            <h2>Error occured, please check Session Id and Retailer Key</h2>
          </section>
        )}
      </Fragment>
    )
  }
}

export default IndexPage

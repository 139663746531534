import React, { PureComponent } from 'react'

class Utilities extends PureComponent {
  render() {
    return (
      <section>
        <h2>Utilitites</h2>
      </section>
    )
  }
}

export default Utilities

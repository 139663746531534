import React from 'react'
import { Modal } from 'spd-oa/components/common'
import PropTypes from 'prop-types'
import ESignature from 'spd-oa/components/common/ESignature'

const lmBtnSmall = 'lm--button lm--button--small'
const dataUrlType = 'image/png'

function SignaturePopup(props) {
  const _sigPad = React.useRef(null)

  const handleClear = () => {
    _sigPad && _sigPad.current.clear()
  }

  const handleSave = () => {
    if (_sigPad && !_sigPad.current.isEmpty()) {
      props
        .handleSave(
          _sigPad.current.getTrimmedCanvas().toDataURL(dataUrlType),
          _sigPad.current.toDataURL(dataUrlType)
        )
        .finally(props.handleClose)
    }
  }

  const assignRef = (ref) => {
    _sigPad.current = ref
    if (_sigPad.current && props.value) {
      _sigPad.current.fromDataURL(props.value)
    }
  }

  if (!props.open) {
    return null
  }

  return (
    <Modal>
      <div className="signature-popup">
        <div className="lm--header">
          <h3>Please sign within the box area below</h3>
          <p>
            Please note that only the image within the allotted space will be
            saved.
          </p>
        </div>
        <div className="signaturePad">
          <ESignature assignRef={assignRef} />
        </div>

        <div className="signature-popup-action">
          <button
            className={`${lmBtnSmall} lm--button--outlined`}
            onClick={handleClear}
          >
            Clear
          </button>

          <div className="action--right">
            <button
              className={`${lmBtnSmall} lm--button--solid`}
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className={`${lmBtnSmall} lm--button--grey `}
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

SignaturePopup.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
}

export default SignaturePopup

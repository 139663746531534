import React, { PureComponent, Fragment } from 'react'
import { Formik } from 'formik'
import { object, string, bool, date, boolean } from 'yup'
import moment from 'moment'
import debounce from 'lodash/debounce'

import {
  FormGroup,
  FormField,
  FormInput,
  FormMobileNumber,
  FormCheckBox,
  FormSelectOption,
  FormDate,
  FormUpload,
  FormCTA,
} from 'spd-oa/components/FormElement'
import { WizError } from 'spd-oa/components/wiz'
import {
  Button,
  Icons,
  SuccessBar,
  ConfirmationDialog,
  MyInfoTerms,
  Tooltip,
} from 'spd-oa/components/common'
import { MyInfo } from 'spd-oa/components/myinfo'
import {
  isEmpty,
  isCitizenOrPR,
  validateNRIC,
  processQueries,
} from 'spd-oa/helpers'
import { API } from 'spd-oa/services'
import Utils from 'spd-oa/utils'
import MyInfoLogo from 'spd-oa/images/logos/singpass_logo_red.svg'
import { personalDocs } from 'spd-oa/attachmentHelpers'

require('moment-timezone')

/**
 * Check if NRIC/FIN document upload is required
 * @param {boolean} use_myinfo
 * @param {boolean} kioskMode
 * @return {boolean}
 */
function isNricRequired(use_myinfo, kioskMode) {
  return !use_myinfo && !kioskMode
}

const schema = object().shape({
  use_myinfo: bool(),
  nric_fin: string()
    .required()
    .min(9, 'NRIC or FIN must be 9 characters')
    .max(9, 'NRIC or FIN must be 9 characters')
    .test('nric_fin', 'Please enter valid NRIC or FIN', (val) => {
      return validateNRIC(val)
    })
    .label('NRIC or FIN Number'),
  nric_fin_doi: date().when('use_myinfo', {
    is: false,
    then: date()
      .required()
      .label('NRIC or FIN Date of Issue'),
    otherwise: date().notRequired(),
  }),
  proof_of_ownership: string(),
  salutation: string()
    .required()
    .label('Salutation'),
  name: string()
    .required()
    .strict(false)
    .trim('Please enter valid name')
    .matches(/^[a-zA-Z,()/\-.'@\s]*$/, 'Please enter valid name')
    .label('Name as per NRIC or FIN')
    .max(80),
  nationality: string(),
  race: string()
    .required()
    .label('Race'),
  dob: date()
    .required()
    .label('Date of Birth'),
  nric_image_front: string(),
  nric_image_back: string(),
  nric_fin_front: string().when(['use_myinfo', 'kioskMode'], {
    is: isNricRequired,
    then: string()
      .required()
      .label('NRIC or FIN (Front)'),
    otherwise: string().notRequired(),
  }),
  nric_fin_back: string().when(['use_myinfo', 'kioskMode'], {
    is: isNricRequired,
    then: string()
      .required()
      .label('NRIC or FIN (Back)'),
    otherwise: string().notRequired(),
  }),
  work_pass: string().when(['use_myinfo', 'nationality', 'nric_fin'], {
    is: (myinfo, nationality, nric_fin) => {
      if (myinfo && nationality !== 'SG' && !isCitizenOrPR(nric_fin)) {
        return true
      }
      return false
    },
    then: string()
      .required()
      .label('Work Pass Status'),
    otherwise: string().notRequired(),
  }),
  ebillEligible: boolean(),
  email: string().when('is_linked_email', {
    is: false,
    then: string()
      .matches(
        /^\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+\s*$/,
        'Please enter valid email address'
      )
      .email('Please enter valid email address')
      .max(64, 'Maximum of 64 characters are allowed for Email address')
      .required()
      .label('Email'),
  }),
  mobile_prefix: string()
    .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, 'Please enter valid mobile prefix')
    .min(3)
    .label('Mobile Prefix'),
  mobile_number: string()
    .required()
    .min(8, 'Mobile Number must be 8 digits')
    .max(8, 'Mobile Number must be 8 digits')
    .matches(/^([98]\d{7})$/, 'Please enter valid mobile number')
    .label('Mobile Number'),
  alternate_contact_number: string()
    .min(8, 'Alternate Contact Number must be 8 digits')
    .max(8, 'Alternate Contact Number must be 8 digits')
    .matches(/^([9863]\d{7})$/, 'Please enter valid alternate contact number')
    .label('Alternate Contact Number')
    .test(
      'contacts-match',
      'Please provide a different contact number',
      function(value) {
        return this.parent.mobile_number !== value
      }
    ),
  bankruptcy: bool(),
  bankruptcy_no: string().when('bankruptcy', {
    is: true,
    then: string()
      .matches(
        /^[a-zA-Z0-9-/&]+$/,
        'Bankruptcy No accepts dash, slash, ampersand, alphabets and numbers'
      )
      .required()
      .label('Bankruptcy No')
      .max(40),
    otherwise: string().notRequired(),
  }),
  kioskMode: bool(),
  hasMissedAppointment: bool().oneOf(
    [false],
    'Cannot continue due to missed appointment'
  ),
  isDisconnected: bool().oneOf([false], 'Cannot continue due to disconnected'),
})

class Particulars extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (
      props.location.search !== '' &&
      props.location.search !== state.search
    ) {
      return {
        search: props.location.search,
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      personal_information: {
        nric_fin: '',
        nric_fin_doi: '',
        work_pass: '',

        salutation: '',
        name: '',
        race: '',
        nationality: '',
        dob: '',
      },
      bankruptcy: {
        bankruptcy: false,
        bankruptcy_no: '',
      },
      personal_contact_information: {
        email: '',
        mobile_prefix: '+65',
        mobile_number: '',
        alternate_contact_prefix: '+65',
        alternate_contact_number: '',
      },
      personal_supporting_docs: {
        proof_of_ownership: '',
        nric_fin_front: '',
        nric_fin_back: '',
      },
      use_myinfo: false,
      otpSent: false,
      ebillEligible: false,
    })

    context.actions.setFields(data)
    this.state = {
      data,
      processingMyInfo: false,
      search: '',
      myinfoDataError: null,
      myinfoDataErrorPop: false,
      myinfoDataFetching: false,
      myinfoDataSuccess: false,
      myinfoDataNameEnable: false,
      myinfoDataWorkPassError: null,
      myinfoDataWorkPassErrorPop: false,
      eligible: true,
      showMyInfoTnc: false,
      skippedMyInfo: false,
      ebillEligibleError: null,
      emailValidating: false,
      nricFinValidating: false,
    }
  }

  componentDidMount() {
    const { data } = this.state
    const {
      context,
      location: { search },
    } = this.props
    context.actions.setFields(data)

    if (this._form) {
      this._form.validateForm()
    }

    window.scrollTo(0, 0)

    this._getAttachments()
    processQueries(search, [
      {
        param: 'target',
        resolver: (id) => {
          const target = document.getElementById(id)
          if (target) {
            const targetBounds = target.getBoundingClientRect()
            setTimeout(() => {
              window.scrollTo({
                left: 0,
                top: targetBounds.top,
                behavior: 'smooth',
              })
            }, 300)
            target.focus()
          }
        },
      },
    ])
  }

  componentDidUpdate(prevProps, prevState) {
    const { context, location } = this.props
    const { search } = location
    const { data: contextData } = context
    const { skippedMyInfo } = this.state
    const { context: prevContext } = prevProps
    const { data: prevContextData } = prevContext

    if (search) {
      processQueries(search, [
        {
          param: 'redirected',
          value: 'myinfo',
          resolver: () => {
            window.scrollTo(0, 0)
            this._getMyInfoData()
          },
        },
        {
          param: 'target',
          resolver: (id) => {
            const target = document.getElementById(id)
            if (target) {
              const targetBounds = target.getBoundingClientRect()
              if (prevState.skippedMyInfo !== skippedMyInfo) {
                if (skippedMyInfo && !contextData.promptMyInfo) {
                  setTimeout(() => {
                    window.scrollTo({
                      left: 0,
                      top: targetBounds.top,
                      behavior: 'smooth',
                    })
                  }, 300)
                  target.focus()
                }
              }
            }
          },
        },
      ])
    } else {
      if (
        location !== prevProps.location ||
        (prevState.skippedMyInfo !== skippedMyInfo &&
          skippedMyInfo &&
          !contextData.promptMyInfo)
      ) {
        window.scrollTo(0, 0)
      }
    }

    if (contextData.isNewCustomer !== prevContextData.isNewCustomer) {
      const { state } = this._form
      const { values } = state
      if (values.email !== '') {
        this._checkIfEligibleForEBill({ email: values.email })
      }
    }

    if (contextData.linked_email !== prevContextData.linked_email) {
      if (contextData.linked_email) {
        this._form.setValues({
          ...this._form.state.values,
          is_linked_email: true,
          email: contextData.linked_email,
        })
      } else {
        this._form.setValues({
          ...this._form.state.values,
          email: '',
          is_linked_email: false,
        })
      }
    }
  }

  render() {
    const { context } = this.props
    const { data: contextData, stateId } = context
    const { mode: oaMode } = Utils.getOAMode()
    const {
      data,
      processingMyInfo,
      myinfoDataFetching,
      myinfoDataSuccess,
      myinfoDataError,
      myinfoDataErrorPop,
      myinfoDataWorkPassError,
      myinfoDataWorkPassErrorPop,
      eligible,
      showMyInfoTnc,
      emailValidating,
      nricFinValidating,
    } = this.state

    return (
      <div className={`signup--particulars signup--form`}>
        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={{
            ...data.personal_information,
            ...data.personal_contact_information,
            ...data.bankruptcy,
            ...data.personal_supporting_docs,
            use_myinfo: data.use_myinfo,
            ebillEligible: data.ebillEligible,
            ebill_otp: '',
            kioskMode: oaMode === 'kiosk',
            is_linked_email: !!contextData.linked_email,
          }}
          onSubmit={this._handleSubmit}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
            submitCount,
            isValid,
          }) => {
            return Utils.getParticularsOptions().personal.myinfo &&
              contextData.promptMyInfo &&
              !values.use_myinfo &&
              !myinfoDataFetching &&
              !myinfoDataError ? (
              <MyInfo
                contextConfig={context.config}
                confirmHandler={this._confirmMyInfoHandler}
                skipHandler={this._skipMyInfoHandler}
                backHandler={this._handleBackBtn}
                processing={processingMyInfo}
              />
            ) : (
              <form
                className="form-container"
                onSubmit={handleSubmit}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
              >
                {myinfoDataError && !myinfoDataErrorPop && (
                  <WizError error={myinfoDataError} />
                )}
                {myinfoDataFetching && (
                  <section className="form-cover">
                    <div className="cover-content">
                      <Icons.spinloader
                        width={50}
                        height={50}
                        color={['#333333']}
                      />
                      <span>Processing..</span>
                    </div>
                  </section>
                )}
                {!values.use_myinfo &&
                  !myinfoDataFetching &&
                  !myinfoDataError && (
                    <MyInfo
                      isField={true}
                      confirmHandler={this._confirmMyInfoHandler}
                      skipHandler={this._skipMyInfoHandler}
                    />
                  )}
                {myinfoDataSuccess && (
                  <SuccessBar>
                    <span>
                      {`You have successfully retrieved your personal and contact information using `}
                    </span>
                    <img
                      className="myinfo-logo"
                      src={MyInfoLogo}
                      alt="Myinfo"
                    />
                  </SuccessBar>
                )}
                <FormGroup groupTitle="Your Personal Information">
                  <FormSelectOption
                    label="Salutation"
                    id="salutation"
                    error={
                      (touched.salutation && errors.salutation) ||
                      errors.salutation
                    }
                    value={values.salutation}
                    onChange={handleChange}
                    options={[
                      {
                        value: '',
                        text: 'Select One',
                      },
                      {
                        value: 'mr',
                        text: 'MR',
                      },
                      {
                        value: 'mrs',
                        text: 'MRS',
                      },
                      {
                        value: 'ms',
                        text: 'MS',
                      },
                      {
                        value: 'mdm',
                        text: 'MDM',
                      },
                      {
                        value: 'dr',
                        text: 'DR',
                      },
                    ]}
                  />

                  <FormInput
                    label="Name as per NRIC or FIN Card"
                    id="name"
                    error={touched.name && errors.name}
                    value={values.name}
                    maxLength={'80'}
                    disabled={values.use_myinfo}
                    capsOnChange={true}
                    setFieldValue={setFieldValue}
                    handleBlur={this._handleInputBlur}
                    autoComplete={'no'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                  />

                  <FormInput
                    label="NRIC No or FIN"
                    id="nric_fin"
                    name="nric_fin"
                    className={'upper'}
                    error={touched.nric_fin && errors.nric_fin}
                    value={values.nric_fin}
                    maxLength={'9'}
                    capsOnChange={true}
                    loading={nricFinValidating}
                    setFieldValue={setFieldValue}
                    disabled={values.use_myinfo}
                    handleBlur={this._handleInputBlur}
                  >
                    <p>{`For the purpose of application for water, gas and electricity only`}</p>
                  </FormInput>
                  {!values.use_myinfo && (
                    <FormDate
                      id="nric_fin_doi"
                      label={
                        <>
                          NRIC or FIN Date of Issue
                          <Tooltip>
                            Please check your NRIC/FIN Card for the Date of
                            Issue
                          </Tooltip>
                        </>
                      }
                      error={
                        (touched.nric_fin_doi && errors.nric_fin_doi) ||
                        errors.nric_fin_doi
                      }
                      setFieldValue={setFieldValue}
                      disablePrev={false}
                      disableNext={true}
                      value={values.nric_fin_doi}
                      showLegend={false}
                    />
                  )}
                  {values.use_myinfo &&
                    values.nationality !== 'SG' &&
                    !isCitizenOrPR(values.nric_fin) && (
                      <FormInput
                        label="Work Permit Status"
                        id="work_pass"
                        error={touched.work_pass && errors.work_pass}
                        value={values.work_pass}
                        handleChange={handleChange}
                        disabled={values.use_myinfo}
                      />
                    )}
                  <FormDate
                    id="dob"
                    label="Date of Birth"
                    setFieldValue={setFieldValue}
                    value={values.dob}
                    showLegend={false}
                    error={(touched.dob && errors.dob) || errors.dob}
                    offset={{
                      years: 18,
                      months: 11 - new Date().getMonth(),
                    }}
                    disabled={values.use_myinfo}
                    disableAfter={moment(new Date())
                      .subtract(18, 'years')
                      .subtract(1, 'days')
                      .toDate()}
                  />
                  <FormSelectOption
                    label="Race"
                    id="race"
                    error={(touched.race && errors.race) || errors.race}
                    value={values.race}
                    onChange={handleChange}
                    options={[
                      {
                        value: '',
                        text: 'Select One',
                      },
                      {
                        value: 'chinese',
                        text: 'Chinese',
                      },
                      {
                        value: 'indian',
                        text: 'Indian',
                      },
                      {
                        value: 'malay',
                        text: 'Malay',
                      },
                      {
                        value: 'others',
                        text: 'Others',
                      },
                    ]}
                    disabled={values.use_myinfo}
                  />
                </FormGroup>

                {oaMode !== 'kiosk' && !values.use_myinfo ? (
                  <FormGroup
                    groupTitle={`Your NRIC or FIN`}
                    groupHeaderContent={`For authentication purpose, please upload your NRIC or FIN copies`}
                  >
                    <Fragment>
                      <FormField
                        label="NRIC or FIN (Front)"
                        field="form-upload_doc"
                      >
                        <FormUpload.Doc
                          id="nric_fin_front"
                          max={4000000}
                          setFieldValue={setFieldValue}
                          value={
                            !values.use_myinfo &&
                            values['nric_fin_front'] !== ''
                              ? [values['nric_fin_front']]
                              : ''
                          }
                          upload={true}
                          params={{
                            transaction_id: stateId,
                            type: 'nric_front',
                          }}
                          error={
                            touched.nric_fin_front && errors.nric_fin_front
                          }
                          useFilename={true}
                        />
                      </FormField>
                      <FormField
                        label="NRIC or FIN (Back)"
                        field="form-upload_doc"
                      >
                        <FormUpload.Doc
                          id="nric_fin_back"
                          max={4000000}
                          setFieldValue={setFieldValue}
                          value={
                            !values.use_myinfo && values['nric_fin_back'] !== ''
                              ? [values['nric_fin_back']]
                              : ''
                          }
                          upload={true}
                          params={{
                            transaction_id: stateId,
                            type: 'nric_back',
                          }}
                          error={touched.nric_fin_back && errors.nric_fin_back}
                          useFilename={true}
                        />
                      </FormField>
                    </Fragment>
                  </FormGroup>
                ) : values.use_myinfo ? null : (
                  <FormGroup
                    groupTitle={
                      <>
                        Your NRIC or FIN
                        <Tooltip>
                          Upload your NRIC/FIN copy in the next page
                        </Tooltip>
                      </>
                    }
                    groupHeaderContent="Please upload a copy of your NRIC or FIN by scanning the QR code at the Required Document section"
                  />
                )}
                <FormGroup groupTitle={`Your Contact Information`}>
                  {contextData.checkEligibleSuccess &&
                    !contextData.linked_email && (
                      <FormInput
                        label="Email"
                        id="email"
                        error={touched.email && errors.email}
                        value={contextData.linked_email || values.email}
                        handleChange={this._handleChange}
                        handleBlur={this._handleInputBlur}
                        autoComplete={'off'}
                        autoCorrect={'off'}
                        autoCapitalize={'off'}
                        loading={emailValidating}
                        disabled={contextData.linked_email}
                      />
                    )}
                  <FormMobileNumber
                    label="Mobile No"
                    id="mobile"
                    errorPrefix={
                      (touched.mobile_prefix && errors.mobile_prefix) ||
                      errors.mobile_prefix
                    }
                    errorNumber={
                      (touched.mobile_number && errors.mobile_number) ||
                      errors.mobile_number
                    }
                    valuePrefix={values.mobile_prefix}
                    value={values.mobile_number}
                    handleChange={handleChange}
                  />
                  <FormMobileNumber
                    label="Alternate Contact No (Optional)"
                    id="alternate_contact"
                    errorPrefix={
                      (touched.alternate_contact_prefix &&
                        errors.alternate_contact_prefix) ||
                      errors.alternate_contact_prefix
                    }
                    errorNumber={
                      values.alternate_contact_number // only validate when alternate contact is filled
                        ? (touched.alternate_contact_number &&
                            errors.alternate_contact_number) ||
                          errors.alternate_contact_number
                        : ''
                    }
                    valuePrefix={values.alternate_contact_prefix}
                    value={values.alternate_contact_number}
                    handleChange={handleChange}
                    params={{
                      checkAgainst: {
                        mobile_number: values.mobile_number,
                      },
                    }}
                  />
                </FormGroup>
                <FormGroup
                  groupTitle={
                    <>
                      Bankruptcy
                      <Tooltip>
                        Please check here if you are an undischarged bankrupt
                      </Tooltip>
                    </>
                  }
                  groupField={
                    <FormCheckBox
                      onlyElem={true}
                      id="bankruptcy"
                      label=""
                      error={touched.bankruptcy && errors.bankruptcy}
                      text={'I am an undischarged bankrupt.'}
                      value={values.bankruptcy}
                      handleChange={handleChange}
                      checked={values.bankruptcy}
                    />
                  }
                >
                  {values.bankruptcy && (
                    <FormInput
                      label="Bankruptcy No"
                      id="bankruptcy_no"
                      error={touched.bankruptcy_no && errors.bankruptcy_no}
                      value={values.bankruptcy_no}
                      maxLength={'40'}
                      capsOnChange={true}
                      setFieldValue={setFieldValue}
                      handleBlur={this._handleInputBlur}
                    />
                  )}
                </FormGroup>

                <FormCTA>
                  <Button
                    variant="secondary"
                    onClickHandler={this._handleBackBtn}
                    disabled={emailValidating || nricFinValidating}
                  >
                    Back
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      (!eligible && !isValid && submitCount > 0) ||
                      !isEmpty(errors) ||
                      (values.use_myinfo && myinfoDataError) ||
                      (values.use_myinfo && myinfoDataWorkPassError) ||
                      emailValidating ||
                      nricFinValidating
                    }
                  >
                    Next
                  </Button>
                </FormCTA>
              </form>
            )
          }}
        />
        {myinfoDataError && myinfoDataErrorPop && (
          <ConfirmationDialog
            id="myinfo-err-prompt"
            confirmOkHandler={this._myinfoErrContinueHandler}
            content={`${myinfoDataError}`}
            processing={false}
            actionConfig={{
              cancel: false,
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}
        {myinfoDataWorkPassError && myinfoDataWorkPassErrorPop && (
          <ConfirmationDialog
            id="myinfo-err-prompt"
            confirmCancelHandler={this._myinfoErrHandler}
            content={`${myinfoDataWorkPassError}`}
            processing={false}
            actionConfig={{
              cancel: {
                label: 'Go back to open account screen',
              },
              ok: false,
            }}
          />
        )}

        {showMyInfoTnc && (
          <ConfirmationDialog
            id="myinfo-tnc-prompt"
            confirmOkHandler={this._confirmMyInfoTermsConditions}
            confirmCancelHandler={this._cancelMyInfoTermsConditions}
            onCloseHandler={this._cancelMyInfoTermsConditions}
            content={<MyInfoTerms />}
            actionConfig={{
              styles: {
                display: 'flex',
                flexDirection: 'column',
              },
              ok: {
                label: 'I want to use Myinfo',
                styles: {
                  margin: 'auto',
                },
              },
              cancel: {
                label: 'Do not proceed',
                styles: {
                  display: 'block',
                  border: 'none',
                  background: 'none',
                  color: '#e54545',
                },
              },
            }}
          />
        )}
      </div>
    )
  }

  _checkEmailDebounce = debounce(
    (value) => {
      this._handleCheckEmail(value)
    },
    1000,
    {
      trailing: true,
      leading: false,
    }
  )

  _handleChange = (e) => {
    const { target } = e
    const { id, value } = target
    const { handleChange } = this._form
    const { context } = this.props
    const { data: contextData, config: contextConfig } = context

    if (handleChange) {
      handleChange(e)
    }
    if (contextConfig.application_domain === 'residential') {
      if (id === 'email') {
        if (value !== '') {
          if (contextData.personal_contact_information.email !== '') {
            this.setState((prevState) => ({
              ...prevState,
              emailValidating: true,
            }))
            this._checkEmailDebounce(value)
          }
        }
      }
    }
  }

  _handleInputBlur = (e) => {
    const { id } = e.target
    this._form.setFieldTouched(id, true)
    if (id === 'nric_fin') {
      this._handleNricFinBlur()
    } else if (id === 'email') {
      this._handleEmailBlur(e)
    }
  }

  _handleNricFinBlur = () => {
    const { context } = this.props
    const { data: contextData } = context
    const { values } = this._form.state

    if (values.nric_fin !== '') {
      if (contextData.personal_information.nric_fin !== values.nric_fin) {
        this._checkIfEligible()
      }
    }
  }

  _handleEmailBlur = (e) => {
    const { value } = e.target
    const { context } = this.props
    const { data: contextData, config: contextConfig } = context
    if (contextConfig.application_domain === 'residential') {
      if (contextData.personal_contact_information.email === '') {
        this._handleCheckEmail(value)
      }
    }
  }

  _handleCheckEmail = (value) => {
    const { context } = this.props
    const { data: contextData } = context
    if (contextData.personal_contact_information.email !== value) {
      this._checkIfEligibleForEBill({ email: value })
    }
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (this._form.state && this._form.state.values) {
      this._preSave(this._form.state.values)
    }
    actions.prev()
  }

  _handleSubmit = (values) => {
    const { context } = this.props
    const { actions } = context
    this._preSave(values)
    actions.next()
  }

  _preSave = (values) => {
    const { actions, data: contextData } = this.props.context
    if (actions.presave) {
      const payload = {
        personal_information: {
          nric_fin: values.nric_fin,
          nric_fin_doi: values.nric_fin_doi,
          work_pass: values.work_pass,
          proof_of_ownership: values.proof_of_ownership,
          salutation: values.salutation,
          name: values.name,
          race: values.race,
          dob: values.dob,
          email: values.email,
          nationality: values.nationality,
          mobile_prefix: values.mobile_prefix,
          mobile_number: values.mobile_number,
          home_contact_prefix: values.home_contact_prefix,
          home_contact_number: values.home_contact_number,
        },
        bankruptcy: {
          bankruptcy: values.bankruptcy,
          bankruptcy_no: values.bankruptcy ? values.bankruptcy_no : '',
        },
        personal_contact_information: {
          alternate_contact_prefix: values.alternate_contact_prefix,
          alternate_contact_number: values.alternate_contact_number,
          mobile_prefix: values.mobile_prefix,
          mobile_number: values.mobile_number,
          email: values.email,
        },
        personal_supporting_docs: {
          nric_fin_front: !contextData.use_myinfo ? values.nric_fin_front : '',
          nric_fin_back: !contextData.use_myinfo ? values.nric_fin_back : '',
        },
        use_myinfo: values.use_myinfo,
        promptMyInfo: true,
        ebillEligible: values.ebillEligible,
      }
      actions.presave(payload)
    }
  }

  _checkIfEligibleForEBill = ({ email }, isMyinfo = false) => {
    const { state } = this._form
    const { errors } = state
    const { isChangedEmailLinked } = this.props.context.data
    const { stateId } = this.props.context

    if (!isMyinfo) {
      if ((email && email !== '' && errors.email) || !email) {
        return null
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      emailValidating: true,
    }))
    // this._form.setFieldTouched('email', false)

    API.ebill
      .validateEmail({ email, isChangedEmailLinked, transaction_id: stateId })
      .then((res) => {
        if (this._form) {
          if (!res.isLinked) {
            this._form.setFieldValue('ebillEligible', true)
            this.setState((prevState) => ({
              ...prevState,
              ebillEligibleError: null,
              emailValidating: false,
            }))
          } else {
            this._form.setFieldValue('ebillEligible', false)
            this.setState((prevState) => ({
              ...prevState,
              ebillEligibleError: res.message,
              emailValidating: false,
            }))
          }
        }
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          emailValidating: false,
        }))
        if (this._form) {
          this._form.setFieldValue('ebillEligible', false)
          this._form.setFieldError('email', err.error_description)
          this._form.setFieldTouched('email', true)
        }
      })
  }

  _checkIfEligible = (nric_fin = '') => {
    const { context } = this.props
    const { actions, data } = context
    const { values } = this._form.state

    const nric_roc = nric_fin !== '' ? nric_fin : values.nric_fin
    const bp_type = '1'

    this.setState((prevState) => ({
      ...prevState,
      nricFinValidating: true,
    }))

    let result = actions.checkEligibility(
      {
        nric_roc,
        bp_type,
        occupancy:
          data.premises.premise_occupancy !== '' &&
          data.premises.premise_occupancy,
      },
      () => {
        this.setState((prevState) => ({
          ...prevState,
          nricFinValidating: false,
        }))
      }
    )
    return result
  }

  _getMyInfoData = () => {
    const {
      context: { stateId, actions, data: contextData },
      history,
      match,
    } = this.props

    this.setState((prevState) => ({
      ...prevState,
      processingMyInfo: false,
      myinfoDataFetching: true,
    }))
    API.myInfo
      .getData({
        stateId,
      })
      .then((res) => {
        const data = {
          nric_fin: res.nric,
          name: res.name,
          dob:
            res.date_of_birth !== ''
              ? moment(res.date_of_birth)
                  .tz('Asia/Singapore')
                  .format()
              : '',
          race: (res.race && res.race.toLowerCase()) || '',
          nationality: res.nationality,
          email: res.email,
          mobile_number: res.mobile,
          alternate_contact_number: res.homeNo,
          work_pass: res.work_pass_status,
          eligible: res.eligible,
          is3rd:
            !res.eligible && contextData.premises.premise_occupancy === 'owner',
          use_myinfo: true,
          nric_fin_back: '',
          nric_fin_front: '',
        }
        let isEligible = this._checkIfEligible(res.nric)
        if (res.email !== '') {
          this._checkIfEligibleForEBill({ email: res.email }, true)
        }
        if (isEligible) {
          this._setFields(data)
          this.setState((prevState) => ({
            ...prevState,
            myinfoDataFetching: false,
            myinfoDataSuccess: true,
            myinfoDataError: null,
            myinfoDataErrorPop: false,
            myinfoDataWorkPassError: null,
            myinfoDataWorkPassErrorPop: false,
            myinfoDataNameEnable: false,
          }))
          actions.presave({
            promptMyInfo: false,
            use_myinfo: true,
            eligible: res.eligible,
            is3rd: !res.eligible,
            isPooRequired:
              (!res.eligible &&
                contextData.premises.premise_occupancy === 'owner') ||
              contextData.isPooRequired,
            personal_information: {
              salutation: '',
              nric_fin: res.nric,
              nric_fin_doi: '',
              name: res.name,
              dob: res.date_of_birth,
              race: (res.race && res.race.toLowerCase()) || '',
              nationality: res.nationality,
              work_pass: res.work_pass_status,
            },
            personal_contact_information: {
              alternate_contact_prefix: '+65',
              alternate_contact_number: res.homeNo,
              mobile_number_prefix: '+65',
              mobile_number: res.mobile,
              email: res.email,
            },
            personal_supporting_docs: {
              nric_fin_front: '',
              nric_fin_back: '',
            },
          })
        }
      })
      .catch((err) => {
        let _err = {
          myinfoDataFetching: false,
          myinfoDataSuccess: false,
          myinfoDataError: null,
          myinfoDataErrorPop: false,
          myinfoDataWorkPassError: null,
          myinfoDataWorkPassErrorPop: false,
          myinfoDataNameEnable: false,
        }
        if (err.error) {
          _err.myinfoDataError = (
            <span>
              An error has occured while using Myinfo.{' '}
              <Button link={true} onClickHandler={this._confirmMyInfoHandler}>
                Try again
              </Button>{' '}
              or fill up your personal information below.
            </span>
          )
          _err.myinfoDataErrorPop = false
          _err.myinfoDataNameEnable = false
          _err.myinfoDataWorkPassErrorPop = false
          if (err.error === 'myinfo_workpass_expired') {
            _err.myinfoDataWorkPassErrorPop = true
            _err.myinfoDataWorkPassError = err.error_description
            _err.myinfoDataError = null
          }
          this._form.setFieldValue('use_myinfo', false)
        }
        actions.presave({
          use_myinfo: false,
          promptMyInfo: false,
        })
        this.setState((prevState) => ({
          ...prevState,
          ..._err,
        }))
      })
    history.push(match.path)
  }

  _confirmMyInfoHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showMyInfoTnc: true,
      skippedMyInfo: false,
    }))
  }

  _skipMyInfoHandler = () => {
    const { context } = this.props
    const { actions } = context
    this.setState((prevState) => ({
      ...prevState,
      showMyInfoTnc: false,
      skippedMyInfo: true,
    }))
    this._form.setFieldValue('use_myinfo', false)
    actions.presave({
      promptMyInfo: false,
      use_myinfo: false,
    })
  }

  _confirmMyInfoTermsConditions = () => {
    const {
      context: { stateId },
    } = this.props
    this.setState((prevState) => ({
      ...prevState,
      processingMyInfo: true,
      showMyInfoTnc: false,
    }))

    API.myInfo.redirect({
      stateId,
    })
  }

  _cancelMyInfoTermsConditions = () => {
    this.setState((prevState) => ({
      ...prevState,
      showMyInfoTnc: false,
    }))
  }

  _sendOtpToMobileHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      otpSent: !prevState.otpSent,
    }))
  }
  _verifyOtpHandler = () => {
    console.log('# verify otp')
  }

  _setFields = (data) => {
    Object.keys(data).forEach((d) => {
      this._form.setFieldValue(d, data[d])
    })
  }

  _myinfoErrHandler = () => {
    const { history } = this.props
    history.push(`/open-account`)
  }

  _myinfoErrContinueHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      myinfoDataFetching: false,
      myinfoDataSuccess: false,
      myinfoDataError: null,
      myinfoDataErrorPop: false,
      myinfoDataWorkPassError: null,
      myinfoDataWorkPassErrorPop: null,
    }))
  }

  _getAttachments = () => {
    const { data: contextData } = this.props.context
    const stateId = Utils.getStateId()
    API.getAttachments({
      transaction_id: stateId,
    }).then((data) => {
      if (this._form) {
        personalDocs.forEach(({ id, type }) => {
          if (
            !isEmpty(data[type]) &&
            !contextData.personal_supporting_docs[id]
          ) {
            this._form.setFieldValue(id, data[type])
          }
        })
      }
    })
  }
}

export default Particulars

import React, { PureComponent, Fragment } from 'react'

class CheckboxGroup extends PureComponent {
  handleChange = (event) => {
    const target = event.currentTarget
    let valueArray = [...this.props.value] || []

    if (target.checked) {
      valueArray.push(target.id)
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1)
    }

    this.props.onChange(this.props.id, valueArray)
  }
  render() {
    const { groupTitle, children, value } = this.props
    return (
      <Fragment>
        {groupTitle !== '' && <p>{groupTitle}</p>}
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            field: {
              value: value.includes(child.props.id),
              onChange: this.handleChange,
            },
          })
        })}
      </Fragment>
    )
  }
}

CheckboxGroup.defaultProps = {
  groupTitle: '',
}

export default CheckboxGroup

import React from 'react'

import { ConfirmationDialog } from 'spd-oa/components/common'

const ThirdPartySiteDialog = ({ children, confirmHandler, cancelHandler }) => {
  return (
    <ConfirmationDialog
      confirmOkHandler={confirmHandler}
      confirmCancelHandler={cancelHandler}
      onCloseHandler={cancelHandler}
      onEscapeKeyDownHandler={cancelHandler}
      content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
      actionConfig={{
        cancel: {
          label: 'Cancel',
        },
        ok: {
          label: 'Continue',
        },
      }}
    >
      {children}
    </ConfirmationDialog>
  )
}

ThirdPartySiteDialog.defaultProps = {
  confirmHandler: () => {},
  cancelHandler: () => {},
}

export default ThirdPartySiteDialog

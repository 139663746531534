import React, { PureComponent } from 'react'
import { titleize } from 'spd-oa/helpers'

class Container extends PureComponent {
  render() {
    const { children, data, respHoriz, ...restProps } = this.props
    if (respHoriz) {
      return (
        <div className="app-table__resp-horiz">
          <table className="app-table lm--table" {...restProps}>
            {this._renderHeader()}
            {this._renderBody()}
          </table>
        </div>
      )
    }
    return (
      <table className="app-table lm--table" {...restProps}>
        {this._renderHeader()}
        {this._renderBody()}
      </table>
    )
  }
  _renderHeader() {
    const { data } = this.props
    if (data.length > 0) {
      return (
        <Thead>
          <Row>
            {Object.keys(data[0]).map((k, i) => {
              return (
                <Cell key={`${k}__header-table-cell-${i}`}>
                  {titleize(k, '_')}
                </Cell>
              )
            })}
          </Row>
        </Thead>
      )
    }
    return null
  }
  _renderBody() {
    const { data } = this.props
    if (data.length > 0) {
      return (
        <Tbody>
          {data.map((d, i) => {
            return (
              <Row key={`table-row-${i}`}>
                {Object.values(d).map((v, j) => {
                  return <Cell key={`${i}__table-cell-${j}`}>{v}</Cell>
                })}
              </Row>
            )
          })}
        </Tbody>
      )
    }
    return null
  }
}

Container.displayName = 'Table'

class Row extends PureComponent {
  render() {
    const { children } = this.props
    return <tr className="table--row t-row">{children}</tr>
  }
}

class Cell extends PureComponent {
  render() {
    const { children } = this.props
    return <td className="table--cell">{children}</td>
  }
}

class Thead extends PureComponent {
  render() {
    const { children } = this.props
    return <thead className="table--thead t-thead">{children}</thead>
  }
}

class Tbody extends PureComponent {
  render() {
    const { children } = this.props
    return <tbody className="table--tbody t-tbody">{children}</tbody>
  }
}

class Tfoot extends PureComponent {
  render() {
    const { children } = this.props
    return <tfoot className="table--tfoot">{children}</tfoot>
  }
}

export default {
  Container,
  Thead,
  Tbody,
  Tfoot,
  Row,
  Cell,
}

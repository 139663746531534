import React from 'react'

const GridItem = ({ className, children, ...rest }) => {
  return (
    <div className={`grid-item ${className}`} {...rest}>
      {children}
    </div>
  )
}

GridItem.defaultProps = {
  className: '',
}

export default GridItem

import React, { PureComponent } from 'react'

export default class CardListItem extends PureComponent {
  render() {
    const { className, children } = this.props
    return (
      <div
        className={`card-list--row card-list--item card-list--node ${className}`}
      >
        {children}
      </div>
    )
  }
}

CardListItem.defaultProps = {
  className: '',
}

import React, { Fragment, PureComponent } from 'react'
import { Formik } from 'formik'
import { boolean, object, string } from 'yup'
import {
  FormCheckBox,
  FormCTA,
  FormField,
  FormGroup,
  FormUpload,
} from 'spd-oa/components/FormElement'
import {
  Button,
  Detail,
  Modal,
  TermsConditions,
  TermsControl,
  Tooltip,
} from 'spd-oa/components/common'
import KioskUpload from 'spd-oa/components/KioskUpload/KioskUpload'
import { isEmpty } from 'spd-oa/helpers'
import Utils from 'spd-oa/utils'
import { meterDocs } from 'spd-oa/attachmentHelpers'
import ApplicationFormField from 'spd-oa/pages/wiz/UploadDocs/ApplicationFormField'

const schema = object().shape({
  ...ApplicationFormField.validationSchema,
  bizfile: string()
    .required()
    .label('Business or Company Registration Certificate'),

  loa: string(),

  // isGstCertRequired: boolean(),
  // gst_reg_cert: string().when('isGstCertRequired', {
  //   is: true,
  //   then: string()
  //     .required()
  //     .label('GST Registration Certification'),
  //   otherwise: string().notRequired(),
  // }),

  meter_readings: boolean(),
  electric_meter_reading: string(),
  water_meter_reading: string(),
  gas_meter_reading: string(),
  npop: string().when('nanl', {
    is: true,
    then: string()
      .required()
      .label('Proof of Premises'),
    otherwise: string().notRequired(),
  }),
  nasd: string(),

  check_i_confirm: boolean()
    .required()
    .oneOf([true], 'Must agree to the Terms and Conditions'),
  // check_i_ack: boolean().required()
})

class ResiCompUploadDocs extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      docs: {
        application_form: '',
        bizfile: '',
        loa: '',
        meter_readings: false,
        electric_meter_reading: '',
        water_meter_reading: '',
        gas_meter_reading: '',
      },
      check_i_confirm: false,
      check_i_ack: false,
    })
    context.actions.setFields(data, false)
    this.state = {
      data,
      showTerms: false,
      submitting: false,
      requiredDocs: [],
    }
  }

  _termsOkHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: false,
    }))
  }

  _termsShowHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: true,
    }))
  }

  componentDidMount() {
    const currentPage = 'upload'
    Utils.getAttachments(
      this.props.context.data,
      this.props.context.config,
      this._form,
      this._preSave,
      currentPage
    ).then(() =>
      this.setState({
        requiredDocs: Utils.getRequiredDocs(
          {
            ...this.props.context.data,
            ...this.props.context.config,
            currentPage,
          },
          this._form.state.values
        ),
      })
    )
  }

  render() {
    const { context } = this.props
    const { data: contextData, stateId } = context
    const { data, submitting, showTerms, requiredDocs } = this.state
    return (
      <div className={`signup--upload signup--form`}>
        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={this._handleSubmit}
          initialValues={{
            ...data.docs,
            check_i_confirm: data.check_i_confirm,
            check_i_ack: data.check_i_ack,
            isGstCertRequired: contextData.check_eeg,
            signature_file_id: contextData.signature_file_id,
            nanl: contextData.premises.nanl,
          }}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            handleChange,
            isValid,
          }) => {
            return (
              <form
                className="form-container"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                onSubmit={handleSubmit}
              >
                <h3>{`Upload and Submit`}</h3>
                <p>
                  {`Now that you have reviewed and completed your application printout with signature and company stamp, you are ready to upload your documents.`}
                </p>
                <small>
                  {`For faster processing, please keep each file size below 1MB.`}
                </small>

                {Utils.getOAMode().mode !== 'kiosk' && (
                  <>
                    <ApplicationFormField transactionId={stateId} />
                    <FormGroup
                      groupTitle={`Business or Company Registration Certificate`}
                      groupHeaderContent={
                        <FormUpload.Doc
                          id="bizfile"
                          setFieldValue={setFieldValue}
                          value={
                            values['bizfile'] !== '' && [values['bizfile']]
                          }
                          upload={true}
                          params={{
                            transaction_id: stateId,
                            type: 'c_acra_manual',
                          }}
                          useFilename={true}
                          max={4000000}
                          errors={errors}
                          onlyElem={true}
                        />
                      }
                    />
                    {contextData.premises.nanl && (
                      <FormGroup
                        groupTitle={
                          <Fragment>
                            {`Proof of Premises`}
                            <Tooltip>
                              <span>
                                {`e.g. Certificate of Numbering
                            from IRAS, Certificate of Factory Registration
                            from MOM`}
                              </span>
                            </Tooltip>
                          </Fragment>
                        }
                        groupHeaderContent={
                          <FormUpload.Doc
                            max={4000000}
                            id="npop"
                            label={null}
                            setFieldValue={setFieldValue}
                            value={
                              values['npop'] && values['npop'] !== ''
                                ? [values['npop']]
                                : []
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'nanl_proof_of_premise',
                            }}
                            useFilename={true}
                            errors={errors}
                          />
                        }
                      />
                    )}
                    {contextData.premises.nanl && (
                      <FormGroup
                        groupTitle={
                          <Fragment>
                            {`Additional Supporting Document (Optional)`}
                            <Tooltip>
                              <span>
                                {`Any other supporting documents for this premises`}
                              </span>
                            </Tooltip>
                          </Fragment>
                        }
                        groupHeaderContent={
                          <FormUpload.Doc
                            max={4000000}
                            id="nasd"
                            label={null}
                            setFieldValue={setFieldValue}
                            value={
                              values['nasd'] && values['nasd'] !== ''
                                ? [values['nasd']]
                                : []
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'nanl_additional_supporting_documents',
                            }}
                            useFilename={true}
                            errors={errors}
                          />
                        }
                      />
                    )}
                    <FormGroup
                      groupTitle={
                        <Fragment>
                          <div>Letter of Authorisation (Optional)</div>
                          <Tooltip>
                            <span>
                              The letter is to be endorsed by your Director
                              (listed in the recent ACRA documents/Bizfile) and
                              printed on your company’s letterhead, indicating
                              your full name, designation and NRIC/FIN number.
                            </span>
                          </Tooltip>
                        </Fragment>
                      }
                      groupHeaderContent={
                        <Fragment>
                          <FormUpload.Doc
                            id="loa"
                            setFieldValue={setFieldValue}
                            value={values['loa'] !== '' && [values['loa']]}
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'c_letter_of_auth',
                            }}
                            useFilename={true}
                            errors={errors}
                            max={4000000}
                            onlyElem={true}
                          />
                        </Fragment>
                      }
                    />
                  </>
                )}
                {Utils.getOAMode().mode === 'kiosk' && requiredDocs.length > 0 && (
                  <Detail.DetailNode>
                    <KioskUpload
                      stateId={stateId}
                      requiredDocs={requiredDocs}
                      preSave={this._preSave}
                      setFormValue={setFieldValue}
                    />
                  </Detail.DetailNode>
                )}

                <Detail.DetailNode>
                  <div className="detail_section--checks">
                    <div className="u-grid">
                      <div className="col-md-12 col-sm-12 u-cell">
                        <TermsControl
                          id="check_i_confirm"
                          name="check_i_confirm"
                          handleChange={handleChange}
                          value={values['check_i_confirm']}
                          byClickMessage={`By checking this box, the Company:`}
                        >
                          <ol>
                            <li>
                              agree to the{' '}
                              <Button
                                link={true}
                                onClickHandler={this._termsShowHandler}
                              >{`Terms and Conditions`}</Button>
                              .
                            </li>
                            <li>
                              {`confirm that the information and/or data provided in this application are true, accurate and complete, and that the Company is the Owner or Tenant of the premises;`}
                            </li>
                            <li>
                              {`shall indemnify and hold harmless SPS and/or its affiliates from any losses, damages, claims, costs or penalties arising from or in connection with any information and/or data provided in this application;`}
                            </li>
                            <li>
                              {`consent and agree to SP Services Limited (“SPS”) using any information or data that the Company provide to SPS for the following purposes:`}
                              <ol>
                                <li>
                                  {`sign the Company up as its customer and set up my utilities account;`}
                                </li>
                                <li>{`verify my identity;`}</li>
                                <li>
                                  {`provide the Company with services/ goods;`}
                                </li>
                                <li>
                                  {`any other purpose that is incidental and/or ancillary to the above stated purposes;`}
                                </li>
                              </ol>
                            </li>
                            <li>
                              {`acknowledge that the Company is required to engage a licensed electrical worker (“LEW”) to inspect and test the electrical installation if the Company have made any alteration or rewiring to it. Thereafter the LEW will lodge a copy of the Certificate of Compliance (“CoC”) together with this application to SPGroup to check the electrical installation if the electrical installation is exempted from an Electrical Installation Licence`}
                            </li>
                            <li>
                              {`acknowledge that the Company is required to engage a licensed plumber (LP) to submit the notification for water service installation (WSI) work if the Company have made any addition or alteration to existing piping and ensure the water meter is not removed or lost during the works.`}
                            </li>
                            <li>
                              {`is aware that it is an offence under the Public Utilities Act (CAP 261) to make any false statement, representation or declaration in connection with the application for water supplied by the Public Utilities Board.`}
                            </li>
                          </ol>
                        </TermsControl>
                      </div>
                    </div>
                  </div>
                </Detail.DetailNode>
                <FormCTA>
                  <Button
                    variant="secondary"
                    className="lm--switch u-ph0"
                    onClickHandler={this._handleBackBtn}
                    type="button"
                    disabled={submitting}
                  >
                    {`Back`}
                  </Button>

                  <Button
                    variant="primary"
                    className="lm--switch u-ph0"
                    type="submit"
                    disabled={!isValid || !isEmpty(errors) || submitting}
                    loading={submitting}
                  >
                    {`Submit`}
                  </Button>
                </FormCTA>
              </form>
            )
          }}
        />
        {showTerms && (
          <Modal
            onClose={this._termsOkHandler}
            onEscapeKeyDown={this._termsOkHandler}
            closeBtn={Utils.getOAMode().mode === 'kiosk'}
            title={`Terms and Conditions`}
            className="modal-terms"
          >
            <section className="terms-container">
              <TermsConditions />
            </section>
          </Modal>
        )}
      </div>
    )
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (this._form.state && this._form.state.values) {
      this._preSave(this._form.state.values)
    }
    actions.prev()
  }

  _handleSubmit = (values) => {
    const { context } = this.props
    const {
      actions,
      data: contextData,
      config: contextConfig,
      stateId,
    } = context
    this.setState((prevState) => ({
      ...prevState,
      submitting: true,
    }))
    if (actions.next) {
      actions.next(
        values,
        () => {
          this.setState((prevState) => ({
            ...prevState,
            submitting: false,
          }))
        },
        {
          contact: {
            email: `${contextData.representative.representative_email}`,
            number: `${contextData.representative.representative_mobile_number}`,
          },
          application: {
            transaction_id: stateId,
            domain: contextConfig.application_domain,
            type: contextConfig.application_type,
            occupancy: contextData.premises.premise_occupancy,
          },
        }
      )
    }
  }

  _handleSubmitMeterCheck = (e) => {
    const currentPage = 'upload'
    this._form.setFieldValue('meter_readings', e.target.checked)
    const newFormState = {
      ...this._form.state.values,
      meter_readings: e.target.checked,
    }
    this.setState({
      requiredDocs: Utils.getRequiredDocs(
        {
          ...this.props.context.data,
          ...this.props.context.config,
          currentPage,
        },
        newFormState
      ),
    })
    if (!e.target.checked) {
      const attachments = meterDocs
        .filter(({ type }) => this._form.state.values[type])
        .map(({ id }) => id)

      Utils.deleteAttachments(attachments, this._form)
    }
  }

  _preSave = (values) => {
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {
        docs: {
          ...values,
          nasd: values.nasd || '',
          npop: values.npop || '',
        },
      }
      actions.presave(payload)
    }
  }
}

export default ResiCompUploadDocs

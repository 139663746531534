import { FormGroup, FormUpload } from 'spd-oa/components/FormElement'
import React from 'react'
import { Field } from 'formik'
import { getESignatureConfig } from 'spd-oa/pages/wiz/Signature/helpers'
import { string } from 'yup'

export default function ApplicationFormField({ transactionId }) {
  const esigConfig = getESignatureConfig()
  if (esigConfig.enableESignature) {
    return <></>
  }

  return (
    <Field
      name="application_form"
      transactionId={transactionId}
      component={withFormikProps}
    />
  )
}

ApplicationFormField.validationSchema = {
  application_form: string()
    .nullable()
    .when('signature_file_id', {
      is: (fileId) => !fileId,
      then: string()
        .label('Application Form')
        .required(),
    }),
}

/**
 * @param transactionId {string}
 * @param field {{ name: string, value: string | undefined }}
 * @param form {{ setFieldValue: function, errors: {} }}
 * @return {JSX.Element}
 */
function withFormikProps({ field, form, transactionId }) {
  const params = React.useMemo(
    () => ({
      transaction_id: transactionId,
      type: 'c_signed_utility_application_form',
    }),
    [transactionId]
  )
  const valueAsArray = React.useMemo(() => {
    return field.value !== '' && [field.value]
  }, [field.value])

  return (
    <FormGroup
      groupTitle="Signed Application Form"
      groupHeaderContent={
        <FormUpload.Doc
          id={field.name}
          setFieldValue={form.setFieldValue}
          value={valueAsArray}
          upload={true}
          params={params}
          useFilename={true}
          max={4000000}
          errors={form.errors}
          onlyElem={true}
        />
      }
    />
  )
}

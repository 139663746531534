import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import { object, string, mixed } from 'yup'
import { FormGroup, FormCTA, FormUpload } from 'spd-oa/components/FormElement'
import { Button } from 'spd-oa/components/common'
import { hasEmpty, isEmpty } from 'spd-oa/helpers'

const schema = object().shape({
  personal_nric_image_front: string().required(),
  personal_nric_image_back: string().required(),
  personal_proof_premise_occupancy: mixed().required(),
  letter_of_authorization: mixed().required(),
  co_registration_certificate: mixed().required(),
  co_proof_premise_occupancy: mixed().required(),
})

class UploadDocs extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      under_personal: {
        personal_nric_image_front: '',
        personal_nric_image_back: '',
        personal_proof_premise_occupancy: '',
      },
      under_company: {
        letter_of_authorization: '',
        co_registration_certificate: '',
        co_proof_premise_occupancy: '',
      },
    })

    context.actions.setFields(data, false)
    this.state = {
      data,
    }
  }

  _handleSubmit = (values) => {
    console.log('# values', values)
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {
        under_personal: {
          personal_nric_image_front: values.personal_nric_image_front,
          personal_nric_image_back: values.personal_nric_image_back,
          personal_proof_premise_occupancy:
            values.personal_proof_premise_occupancy,
        },
        under_company: {
          letter_of_authorization: values.letter_of_authorization,
          co_registration_certificate: values.co_registration_certificate,
          co_proof_premise_occupancy: values.co_proof_premise_occupancy,
        },
      }
      actions.presave(payload, false)
      actions.next()
    }
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    actions.prev()
  }

  componentDidMount() {
    const { data } = this.state
    const { context } = this.props
    context.actions.setFields(data)
  }

  render() {
    const { data } = this.state
    return (
      <div className={`rsk_signup--upload rsk_signup--form`}>
        <Formik
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={this._handleSubmit}
          initialValues={{
            ...data.under_personal,
            ...data.under_company,
          }}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            isValid,
          }) => {
            return (
              <form className="form-container" onSubmit={handleSubmit}>
                <FormGroup groupTitle="Under Personal Name">
                  <div className={'lm--formItem lm--formItem--inline '}>
                    <div className="lm--formItem-label">
                      {`Photo of your NRIC/FIN`}
                    </div>
                    <div className="lm--formItem-control">
                      <FormUpload.Photo
                        id="personal_nric_image_front"
                        label="Add Front"
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                      <FormUpload.Photo
                        id="personal_nric_image_back"
                        label="Add Back"
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className={'lm--formItem lm--formItem--inline '}>
                    <div className="lm--formItem-label">
                      {`Proof of Premise Occupancy`}
                    </div>
                    <div className="lm--formItem-control">
                      <FormUpload.Doc
                        id="personal_proof_premise_occupancy"
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormGroup
                  groupTitle="Under Company Name"
                  className="upload_docs--company"
                >
                  <div className={'lm--formItem lm--formItem--inline '}>
                    <div className="lm--formItem-label">
                      {`Letter of Authorization`}
                    </div>
                    <div className="lm--formItem-control">
                      <FormUpload.Doc
                        id="letter_of_authorization"
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className={'lm--formItem lm--formItem--inline '}>
                    <div className="lm--formItem-label">
                      {`Co Registration Certificate`}
                    </div>
                    <div className="lm--formItem-control">
                      <FormUpload.Doc
                        id="co_registration_certificate"
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className={'lm--formItem lm--formItem--inline '}>
                    <div className="lm--formItem-label">
                      {`Proof of Premises Occupancy`}
                    </div>
                    <div className="lm--formItem-control">
                      <FormUpload.Doc
                        id="co_proof_premise_occupancy"
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormCTA>
                  <Button
                    variant="secondary"
                    className="lm--switch u-ph0"
                    onClickHandler={this._handleBackBtn}
                  >
                    Back
                  </Button>

                  <Button
                    variant="primary"
                    className="lm--switch u-ph0"
                    type="submit"
                    disabled={
                      (hasEmpty(values) && isEmpty(touched)) ||
                      (!isValid && !isEmpty(touched))
                    }
                  >
                    Next
                  </Button>
                </FormCTA>
              </form>
            )
          }}
        />
      </div>
    )
  }
}

export default UploadDocs

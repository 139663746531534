import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { openLink } from 'spd-oa/helpers'
import { Modal } from 'spd-oa/components/common'
import Utils from 'spd-oa/utils'
import TermsCoolingService from './TermsCoolingService'

const TermsConditions = ({ ...rest }) => {
  const [showTerms, setShowTerms] = useState(false)

  const termsOkHandler = () => {
    setShowTerms(false)
  }

  const linkHandler = (path, target) => () => {
    if (Utils.getOAMode().mode === 'kiosk') {
      setShowTerms(true)
    } else {
      openLink(path, target)
    }
  }

  return (
    <div className={`terms-and-conditions ${rest.className}`}>
      <div>
        The application for the supply of water, electricity, centralised
        cooling services and / or gas is subjected to the following terms and
        conditions.
      </div>
      <div className="extTitle">SP SERVICES LTD</div>
      <div>
        {' '}
        (Acting for itself and as agent for and on behalf of PUB and City Energy
        Pte. Ltd. (as Trustee of City Energy Trust) for the supply of utilities,
        and as agent for SP Home Cooling Pte. Ltd. for the supply of centralised
        cooling services, and Veolia ES Singapore Pte Ltd, Colex Environmental
        Pte Ltd, SembWaste Pte Ltd, 800 Super Waste Management Pte Ltd, TEE
        Environmental Pte. Ltd. and Alba W&amp;H Smart City Pte. Ltd. in
        relation to the collection of refuse.)
      </div>
      <div className="extContents">
        <div>
          1. I/We agree that the opening of the utilities account is subject to
          the existing customer's objection and SP Services Ltd has the rights
          to terminate my utilities account should the existing account holder
          object to my taking over the account.
        </div>
        <div>
          {' '}
          2. I/We agree that each application for a utility, when accepted, will
          constitute a binding contract between myself/ourselves and PUB / SP
          Services Ltd / City Energy Pte. Ltd. (as Trustee of City Energy
          Trust)/ SP Home Cooling Pte. Ltd./ Veolia ES Singapore Pte Ltd/ Colex
          Environmental Pte Ltd/ SembWaste Pte Ltd/ 800 Super Waste Management
          Pte Ltd/ TEE Environmental Pte. Ltd. and/or Alba W&amp;H Smart City
          Pte. Ltd. (as the case may be) and I / We agree that such contract
          shall be separate and independent from each other and shall be
          separately enforceable.
        </div>
        <div>
          {' '}
          3. In the event that I/We make an application for gas at a later date,
          I / We agree to be bound by the terms and conditions for supply of gas
          set out in Part III overleaf and such other terms and conditions which
          City Energy Pte. Ltd. (as Trustee of City Energy Trust) may, at its
          absolute discretion, notify and impose. I / We understand that nothing
          herein shall be constructed as an offer by City Energy Pte. Ltd. (as
          Trustee of City Energy Trust) to supply gas to me / us and that City
          Energy Pte. Ltd. (as Trustee of City Energy Trust) has the absolute
          discretion to reject my application for gas supply for any reason.
        </div>
        <div>
          {' '}
          4. I/ We acknowledge and agree that SP Services Ltd reserves the right
          to transfer any amounts due and owing arising from my/ our previous/
          closed account to an existing/ new account belonging to me/ us.
        </div>
        <div>
          {' '}
          5. I/We agree to be bound by the terms and conditions specified by
          each supplier as set out in Part I (Water Supply) / Part II
          (Electricity Supply) / Part III (Gas Supply)/ Part IV (Collection of
          Refuse)/ Part V (Centralised Cooling Services).
        </div>
        <div>
          {' '}
          6. I/We acknowledge and agree that PUB, SP Services Ltd, City Energy
          Pte. Ltd. (as Trustee of City Energy Trust), SP Home Cooling Pte.
          Ltd., Veolia ES Singapore Pte Ltd, Colex Environmental Pte Ltd,
          SembWaste Pte Ltd, 800 Super Waste Management Pte Ltd, TEE
          Environmental Pte. Ltd. and/or Alba W&amp;H Smart City Pte. Ltd. (as
          the case may be) may collect, use and disclose to any third party any
          and all particulars relating to my/our personal information, in
          accordance with the Personal Data Protection Act 2012 and SP Group's
          data protection policy (available at SP Group's website{' '}
          {Utils.getOAMode().mode === 'kiosk' ? (
            'https://www.spgroup.com.sg/personal-data-protection-policy'
          ) : (
            <a
              href="https://www.spgroup.com.sg/personal-data-protection-policy"
              target="_blank"
            >
              https://www.spgroup.com.sg/personal-data-protection-policy
            </a>
          )}
          ), for the purposes of (i) providing the requested services, (ii)
          billing and account management (including debt collection or
          recovery); (iii) conducting surveys or obtaining feedback; (iv)
          informing me / us of their or their related entities' and business
          affiliates' services and offers (unless I/we duly inform you
          otherwise); and (v) complying with all applicable laws and
          regulations, and business requirements.
        </div>
        <div>
          7. If I/We am/are (or subsequently become) GST-registered, I/We agree
          that I/We will not issue any tax invoice for electricity sold to SP
          Services Limited but hereby authorize SP Services to issue tax
          invoices on my/our behalf. I/We further agree that where SP Services
          Limited issues any tax invoice(s) on my behalf in respect of any
          taxable supplies that I/we provide, SP Services Limited shall be
          entitled to charge GST at the prevailing rate as at the date on which
          such taxable supplies are provided.
        </div>
        <div>
          8. I/We will notify SP Services Limited in writing immediately if
          there is any change to my/our GST status, including without limitation
          if my/our GST registration is cancelled; I/we am/are not currently
          GST-registered but subsequently become GST-registered; I/We am/are
          issued with a new GST registration number, etc.
        </div>
      </div>
      <div className="extTitle">PART I</div>
      <div>
        Terms and Conditions of the Contract for the Supply of Water by the PUB
      </div>
      <div>
        1. The Consumer shall pay an initial deposit and maintain it at an
        amount not less than one and a half times the monthly charges. For
        non-Singaporeans and non-permanent residents an amount not less than 3
        times the monthly charges.
      </div>
      <div>2. The Consumer shall accept liability for this account.</div>
      <div>
        3. Either party may give 4 working days' notice in writing to the other
        party to terminate this agreement.
      </div>
      <div>
        4. If the water meter does not in the opinion of PUB correctly register
        the amount of water supplied to the Premises, PUB shall be entitled to
        charge such sum as it shall assess and the Consumer shall accept such
        assessment as final and conclusive.
      </div>
      <div>
        5. The Consumer shall pay for the water supplied to the Premises at the
        appropriate water tariff according to the use of the Premises. The
        Consumer shall notify PUB of the change of use of the Premises within 7
        days of such change.
      </div>
      <div>
        6. The Consumer shall pay a fee on every reminder issued to the Consumer
        for failure to make payment in accordance with a bill sent to the
        Consumer.
      </div>
      <div>
        7. The Consumer shall pay a late payment charge of 1% on any outstanding
        amount owed by the Consumer, or at such rate as may be gazette by PUB
        from time to time.
      </div>
      <div>
        8. PUB shall not be liable for any failure or inconsistency in the water
        supply to the Premises whatsoever and howsoever caused.
      </div>
      <div>
        9. The Consumer’s attention is drawn to its duties and obligations in
        the Public Utilities Act (Cap 261) and the Public Utilities (Water
        Supply) Regulations, as amended from time to time.
      </div>
      <div className="extTitle">PART II</div>
      <div>
        {' '}
        Terms and Conditions of the Contract for the Supply of Electricity by SP
        Services Ltd ('SP Services')
      </div>
      <div>
        1. The Consumer shall pay an initial deposit and maintain it at an
        amount not less than one and a half times the monthly charges. For
        non-Singaporeans and non-permanent residents an amount not less than 3
        times the monthly charges.
      </div>
      <div>2. The Consumer shall accept liability for this account.</div>
      <div>
        3. Either party may give 4 working days' notice in writing to the other
        party to terminate this agreement.
      </div>
      <div>
        4. If the electricity meter does not in the opinion of SP Services
        correctly register the amount of electricity supplied to the Premises,
        SP Services shall be entitled to charge such sum as it shall assess.
      </div>
      <div>
        5. The Consumer shall pay for the electricity supplied to the Premises
        at such rates of electricity tariffs for the appropriate category of
        consumer as is fixed by SP Services from time to time.
      </div>
      <div>
        6. The Consumer shall pay a fee, as is fixed by SP Services from time to
        time, for every reminder issued to the Consumer for failure to make
        payment in accordance with a bill sent to the Consumer.
      </div>
      <div>
        7. The Consumer shall pay a late payment charge of 1% on any outstanding
        amount owed by the Consumer.
      </div>
      <div>
        8. SP Services shall not be liable for any failure or defect in the
        supply of electricity to the Premises whatsoever and howsoever caused.
      </div>
      <div className="extTitle">PART III</div>
      <div>
        {' '}
        Terms and Conditions of the Contract for the Supply of Gas by City
        Energy Pte. Ltd. (as Trustee of City Energy Trust) ('City Energy')
      </div>
      <div>
        1. City Energy has appointed SP Services Ltd to be its agent for the
        opening and termination of this gas supply account, and the billing,
        collection and settlement of all charges under this account.
      </div>
      <div>
        2. The Consumer shall pay an initial deposit and maintain it at an
        amount not less than one and a half times the monthly charges. For
        non-Singaporeans and non-permanent residents an amount not less than 3
        times the monthly charges.
      </div>
      <div>3. The Consumer shall accept liability for this account.</div>
      <div>
        4. Either party may give 4 working days' notice in writing to the other
        party to terminate this agreement.
      </div>
      <div>
        5. If the gas meter does not in the opinion of City Energy correctly
        register the amount of gas supplied to the Premises, City Energy shall
        be entitled to charge such sum as it shall assess.
      </div>
      <div>
        6. The Consumer shall pay for the gas supplied to the Premises at such
        rates of gas tariffs for the appropriate category of consumer as is
        fixed by City Energy from time to time. The Consumer shall notify SP
        Services Ltd of the change of use of the Premises within 7 days of such
        change.
      </div>
      <div>
        7. The Consumer shall pay a fee, as is fixed by City Energy from time to
        time, for every reminder issued to the Consumer for failure to make
        payment in accordance with a bill sent to the Consumer.
      </div>
      <div>
        8. The Consumer shall pay a late payment charge of 1% on any outstanding
        amount owed by the Consumer.
      </div>
      <div>
        9. City Energy shall not be liable for any failure or defect in the
        supply of gas to the Premises whatsoever and howsoever caused.
      </div>
      <div className="extTitle">PART IV</div>
      <div>
        Terms and Conditions of the Contract for the Collection of Refuse by
        either Veolia ES Singapore Pte Ltd, Colex Environmental Pte Ltd,
        SembWaste Pte Ltd, 800 Super Waste Management Pte Ltd, TEE Environmental
        Pte. Ltd. and Alba W&amp;H Smart City Pte. Ltd. (collectively referred
        to as "Refuse Collection Companies")
      </div>
      <div>
        1. The Refuse Collection Companies have appointed SP Services Ltd to be
        their agent for the opening and termination of this refuse collection
        account, and the billing, collection and settlement of all charges under
        this account.
      </div>
      <div>2. The Consumer shall accept liability for this account.</div>
      <div>
        3. Either party may give 4 working days' notice in writing to the other
        party to terminate this agreement.
      </div>
      <div>
        4. The Consumer shall pay for the refuse collection service performed at
        the Premises at such rates for the appropriate category of consumer as
        is fixed by the National Environment Agency from time to time or at such
        rates determined between the Consumer and the relevant Refuse Collection
        Company where the Premises do not fall under the Public Waste Collection
        Scheme and the refuse collection is contracted privately. The Consumer
        shall notify SP Services Ltd of the change of use of the Premises within
        7 days of such change.
      </div>
      <div>
        5. The Consumer shall pay a fee, as is fixed by the Refuse Collection
        Companies from time to time, for every reminder issued to the Consumer
        for failure to make payment in accordance with a bill sent to the
        Consumer.
      </div>
      <div>
        6. The Consumer shall pay a late payment charge of 1% on any outstanding
        amount owed by the Consumer.
      </div>
      <div>
        7. The Refuse Collection Companies shall not be liable for any failure
        or defect in the collection of refuse at the Premises whatsoever and
        howsoever caused.
      </div>
      <div className="extTitle">PART V</div>
      <div>
        Terms and Conditions of the Contract for the Supply of Centralised
        Cooling Services by SP Home Cooling Pte. Ltd. (‘SP Home’)
      </div>
      <div>
        1. SP Home has appointed SP Services Ltd to be their agent for the
        opening and termination of this centralised cooling services account,
        and the billing, collection and settlement of all charges under this
        account.
      </div>
      <div>2. The Consumer shall accept liability for this account.</div>
      <div>
        3. The Consumer shall pay for the centralised cooling services supplied
        to the Premises at such rates fixed by SP Home from time to time.
      </div>
      <div>
        4. The Consumer shall pay a late payment charge of 1% per month on any
        outstanding amount owed by the Consumer.
      </div>
      <div>
        5. SP Services shall not be liable for any failure or defect in the
        supply of centralised cooling services to the Premises whatsoever and
        howsoever caused.
      </div>
      <div>
        6. The opening of the centralised cooling services account is subject to
        SP Home, whom reserves the rights to decline application for centralised
        cooling services, should there be any objection to the Consumer’s taking
        over the account.
      </div>
      <div>
        7. The subscription to the centralised cooling services will constitute
        a binding contract between the Consumer and SP Home.
      </div>
      <div>
        8. SP Home reserves the right to transfer any amounts due and owing
        arising from the Consumer’s previous/closed account to an existing/new
        account belonging to the Consumer.
      </div>
      <div>
        9. The Consumer shall be bound by the “Terms and Conditions for the
        Provision of Centralised Cooling Services” specified by SP Home
        (currently published{' '}
        <Link
          to="#"
          onClick={linkHandler(
            'https://www.mytengah.sg/dam/mytengahsg/pdf/Terms-and-Conditions-for-the-Provision-of-Centralised-Cooling-Services.pdf',
            '_blank'
          )}
        >
          here
        </Link>{' '}
        on the MyTengah website by SP Home).
      </div>
      <div className="extTitle">Security Deposit</div>{' '}
      <div>
        1. In the event that the amount of the security deposit paid is lower
        than the required amount by less than 10%, SP Services Ltd shall be
        entitled to bill the difference in the customer's bill. If the
        difference is more than 10%, we require the customer to top up the
        difference before SP Services Ltd proceeds with the processing of the
        account opening application.
      </div>
      <div>
        2. SP Services Ltd reserves the right not to refund the security deposit
        in the event of any unsuccessful online application where payment was
        made successfully. Please call the hotline at 1800 2222 333 or visit any
        of our Customer Service Centres for assistance. All requests for refunds
        shall be made within a month from the date of the payment.
      </div>
      <div>
        3. Administrative fees may be payable for payments via credit card and
        such fees shall not be reflected in the customer's utilities account. SP
        Services Ltd will not refund any administrative fees whatsoever, even in
        the event the security deposit is refunded.
      </div>
      {showTerms && (
        <Modal
          onClose={termsOkHandler}
          onEscapeKeyDown={termsOkHandler}
          closeBtn={Utils.getOAMode().mode === 'kiosk'}
          title={`Terms and Conditions`}
          className="modal-terms"
        >
          <section className="terms-container">
            <TermsCoolingService />
          </section>
        </Modal>
      )}
    </div>
  )
}

export default TermsConditions

import React, { PureComponent } from 'react'
import classnames from 'classnames'

import { Icons } from 'spd-oa/components/common'

class WizError extends PureComponent {
  render() {
    const { error, popup } = this.props
    const _WizError = classnames('wiz-error', {
      'is-popup': popup,
    })
    return (
      <section className={_WizError}>
        <figure className="error-icon">
          <Icons.close width={15} height={15} color={['#ffffff']} />
        </figure>
        <div className="error-content">{error}</div>
      </section>
    )
  }
}

export default WizError

import axios from 'axios'
import Utils from 'spd-oa/utils'
import {
  parseDate,
  isCitizenOrPR,
  getGaIdByLinkerParam,
  reportToSentry,
} from 'spd-oa/helpers'
import { getMultiFileTypes } from 'spd-oa/attachmentHelpers'
import { eSignatureServices } from 'spd-oa/services/eSignature/eSignatureServices'
import { EventSourcePolyfill } from 'event-source-polyfill'

const API_BASE = process.env.REACT_APP_API_BASE
const KIOSK_API_BASE = process.env.REACT_APP_KIOSK_API_BASE

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const GATemplate_OACommercialPayment = {
  event: 'API Response',
  eventCategory: 'Open Commercial Account - Security Deposit Payment',
  eventAction: 'Payment Status',
}

const defaultErrorMsg = {
  transactionFailed:
    'Sorry, your payment transaction failed. Please try again later.',
  paymentStatus: {
    pending:
      'Your payment is still pending. Please try again or visit our Customer Service Centre to open a utilities account.',
    failed:
      'We are unable to process your application as the payment transaction was not successful. Please try again or visit our Customer Service Centre to open a utilities account.',
  },
}

const getMockNewCustomer = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          eligible: true,
          timeslot_allowed: true,
          is_new_customer: true,
          has_missed_appointment: false,
          is_disconnected: false,
          linked_email: 'k********@gmail.com',
        },
      })
    }, TIMEOUT)
  })
}

const TIMEOUT = 700

const request = (payload) => {
  const options = {
    ...payload,
    headers: {
      ...DEFAULT_HEADERS,
      ...payload.headers,
    },
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        if (res.status >= 200 && res.status < 400) {
          resolve(res)
        } else {
          reject({
            message: 'Error occured',
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data)
        } else {
          reject(err)
        }
      })
  })
}

/**
 * @typedef {{ active: boolean, message: string }} ConfigDTO
 */

/**
 * @typedef {{
 * "bypass-new-email-oa": ConfigDTO,
 * "ca-bypass-otp": ConfigDTO,
 * "ca-livechat": ConfigDTO,
 * "ca-maintenance": ConfigDTO,
 * "e-signature-kiosk": ConfigDTO,
 * "e-signature-web": ConfigDTO,
 * "maintenance": ConfigDTO,
 * "oa-bypass-otp": ConfigDTO,
 * "oa-commercial": ConfigDTO,
 * "oa-livechat": ConfigDTO,
 * }} AppConfig
 */

/**
 * @return {Promise<AppConfig>}
 */
const getFeaturesAvailability = () => {
  return new Promise((resolve, reject) => {
    request({
      url: `${API_BASE}/appconfig`,
      method: 'get',
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 400) {
          resolve({
            ...res.data,
          })
        } else {
          reject({
            error: 'api_error',
            error_description: `Apologies but there's something wrong with the service.`,
          })
        }
      })
      .catch((err) => {
        resolve({
          'bypass-new-email-oa': {
            active: true,
            message: '',
          },
          'ca-bypass-otp-': {
            active: false,
            message: '',
          },
          'ca-livechat': {
            active: true,
            message: '',
          },
          'ca-maintenance': {
            active: true,
            message: '',
          },
          'e-signature-kiosk': {
            active: true,
            message: '',
          },
          'e-signature-web': {
            active: true,
            message: '',
          },
          maintenance: {
            active: true,
            message: '',
          },
          'oa-bypass-otp': {
            active: true,
            message: '',
          },
          'oa-livechat': {
            active: true,
            message: '',
          },
          'reschedule-maintenance': {
            active: true,
            message: '',
          },
        })
        reportToSentry(err)
      })
    // setTimeout(() => {
    //   resolve({
    //     'conn-serv': {
    //       active: false,
    //       message: '',
    //     },
    //     mimo: {
    //       active: false,
    //       message: '',
    //     },
    //     'oa-commercial': {
    //       active: true,
    //       message: '',
    //     },
    //   })
    // }, TIMEOUT)
  })
}

const start = ({ application_domain, application_type }) => {
  return new Promise((resolve, reject) => {
    request({
      method: 'get',
      url: `${API_BASE}/v1/transaction?appDomain=${application_domain}&appType=${application_type}`,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setTimeout(() => {
            resolve(res.data)
          }, TIMEOUT)
        } else {
          reject({
            error: 'api_error',
            error_description: `Apologies but there's something wrong with the service, you may close this prompt and try again later.`,
          })
        }
      })
      .catch((err) => {
        reject({
          ...err,
          error: 'network_error',
          error_description: `Apologies but there's something wrong with the service, you may close this prompt and try again later.`,
        })
      })
  })
}

const myInfo = {
  redirect: ({ stateId }) => {
    let url = ''
    let gaId = ''
    if (window.ga) {
      if (window.ga.getAll) {
        gaId = window.ga.getAll()[0].get('linkerParam')
        gaId = gaId ? gaId.split('=')[1] : ''
        url = `${API_BASE}/v1/myinfo/authorize/${stateId}/${gaId}`
      } else {
        url = `${API_BASE}/v1/myinfo/authorize/${stateId}/00000`
      }
    } else {
      url = `${API_BASE}/v1/myinfo/authorize/${stateId}/00000`
    }

    window.location.replace(url)
    // window.open(url)
  },
  getData: ({ stateId }) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'GET',
        url: `${API_BASE}/v1/myinfo/data/${stateId}`,
      })
        .then((res) => {
          if (res.error) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'Open Account - MyInfo',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: res.error,
            })

            reject(res.error)
          } else {
            setTimeout(() => {
              if (
                !isCitizenOrPR(res.data.nric) &&
                res.data.work_pass_status === ''
              ) {
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: 'Open Account - MyInfo',
                  eventAction: 'Results',
                  eventLabel: 'Fail',
                  errorMessage: 'myinfo_workpass_expired',
                })
                reject({
                  error: 'myinfo_workpass_expired',
                  error_description:
                    'Your work pass has either expired or was not approved. Please try submitting your application again once your work pass has been renewed or approved. Thank you.',
                })
              } else {
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: 'Open Account - MyInfo',
                  eventAction: 'Results',
                  eventLabel: 'Success',
                })
                resolve(res.data)
              }
            }, TIMEOUT)
          }
        })
        .catch((err) => {
          if (err.response) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'Open Account - MyInfo',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: err.response.data.error,
            })
            reject(err.response.data)
            reportToSentry(err.response, { stateId })
          } else {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'Open Account - MyInfo',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: 'There was an error occured',
            })
            reject(err)
            reportToSentry(err, { stateId })
          }
        })
    })
  },
}

const myInfoBiz = {
  redirect: ({ stateId }) => {
    let url = ''
    let gaId = ''
    if (window.ga) {
      if (window.ga.getAll) {
        gaId = window.ga.getAll()[0].get('linkerParam')
        gaId = gaId ? gaId.split('=')[1] : ''
        url = `${API_BASE}/v1/myinfob/authorize/${stateId}/${gaId}`
      } else {
        url = `${API_BASE}/v1/myinfob/authorize/${stateId}`
      }
    } else {
      url = `${API_BASE}/v1/myinfob/authorize/${stateId}`
    }
    window.location.replace(url)
  },
  getData: ({ stateId }) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'GET',
        url: `${API_BASE}/v1/myinfob/data/${stateId}`,
      })
        .then((res) => {
          if (res.error) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'MyInfo Biz',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: res.error,
            })

            reject(res.error)
          } else {
            setTimeout(() => {
              if (res.data.company_status !== 'LIVE') {
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: 'MyInfo Biz',
                  eventAction: 'Results',
                  eventLabel: 'Fail',
                  errorMessage: 'myinfobiz_companystatus_notlive',
                })
                reject({
                  error: 'myinfobiz_companystatus_notlive',
                  error_description:
                    'Company status is invalid. Please enter an active company UEN.',
                })
              } else {
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: 'MyInfo Biz',
                  eventAction: 'Results',
                  eventLabel: 'Success',
                })
                resolve(res.data)
              }
            }, TIMEOUT)
          }
        })
        .catch((err) => {
          if (err.response) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'MyInfo Biz',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: err.response.data.error,
            })
            reject(err.response.data)
          } else {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'MyInfo Biz',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: 'There was an error occured',
            })
            reject(err)
          }
        })
    })
  },
}

const retrieveCalendar = ({
  postal_code,
  block,
  unit,
  floor,
  source,
  account_type,
  is_open_account = true,
  timeslot = false,
  electricity = false,
  gas = false,
  water = false,
  nanl = false,
  personal = true,
}) => {
  const state_id = Utils.getStateId()
  return new Promise((resolve, reject) => {
    request({
      method: 'POST',
      url: `${API_BASE}/v1/calendar`,
      data: {
        state_id,
        block,
        floor,
        unit,
        postal_code,
        timeslot,
        electricity,
        gas,
        water,
        nanl,
        source,
        is_open_account,
        account_type,
        personal,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            if (res.data.Offset) {
              reject({
                error: 'offset',
                error_description: 'Problem occured when retrieving calendar',
              })
            } else {
              resolve(res.data)
            }
          } else {
            reject({
              error: 'calendar_retrieval_backend',
              error_description: 'Problem occured when retrieving calendar',
            })
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          reject(err)
        }, TIMEOUT)
        reportToSentry(err, {
          postal_code,
          block,
          unit,
          floor,
          timeslot,
          electricity,
          gas,
          water,
        })
      })
  })
}

const getMockCalendar = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          server_time: '2022-08-23T11:27:40.266454+08:00',
          oc_mo_date: '2022-08-26',
          available: [
            {
              date: '2022-08-25T00:00:00+08:00',
              date_hash: 'MjAyMi0wOC0yNlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-08-26T00:00:00+08:00',
              date_hash: 'MjAyMi0wOC0yNlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-08-27T00:00:00+08:00',
              date_hash: 'MjAyMi0wOC0yN1QwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-08-29T00:00:00+08:00',
              date_hash: 'MjAyMi0wOC0yOVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-08-30T00:00:00+08:00',
              date_hash: 'MjAyMi0wOC0zMFQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-08-31T00:00:00+08:00',
              date_hash: 'MjAyMi0wOC0zMVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-01T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wMVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-02T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wMlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-03T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wM1QwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-05T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wNVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-06T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wNlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-07T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wN1QwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-08T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wOFQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-09T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0wOVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-10T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xMFQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-12T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xMlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-13T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xM1QwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-14T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xNFQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-15T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xNVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-16T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xNlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-17T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xN1QwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-19T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0xOVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-20T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0yMFQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-21T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0yMVQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-22T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0yMlQwMDowMDowMCswODowMA==',
            },
            {
              date: '2022-09-23T00:00:00+08:00',
              date_hash: 'MjAyMi0wOS0yM1QwMDowMDowMCswODowMA==',
            },
          ],
          public_holidays: [
            '2022-01-01',
            '2022-02-01',
            '2022-02-02',
            '2022-04-25',
            '2022-04-26',
            '2022-05-15',
            '2022-05-16',
          ],
          special_holidays: ['2022-01-31'],
        },
        status: 200,
      })
    }, TIMEOUT)
  })
}

const retrieveTimeslot = ({
  block,
  floor,
  unit,
  postal_code,
  electricity,
  water,
  gas,
  selected_date,
  notification_number,
  timeslot,
  selected_date_hash,
  open = true,
  resi = true,
  personal = true,
}) => {
  return new Promise((resolve, reject) => {
    const state_id = Utils.getStateId()
    request({
      method: 'POST',
      url: `${API_BASE}/v1/calendar/timeslots`,
      data: {
        state_id,
        block,
        floor,
        unit,
        postal_code,
        electricity,
        water,
        gas,
        selected_date,
        notification_number,
        timeslot,
        selected_date_hash,
        open,
        resi,
        personal,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.error) {
            reject(res.error)
          } else {
            let d = {}
            let timeslots = []

            if (res.data.timeslots && res.data.timeslots.length > 0) {
              timeslots = res.data.timeslots.filter((time) => {
                if (
                  parseDate(time.start, 'YYYYMMDD') === selected_date &&
                  parseDate(time.end, 'YYYYMMDD') === selected_date
                ) {
                  return true
                }
              })
            }
            resolve({
              ...res.data,
              timeslots: timeslots,
            })
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          reject(err)
        }, TIMEOUT)
        reportToSentry(err, {
          block,
          floor,
          unit,
          postal_code,
          electricity,
          water,
          gas,
          selected_date,
          notification_number,
          timeslot,
          selected_date_hash,
        })
      })
  })
}

const getMockTimeslot = (payload) => {
  return new Promise((resolve, reject) => {
    const r = false
    setTimeout(() => {
      let timeslots = []
      let res = {
        data: {
          meter_reading_required: false,
          notification_number: 'I16BF429E4D0',
          notification_type: 'ZZ',
          timeslot_required: false,
          timeslots: [
            {
              start: '2019-07-31T09:00:00+08:00',
              end: '2019-07-31T10:00:00+08:00',
              time_hash: 'MjAxOC0wMS0yNFQwOTowMDowMCswODowMA==',
            },
            {
              start: '2019-07-31T10:00:00+08:00',
              end: '2019-07-31T11:00:00+08:00',
              time_hash: 'MjAxOC0wMS0yNFQxMDowMDowMCswODowMA==',
            },
            {
              start: '2019-07-31T11:00:00+08:00',
              end: '2019-07-31T12:00:00+08:00',
              time_hash: 'MjAxOC0wMS0yNFQxMTowMDowMCswODowMA==',
            },
            {
              start: '2019-07-31T14:00:00+08:00',
              end: '2019-07-31T15:00:00+08:00',
              time_hash: 'MjAxOC0wMS0yNFQxNDowMDowMCswODowMA==',
            },
            {
              start: '2019-07-31T15:00:00+08:00',
              end: '2019-07-31T16:00:00+08:00',
              time_hash: 'MjAxOC0wMS0yNFQxNTowMDowMCswODowMA==',
            },
          ],
        },
      }
      if (res.data.timeslots && res.data.timeslots.length > 0) {
        timeslots = res.data.timeslots.filter((time) => {
          if (
            parseDate(time.start, 'YYYYMMDD') === payload.selected_date &&
            parseDate(time.end, 'YYYYMMDD') === payload.selected_date
          ) {
            return true
          }
        })
      }
      resolve({
        ...res.data,
        timeslots,
      })
    }, TIMEOUT)
    if (r) {
      reject()
    }
  })
}

const confirmTimeslot = ({
  notification_number,
  notification_type,
  selected_date,
  selected_time,
  time_hash,
}) => {
  return new Promise((resolve, reject) => {
    const state_id = Utils.getStateId()
    request({
      method: 'POST',
      url: `${API_BASE}/v1/calendar/timeslots/confirm`,
      data: {
        state_id,
        selected_date,
        notification_number,
        notification_type,
        selected_time,
        time_hash,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.error) {
            reject(res.error)
          } else {
            resolve({
              success: true,
            })
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          reject(err)
        }, TIMEOUT)
        reportToSentry(err, {
          notification_number,
          notification_type,
          selected_date,
          selected_time,
          time_hash,
        })
      })
  })
}

const retrieveStreetName = ({
  postcode = '',
  block = '',
  unit = '',
  floor = '',
  for_premises = false,
  utilities_flag = 1,
}) => {
  return new Promise((resolve, reject) => {
    request({
      method: 'post',
      url: `${API_BASE}/v1/premise/lookup-utilities?utilities=${utilities_flag}`,
      data: {
        house_number: block,
        floor: floor,
        unit: unit,
        postal_code: postcode,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            if (res.data.premise_no === '') {
              if (for_premises) {
                let errMsg =
                  'You have entered an invalid premises. Please check that your premises address details are correct.'
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: 'Street Name',
                  eventAction: 'Results',
                  eventLabel: 'Fail',
                  errorMessage: errMsg,
                })
                reject(new Error(errMsg))
              } else {
                let errMsg = 'You have entered an invalid mailing address'
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: 'Street Name',
                  eventAction: 'Results',
                  eventLabel: 'Fail',
                  errorMessage: errMsg,
                })
                reject(new Error(errMsg))
              }
            } else {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: 'Street Name',
                eventAction: 'Results',
                eventLabel: 'Success',
              })
              resolve(res.data)
            }
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          if (err.response) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'Street Name',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: err.response.data.error,
            })
            reject(err.response.data)
          } else {
            let errMsg = 'There was a problem retrieving premises street name'
            if (!for_premises) {
              errMsg = 'There was a problem retrieving mailing street name'
            }
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: 'Street Name',
              eventAction: 'Results',
              eventLabel: 'Fail',
              errorMessage: errMsg,
            })
            reject(new Error(errMsg))
          }
        }, TIMEOUT)
        reportToSentry(err, {
          postcode,
          block,
          unit,
          floor,
          for_premises,
          utilities_flag,
        })
      })
  })
}

const attachDoc = ({ transaction_id, type, file }) => {
  const filename = file.name
  const ext = filename
    .split('.')
    .slice(-1)
    .join('')
  let name = filename
    .split('.')
    .slice(0, -1)
    .join('.')
  name = name.replace(/[^a-zA-Z0-9 ]*/g, '') + '.' + ext
  return new Promise((resolve, reject) => {
    request({
      url: `${API_BASE}/v1/transaction/attachment?transaction_id=${transaction_id}&type=${type}&filename=${name}`,
      method: 'post',
      data: file,
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            resolve(res.data)
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          if (err.response) {
            reject(err.response.data)
          } else {
            reject(err)
          }
        }, TIMEOUT)
        reportToSentry(err, { transaction_id, type, file })
      })
  })
}

/**
 *
 * @param transaction_id
 * @returns {Promise<{ [docType]: string || [filename] } | { }>}
 */
const getAttachments = ({ transaction_id }) => {
  return new Promise((resolve, reject) => {
    request({
      url: `${API_BASE}/v1/transaction/attachment/${transaction_id}`,
      method: 'get',
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let data = {}
          if (res.data.attachmentDetails) {
            data = res.data.attachmentDetails.reduce(
              (result, currentDoc) => ({
                ...result,
                [currentDoc.type]: [
                  ...(result[currentDoc.type] || []),
                  currentDoc.filename,
                ],
              }),
              {}
            )

            // if the doc is not multi, then just keep single file
            const multiFileTypes = getMultiFileTypes()
            Object.keys(data)
              .filter((docType) => !multiFileTypes.includes(docType))
              .forEach((docType) => {
                data[docType] = data[docType][0]
              })
          }
          resolve(data)
        }
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data)
        } else {
          reject(err)
        }
        reportToSentry(err, { transaction_id })
      })
  })
}

const deleteAttachment = ({ transaction_id, type }) => {
  return new Promise((resolve, reject) => {
    request({
      url: `${API_BASE}/v1/transaction/attachment`,
      method: 'delete',
      data: {
        state_id: transaction_id,
        type: type,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            resolve(res.data)
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          if (err.response) {
            reject(err.response.data)
          } else {
            reject(err)
          }
        }, TIMEOUT)
        reportToSentry(err, { transaction_id, type })
      })
  })
}

const retrieveCompanyName = ({ uen }) => {
  return new Promise((resolve, reject) => {
    request({
      method: 'post',
      url: `${API_BASE}/v1/account/lookup_company_name_acra`,
      data: {
        uen: uen,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            resolve(res.data)
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          if (err.response) {
            reject(err.response.data)
          } else {
            reject(new Error('There was a problem retrieving company name'))
          }
        }, TIMEOUT)
        reportToSentry(err, { uen })
      })
  })
}

const checkEligibility = ({
  nric_roc,
  bp_type,
  premise_no,
  transaction_id,
}) => {
  return new Promise((resolve, reject) => {
    request({
      method: 'post',
      url: `${API_BASE}/v1/accounts/profile/is-new-ebs-customer`,
      data: {
        nric_roc,
        bp_type,
        premises: premise_no,
        transaction_id,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            resolve(res.data)
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          if (err.response) {
            reject(err.response.data)
          } else {
            reject(new Error('There was a problem on checking eligibility'))
          }
        }, TIMEOUT)
        reportToSentry(err, { nric_roc, bp_type, premise_no, transaction_id })
      })
  })
}

const checkIfNewCustomer = ({ nric }) => {
  return new Promise((resolve, reject) => {
    request({
      method: 'post',
      url: `${API_BASE}/v1/accounts/profile/is-new-ebs-customer`,
      data: {
        nric,
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status >= 200 && res.status < 400) {
            resolve({
              isNewCustomer: res.data.isnewcustomer,
            })
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        setTimeout(() => {
          if (err.response) {
            reject({
              verified: false,
              error: err.response.data.error,
              error_description: err.response.data.error_description,
            })
          } else {
            reject({
              verified: false,
              error: 'new_customer_error',
              error_description:
                'There was a problem on checking if new customer',
            })
          }
        }, TIMEOUT)
        reportToSentry(err, { nric })
      })
  })
}

const ebill = {
  // VALIDATE EMAIL FOR EBILL
  validateEmail: ({ email, isChangedEmailLinked, transaction_id }) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'POST',
        url: `${API_BASE}/v1/accounts/profile/is-email-linked`,
        data: {
          email,
          transaction_id,
        },
      })
        .then((res) => {
          if (isChangedEmailLinked) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: `Open Account - Change Linked Email`,
              eventAction: 'Results',
              eventLabel: true,
              errorMessage: '',
            })
          }
          resolve({
            emailId: res.data.emailid,
            isLinked: res.data.islinked,
            message: res.data.islinked
              ? 'This email has been used in another utilities account. Please use another email.'
              : '',
          })
        })
        .catch((err) => {
          setTimeout(() => {
            if (err.response) {
              reject({
                verified: false,
                error: err.response.data.error,
                error_description: err.response.data.error_description,
              })
            } else {
              reject({
                verified: false,
                error: 'validate_email_error',
                error_description:
                  'There was a problem on validating email for e-bill',
              })
            }
          }, TIMEOUT)
          reportToSentry(err, { email, isChangedEmailLinked, transaction_id })
        })

      // setTimeout(() => {
      //   resolve({
      //     isLinked: false,
      //     message:
      //       'This email has been used in another utilities account. Please use another email.'
      //   })
      // }, TIMEOUT)
    })
  },

  // VERIFY EMAIL FOR EBILL
  verifyEmail: ({ email, transaction_id }) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'post',
        url: `${API_BASE}/v1/accounts/profile/request-email-otp`,
        data: {
          email,
          transaction_id,
        },
      })
        .then((res) => {
          setTimeout(() => {
            resolve({
              success: res.data.status === 'SUCCESS',
              message:
                res.data.status !== 'SUCCESS'
                  ? `There was a problem on requesting OTP for e-bill. Please try again.`
                  : '',
            })
          }, 10)
        })
        .catch((err) => {
          setTimeout(() => {
            if (err.response) {
              reject({
                verified: false,
                error: err.response.data.error,
                error_description: err.response.data.error_description,
              })
            } else {
              reject({
                verified: false,
                error: 'request_for_otp_error',
                error_description:
                  'There was a problem on requesting OTP for e-bill. Please try again.',
              })
            }
          }, 10)
          reportToSentry(err, { email, transaction_id })
        })
      // setTimeout(() => {
      //   resolve({
      //     success: true
      //   })
      //   // reject({
      //   //   verified: false,
      //   //   error: 'request_for_otp_error',
      //   //   error_description: `An error has occured when requesting OTP. Please try again.`
      //   // })
      // }, TIMEOUT)
    })
  },

  // VERIFY OTP FOR EBILL
  verifyOtp: ({ email, otp, transaction_id }) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'post',
        url: `${API_BASE}/v1/accounts/profile/verify-email-otp`,
        data: {
          email,
          otp,
          transaction_id,
        },
      })
        .then((res) => {
          setTimeout(() => {
            resolve({
              verified: res.data.status === 'SUCCESS',
              message:
                res.data.status !== 'SUCCESS'
                  ? `The OTP you have entered is invalid. Please try again.`
                  : '',
            })
          }, 10)
        })
        .catch((err) => {
          setTimeout(() => {
            if (err.response) {
              reject({
                verified: false,
                error: err.response.data.error,
                error_description: err.response.data.error_description,
              })
            } else {
              reject({
                verified: false,
                error: 'verify_otp_error',
                error_description:
                  'The OTP you have entered is invalid. Please try again.',
              })
            }
          }, 10)
          reportToSentry(err, { email, otp, transaction_id })
        })
      // setTimeout(() => {
      //   if (otp === '123456') {
      //     resolve({
      //       verified: true,
      //       message: ''
      //     })
      //   } else {
      //     resolve({
      //       verified: false,
      //       message: `The OTP you have entered is invalid. Please try again.`
      //     })
      //   }
      // }, TIMEOUT + 2000)
    })
  },
}

const payment = {
  fetchSecurityDepositOptions: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        request({
          method: 'post',
          url: `${API_BASE}/v1/openacc/fetchSDOptions`,
        })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            if (err.response) {
              reject({
                error: err.response.data.error,
                error_description: err.response.data.error_description,
              })
            } else {
              reject({
                error: 'fetch_sd_options_error',
                error_description:
                  'There was a problem on fetching options for security deposit. Please try again.',
              })
            }
            reportToSentry(err)
          })
      }, TIMEOUT)
    })
  },

  calculateSecurityDeposit: (payload) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        request({
          method: 'post',
          url: `${API_BASE}/v1/openacc/calculateSD`,
          data: payload,
        })
          .then((res) => {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: `Open Account - Fetch`,
              eventAction: 'Security Deposit',
              eventLabel: 'Success',
              errorMessage: '',
            })
            resolve(res.data)
          })
          .catch((err) => {
            if (err.response) {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: `Open Account - Fetch`,
                eventAction: 'Security Deposit',
                eventLabel: 'Fail',
                errorMessage: err.response.data.error_description,
              })
              reject({
                error: err.response.data.error,
                error_description: err.response.data.error_description,
              })
            } else {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: `Open Account - Fetch`,
                eventAction: 'Security Deposit',
                eventLabel: 'Fail',
                errorMessage:
                  'There was a problem on calculating the security deposit. Please try again.',
              })
              reject({
                error: 'calculate_sd_error',
                error_description:
                  'There was a problem on calculating the security deposit. Please try again.',
              })
            }
            reportToSentry(err, payload)
          })
      }, TIMEOUT)
    })
  },

  redirectToEnets: (url, payload) => {
    payload.apiKey = payload.api_key
    payload.api_key = null
    delete payload.api_key

    const data = Utils.objToUrlEncoded(payload)
    return request({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      },
    }).then((res) => {
      document.documentElement.innerHTML = res.data
    })
  },

  getStatus: (txnRef) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        request({
          method: 'GET',
          url: `${API_BASE}/payment/${txnRef}`,
        })
          .then((res) => {
            if (res.data && res.data.status) {
              if (res.data.status === 'PAYMENT_FAILED') {
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: `Open Account - Payment`,
                  eventAction: 'Payment Status',
                  eventLabel: 'Fail',
                  errorMessage: '',
                })
                reject({
                  error_description:
                    res.data.message ||
                    'We are unable to process your application as the payment transaction was not successful. Please try again or visit our Customer Service Centre to open a utilities account.',
                })
              } else if (res.data.status === 'PAYMENT_PENDING') {
                reject({
                  error_description:
                    res.data.message ||
                    'Your payment is still pending. Please try again or visit our Customer Service Centre to open a utilities account.',
                })
              } else {
                window.dataLayer.push({
                  event: 'API Response',
                  eventCategory: `Open Account - Payment`,
                  eventAction: 'Payment Status',
                  eventLabel: 'Success',
                  errorMessage: '',
                })
                resolve(res.data.status)
              }
            }
          })
          .catch((err) => {
            if (err.response) {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: `Open Account - Payment`,
                eventAction: 'Payment Status',
                eventLabel: 'Fail',
                errorMessage: err.response.data.error_description,
              })
              reject({
                error_description: err.response.data.error_description,
              })
            } else {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: `Open Account - Payment`,
                eventAction: 'Payment Status',
                eventLabel: 'Fail',
                errorMessage: 'Error Occurred',
              })
              reject({
                error_description:
                  'Sorry, your payment transaction failed. Please try again later.',
              })
            }
            reportToSentry(err, { txnRef })
          })
      }, TIMEOUT)
    })
  },

  updatePaymentStatus(payload) {
    return request({
      method: 'POST',
      url: `${API_BASE}/payment/khaos/process`,
      data: payload,
      // timeout: TIMEOUT,
    })
      .then((res) => {
        window.dataLayer.push({
          ...GATemplate_OACommercialPayment,
          eventLabel: 'Success',
          errorMessage: '',
        })
        return res.data
      })
      .catch((err) => {
        reportToSentry(err, payload)
        if (err.response) {
          window.dataLayer.push({
            ...GATemplate_OACommercialPayment,
            eventLabel: 'Fail',
            errorMessage: err.response.data.error_description,
          })
          return { error_description: err.response.data.error_description }
        } else {
          window.dataLayer.push({
            ...GATemplate_OACommercialPayment,
            eventLabel: 'Fail',
            errorMessage: 'Error Occurred',
          })
          return {
            error_description: defaultErrorMsg.transactionFailed,
          }
        }
      })
  },

  getStatusByAppId(appId) {
    return request({
      method: 'GET',
      url: `${API_BASE}/payment?appId=${appId}`,
      // timeout: TIMEOUT,
    })
      .then((res) => {
        if (res.data && res.data.status) {
          if (res.data.status === 'PAYMENT_FAILED') {
            window.dataLayer.push({
              ...GATemplate_OACommercialPayment,
              eventLabel: 'Fail',
              errorMessage: '',
            })
            return {
              error_description:
                res.data.message || defaultErrorMsg.paymentStatus.failed,
            }
          } else if (res.data.status === 'PAYMENT_PENDING') {
            return {
              error_description:
                res.data.message || defaultErrorMsg.paymentStatus.pending,
            }
          } else {
            window.dataLayer.push({
              ...GATemplate_OACommercialPayment,
              eventLabel: 'Success',
              errorMessage: '',
            })
            return res.data.status
          }
        }
      })
      .catch((err) => {
        reportToSentry(err, { appId })
        if (err.response) {
          window.dataLayer.push({
            ...GATemplate_OACommercialPayment,
            eventLabel: 'Fail',
            errorMessage: err.response.data.error_description,
          })
          return {
            error_description: err.response.data.error_description,
          }
        } else {
          window.dataLayer.push({
            ...GATemplate_OACommercialPayment,
            eventLabel: 'Fail',
            errorMessage: 'Error Occurred',
          })
          return {
            error_description: defaultErrorMsg.transactionFailed,
          }
        }
      })
  },
}

const submit = (payload, myinfo = false) => {
  return new Promise((resolve, reject) => {
    request({
      url: `${API_BASE}/v1/transaction/submit`,
      method: 'post',
      data: {
        ...payload,
        gaId: getGaIdByLinkerParam(),
      },
    })
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200 || res.status === 201) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: `Open Account - ${
                payload.account_type === '01' ? 'Residential' : 'Commercial'
              } - ${
                payload.account_holder_type === 'P' ? 'Personal' : 'Company'
              }`,
              eventAction: 'Results',
              eventMyinfo: myinfo,
              eventLabel: 'Success',
              errorMessage: '',
            })
            if (payload.account_type !== '01') {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: `Open Account - Payment Method`,
                eventAction: 'Results',
                eventLabel: payload.payment_method,
                errorMessage: '',
              })
            }
            if (payload.enable_paper_bill) {
              window.dataLayer.push({
                event: 'API Response',
                eventCategory: `Open Account - Change Linked Email`,
                eventAction: 'Results',
                eventLabel: true,
                errorMessage: '',
              })
            }
            resolve(res.data)
          } else {
            reject(res)
          }
        }, TIMEOUT)
      })
      .catch((err) => {
        reportToSentry(err, payload)
        setTimeout(() => {
          if (err.response) {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: `Open Account - ${
                payload.account_type === '01' ? 'Residential' : 'Commercial'
              } - ${
                payload.account_holder_type === 'P' ? 'Personal' : 'Company'
              }`,
              eventAction: 'Results',
              eventLabel: 'Fail',
              eventMyinfo: myinfo,
              errorMessage: err.response.data.error,
            })
            reject(err.response.data)
          } else {
            window.dataLayer.push({
              event: 'API Response',
              eventCategory: `Open Account - ${
                payload.account_type === '01' ? 'Residential' : 'Commercial'
              } - ${
                payload.account_holder_type === 'P' ? 'Personal' : 'Company'
              }`,
              eventAction: 'Results',
              eventMyinfo: myinfo,
              eventLabel: 'Fail',
              errorMessage: err.error || 'There was an error occured',
            })
            reject(err)
          }
        }, TIMEOUT)
      })
  })
}

function subscribeUploadEvent(spec, token) {
  return new EventSourcePolyfill(
    `//${KIOSK_API_BASE}/khaos/v1/upload/event?state=${btoa(
      JSON.stringify(spec)
    )}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

function closeUploadSession(sessionId) {
  return request({
    url: `https://${KIOSK_API_BASE}/khaos/v1/upload/close/${sessionId}`,
    method: 'get',
  })
}

function getListUserOfPremise() {
  return request({
    url: `${API_BASE}/v1/premise/useOfPremise`,
    method: 'post',
  })
    .then((res) => {
      const newList = Object.keys(res.data).map((key) => ({
        text: res.data[key].label,
        value: key,
        domain: res.data[key].accountType,
        useOfPremise: res.data[key].useOfPremise,
        order: res.data[key].order,
      }))
      return newList
    })
    .catch((err) => {
      reportToSentry(err)
    })
}

function sendDeletedFileDetails(sessionId, attachment) {
  return request({
    url:
      `https://${KIOSK_API_BASE}/khaos/v1/upload/files/delete` +
      `?sessionId=${sessionId}`,
    method: 'post',
    data: attachment,
  }).then((res) => {
    if (res.data && res.data.status === 'SUCCESS') {
      return res.data
    } else {
      throw new Error(
        'Something went wrong while sending deleted file to stream'
      )
    }
  })
}

function sendMsgToKiosk(kioskSessionId, msg) {
  return request({
    url: `https://${KIOSK_API_BASE}/khaos/v1/upload/files/send?sessionId=${kioskSessionId}&message=${msg}`,
    method: 'post',
  })
}

function resetUploadedFiles(sessionId) {
  return request({
    url: `https://${KIOSK_API_BASE}/khaos/v1/upload/files/reset?sessionId=${sessionId}`,
    method: 'post',
  })
}

export default {
  getFeaturesAvailability,
  start,
  retrieveStreetName,
  myInfo,
  myInfoBiz,
  retrieveCalendar,
  getMockCalendar,
  getMockNewCustomer,
  retrieveTimeslot,
  getMockTimeslot,
  confirmTimeslot,
  attachDoc,
  deleteAttachment,
  getAttachments,
  retrieveCompanyName,
  checkEligibility,
  checkIfNewCustomer,
  ebill,
  payment,
  submit,
  subscribeUploadEvent,
  closeUploadSession,
  sendDeletedFileDetails,
  sendMsgToKiosk,
  resetUploadedFiles,
  getListUserOfPremise,
  eSignatureServices: eSignatureServices(request),
}

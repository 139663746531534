import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Breadcrumbs extends PureComponent {
  _isWithParams = (p) => {
    const { match, withParams } = this.props
    if (match) {
      if (withParams.length > 0) {
        for (let i = 0; i < withParams.length; i++) {
          let route = withParams[i]
          if (route.path === p) {
            if (match.path) {
              return `${p}/${route.param}` === match.path
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return <ol className="lm--breadcrumb">{this.renderItems()}</ol>
  }

  renderItems() {
    const { location, crumbRoutes, defaultCrumbs } = this.props
    let crumbsToRender = []

    if (defaultCrumbs.length > 0) {
      crumbsToRender = [...defaultCrumbs]
    }
    if (location) {
      let _url = '/'
      let urls = ['/']
      if (location.pathname !== '/') {
        _url = ''
        urls = location.pathname.split('/').filter((u) => {
          return u !== ''
        })
      }

      urls.forEach((u) => {
        if (u !== '/') {
          _url = `${_url}/${u}`
        }
        if (crumbRoutes[_url]) {
          if (defaultCrumbs.indexOf(_url) < 0) {
            crumbsToRender.push(_url)
          }
        }
      })
    }
    return crumbsToRender.map((p, i) => {
      return p === location.pathname || this._isWithParams(p) ? (
        <li className="crumb active" key={`sl-bc-${i}`}>
          <span>{crumbRoutes[p]}</span>
        </li>
      ) : (
        <li className="crumb" key={`sl-bc-${i}`}>
          <a href={p}>
            <span>{crumbRoutes[p]}</span>
          </a>
        </li>
      )
    })
  }
}

Breadcrumbs.defaultProps = {
  crumbRoutes: {},
  defaultCrumbs: [],
  withParams: [],
}

Breadcrumbs.propTypes = {
  crumbRoutes: PropTypes.object.isRequired,
  defaultCrumbs: PropTypes.array,
  withParams: PropTypes.array,
}

export default Breadcrumbs

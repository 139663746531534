import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import { object, boolean, date, mixed, string, ref } from 'yup'

import { TurnOnServices } from 'spd-oa/components'
import { Detail, Button, Summary } from 'spd-oa/components/common'
import { FormCTA } from 'spd-oa/components/FormElement'
import { isEmpty, getElectCapacityRangeIdx } from 'spd-oa/helpers'
import Utils from 'spd-oa/utils'
import {
  kioskConfig,
  handleNext,
} from 'spd-oa/components/KioskUpload/kioskUploadHelpers'

const SESSION_STATES_KEY = kioskConfig.SESSION_STATES_KEY
const UPLOAD_STATUS = kioskConfig.UPLOAD_STATUS

const schema = object().shape({
  application_domain: string(),
  application_type: string(),

  USE_OF_PREMISES_LIST: string()
    .required()
    .label('Type of Premises'),
  ELECT_CAPACITY: string()
    .when(['USE_OF_PREMISES_LIST', 'services'], {
      is: (use_premises, services) => {
        const criteria = [
          'PREMISES_PUBLIC_LIGHTING',
          'PREMISES_OTHERS',
          'PREMISES_CONSTRUCTION_SITE',
        ]
        return (
          services.some((s) => s === 'electricity') &&
          criteria.some((c) => c === use_premises)
        )
      },
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .label('Electricity Supply Capacity (kVA)'),
  WATER_CAPACITY: string()
    .when(['USE_OF_PREMISES_LIST', 'services'], {
      is: (use_premises, services) => {
        const criteria = ['PREMISES_OTHERS', 'PREMISES_CONSTRUCTION_SITE']
        return (
          services.some((s) => s === 'water') &&
          criteria.some((c) => c === use_premises)
        )
      },
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .matches(/(^[0-9]+$)/, 'Water Supply Capacity must be numeric')
    .label('Water Supply Capacity'),
  NO_DAYS: string()
    .when(['USE_OF_PREMISES_LIST', 'services'], {
      is: (use_premises, services) => {
        const criteria = ['PREMISES_WAYANG']
        return criteria.some((c) => c === use_premises)
      },
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .label('No Of Days'),
  meter_readings: boolean(),
  electric_meter_reading: string(),
  water_meter_reading: string(),
  gas_meter_reading: string(),

  isPooopRequired: boolean(),
  isEilRequired: boolean(),
  isSpPwrGridQuotRequired: boolean(),
  isPubWatQuotRequired: boolean(),
  isLoawrdRequired: boolean(),

  use_myinfo: boolean(),
  hla: boolean(),
  timeslot_required: boolean(),

  turn_on_date: string(),
  turn_on_time: mixed(),

  check_eeg: boolean(),
  gst_reg_no: string().when('check_eeg', {
    is: true,
    then: string()
      .matches(
        /^[a-zA-Z0-9-/&]+$/,
        'GST Reg No. accepts dash, slash, ampersand, alphabets and numbers'
      )
      .required()
      .label('GST Reg No.'),
    otherwise: string().notRequired(),
  }),
  gst_reg_date: date().when('check_eeg', {
    is: true,
    then: date()
      .required()
      .label('GST Valid Reg. Date'),
    otherwise: date().notRequired(),
  }),

  npop: string().when('nanl', {
    is: true,
    then: string()
      .required()
      .label('Proof of Premise'),
    otherwise: string().notRequired(),
  }),

  nasd: string(),

  pooop: string().when('isPooopRequired', {
    is: true,
    then: string()
      .required()
      .label('Proof of occupancy of premises document'),
    otherwise: string().notRequired(),
  }),

  eil: string().when('isEilRequired', {
    is: true,
    then: string()
      .required()
      .label('Signed Electrical Installation License Form'),
    otherwise: string().notRequired(),
  }),

  sp_pwrgrd_quot: string().when('isSpPwrGridQuotRequired', {
    is: true,
    then: string()
      .required()
      .label('SP PowerGrid Quotation document'),
    otherwise: string().notRequired(),
  }),

  pub_wat_quot: string().when('isPubWatQuotRequired', {
    is: true,
    then: string()
      .required()
      .label('PUB Water Quotation'),
    otherwise: string().notRequired(),
  }),

  loawrd: string().when('isLoawrdRequired', {
    is: true,
    then: string()
      .required()
      .label('Letter of Award'),
    otherwise: string().notRequired(),
  }),
})

class CommReview extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      meter_readings: false,
      electric_meter_reading: '',
      water_meter_reading: '',
      gas_meter_reading: '',
      turn_on_date: '',
      turn_on_time: '',
      turn_on_datetime: '',
      notification_type: '',
      meter_reading_required: false,
      poo: null,
      check_eeg: false,
      gst_reg_no: '',
      gst_reg_date: '',
      pooop: '',
      npop: '',
      nasd: '',
      eil: '',
      pub_wat_quot: '',
      sp_pwrgrd_quot: '',
      loawrd: '',
      hla: false,
      security_deposit_calculation: {
        security_deposit: 0,
        USE_OF_PREMISES_LIST: '',
        ELECT_CAPACITY: '',
        WATER_CAPACITY: '',
        NO_DAYS: '',
      },
    })

    context.actions.setFields(data)
    this.state = {
      data,
      requiredDocs: [],
    }
  }

  componentDidUpdate() {
    this._form.validateForm()
  }

  componentDidMount() {
    Utils.getAttachments(
      this.props.context.data,
      this.props.context.config,
      this._form,
      this._preSave
    ).then(() =>
      this.setState({
        requiredDocs: Utils.getRequiredDocs(
          {
            ...this.props.context.data,
            ...this.props.context.config,
            isEilRequired: this._form && this._form.state.values.isEilRequired,
          },
          this._form.state.values
        ),
      })
    )
  }

  render() {
    const { context } = this.props
    const {
      data: contextData,
      config: contextConfig,
      shared: contextShared,
      actions: contextActions,
      stateId,
    } = context
    const { data, requiredDocs } = this.state

    const isPooopRequired = this._checkForRequiredDocs('pooop')
    const isEilRequired = this._checkForRequiredDocs('eil')
    const isSpPwrGridQuotRequired = this._checkForRequiredDocs('sp_pwrgrd_quot')
    const isPubWatQuotRequired = this._checkForRequiredDocs('pub_wat_quot')
    const isLoawrdRequired = this._checkForRequiredDocs('loawrd')

    return (
      <div className={`signup--print signup--form`}>
        <header className="signup--head">
          <h3>{`Review`}</h3>
          <p>
            {`Please review and confirm the information before you submit your application.`}
          </p>
        </header>

        <Summary contextConfig={contextConfig} data={contextData} />

        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          initialValues={{
            ...data,
            application_domain: contextConfig.application_domain,
            application_type: contextConfig.application_type,
            use_myinfo: contextData.use_myinfo,
            ...data.security_deposit_calculation,
            nanl: contextData.premises.nanl,
            turn_on_date: '',
            turn_on_datetime: '',
            turn_on_time: '',
            timeslot_required: false,
            isPooopRequired,
            isEilRequired,
            isSpPwrGridQuotRequired,
            isPubWatQuotRequired,
            isLoawrdRequired,
            services:
              (contextData.turnOnServices &&
                contextData.turnOnServices.services) ||
              [],
          }}
          onSubmit={this._handleSubmit}
          render={({
            values,
            touched,
            errors,
            isValid,
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            handleBlur,
          }) => (
            <form
              className="form-container"
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              onSubmit={handleSubmit}
            >
              <Detail.DetailNode>
                <TurnOnServices
                  stateId={stateId}
                  contextData={contextData}
                  contextShared={contextShared}
                  contextActions={contextActions}
                  contextConfig={contextConfig}
                  handleSubmitMeterCheck={this._handleSubmitMeterCheck}
                  handleEegCheck={this._handleEegCheck}
                  handleEegFields={null}
                  requiredDocs={requiredDocs}
                  setRequiredDocs={this._setRequiredDocs}
                  preSave={contextActions.presave}
                  formProps={{
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    setFieldError,
                    handleChange,
                    setFieldTouched,
                    handleBlur,
                  }}
                  homeContent={false}
                />
              </Detail.DetailNode>

              <FormCTA>
                <Button
                  variant="secondary"
                  onClickHandler={this._handleBackBtn}
                >
                  {`Back`}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    values['turn_on_datetime'] === '' ||
                    (values['isPooRequired'] && !values['poo']) ||
                    (values['check_eeg'] &&
                      (!values['gst_reg_no'] || !values['gst_reg_date'])) ||
                    !Utils.isNricUploaded(contextData) ||
                    !isEmpty(errors)
                  }
                >
                  {`Proceed to Payment`}
                </Button>
              </FormCTA>
            </form>
          )}
        />
      </div>
    )
  }

  _handleSubmit = (values) => {
    const { context } = this.props
    const { actions } = context
    const uploadStatus = sessionStorage.getItem(
      kioskConfig.SESSION_STATES_KEY.uploadStatus
    )

    if (!values.meter_readings) {
      values.gas_meter_reading = ''
      values.electric_meter_reading = ''
      values.water_meter_reading = ''
    }

    if (!values.check_eeg) {
      values.gst_reg_no = ''
      values.gst_reg_date = ''
    }

    if (
      Utils.getOAMode().mode === 'kiosk' &&
      uploadStatus !== UPLOAD_STATUS.DONE
    ) {
      handleNext().then(() => {
        sessionStorage.setItem(
          SESSION_STATES_KEY.uploadStatus,
          UPLOAD_STATUS.DONE
        )
        this._preSave(values)
        actions.next()
      })
    } else {
      this._preSave(values)
      actions.next()
    }
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (Utils.getOAMode().mode === 'kiosk') {
      sessionStorage.removeItem(SESSION_STATES_KEY.uploadStatus)
    } else {
      if (this._form.state && this._form.state.values) {
        this._preSave(this._form.state.values)
      }
    }
    actions.prev()
  }

  _setRequiredDocs = (isEilRequired) => {
    this.setState({
      requiredDocs: Utils.getRequiredDocs(
        {
          ...this.props.context.data,
          ...this.props.context.config,
          isEilRequired: isEilRequired,
        },
        this._form.state.values
      ),
    })
  }

  _handleSubmitMeterCheck = (e) => {
    const checked = e.target.checked
    this._form.setFieldValue('meter_readings', checked)
    if (!e.target.checked) {
      let attachments = []
      if (this._form.state.values.electric_meter_reading)
        attachments.push('meter_electric')
      if (this._form.state.values.water_meter_reading)
        attachments.push('meter_water')
      if (this._form.state.values.gas_meter_reading)
        attachments.push('meter_gas')

      Utils.deleteAttachments(attachments, this._form)
    }
  }

  _handleEegCheck = (e) => {
    this._form.setFieldValue('check_eeg', e.target.checked)
    const newFormState = {
      ...this._form.state.values,
      check_eeg: e.target.checked,
    }
    this._preSave(newFormState)
    this.setState({
      requiredDocs: Utils.getRequiredDocs(
        {
          ...this.props.context.data,
          ...this.props.context.config,
          isEilRequired: this._form && this._form.state.values.isEilRequired,
        },
        newFormState
      ),
    })

    if (!e.target.checked) {
      let attachments = []
      this._form.setFieldValue('gst_reg_no', '')
      this._form.setFieldValue('gst_reg_date', '')
      Utils.deleteAttachments(attachments, this._form)
      this._preSave({
        ...this._form.state.values,
        check_eeg: false,
        gst_reg_no: '',
        gst_reg_date: '',
      })
    }
  }

  _checkForRequiredDocs = (doc = '') => {
    if (doc === '') return false
    // if (!this._form) return false

    const { context } = this.props
    const { data: contextData, config: contextConfig } = context

    let formProps
    if (this._form) {
      formProps = this._form.state
    } else {
      if (doc === 'eil') {
        if (contextData.security_deposit_calculation) {
          if (contextData.security_deposit_calculation['ELECT_CAPACITY']) {
            formProps = {
              values: {
                ELECT_CAPACITY:
                  contextData.security_deposit_calculation['ELECT_CAPACITY'],
              },
            }
          }
        }
      }
    }

    if (contextConfig.application_domain === 'commercial') {
      if (doc === 'pooop') {
        if (contextData.turnOnServices.type_of_usage === 'permanent') {
          return true
        }
      } else if (doc === 'eil') {
        if (
          contextData.turnOnServices &&
          contextData.turnOnServices.services &&
          contextData.turnOnServices.services.indexOf('electricity') !== -1 &&
          contextData.turnOnServices.type_of_usage === 'permanent'
        ) {
          if (formProps) {
            const eCRangeIdx = getElectCapacityRangeIdx({
              ELECT_CAPACITY: formProps.values.ELECT_CAPACITY,
            })
            return eCRangeIdx && eCRangeIdx > 2
          }
        }
      } else {
        if (
          contextData.turnOnServices &&
          contextData.turnOnServices.type_of_usage === 'temporary'
        ) {
          if (doc === 'loawrd') {
            return true
          }
          if (
            contextData.turnOnServices.services &&
            contextData.turnOnServices.services.length > 0
          ) {
            if (doc === 'sp_pwrgrd_quot') {
              return (
                contextData.turnOnServices.services.indexOf('electricity') !==
                -1
              )
            } else if (doc === 'pub_wat_quot') {
              return contextData.turnOnServices.services.indexOf('water') !== -1
            }
          }
        }
      }
    }
    return false
  }

  _preSave = (values) => {
    const { actions, data } = this.props.context
    if (actions.presave) {
      const payload = {
        meter_readings: values.meter_readings,
        electric_meter_reading: values.electric_meter_reading,
        water_meter_reading: values.water_meter_reading,
        gas_meter_reading: values.gas_meter_reading,
        notification_type: values.notification_type,
        check_eeg: values.check_eeg,
        gst_reg_no: values.gst_reg_no,
        gst_reg_date: values.gst_reg_date,
        npop: values.npop,
        nasd: values.nasd,
        pooop: values.pooop,
        eil: values.eil,
        pub_wat_quot: values.pub_wat_quot,
        sp_pwrgrd_quot: values.sp_pwrgrd_quot,
        loawrd: values.loawrd,
        hla: false,
        turnOnServices: {
          ...data.turnOnServices,
          bypassZCGas: false,
        },
        zcGasDate: '',
        zczzDate: '',
      }
      actions.presave(payload)
    }
  }
}

export default CommReview

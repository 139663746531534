import React, { PureComponent } from 'react'
import { Button } from 'spd-oa/components/common'
import FormErrorBlock from './FormErrorBlock'

class FormMobileNumber extends PureComponent {
  state = {
    errorPrefix: null,
    errorNumber: null,
  }
  render() {
    const {
      id,
      label,
      errorPrefix,
      errorNumber,
      valuePrefix,
      value,
      name,
      handleChange,
      ctaHandler,
      ctaLabel,
      ...props
    } = this.props
    const {
      errorPrefix: stateErrorPrefix,
      errorNumber: stateErrorNumber,
    } = this.state
    return (
      <div
        className={`lm--formItem lm--formItem--inline ${
          stateErrorPrefix || stateErrorNumber ? 'is-error' : ''
        }${ctaHandler ? 'with-cta' : ''}`}
      >
        <div className="lm--formItem-label">{label}</div>
        <div className={`lm--formItem-control`}>
          <input
            disabled
            id={id + '_prefix'}
            className="mobile_prefix"
            type="text"
            value={valuePrefix}
            maxLength="3"
            name={name}
            onChange={handleChange}
            onBlur={this._blurHandler}
            {...props}
          />
          <input
            id={id + '_number'}
            className="number"
            type="text"
            value={value}
            name={name}
            maxLength="8"
            onChange={handleChange}
            onBlur={this._blurHandler}
            {...props}
          />
          {ctaHandler && (
            <div className="field-cta">
              <Button
                link={true}
                onClickHandler={ctaHandler}
                variant={'secondary'}
              >
                {ctaLabel || 'Continue'}
              </Button>
            </div>
          )}
          <FormErrorBlock
            id={`${id}-field_error_prefix`}
            error={stateErrorPrefix}
          />
          <FormErrorBlock
            id={`${id}-field_error_number`}
            error={stateErrorNumber}
          />
        </div>
      </div>
    )
  }
  _blurHandler = (e) => {
    const { handleBlur, errorPrefix, errorNumber } = this.props

    if (handleBlur) {
      handleBlur(e)
    }

    if (errorPrefix || errorNumber) {
      this.setState((prevState) => ({
        ...prevState,
        errorPrefix,
        errorNumber,
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorPrefix: null,
        errorNumber: null,
      }))
    }
  }
}

FormMobileNumber.defaultProps = {
  valuePrefix: '+65',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
}

export default FormMobileNumber

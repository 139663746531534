import SignupConfig from 'spd-oa/config'
import { API } from 'spd-oa/services'
import { isEmpty, get, includes } from 'lodash'
import {
  commercialDocs,
  deletingDocs,
  meterDocs,
} from 'spd-oa/attachmentHelpers'
import { getESignatureConfig } from 'spd-oa/pages/wiz/Signature/helpers'

const maskEmail = (str) => {
  let email_address = str.split('@')
  let email_length

  let email = email_address[0]
  email_length = email.length
  // email_value = email_length / 2
  // email_masked = email_length - email_value

  let email_value_first_char = email[0]
  let email_value_last_char = email[email_length - 1]
  let email_masked_txt = ''

  email_masked_txt = email_value_first_char
  for (let i = 0; i < email_length - 2; i++) {
    email_masked_txt += '*'
  }
  email_masked_txt += email_value_last_char

  return email_masked_txt + '@' + email_address[1]
}

const maskMobile = (str) => {
  let print = str.split('')
  let offset = 4
  for (let i = 1; i <= offset; i++) {
    print.splice(str.length - i, 1, '*')
  }

  return print.join('')
}

const parseQueryParams = (queryStr = '') => {
  if (queryStr === '') return null
  const search = queryStr.split('?')
  const queryParams = {}
  if (search.length > 0) {
    const queries = search[1].split('&')
    if (queries.length > 0) {
      queries.forEach((q) => {
        const _query = q.split('=')
        if (_query.length > 0) {
          queryParams[_query[0]] = _query[1]
        }
      })
    }
  }
  return queryParams
}

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const generateNotifcationNumber = (date) => {
  /**
   * I#{(Time.now.getutc.to_i*1000).to_s(16)}
   */
  if (date) {
    return `I${date.toString(16)}`
  }
  return null
}

const printArray = (arr) => {
  let result = ''

  if (arr.length === 1) {
    result = capitalize(arr[0])
    return result
  }

  const serviceSorted = [
    'electricity',
    'water',
    'gas',
    'Centralised Cooling Services',
  ].filter((val) =>
    arr.some((service) => service.toLowerCase() === val.toLowerCase())
  )

  serviceSorted.forEach((el, i) => {
    if (i === serviceSorted.length - 1) {
      result = `${result}and ${capitalize(el)}`
    } else if (i === serviceSorted.length - 2) {
      result = `${result}${capitalize(el)} `
    } else {
      result = `${result}${capitalize(el)}, `
    }
  })

  return result
}

const stringToHex = (str) => {
  let hex, i
  let result = ''
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16)
    result += ('000' + hex).slice(-4)
  }

  return result
}

const getStateId = () => {
  let stateId
  if (sessionStorage.getItem('signupStateId')) {
    stateId = sessionStorage.getItem('signupStateId')
  }
  return stateId
}

const prepareSubmissionData = (payload, type, under) => {
  const { submissionFields } = SignupConfig
  const result = {}
  const _fields = submissionFields[type][under]

  Object.keys(_fields).forEach((sf) => {
    const _field = _fields[sf]
    if (_field.parent && _field.parent !== '') {
      if (payload[_field.parent]) {
        result[sf] = payload[_field.parent]
        if (Array.isArray(_field.field) && _field.field.length > 0) {
          const d = {}
          _field.field.forEach((f) => {
            d[f] = payload[_field.parent][f]
          })
          if (_field.resolve) {
            result[sf] = _field.resolve(d)
          }
        } else {
          result[sf] = payload[_field.parent][_field.field]
          if (_field.resolve) {
            result[sf] = _field.resolve(result[sf])
          }
        }
      }
    } else {
      result[sf] = ''
      if (Array.isArray(_field.field) && _field.field.length > 0) {
        const d = {}
        _field.field.forEach((f) => {
          d[f] = payload[f]
        })
        if (_field.resolve) {
          result[sf] = _field.resolve(d)
        }
      } else {
        if (payload.hasOwnProperty(_field.field)) {
          result[sf] = payload[_field.field]
          if (_field.resolve) {
            result[sf] = _field.resolve(result[sf])
          }
        }
      }
    }
  })

  return result
}

const isMeterReadingsOk = (
  isMeterReading = false,
  services = [],
  values = {}
) => {
  if (!isMeterReading) return true
  if (services.length === 0) return false
  let result = []
  services.forEach((s, i) => {
    let check = s

    if (s === 'electricity') {
      check = 'electric'
    }
    if (
      values[`${check}_meter_reading`] &&
      values[`${check}_meter_reading`] !== ''
    ) {
      result.push(s)
    }
  })
  return result.length === services.length
}

function isNricUploaded(data) {
  return data.use_myinfo
    ? true
    : Object.values(data.personal_supporting_docs).every((filename) => filename)
}

const detectIE = () => {
  let ua = window.navigator.userAgent

  let msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  let trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  let edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

const getAvailableOADomain = () => {
  const oa = { residential: true, commercial: true }
  const mode = sessionStorage.getItem('oa_mode')
  if (mode) {
    return {
      residential: SignupConfig.modesFor.domain.residential.some(
        (m) => m === mode
      ),
      commercial: SignupConfig.modesFor.domain.commercial.some(
        (m) => m === mode
      ),
    }
  }
  return {
    ...oa,
  }
}

const getAvailableOATypes = (domain = 'residential', type = 'personal') => {
  const oa = {
    residential: {
      personal: true,
      company: true,
    },
    // For future use (OA 2B)
    commercial: {
      personal: true,
      company: true,
    },
  }

  if (!domain) {
    return {
      personal: true,
      company: true,
    }
  }

  return {
    ...oa[domain],
  }
}

const getParticularsOptions = (domain = 'residential') => {
  const options = {
    residential: {
      personal: {
        manual: true,
        myinfo: true,
      },
      company: {
        manual: true,
        myinfo: true,
      },
    },
    // For future use (OA 2B)
    commercial: {
      personal: {
        manual: true,
        myinfo: true,
      },
      company: {
        manual: true,
        myinfo: true,
      },
    },
  }

  const mode = sessionStorage.getItem('oa_mode')
  if (mode) {
    if (mode === 'kiosk') {
      options.residential.company.manual = false
    }
    if (mode === 'mobile') {
      options.residential.company.manual = false
      options.commercial.company.manual = false
    }
  }

  return {
    ...options[domain],
  }
}

const getOAMode = ({ forQueryParam } = {}) => {
  const mode = window.sessionStorage.getItem('oa_mode')
  const redirect = window.sessionStorage.getItem('redirect')

  if (mode) {
    if (redirect) {
      if (forQueryParam) {
        return `mode=${mode}&redirect=${redirect}`
      }
      return {
        mode,
        redirect,
      }
    }
    return {
      mode,
    }
  }

  return {
    mode: 'normal',
  }
}

const isPaymentKioskDevice = () => {
  const { mode, redirect } = getOAMode()
  return (
    mode === 'kiosk' &&
    redirect &&
    new URL(redirect).pathname.match(/^\/payment/)
  )
}

const redirect = (to = '') => {
  if (to !== '') {
    window.location = to
    return
  }
  const redirect_url = sessionStorage.getItem('redirect')
  if (redirect_url) {
    console.log('# redirect_url', redirect_url)
    window.location = redirect_url
  }
}

const clearSession = () => {
  const values = [
    'oa_mode',
    'redirect',
    'signupOTPConfig',
    'token',
    'userOfPremise',
  ].map((key) => ({
    key,
    value: window.sessionStorage.getItem(key),
  }))
  window.sessionStorage.clear()
  values.forEach(function({ key, value }) {
    if (value) {
      window.sessionStorage.setItem(key, value)
    }
  })
}

function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

function objToUrlEncoded(element, key, list) {
  let _list = list || []
  if (typeof element === 'object') {
    for (let idx in element)
      objToUrlEncoded(element[idx], key ? key + '[' + idx + ']' : idx, _list)
  } else {
    _list.push(key + '=' + encodeURIComponent(element))
  }
  return _list.join('&')
}

const getAttachments = (
  contextData,
  contextConfig,
  formProps,
  preSave,
  currentPage
) => {
  const stateId = getStateId()
  return API.getAttachments({
    transaction_id: stateId,
  }).then((data) => {
    const shouldUpload = shouldUploadDocs(
      contextConfig.application_type,
      contextData.use_myinfo,
      currentPage
    )
    const updateDocValue = (docId, docType, contextDocs) => {
      if (!isEmpty(data[docType]) && isEmpty(get(contextDocs, docId))) {
        formProps.setFieldValue(docId, data[docType])
      }
    }

    if (shouldUpload) {
      updateDocValue(
        'gst_reg_cert',
        'gst_registration_certification',
        contextData
      )
    }
    if (currentPage === 'upload') {
      updateDocValue(
        'application_form',
        'c_signed_utility_application_form',
        contextData.docs
      )
      updateDocValue('bizfile', 'c_acra_manual', contextData.docs)
      updateDocValue('loa', 'c_letter_of_auth', contextData.docs)
    }

    if (contextConfig.application_domain === 'residential') {
      if (currentPage !== 'upload') {
        updateDocValue('poo', 'proof_of_ownership', contextData)
      }
      if (shouldUpload) {
        meterDocs.forEach(({ id, type }) => {
          updateDocValue(id, type, contextData)
        })
      }
    }

    if (contextConfig.application_domain === 'commercial' && shouldUpload) {
      const commDocs = currentPage === 'upload' ? contextData.docs : contextData
      commercialDocs.forEach(({ id, type }) => {
        updateDocValue(id, type, commDocs)
      })
    }

    if (
      contextConfig.application_domain === 'residential' &&
      contextConfig.application_type === 'personal' &&
      !contextData.use_myinfo
    ) {
      preSave({
        ...contextData,
        ...formProps.state.values,
        personal_supporting_docs: {
          nric_fin_front:
            get(contextData.personal_supporting_docs, 'nric_fin_front') ||
            data.nric_front ||
            '',
          nric_fin_back:
            get(contextData.personal_supporting_docs, 'nric_fin_back') ||
            data.nric_back ||
            '',
        },
      })
    }
  })
}

const deleteAttachments = (attachments, formProps) => {
  const stateId = getStateId()
  deletingDocs.forEach(({ id, type }) => {
    if (includes(attachments, type)) {
      API.deleteAttachment({
        transaction_id: stateId,
        type,
      }).then(() => {
        formProps.setFieldValue(id, '')
      })
    }
  })
}

const shouldUploadDocs = (application_type, use_myinfo, currentPage) => {
  if (application_type === 'company') {
    if (use_myinfo || currentPage === 'upload') {
      return true
    }
  } else {
    // if type is Personal
    return true
  }
  return false
}

const getRequiredDocs = (data, formValues) => {
  const { mode } = getOAMode()
  const createDocOf = (docId) => {
    return { id: docId, value: formValues[docId] }
  }
  if (mode !== 'kiosk') {
    return []
  }
  const requiredDocs = []

  // NRIC/FIN
  if (data.application_type === 'personal') {
    if (!data.use_myinfo) {
      Object.entries(data.personal_supporting_docs).forEach(([id, value]) => {
        requiredDocs.push({ id, value })
      })
    }
  }
  // Upload
  if (data.currentPage === 'upload') {
    if (!getESignatureConfig().enableESignature) {
      requiredDocs.push(createDocOf('application_form'))
    }
    requiredDocs.push(createDocOf('bizfile'), createDocOf('loa'))
  }
  // Proof of ownership
  else if (data.isPooRequired) {
    requiredDocs.push({ id: 'poo', value: formValues.poo })
  }
  // Meter readings
  const serviceDocIdMap = {
    electricity: 'electric_meter_reading',
    water: 'water_meter_reading',
    gas: 'gas_meter_reading',
  }
  if (formValues.meter_readings) {
    data.turnOnServices.services
      .map((serviceId) => ({
        id: serviceDocIdMap[serviceId],
        value: formValues[serviceDocIdMap[serviceId]],
      }))
      .forEach((doc) => requiredDocs.push(doc))
  }
  if (
    !shouldUploadDocs(data.application_type, data.use_myinfo, data.currentPage)
  ) {
    return requiredDocs
  }
  // Commercial documents
  if (data.application_domain === 'commercial') {
    if (data.turnOnServices.type_of_usage === 'permanent') {
      requiredDocs.push(createDocOf('pooop'))
      if (includes(data.turnOnServices.services, 'electricity')) {
        requiredDocs.push({
          ...createDocOf('eil'),
          is_required: data.isEilRequired,
        })
      }
    } else if (data.turnOnServices.type_of_usage === 'temporary') {
      if (!isEmpty(data.turnOnServices.services)) {
        requiredDocs.push(createDocOf('loawrd'))
        if (includes(data.turnOnServices.services, 'electricity')) {
          requiredDocs.push(createDocOf('sp_pwrgrd_quot'))
        }
        if (includes(data.turnOnServices.services, 'water')) {
          requiredDocs.push(createDocOf('pub_wat_quot'))
        }
      }
    }
  }

  if (data.premises.nanl) {
    requiredDocs.push(createDocOf('npop'), {
      ...createDocOf('nasd'),
      is_required: false,
    })
  }

  return requiredDocs
}

const getListUserOfPremise = (data, premise, type) => {
  return data
    .filter(
      (item) =>
        (item.useOfPremise === premise || !premise) &&
        item.domain.some((e) => e === type)
    )
    .sort((a, b) => a.order - b.order)
    .map((item) => ({
      ...item,
      text: item.text[type],
    }))
}

const getItemFromUserOfPremise = (value, type) => {
  const listUserOfPremise = JSON.parse(sessionStorage.getItem('userOfPremise'))
  return listUserOfPremise.find((it) => it.value === value).text[type]
}

const getSource = () => {
  const mode = getOAMode().mode
  if (mode === 'kiosk') {
    return 'K' // Kiosk
  } else if (mode === 'mobile') {
    return 'M' // Mobile
  }
  return 'W' // mode is normal then source is Web
}

export default {
  detectIE,
  getAvailableOADomain,
  getAvailableOATypes,
  getParticularsOptions,
  getOAMode,
  getWindowSize,
  maskEmail,
  maskMobile,
  parseQueryParams,
  capitalize,
  printArray,
  generateNotifcationNumber,
  stringToHex,
  getStateId,
  isMeterReadingsOk,
  isNricUploaded,
  prepareSubmissionData,
  redirect,
  clearSession,
  objToUrlEncoded,
  getAttachments,
  deleteAttachments,
  getRequiredDocs,
  isPaymentKioskDevice,
  getListUserOfPremise,
  getItemFromUserOfPremise,
  getSource,
}

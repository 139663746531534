import React from 'react'
import classnames from 'classnames'

import Modal from './Modal'

/**
 * Cache menu default props, useful for merging default and passed in props
 */
const MENU_DEFAULT_PROPS = {
  disableEscapeKey: false,
  className: '',
  withBackdrop: false,
  arrow: 'relative',
  offset: {
    x: 0,
    y: -10,
  },
}

const ContextMenu = ({
  className,
  targetNode,
  onCloseHandler,
  withBackdrop,
  arrow,
  offset,
  children,
  visible: visibleProp,
  fullTargetWidth,
  open,
  appearAnimation,
  disappearAnimation,
  width,
  alignX,
  style,
}) => {
  const [visible, setVisible] = React.useState(visibleProp)
  const [shouldRender, setShouldRender] = React.useState(open)

  const _ContextMenu = classnames('cui-menu', className)

  React.useEffect(() => {
    setVisible(visibleProp)
  }, [visibleProp])

  React.useEffect(() => {
    if (open) {
      setShouldRender(true)
      setVisible(true)
    } else {
      setTimeout(() => {
        setShouldRender(false)
      }, 100)
    }
  }, [open])

  if (!shouldRender) {
    return null
  }

  const handleCloseMenu = () => {
    onCloseHandler()
    setVisible(false)
  }

  return (
    <>
      <Modal
        visible={visible}
        target={targetNode}
        fullTargetWidth={fullTargetWidth}
        className={_ContextMenu}
        onEscapeKeyDown={handleCloseMenu}
        withBackdrop={withBackdrop}
        arrow={arrow}
        offset={{
          ...MENU_DEFAULT_PROPS.offset,
          ...offset,
        }}
        handleClose={handleCloseMenu}
        appearAnimation={appearAnimation}
        disappearAnimation={disappearAnimation}
        width={width}
        alignX={alignX}
        useMinHeight={false}
        style={style}
      >
        {children}
      </Modal>
    </>
  )
}

ContextMenu.defaultProps = {
  visible: true,
  open: false,
}

export default ContextMenu

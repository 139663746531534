import React from 'react'
import FormErrorBlock from './FormErrorBlock'

class FormFileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }
  onChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      console.log(event.target.files[0])
      this.props.setFieldValue(this.props.id, event.target.files[0])
    } else {
      // reset the value to empty if no file is selected
      this.props.setFieldValue(this.props.id, '')
    }
  }
  render() {
    const { id, label, error, value, name, accept } = this.props
    return (
      <div
        className={
          'lm--formItem lm--formItem--inline ' + (error ? 'is-error' : '')
        }
      >
        <div className="lm--formItem-label">{label}</div>
        <div className="lm--formItem-control">
          <input
            id={id}
            name={name}
            type="file"
            value={value}
            onChange={this.onChange}
            accept={accept}
          />
          <FormErrorBlock id={`${id}-field_error`} error={error} />
        </div>
      </div>
    )
  }
}

FormFileUpload.defaultProps = {
  accept: '.csv',
}

export default FormFileUpload

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './choice.scss'

const Choice = ({
  id,
  children,
  className,
  title,
  active,
  disabled,
  onClickHandler,
}) => {
  const _Choice = classnames('cui-choice', className, {
    'is-active': active,
    'is-disabled': disabled,
  })

  const handleClick = (e) => {
    if (onClickHandler) {
      onClickHandler({
        id,
      })
    }
  }

  const renderChoiceBtn = () => {
    return (
      <button
        className="cui-choice__btn"
        onClick={handleClick}
        disabled={disabled}
        type="button"
      >
        {title}
      </button>
    )
  }

  return (
    <div className={_Choice}>
      {renderChoiceBtn()}
      {active && children && (
        <div className="cui-choice__content">{children}</div>
      )}
    </div>
  )
}

Choice.defaultProps = {
  active: false,
  disabled: false,
}

Choice.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Choice

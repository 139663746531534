import React, { useState } from 'react'
import Utils from 'spd-oa/utils'

import { withKioskSideBarContext } from 'spd-oa/kioskContext'

const KioskSideBar = (props) => {
  const { children, kioskContext: context } = props
  const [shouldHideEndSession, setShouldHideEndSession] = useState(false)
  if (context) {
    if (shouldHideEndSession !== context.shouldHideEndSession) {
      setShouldHideEndSession(context.shouldHideEndSession)
    }
  }

  let endSessionHandler = (e) => {
    Utils.redirect()
  }

  return (
    <>
      <div className="kiosk-mode">
        <aside className="sidebar">
          <>
            <div className="sidebar_logo">
              <figure className="logo">
                <img src="/images/spgroup-logo-white.svg" alt="SP Group Ltd" />
              </figure>
            </div>
            <div className="sidebar_menu">
              <ul>
                {shouldHideEndSession ? null : (
                  <li className="app-contentnode">
                    <span className="app-contentnode__label"></span>
                    <span className="app-contentnode__value">
                      <button
                        className="btn-endsession"
                        onClick={endSessionHandler}
                      >
                        End Session
                      </button>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </>
        </aside>
        {children}
      </div>
    </>
  )
}

export default withKioskSideBarContext(KioskSideBar)

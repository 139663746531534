import React, { PureComponent } from 'react'

class SuccessBar extends PureComponent {
  render() {
    const { children } = this.props
    return <section className="success-bar">{children}</section>
  }
}

export default SuccessBar

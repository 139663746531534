import React, { PureComponent, Fragment } from 'react'

class Display extends PureComponent {
  render() {
    const { icon, title, text, children } = this.props
    return (
      <Fragment>
        <div className="app-display">
          {icon && <figure>{icon}</figure>}

          {title && title !== '' && <h3>{title}</h3>}

          {text && text !== '' && <p>{text}</p>}
          {children && children}
        </div>
      </Fragment>
    )
  }
}

export default Display

import React from 'react'

const DetailNode = ({ label, children, ...props }) => {
  return (
    <div className="detail_section" {...props}>
      {label !== '' && (
        <div className="u-grid detail_section--title">
          <div className="col-md-4 col-sm-12 u-cell label">
            <h4>{label}</h4>
          </div>
        </div>
      )}
      <div className="detail_section--content">{children}</div>
    </div>
  )
}

DetailNode.defaultProps = {
  label: '',
}

const DetailNodeItem = ({ label, data, render, ...props }) => {
  return (
    <div className="u-grid" {...props}>
      <div className="col-md-4 col-sm-12 u-cell label u-text-muted">
        {label}
      </div>
      <div className="col-md-8 col-sm-12 u-cell data">{render(data)}</div>
    </div>
  )
}

DetailNodeItem.defaultProps = {
  data: {},
  render: () => null,
}

export default {
  DetailNode,
  DetailNodeItem,
}

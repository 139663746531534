import moment from 'moment'
import { submitFieldsEsignature } from './submit-fields-esign'

const submissionFields = {
  commercial: {
    // SUBMISSION PAYLOAD FOR COMMERCIAL - PERSONAL NAME
    personal: {
      transaction_id: {
        field: 'transaction_id',
      },
      source: {
        field: 'source',
      },
      ebill: {
        field: ['ebill', 'ebill_verified'],
        resolve: (data) => {
          return data.ebill && data.ebill_verified
        },
      },
      salutation: {
        field: 'salutation',
        parent: 'personal_information',
      },
      name: {
        field: 'name',
        parent: 'personal_information',
      },
      nric: {
        field: 'nric_fin',
        parent: 'personal_information',
      },
      nric_issue_yyyymmdd: {
        field: 'nric_fin_doi',
        parent: 'personal_information',
        resolve: (data) => {
          const d = moment(data).format('YYYYMMDD')
          if (d === 'Invalid date') {
            return '19000101'
          }
          return d
        },
      },
      race: {
        field: 'race',
        parent: 'personal_information',
      },
      birth_date_yyyymmdd: {
        field: 'dob',
        parent: 'personal_information',
        resolve: (data) => {
          const d = moment(data).format('YYYYMMDD')
          if (d === 'Invalid date') {
            return ''
          }
          return d
        },
      },
      phone_number: {
        field: ['phone_prefix', 'phone_number'],
        parent: 'personal_contact_information',
        resolve: (data) => {
          if (!data.phone_number || data.phone_number === '') return ''
          return `${data.phone_number}`
        },
      },
      mobile_number: {
        field: ['mobile_prefix', 'mobile_number'],
        parent: 'personal_contact_information',
        resolve: (data) => {
          if (!data.mobile_number || data.mobile_number === '') return ''
          return `${data.mobile_number}`
        },
      },
      other_contact: {
        field: ['alternate_contact_prefix', 'alternate_contact_number'],
        parent: 'personal_contact_information',
        show: (data) => {
          return data.alternate_contact_number !== ''
        },
        resolve: (data) => {
          if (
            !data.alternate_contact_number ||
            data.alternate_contact_number === ''
          )
            return ''
          return `${data.alternate_contact_number}`
        },
      },
      email: {
        field: 'email',
        parent: 'personal_contact_information',
      },
      other_email: {
        field: 'alternate_email',
        parent: 'personal_contact_information',
      },
      bankruptcy_number: {
        field: 'bankruptcy_no',
        parent: 'bankruptcy',
      },
      electricity: {
        field: 'services',
        parent: 'turnOnServices',
        resolve: (data) => {
          return data.length > 0 && data.indexOf('electricity') !== -1
        },
      },
      water: {
        field: 'services',
        parent: 'turnOnServices',
        resolve: (data) => {
          return data.length > 0 && data.indexOf('water') !== -1
        },
      },
      gas: {
        field: ['services', 'bypassZCGas'],
        parent: 'turnOnServices',
        resolve: (data) => {
          return (
            data.services.length > 0 &&
            data.services.indexOf('gas') !== -1 &&
            !data.bypassZCGas
          )
        },
      },
      chilled_water: {
        field: 'chilled_water',
        parent: 'premises',
      },
      move_in_yyyymmdd: {
        field: 'turn_on_datetime',
        resolve: (data) => {
          let d = moment(data)
            .tz('Asia/Singapore')
            .format('YYYYMMDD')
          if (data.start && data.end) {
            d = moment(data.start)
              .tz('Asia/Singapore')
              .format('YYYYMMDD')
          }
          if (d === 'Invalid date') {
            return ''
          }
          return d
        },
      },
      move_in_hash: {
        field: 'turn_on_date_hash',
      },
      appointment_hash: {
        field: 'turn_on_time_hash',
      },
      appointment_notification_number: {
        field: ['notification_no', 'turn_on_datetime'],
        resolve: (data) => {
          if (!data.turn_on_datetime.start && !data.turn_on_datetime.end) {
            return ''
          }
          return data.notification_no
        },
      },
      appointment_start_time: {
        field: 'turn_on_datetime',
        resolve: (data) => {
          if (data.start) {
            return moment(data.start)
              .tz('Asia/Singapore')
              .format('HHmmss')
          }
          return ''
        },
      },
      appointment_end_time: {
        field: 'turn_on_datetime',
        resolve: (data) => {
          if (data.end) {
            return moment(data.end)
              .tz('Asia/Singapore')
              .format('HHmmss')
          }
          return ''
        },
      },
      occ_type: {
        field: 'premise_occupancy',
        parent: 'premises',
      },
      use_of_premises: {
        field: 'use_of_premises',
        parent: 'premises',
      },
      premise_number: {
        field: 'premise_no',
        parent: 'premises',
      },
      premise_address: {
        field: ['premise_address', 'nanl'],
        parent: 'premises',
        resolve: (data) => {
          if (data.nanl) return undefined
          return {
            block: data.premise_address.premise_block,
            floor: data.premise_address.premise_floor,
            unit: data.premise_address.premise_unit,
            street: data.premise_address.premise_street_name,
            postal_code: data.premise_address.premise_postal_code,
          }
        },
      },
      nanl: {
        field: 'nanl',
        parent: 'premises',
      },
      nanl_additional_info: {
        field: 'premise_address',
        parent: 'premises',
        resolve: (data) => data.premise_description,
      },
      hawker_stall_no: {
        field: 'hawker_stall_no',
        parent: 'premises',
      },
      type_of_usage: {
        field: 'type_of_usage',
        parent: 'turnOnServices',
      },
      supply_application_no: {
        field: 'supply_application_number',
        parent: 'turnOnServices',
      },
      mailing_address: {
        field: 'mail_address',
        parent: 'premises',
        resolve: (data) => {
          return {
            block: data.mail_block,
            floor: data.mail_floor,
            unit: data.mail_unit,
            street: data.mail_street_name,
            postal_code: data.mail_postal_code,
          }
        },
      },
      account_type: {
        field: 'account_type',
      },
      account_holder_type: {
        field: 'account_holder_type',
      },

      gst_no: {
        field: 'gst_reg_no',
      },
      gst_reg_date: {
        field: 'gst_reg_date',
        resolve: (data) => {
          const d = moment(data).format('YYYYMMDD')
          if (d === 'Invalid date') {
            return ''
          }
          return d
        },
      },
      meter_reading_electricity: {
        field: 'electric_meter_reading',
        resolve: (reading) => {
          if (reading !== '') {
            return true
          }
          return false
        },
      },
      meter_reading_water: {
        field: 'water_meter_reading',
        resolve: (reading) => {
          if (reading !== '') {
            return true
          }
          return false
        },
      },
      meter_reading_gas: {
        field: 'gas_meter_reading',
        resolve: (reading) => {
          if (reading !== '') {
            return true
          }
          return false
        },
      },
      fromMyInfo: {
        field: 'use_myinfo',
      },
      work_pass_status: {
        field: 'work_pass',
        parent: 'personal_information',
      },
      hla_consent_flag: {
        field: 'hla',
        // resolve: hla => {
        //   console.log('# hla', hla)
        //   return hla !== ''
        // }
      },
      use_of_premises_selected: {
        field: 'USE_OF_PREMISES_LIST',
      },
      elect_capacity: {
        field: 'ELECT_CAPACITY',
      },
      water_capacity: {
        field: 'WATER_CAPACITY',
      },
      no_days: {
        field: 'NO_DAYS',
      },
      payment_method: {
        field: 'PAYMENT_METHOD',
      },
      payment_redirect: {
        field: 'payment_redirect',
        resolve: () => {
          return `${window.location.origin}/v1/payment/callback`
        },
      },
      bypass_dup_check: {
        field: 'bypass_dup_check',
        resolve: (val) => {
          return val === 'true'
        },
      },
    },

    // SUBMISSION PAYLOAD FOR COMMERCIAL - COMPANY NAME
    company: {
      transaction_id: {
        field: 'transaction_id',
      },
      source: {
        field: 'source',
      },
      salutation: {
        field: 'salutation',
        parent: 'personal_information',
      },
      name: {
        field: 'company_name',
        parent: 'company_information',
      },
      nric: {
        field: 'uen',
        parent: 'company_information',
      },
      company_name_source: {
        field: 'company_name_source',
        parent: 'company_information',
      },
      ebs_company_name: {
        field: 'ebs_company_name',
        parent: 'company_information',
        resolve: (val) => {
          return val || undefined
        },
      },
      nric_issue_yyyymmdd: {
        field: 'nric_fin_doi',
        parent: 'company_information',
        resolve: () => {
          return ''
        },
      },
      authorised_name: {
        field: 'representative_name',
        parent: 'representative',
      },
      authorised_nric: {
        field: 'representative_nric_fin',
        parent: 'representative',
      },
      authorised_designation: {
        field: 'representative_designation',
        parent: 'representative',
      },
      race: {
        field: 'race',
        parent: 'representative',
        resolve: () => {
          return ''
        },
      },
      birth_date_yyyymmdd: {
        field: 'dob',
        parent: 'representative',
        resolve: (data) => {
          return ''
        },
      },
      phone_number: {
        field: ['representative_phone_prefix', 'representative_phone_number'],
        parent: 'representative',
        resolve: (data) => {
          if (
            !data.representative_phone_number ||
            data.representative_phone_number === ''
          )
            return ''
          return `${data.representative_phone_number}`
        },
      },
      mobile_number: {
        field: ['representative_mobile_prefix', 'representative_mobile_number'],
        parent: 'representative',
        resolve: (data) => {
          if (
            !data.representative_mobile_number ||
            data.representative_mobile_number === ''
          )
            return ''
          return `${data.representative_mobile_number}`
        },
      },
      other_contact: {
        field: [
          'representative_contact_prefix',
          'representative_contact_number',
        ],
        parent: 'representative',
        show: (data) => {
          return data.representative_contact_number !== ''
        },
        resolve: (data) => {
          if (
            !data.representative_contact_number ||
            data.representative_contact_number === ''
          )
            return ''
          return `${data.representative_contact_number}`
        },
      },
      email: {
        field: 'representative_email',
        parent: 'representative',
      },
      other_email: {
        field: 'representative_alternate_email',
        parent: 'representative',
      },
      bankruptcy_number: {
        field: 'bankruptcy_no',
        parent: 'bankruptcy',
      },
      electricity: {
        field: 'services',
        parent: 'turnOnServices',
        resolve: (data) => {
          return data.length > 0 && data.indexOf('electricity') !== -1
        },
      },
      water: {
        field: 'services',
        parent: 'turnOnServices',
        resolve: (data) => {
          return data.length > 0 && data.indexOf('water') !== -1
        },
      },
      gas: {
        field: ['services', 'bypassZCGas'],
        parent: 'turnOnServices',
        resolve: (data) => {
          return (
            data.services.length > 0 &&
            data.services.indexOf('gas') !== -1 &&
            !data.bypassZCGas
          )
        },
      },
      chilled_water: {
        field: 'chilled_water',
        parent: 'premises',
      },
      move_in_yyyymmdd: {
        field: 'turn_on_datetime',
        parent: 'preferred_date',
        resolve: (data) => {
          let d = moment(data)
            .tz('Asia/Singapore')
            .format('YYYYMMDD')
          if (data.start && data.end) {
            d = moment(data.start)
              .tz('Asia/Singapore')
              .format('YYYYMMDD')
          }
          return d || ''
        },
      },
      move_in_hash: {
        field: 'turn_on_date_hash',
      },
      appointment_notification_number: {
        field: ['notification_no', 'turn_on_datetime'],
        resolve: (data) => {
          if (!data.turn_on_datetime.start && !data.turn_on_datetime.end) {
            return ''
          }
          return data.notification_no
        },
      },
      appointment_start_time: {
        field: 'turn_on_datetime',
        resolve: (data) => {
          if (data.start) {
            return moment(data.start)
              .tz('Asia/Singapore')
              .format('HHmmss')
          }
          return ''
        },
      },
      appointment_end_time: {
        field: 'turn_on_datetime',
        resolve: (data) => {
          if (data.end) {
            return moment(data.end)
              .tz('Asia/Singapore')
              .format('HHmmss')
          }
          return ''
        },
      },
      occ_type: {
        field: 'premise_occupancy',
        parent: 'premises',
      },
      use_of_premises: {
        field: 'use_of_premises',
        parent: 'premises',
      },
      premise_number: {
        field: 'premise_no',
        parent: 'premises',
      },
      premise_address: {
        field: ['premise_address', 'nanl'],
        parent: 'premises',
        resolve: (data) => {
          if (data.nanl) return undefined
          return {
            block: data.premise_address.premise_block,
            floor: data.premise_address.premise_floor,
            unit: data.premise_address.premise_unit,
            street: data.premise_address.premise_street_name,
            postal_code: data.premise_address.premise_postal_code,
          }
        },
      },
      nanl: {
        field: 'nanl',
        parent: 'premises',
      },
      nanl_additional_info: {
        field: 'premise_address',
        parent: 'premises',
        resolve: (data) => data.premise_description,
      },
      hawker_stall_no: {
        field: 'hawker_stall_no',
        parent: 'premises',
      },
      type_of_usage: {
        field: 'type_of_usage',
        parent: 'turnOnServices',
      },
      supply_application_no: {
        field: 'supply_application_number',
        parent: 'turnOnServices',
      },
      mailing_address: {
        field: 'mail_address',
        parent: 'premises',
        resolve: (data) => {
          return {
            block: data.mail_block,
            floor: data.mail_floor,
            unit: data.mail_unit,
            street: data.mail_street_name,
            postal_code: data.mail_postal_code,
          }
        },
      },
      account_type: {
        field: 'account_type',
      },
      account_holder_type: {
        field: 'account_holder_type',
      },

      gst_no: {
        field: 'gst_reg_no',
      },
      gst_reg_date: {
        field: 'gst_reg_date',
        resolve: (data) => {
          const d = moment(data).format('YYYYMMDD')
          if (d === 'Invalid date') {
            return ''
          }
          return d
        },
      },
      meter_reading_electricity: {
        field: 'electric_meter_reading',
        resolve: (reading) => {
          if (reading !== '') {
            return true
          }
          return false
        },
      },
      meter_reading_water: {
        field: 'water_meter_reading',
        resolve: (reading) => {
          if (reading !== '') {
            return true
          }
          return false
        },
      },
      meter_reading_gas: {
        field: 'gas_meter_reading',
        resolve: (reading) => {
          if (reading !== '') {
            return true
          }
          return false
        },
      },
      work_pass_status: {
        field: 'work_pass',
        parent: 'personal_information',
      },
      use_of_premises_selected: {
        field: 'USE_OF_PREMISES_LIST',
      },
      elect_capacity: {
        field: 'ELECT_CAPACITY',
      },
      water_capacity: {
        field: 'WATER_CAPACITY',
      },
      no_days: {
        field: 'NO_DAYS',
      },
      payment_method: {
        field: 'PAYMENT_METHOD',
      },
      payment_redirect: {
        field: 'payment_redirect',
        resolve: () => {
          return `${window.location.origin}/v1/payment/callback`
        },
      },
      bypass_dup_check: {
        field: 'bypass_dup_check',
        resolve: (val) => {
          return val === 'true'
        },
      },

      ...submitFieldsEsignature,
    },
  },
}

export default submissionFields

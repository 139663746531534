import React from 'react'
import FormErrorBlock from './FormErrorBlock'
import Sprite from 'atlas/dist/sprite.svg'
import moment from 'moment'

class FormDatePicker extends React.Component {
  constructor(props) {
    super(props)
    this.displayDate = this.displayDate.bind(this)
    this.datePickerInstance = null
  }
  componentDidMount() {
    this.initDatepicker()
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.minDate !== this.props.minDate ||
      prevProps.maxDate !== this.props.maxDate
    ) {
      this.datePickerInstance.pikaday.config({
        minDate: moment(this.props.minDate).toDate(),
        maxDate: moment(this.props.maxDate).toDate(),
      })
    }
  }
  initDatepicker() {
    const self = this
    // Let's find the Action Date datepicker with id dpActionDate
    const dpActionDate = this.refs.dpActionDate
    if (dpActionDate) {
      // exists in DOM
      if (!window.Atlas.Datepicker.components.get(dpActionDate)) {
        this.datePickerInstance = new window.Atlas.Datepicker(dpActionDate, { // eslint-disable-line
          keyboardInput: false,
          showTime: false,
          showMinutes: false,
          maxDate: this.props.maxDate
            ? moment(this.props.maxDate).toDate()
            : '',
          onSelect: function(date) {
            let formmattedDate = moment(date).format()
            self.props.setFieldValue(self.props.id, formmattedDate)
          },
        })
      }
    }
  }
  displayDate(date) {
    return date ? moment(date).format(this.props.formatDate) : ''
  }
  render() {
    const { id, label, error, value, name, formatDate, isInline } = this.props
    return (
      <div
        className={
          'lm--formItem' +
          (isInline ? ' lm--formItem--inline' : '') +
          (error ? ' is-error' : '')
        }
      >
        <div className="lm--formItem-label">{label}</div>
        <div className="lm--formItem-control">
          <label className="lm--inputGroup">
            <div className="lm--inputGroup-suffix">
              <svg className="lm--icon">
                <use href={Sprite + '#calendar'} />
              </svg>
            </div>
            <input
              type="text"
              id={id}
              name={name}
              readOnly
              ref="dpActionDate"
              className="lm--inputGroup-field-suffix"
              autoComplete="off"
              data-datepicker=""
              data-show-time=""
              data-format={formatDate}
              data-min-date={this.props.minDate}
              defaultValue={this.displayDate(value)}
            />
          </label>
          {this.props.children}
          <FormErrorBlock id={`${id}-field_error`} error={error} />
        </div>
      </div>
    )
  }
}

FormDatePicker.defaultProps = {
  minDate: moment().format(), // default is today's date
}

export default FormDatePicker

import React, { Fragment, PureComponent } from 'react'
import { AdBanner, Icons } from 'spd-oa/components/common'
import Utils from 'spd-oa/utils'

class ThankYou extends PureComponent {
  constructor(props) {
    super(props)
    this._checkStatus()
    this.state = {
      ads: [],
    }
  }

  _getAdsForPage() {
    const response = [
      {
        name: '[CAPP] OpenAccount - GET (commercial)_web',
        creative_type: 'IMAGE',
        ad_id: 11356,
        creative: {
          url:
            'https://www.spdigital.sg/get-tenantcare.html?utm_source=SPSAccOpeningSuccess&utm_medium=digitalbanner&utm_campaign=SPS+Customers+GET+TenantCare+Awareness&utm_content=GETTenantCareAd1',
          title: '',
          subtitle:
            'Digitalise tenant utilities and billing for greater efficiency',
          source: 'https://openaccount.spdigital.sg/images/banner-3B(web).png',
          caption: 'Learn more',
          width: '',
        },
      },
      {
        name: '[CAPP] OpenAccount - GET (residential)_mobile',
        creative_type: 'IMAGE',
        ad_id: 11360,
        creative: {
          url: 'https://www.hlas.com.sg/homesafe',
          source:
            'https://openaccount.spdigital.sg/images/hla-banner-mobile.jpg',
          width: '767',
        },
      },
      {
        name: '[CAPP] OpenAccount - GET (commercial)_mobile',
        creative_type: 'IMAGE',
        ad_id: 11359,
        creative: {
          url:
            'https://www.spdigital.sg/get-tenantcare.html?utm_source=SPSAccOpeningSuccess&utm_medium=digitalbanner&utm_campaign=SPS+Customers+GET+TenantCare+Awareness&utm_content=GETTenantCareAd1',
          subtitle:
            'Digitalise tenant utilities and billing for greater efficiency',
          source: 'https://openaccount.spdigital.sg/images/banner-3B.png',
          caption: 'Learn more',
          width: '767',
        },
      },
      {
        name: '[CAPP] OpenAccount - GET (normal)_web',
        creative_type: 'IMAGE',
        ad_id: 11358,
        creative: {
          url:
            'https://www.spgroup.com.sg/faq?getQues=how-to-submit-meter-reading&fbclid=IwAR2IOqbWt7ruy2SMfubqZwDp0vzS1Kv_pKiywJAcv2nD5oyfd5qEa1HAyyc',
          source:
            'https://openaccount.spdigital.sg/images/ebill-meter-banner.png',
          width: ' 767',
        },
      },
      {
        name: '[CAPP] OpenAccount - GET (normal)_mobile',
        creative_type: 'IMAGE',
        ad_id: 11361,
        creative: {
          url: 'https://www.hlas.com.sg/homesafe',
          source:
            'https://openaccount.spdigital.sg/images/ebill-meter-banner-mobile.png',
          width: ' 767',
        },
      },
      {
        name: '[CAPP] OpenAccount - GET (residential)_web',
        creative_type: 'IMAGE',
        ad_id: 11357,
        creative: {
          url: 'https://www.hlas.com.sg/homesafe',
          source: 'https://openaccount.spdigital.sg/images/hla-banner.jpg',
        },
      },
    ]

    this.setState({
      ads: response,
    })
    // return API.getAds().then((res) => {
    //   this.setState({
    //     ads: response,
    //   })
    // })
  }

  componentDidMount() {
    this._checkStatus()
  }

  render() {
    const { location } = this.props
    const { mode: oaMode } = Utils.getOAMode()
    window.mopinion_cust_email =
      location && location.state && location.state.contact.email
    return (
      <Fragment>
        <section className="thank-you">
          <div id="oa-thank-you" className="lm--box thank-you--container">
            <figure className="lm--item thank-you--image">
              <Icons.success width={204} height={153} />
            </figure>
            <div className="lm--item thank-you--desc">
              <h3>Success!</h3>

              <p>
                {`Thank you for your submission.`}
                <br />
                {`Your Application ID is ${location.state &&
                  location.state.appId} for your reference. `}
                <br />
                <br />

                {location.state &&
                  location.state.application.domain === 'residential' &&
                  location.state.application.type === 'personal' && (
                    <>
                      {location.state.paper_bill ? (
                        <>
                          {`We note that you have also opted for paper bills. However, we encourage you to view your e-bills on the SP app or SP Utilities Portal for better convenience. If you do not have an online account, it will be created for you to view your e-bills. You will receive a separate email with login details after your utilities account is opened successfully.`}
                          <br />
                          <br />
                        </>
                      ) : (
                        <>
                          {`Please note that you will not receive paper bills. To view your e-bills, please login to the SP app or SP Utilities Portal. If you do not have an online account, it will be created for you to view your e-bills. You will receive a separate email with login details after your utilities account is opened successfully.`}
                          <br />
                          <br />
                        </>
                      )}
                    </>
                  )}

                {location.state &&
                  location.state.contact &&
                  `You will receive a confirmation on your selected appointment date via your email at ${location.state.contact.email} and mobile number at ${location.state.contact.number}.`}
              </p>

              <p>
                {`You will also receive a hardcopy confirmation on your account opening to your mailing address.`}
              </p>

              <p>
                If you have any queries, please contact us at{' '}
                {oaMode === 'kiosk' ? (
                  '1800-2222-333'
                ) : (
                  <a href="tel:1800-2222-333">1800-2222-333</a>
                )}
              </p>
            </div>

            {location.state &&
              location.state.application &&
              location.state.application.domain === 'residential' && (
                <>
                  {!location.state.nanl &&
                    oaMode === 'normal' &&
                    location.state.application.type === 'personal' && (
                      <AdBanner to="https://www.hlas.com.sg/homesafe">
                        <picture>
                          <source
                            media="(max-width: 767px)"
                            srcSet="/images/hla-banner-mobile.jpg"
                          />
                          <img src="/images/hla-banner.jpg" alt="HLA banner" />
                        </picture>
                      </AdBanner>
                    )}

                  {oaMode !== 'kiosk' && (
                    <AdBanner to="https://www.spgroup.com.sg/faq?getQues=how-to-submit-meter-reading&fbclid=IwAR2IOqbWt7ruy2SMfubqZwDp0vzS1Kv_pKiywJAcv2nD5oyfd5qEa1HAyyc">
                      <picture>
                        <source
                          media="(max-width: 767px)"
                          srcSet="/images/ebill-meter-banner-mobile.png"
                        />
                        <img
                          src="/images/ebill-meter-banner.png"
                          alt="eBill Meter banner"
                        />
                      </picture>
                    </AdBanner>
                  )}
                </>
              )}
          </div>
        </section>
      </Fragment>
    )
  }

  _checkStatus = () => {
    const { location, history } = this.props
    if (location.state) {
      if (!location.state.success) {
        history.push('/')
      }
    } else {
      history.push('/')
    }
  }
}

export default ThankYou

import React from 'react'
import PropTypes from 'prop-types'

const FormCTA = ({ label, children }) => {
  return (
    <div className="lm--formItem lm--formItem--inline form-cta">
      <div className="lm--formItem-label">{label}</div>
      {children}
    </div>
  )
}

FormCTA.defaultProps = {
  label: '',
}

FormCTA.propTypes = {
  label: PropTypes.string,
}

export default FormCTA

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Container extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeTabId: props.defaultActive,
    }
  }
  render() {
    const { children } = this.props
    const { activeTabId } = this.state
    const tabNavItems = this._getNavItems()
    return (
      <div className="app-tabs">
        <nav className="tabs--nav">
          <ul>{tabNavItems}</ul>
        </nav>
        <div className="tabs--content">
          {children.filter((child) => {
            return child.props.id === activeTabId
          })}
        </div>
      </div>
    )
  }
  _getNavItems() {
    const { children } = this.props
    const { activeTabId } = this.state
    const items = []
    for (let child in children) {
      if (children[child].type.displayName === 'Tab') {
        items.push(children[child])
      }
    }
    return items.map((item, i) => {
      return (
        <li
          key={`tab-navitem--${i}`}
          className={`tabs--nav-item ${
            activeTabId === item.props.id ? 'is-active' : ''
          }`}
        >
          <button
            onClick={() => {
              this._navItemClickHandler(item.props.id)
            }}
          >
            {item.props.label}
          </button>
        </li>
      )
    })
  }
  _navItemClickHandler = (id) => {
    this.setState((prevState) => ({
      ...prevState,
      activeTabId: id,
    }))
  }
}
Container.displayName = 'Tabs'
Container.propTypes = {
  defaultActive: PropTypes.string.isRequired,
}

class Item extends PureComponent {
  render() {
    const { children } = this.props
    return <div className="tab-item">{children}</div>
  }
}

Item.displayName = 'Tab'
Item.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default {
  Container,
  Item,
}

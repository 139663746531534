import React from 'react'
import PropTypes from 'prop-types'

const FormGroup = ({
  groupTitle,
  groupField,
  groupHeaderContent,
  children,
  className,
}) => {
  return (
    <section className={`u-mb4 form-group ${className}`}>
      {groupTitle && (
        <header>
          <div className="lm--formItem lm--formItem--inline">
            <div className="lm--formItem-label subtitle">{groupTitle}</div>
            {groupField && (
              <div className="lm--formItem-control">{groupField}</div>
            )}
            {groupHeaderContent && (
              <div className="head-content">{groupHeaderContent}</div>
            )}
          </div>
        </header>
      )}
      {children}
    </section>
  )
}

FormGroup.defaultProps = {
  groupTitle: '',
  className: '',
}

FormGroup.propTypes = {
  groupTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
}

export default FormGroup

import React from 'react'
import classnames from 'classnames'
import FormErrorBlock from './FormErrorBlock'

const FormCheckbox = ({
  id,
  label,
  error,
  value,
  name,
  handleChange,
  text,
  textLink,
  onlyElem,
  className,
  children,
  ...props
}) => {
  const _FormCheckbox = classnames(`lm--checkbox ${className}`, {
    'is-error': error,
    'is-disabled': props.disabled,
  })
  let labelText
  if (textLink) {
    labelText = '<a href=' + textLink + ' target=_blank>' + text + '<a>'
  } else {
    labelText = text
  }
  if (onlyElem) {
    return (
      <div className={_FormCheckbox}>
        <input
          type="checkbox"
          className="lm--checkbox-input"
          value={value}
          id={id}
          name={name}
          onChange={handleChange}
          {...props}
        />
        <label htmlFor={id} className="btn--oa lm--checkbox-label">
          {children ? (
            <div>{children}</div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: labelText }} />
          )}
        </label>
        <FormErrorBlock id={`${id}-field_error`} error={error} />
      </div>
    )
  }
  return (
    <div className="lm--formItem lm--formItem--inline u-mb4">
      {label ||
        (label === '' && <div className="lm--formItem-label">{label}</div>)}
      <div className="lm--formItem-control">
        <div className={`lm--checkbox ${error ? 'is-error' : ''} ${className}`}>
          <input
            type="checkbox"
            className="lm--checkbox-input"
            value={value}
            id={id}
            name={name}
            onChange={handleChange}
            {...props}
          />
          <label htmlFor={id} className="btn--oa lm--checkbox-label">
            {children ? (
              <div>{children}</div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: labelText }} />
            )}
          </label>
          <FormErrorBlock id={`${id}-field_error`} error={error} />
        </div>
      </div>
    </div>
  )
}

FormCheckbox.defaultProps = {
  className: '',
}

export default FormCheckbox

import React, { PureComponent } from 'react'
import FormErrorBlock from './FormErrorBlock'

class FormSelectOption extends PureComponent {
  state = {
    error: null,
  }
  render() {
    const {
      label,
      id,
      name,
      error,
      value,
      onChange,
      children,
      options,
      ...props
    } = this.props
    const { error: stateError } = this.state
    let val = value
    // if (typeof value === 'string') {
    //   val = val.toLowerCase()
    // }
    return (
      <div
        className={
          'lm--formItem lm--formItem--inline' + (stateError ? ' is-error' : '')
        }
      >
        {label && <div className="lm--formItem-label">{label}</div>}
        <div className="lm--formItem-control">
          <select
            className="lm--select"
            id={id}
            name={name}
            value={val}
            placeholder={props.placeholder}
            onChange={onChange}
            onBlur={this._blurHandler}
            disabled={props.disabled}
          >
            {options.map((option) => {
              let op_v = option.value
              if (typeof option.value === 'string') {
                op_v = op_v.toLowerCase()
              }
              return (
                <option
                  disabled={option.disabled}
                  value={option.id || op_v}
                  key={option.value || option.text}
                >
                  {option.text || option.value}
                </option>
              )
            })}
          </select>
          {stateError && (
            <FormErrorBlock id={`${id}-field_error`} error={stateError} />
          )}
        </div>
      </div>
    )
  }

  _blurHandler = (e) => {
    const { handleBlur, error } = this.props

    if (handleBlur) {
      handleBlur(e)
    }
    if (error) {
      this.setState((prevState) => ({
        ...prevState,
        error,
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: null,
      }))
    }
  }
}

export default FormSelectOption

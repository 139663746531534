import React, { Fragment, PureComponent } from 'react'
import { Formik } from 'formik'
import { boolean, date, mixed, object, ref, string } from 'yup'
import classnames from 'classnames'

import { TurnOnServices } from 'spd-oa/components'
import { FormCTA } from 'spd-oa/components/FormElement'
import {
  Button,
  ConfirmationDialog,
  Detail,
  Modal,
  Summary,
  TermsConditions,
  TermsControl,
} from 'spd-oa/components/common'
import Utils from 'spd-oa/utils'
import { API } from 'spd-oa/services'
import { isEmpty } from 'spd-oa/helpers'
import { kioskConfig } from 'spd-oa/components/KioskUpload/kioskUploadHelpers'

const SESSION_STATES_KEY = kioskConfig.SESSION_STATES_KEY
const UPLOAD_STATUS = kioskConfig.UPLOAD_STATUS

const schema = object().shape({
  check1: boolean(),
  check2: boolean(),
  check3: boolean(),

  check_i_confirm: boolean()
    .required()
    .oneOf([true], 'Must agree to the Terms and Conditions'),

  meter_readings: boolean(),
  electric_meter_reading: string(),
  water_meter_reading: string(),
  gas_meter_reading: string(),
  hla: boolean(),

  turn_on_datetime: mixed().when(
    ['turn_on_time', 'timeslot_required', 'timeslot_allowed'],
    {
      is: (time, timeIsRequired, timeIsAllowed) => {
        if (!timeIsAllowed) {
          return false
        }
        if (!timeIsRequired) {
          return false
        }
        return true
      },
      then: mixed()
        .required()
        .label('Turn On Date and Time'),
      otherwise: date()
        .required()
        .label('Turn On Date'),
    }
  ),
  npop: string().when('nanl', {
    is: true,
    then: string()
      .required()
      .label('Proof of Premise'),
    otherwise: string().notRequired(),
  }),
  turn_on_date: date(),
  turn_on_time: mixed(),

  timeslot_required: boolean(),
  timeslot_allowed: boolean(),

  isPooRequired: boolean(),
  poo: string()
    .nullable()
    .when('isPooRequired', {
      is: true,
      then: string()
        .required()
        .label('Proof of Ownership'),
      otherwise: string().notRequired(),
    }),

  check_eeg: boolean(),
  gst_reg_no: string().when('check_eeg', {
    is: true,
    then: string()
      .matches(
        /^[a-zA-Z0-9-/&]+$/,
        'GST Reg No. accepts dash, slash, ampersand, alphabets and numbers'
      )
      .required()
      .label('GST Reg No.'),
    otherwise: string().notRequired(),
  }),
  gst_reg_date: date().when('check_eeg', {
    is: true,
    then: date()
      .required()
      .label('GST Valid Reg. Date'),
    otherwise: date().notRequired(),
  }),
  ebill: boolean(),
  ebill_otp: string().when(['ebill', 'nanl'], {
    is: (ebill, nanl) => !nanl && ebill,
    then: string()
      .required('OTP')
      .matches(/^[0-9]{6}$/),
    otherwise: string(),
  }),
  ebill_verified: boolean().when(['ebill', 'nanl'], {
    is: (ebill, nanl) => !nanl && ebill,
    then: boolean().required(),
    otherwise: boolean(),
  }),
  kioskMode: boolean(),
})

class Confirmation extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      check_i_confirm: false,
      check1: false,
      check2: false,
      check3: false,
      hla: false,
      meter_readings: false,
      electric_meter_reading: '',
      water_meter_reading: '',
      gas_meter_reading: '',
      turn_on_date: '',
      turn_on_time: '',
      turn_on_datetime: '',
      notification_type: '',
      meter_reading_required: false,
      poo: null,
      check_eeg: false,
      gst_reg_no: '',
      gst_reg_date: '',
    })

    context.actions.setFields(data)

    this.state = {
      data,
      confirm: false,
      showTerms: false,
      submitting: false,
      errors: {
        ebill_otp: null,
      },
      requiredDocs: [],
    }
  }

  _handleConfirm = (values) => {
    const { context } = this.props
    const {
      actions,
      data: contextData,
      config: contextConfig,
      stateId,
    } = context

    if (contextData.preTurnOn && !contextData.showPreTurnOnPrompt) {
      actions.presave({ showPreTurnOnPrompt: true })
    } else {
      this.setState((prevState) => ({
        ...prevState,
        submitting: true,
      }))
      if (actions.next) {
        const contact = {
          email: '',
          number: '',
        }
        if (contextConfig.application_type === 'personal') {
          contact.email = `${contextData.personal_information.email}`
          contact.number = `${contextData.personal_information.mobile_number}`
        } else if (contextConfig.application_type === 'company') {
          contact.email = `${contextData.representative.representative_email}`
          contact.number = `${contextData.representative.representative_mobile_number}`
        }
        actions.next(
          values,
          () => {
            this.setState((prevState) => ({
              ...prevState,
              submitting: false,
            }))
          },
          {
            nanl: contextData.premises.nanl,
            contact,
            application: {
              transaction_id: stateId,
              domain: contextConfig.application_domain,
              type: contextConfig.application_type,
              occupancy: contextData.premises.premise_occupancy,
            },
          }
        )
      }
    }
  }

  _setFieldValue = (key, value) => this._form.setFieldValue(key, value)

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (Utils.getOAMode().mode === 'kiosk') {
      sessionStorage.removeItem(SESSION_STATES_KEY.uploadStatus)
    } else {
      if (this._form.state && this._form.state.values) {
        this._preSave(this._form.state.values)
      }
    }
    actions.prev()
  }

  _preSave = (values) => {
    const { actions, data } = this.props.context
    if (actions.presave) {
      const payload = {
        ...values,
        check_i_confirm: false,
        turn_on_date: values.turn_on_date,
        turn_on_time: values.turn_on_time,
        turn_on_datetime: values.turn_on_datetime,
        turnOnServices: {
          ...data.turnOnServices,
          bypassZCGas: false,
        },
        zcGasDate: '',
        zczzDate: '',
      }
      actions.presave(payload)
    }
  }

  _termsOkHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: false,
    }))
  }

  _preTurnOnContinueHandler = () => {
    const {
      state: { values },
    } = this._form
    this._handleConfirm(values)
  }

  _termsShowHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: true,
    }))
  }

  _termsCheckHandler = (e) => {
    const checked = e.target.checked
    const { context } = this.props
    const { config: contextConfig } = context
    const {
      handleChange,
      setFieldTouched,
      state: { values },
    } = this._form
    let ebillError = null
    setFieldTouched('ebill_otp', false)
    if (checked) {
      if (contextConfig.application_type === 'personal') {
        if (!values.ebill_verified) {
          ebillError = 'Please enter the OTP to verify your email address.'
        }
        if (!values.ebill_requested) {
          ebillError = 'Please request the OTP to verify your email address.'
        }
        if (values.ebill_requested || !values.ebill_verified) {
          setFieldTouched('ebill_otp', true)
        }
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ebill_otp: ebillError,
      },
    }))
    handleChange(e)
  }

  componentDidMount() {
    const {
      context: { actions: contextActions },
    } = this.props

    contextActions.presave({
      preTurnOn: false,
      showPreTurnOnPrompt: false,
    })
    Utils.getAttachments(
      this.props.context.data,
      this.props.context.config,
      this._form,
      this._preSave
    ).then(() =>
      this.setState({
        requiredDocs: Utils.getRequiredDocs(
          {
            ...this.props.context.data,
            ...this.props.context.config,
          },
          this._form.state.values
        ),
      })
    )
  }

  componentDidUpdate() {
    this._form.validateForm()
  }

  render() {
    const { context } = this.props
    const {
      data: contextData,
      config: contextConfig,
      shared: contextShared,
      actions: contextActions,
      stateId,
    } = context
    const {
      data,
      confirm,
      showTerms,
      submitting,
      errors: stateErrors,
      requiredDocs,
    } = this.state
    const { mode: oaMode } = Utils.getOAMode()

    return (
      <div
        className={classnames('signup--confirmation signup--form', {
          'existing-uportal-account': contextData.linked_email,
        })}
      >
        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          enableReinitialize={true}
          initialValues={{
            ...data,
            turn_on_time: '',
            turn_on_date: '',
            turn_on_datetime: '',
            timeslot_required: false,
            timeslot_allowed: contextData.timeslot_allowed,
            isPooRequired: contextData.isPooRequired,
            nanl: contextData.premises.nanl,
            ebill:
              contextConfig.application_type === 'personal' &&
              oaMode !== 'kiosk',
            enable_paper_bill: false,
            ebill_requested: false,
            ebill_verified: false,
            bill_mode: oaMode !== 'kiosk' && ['bill_mode_ebill'],
            kioskMode: oaMode === 'kiosk',
          }}
          onSubmit={this._handleConfirm}
          render={({
            values,
            touched,
            errors,
            isValid,
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => (
            <form
              className="form-container"
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              onSubmit={handleSubmit}
            >
              <Summary contextConfig={contextConfig} data={contextData} />

              <Detail.DetailNode>
                <TurnOnServices
                  stateId={stateId}
                  contextData={contextData}
                  contextShared={contextShared}
                  contextActions={contextActions}
                  contextConfig={contextConfig}
                  handleSubmitMeterCheck={this._handleSubmitMeterCheck}
                  handleEegCheck={this._handleEegCheck}
                  requiredDocs={requiredDocs}
                  preSave={contextActions.presave}
                  homeContent={
                    oaMode !== 'mobile' &&
                    contextConfig.application_domain === 'residential' &&
                    contextConfig.application_type === 'personal'
                  }
                  formProps={{
                    touched,
                    errors: {
                      ...errors,
                      ebill_otp: stateErrors.ebill_otp
                        ? stateErrors.ebill_otp
                        : errors.ebill_otp,
                    },
                    values,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    handleChange,
                  }}
                />
              </Detail.DetailNode>
              <Detail.DetailNode>
                <div className="detail_section--checks">
                  <div className="u-grid">
                    <div className="col-md-12 col-sm-12 u-cell">
                      <TermsControl
                        id="check_i_confirm"
                        name="check_i_confirm"
                        handleChange={this._termsCheckHandler}
                        value={values['check_i_confirm']}
                        byClickMessage={`By checking this box, ${
                          contextConfig.application_type === 'personal'
                            ? 'I:'
                            : 'the Company:'
                        }`}
                      >
                        {contextData.use_myinfo ? (
                          <ol>
                            <li>
                              agree to the{' '}
                              <Button
                                link={true}
                                onClickHandler={this._termsShowHandler}
                              >{`Terms and Conditions`}</Button>
                              .
                            </li>
                            {contextConfig.application_type === 'personal' ? (
                              <Fragment>
                                <li>
                                  {`confirm that the information and/or data that I provided or made available to SP Services Limited (“SPS”) in this application (including any information or data from the Myinfo database (“Myinfo Data”)) are true, accurate and complete, and that I am the owner or tenant of the premises;`}
                                </li>
                                <li>
                                  {`agree to indemnify and hold harmless SPS and/or its affiliates from any losses, damages, claims, costs or penalties arising from or in connection with any information and/or data that I provided or made available to SPS in this application (including any Myinfo Data); and`}
                                </li>
                                <li>
                                  {`acknowledge that I am required to engage a licensed electrical worker (“LEW”) to inspect and test my electrical installation if I have made any alteration or rewiring to it. Thereafter the LEW will lodge a copy of the Certificate of Compliance (“CoC”) together with this application to SPS to check my electrical installation to determine if the electrical installation is exempted from an Electrical Installation Licence.`}
                                </li>
                                <li>
                                  {`acknowledge that I am required to engage a licensed plumber (LP) to submit the notification for water service installation (WSI) work if I have made any addition or alteration to existing piping and ensure the water meter is not removed or lost during the works.`}
                                </li>
                                <li>
                                  {`am aware that it is an offence under the Public Utilities Act (CAP 261) to make any false statement, representation or declaration in connection with the application for water supplied by the Public Utilities Board.`}
                                </li>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <li>
                                  {`hereby authorises the Corppass User to represent the Company to effect and transact on all matters arising out of and/or in connection with the utilities account for the above-mentioned premises;`}
                                </li>
                                <li>
                                  {`confirm that the information and/or data that the Company provided or made available to SP Services Limited (“SPS”) in this application (including any information or data from the Myinfo database (“Myinfo Data”) are true, accurate and complete, and the Company is the owner or tenant of the premises;`}
                                </li>
                                <li>
                                  {`agree to indemnify and hold harmless SPS and/or its affiliates from any losses, damages, claims, costs or penalties arising from or in connection with any information and/or data that the Company provided or made available to SPS in this application (including any Myinfo Data); and`}
                                </li>
                                <li>
                                  {`acknowledge that the Company is required to engage a licensed electrical worker (“LEW”) to inspect and test the electrical installation if the Company have made any alteration or rewiring to it. Thereafter the LEW will lodge a copy of the Certificate of Compliance (“CoC”) together with this application to SPS to check the electrical installation to determine if the electrical installation is exempted from an Electrical Installation License.`}
                                </li>
                                <li>
                                  {`acknowledge that the Company is required to engage a licensed plumber (LP) to submit the notification for water service installation (WSI) work if the Company have made any addition or alteration to existing piping and ensure the water meter is not removed or lost during the works.`}
                                </li>
                                <li>
                                  {`is aware that it is an offence under the Public Utilities Act (CAP 261) to make any false statement, representation or declaration in connection with the application for water supplied by the Public Utilities Board.`}
                                </li>
                              </Fragment>
                            )}
                          </ol>
                        ) : (
                          <ol>
                            <li>
                              agree to the{' '}
                              <Button
                                link={true}
                                onClickHandler={this._termsShowHandler}
                              >{`Terms and Conditions`}</Button>
                              .
                            </li>
                            <li>
                              {`confirm that the information and/or data that I provided or made available to SP Services Limited (“SPS”) in this application are true, accurate and complete, and that I am the owner or tenant of the premises;`}
                            </li>
                            <li>
                              {`accept that SPS does not endorse and is not responsible for any information and/or data that I provided or made available to SPS in this application, and shall not be liable for any errors, inaccuracies or omissions in such information and/or data;`}
                            </li>
                            <li>
                              {`agree to indemnify and hold harmless SPS and/or its affiliates from any losses, damages, claims, costs or penalties arising from or in connection with any information and/or data that I provided or made available to SPS in this application;`}
                            </li>
                            <li>
                              {`consent and agree to SPS collecting and using any information or data that I provide or make available to SPS for the following purposes:`}
                              <ol>
                                <li>
                                  {`to sign me up as its customer and set up my utilities account;`}
                                </li>
                                <li>{`to verify my identity;`}</li>
                                <li>{`to provide me with services/ goods;`}</li>
                                <li>
                                  {`to contact me for any of the above purposes; and`}
                                </li>
                                <li>
                                  {`any other purpose that is incidental and/or ancillary to the above stated purposes`}
                                </li>
                              </ol>
                            </li>
                            <li>
                              {`acknowledge that I am required to engage a licensed electrical worker (“LEW”) to inspect and test my electrical installation if I have made any alteration or rewiring to it. Thereafter the LEW will lodge a copy of the Certificate of Compliance (“CoC”) together with this application to SPS to check my electrical installation to determine if the electrical installation is exempted from an Electrical Installation Licence.`}
                            </li>
                            <li>
                              {`acknowledge that I am required to engage a licensed plumber (LP) to submit the notification for water service installation (WSI) work if I have made any addition or alteration to existing piping and ensure the water meter is not removed or lost during the works.`}
                            </li>
                            <li>
                              {`am aware that it is an offence under the Public Utilities Act (CAP 261) to make any false statement, representation or declaration in connection with the application for water supplied by the Public Utilities Board.`}
                            </li>
                          </ol>
                        )}
                      </TermsControl>
                    </div>
                  </div>
                </div>
              </Detail.DetailNode>

              <FormCTA>
                <Button
                  variant="secondary"
                  onClickHandler={this._handleBackBtn}
                  disabled={submitting}
                >
                  {`Back`}
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    (!contextData.premises.nanl && !values['turn_on_date']) ||
                    (values['timeslot_required'] &&
                      values['turn_on_datetime'] &&
                      (!values['turn_on_datetime'].start ||
                        !values['turn_on_datetime'].end)) ||
                    // !Utils.isMeterReadingsOk(
                    //   values.meter_readings,
                    //   contextData.turnOnServices.services,
                    //   values
                    // ) ||
                    !Utils.isNricUploaded(contextData) ||
                    !isEmpty(errors) ||
                    !isValid ||
                    submitting
                  }
                  loading={submitting}
                >
                  {`Submit`}
                </Button>
              </FormCTA>
            </form>
          )}
        />
        {confirm && (
          <ConfirmationDialog
            confirmOkHandler={this._confirmationOkHandler}
            confirmCancelHandler={this._confirmationCloseHandler}
            onCloseHandler={this._confirmationCloseHandler}
            onEscapeKeyDownHandler={this._confirmationCloseHandler}
            title={`Confirm`}
            content={`Do you want to submit your application?`}
          />
        )}
        {showTerms && (
          <Modal
            onClose={this._termsOkHandler}
            onEscapeKeyDown={this._termsOkHandler}
            title={`Terms and Conditions`}
            closeBtn={Utils.getOAMode().mode === 'kiosk'}
            className="modal-terms"
          >
            <section className="terms-container">
              <TermsConditions />
            </section>
          </Modal>
        )}
        {contextData.showPreTurnOnPrompt && (
          <ConfirmationDialog
            confirmOkHandler={this._preTurnOnContinueHandler}
            actionConfig={{
              cancel: {
                label: '',
              },
              ok: {
                label: 'Continue',
              },
            }}
            processing={submitting}
          >
            <span style={{ textAlign: 'center' }}>
              New HDB Build-To-Order (BTO) premises have ongoing supplies. You
              do not need to be present during the turn-on date
            </span>
          </ConfirmationDialog>
        )}
      </div>
    )
  }

  _handleSubmitMeterCheck = (e) => {
    this._form.setFieldValue('meter_readings', e.target.checked)
    const newFormState = {
      ...this._form.state.values,
      meter_readings: e.target.checked,
    }
    this.setState({
      requiredDocs: Utils.getRequiredDocs(
        {
          ...this.props.context.data,
          ...this.props.context.config,
        },
        newFormState
      ),
    })
    if (!e.target.checked) {
      let attachments = []
      if (this._form.state.values.electric_meter_reading)
        attachments.push('meter_electric')
      if (this._form.state.values.water_meter_reading)
        attachments.push('meter_water')
      if (this._form.state.values.gas_meter_reading)
        attachments.push('meter_gas')

      Utils.deleteAttachments(attachments, this._form)
    }
  }

  _handleEegCheck = (e) => {
    this._form.setFieldValue('check_eeg', e.target.checked)
    const newFormState = {
      ...this._form.state.values,
      check_eeg: e.target.checked,
    }
    this._preSave(newFormState)
    this.setState({
      requiredDocs: Utils.getRequiredDocs(
        {
          ...this.props.context.data,
          ...this.props.context.config,
        },
        newFormState
      ),
    })

    if (!e.target.checked) {
      let attachments = []
      this._form.setFieldValue('gst_reg_no', '')
      this._form.setFieldValue('gst_reg_date', '')
      this._preSave({
        ...this._form.state.values,
        check_eeg: false,
        gst_reg_no: '',
        gst_reg_date: '',
      })
      Utils.deleteAttachments(attachments, this._form)
    }
  }
}

export default Confirmation

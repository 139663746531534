import {
  flow,
  getFlow,
  updateFlow,
  resetFlow,
  myInfoUsage,
  indexPages,
  modesFor,
  externalAppConfig,
} from './signup-flow'

import confirmationFields from './confirmation/index.js'
import printFields from './print/index.js'
import submissionFields from './submission/index.js'

export default {
  flow,
  getFlow,
  updateFlow,
  resetFlow,
  myInfoUsage,
  confirmationFields,
  printFields,
  submissionFields,
  indexPages,
  modesFor,
  externalAppConfig,
}

import React, { Component, PureComponent } from 'react'

const Gfx = (GfxComponent) => {
  return class extends Component {
    static defaultProps = {
      stroke: 'none',
    }
    render() {
      const { width, height, ...rest } = this.props
      return (
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${this.props.width} ${this.props.height}`}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <GfxComponent {...rest} />
        </svg>
      )
    }
  }
}

class Wave extends PureComponent {
  render() {
    return (
      <g
        className="icon-plans"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(0.000000, -912.000000)" fill="#48DCC0">
          <g transform="translate(-349.000000, 912.000000)">
            <path
              d="M418.780545,22.2393186 C389.238523,41.1535109 338.694809,51.4723299 286.924653,51.4723299 C257.525865,51.4723299 227.751087,48.1503258 201.181171,41.1878549 C174.315835,34.1442072 145.149803,30.7816147 117.138594,30.7816147 C68.6538692,30.7816147 23.6246657,40.8631477 0,59.402678 L0,149 L1476,149 C1476,149 1445.49115,125.068458 1408.20554,95.8198353 C1384.57192,77.2803051 1339.54271,67.1987721 1291.04904,67.1987721 C1263.03783,67.1987721 1233.8718,70.5613645 1207.00646,77.6018901 C1180.14112,84.6455378 1150.97509,88.0081303 1122.96388,88.0081303 C1074.47021,88.0081303 1029.44995,77.9265972 1005.81634,59.387067 C982.19167,40.850659 937.171419,30.769126 888.686694,30.769126 C860.675485,30.769126 831.518405,34.1317185 804.653069,41.1753662 C778.092105,48.1378371 748.299423,51.4629634 718.927491,51.4629634 C667.157335,51.4629634 616.64943,41.1472665 587.125312,22.2361964 C563.984061,7.41206546 533.475209,0 502.966357,0 C472.448553,0 441.930748,7.41206546 418.780545,22.2393186 Z"
              id="image_wave_1"
              opacity="0.300000012"
            ></path>
            <path
              d="M180,138 L1609,138 L1609,53.0087504 C1575.42699,66.0668265 1511.46188,73.1683898 1442.58082,73.1683898 C1402.78336,73.1683898 1361.3593,70.7995353 1323.18843,65.8380664 C1285.45133,60.9341 1243.13083,58.5914967 1201.38868,58.5914967 C1127.8447,58.5902466 1056.07191,65.8568172 1014.12734,79.179905 C981.255576,89.6203656 937.908513,94.841846 894.561449,94.841846 C851.214386,94.841846 807.860093,89.6203656 774.966641,79.1761548 C733.000379,65.8543171 661.184214,58.5852464 587.633009,58.5839964 C545.876398,58.5839964 503.570358,60.9253496 465.826026,65.8305661 C427.655162,70.7907849 386.216642,73.1596394 346.426409,73.1596394 C277.538122,73.1596394 213.573009,66.0593261 180,53 L180,138 Z"
              id="image_wave_2"
              opacity="0.200000003"
            ></path>
          </g>
        </g>
      </g>
    )
  }
}

export default {
  wave: Gfx(Wave),
}

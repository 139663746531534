import { get } from 'lodash'

export function getESignatureConfig() {
  const mode = window.sessionStorage.getItem('oa_mode') || 'normal'
  const redirect = window.sessionStorage.getItem('redirect') || ''

  // kiosk has two path: /payment and /menu.
  const isPathCorrect =
    mode === 'kiosk' ? /^\/payment/.test(new URL(redirect).pathname) : true

  if (signatureFlag().getFlag()[mode] && isPathCorrect) {
    return {
      enableESignature: true,
      customerHelperText: '[Click HERE to Sign]',
      spHelperText: '[Date Signed]',
      signedByCustomer: ['Signature'],
      signedBySP: ['Date'],

      reviewStepTitle: 'Review and Sign',
      reviewStepInstruction:
        'You can review your application details and enter your Signature before submission',
    }
  }

  return {
    enableESignature: false,
    signedBySP: ['Company Stamp (If available)'],
    reviewStepTitle: 'Review and Print',
    reviewStepInstruction:
      'You can print out a copy to review your application details with terms & conditions, sign and provide your company stamp (if available) before submission.',
  }
}

export function signatureFlag() {
  const FLAG_KEY = 'eSignature'
  const DEFAULT_FLAG = Object.freeze({
    kiosk: true,
    normal: false,
  })

  /**
   * @param appConfig {AppConfig}
   * @return {SignatureFlag}
   */
  function fromAppConfig(appConfig) {
    return {
      kiosk: get(appConfig, ['e-signature-kiosk', 'active']),
      normal: get(appConfig, ['e-signature-web', 'active']),
    }
  }

  /**
   * @param appConfig {AppConfig}
   */
  function setFlag(appConfig) {
    const flag = fromAppConfig(appConfig)

    // default for kiosk is true
    if (flag.kiosk === undefined) {
      flag.kiosk = true
    }

    window.sessionStorage.setItem(FLAG_KEY, JSON.stringify(flag))
  }

  /**
   * @return {SignatureFlag}
   */
  function getFlag() {
    const flag = JSON.parse(window.sessionStorage.getItem(FLAG_KEY))
    return flag || DEFAULT_FLAG
  }

  function clear() {
    window.sessionStorage.removeItem(FLAG_KEY)
  }

  return { setFlag, getFlag, clear }
}

/**
 * @typedef {'kiosk' | 'normal' | 'mobile'} OAMode
 *
 * @typedef {{ [key: OAMode]: boolean }} SignatureFlag
 */

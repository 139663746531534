import React, { PureComponent, Fragment } from 'react'
import moment from 'moment'

import { Menu as MenuComp, Icons } from 'spd-oa/components/common'

require('moment-timezone')

class TimePicker extends PureComponent {
  state = {
    from: '',
    to: '',
    selectedTime: '',
    selected: '',
    showPicker: false,
  }

  componentDidMount() {
    const { timeslot, onSelectHandler, id } = this.props
    if (timeslot.length === 1) {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedTime: timeslot[0].time_hash,
          selected: this._getRangeValue({
            start: timeslot[0].start,
            end: timeslot[0].end,
          }),
        }),
        () => {
          if (onSelectHandler) {
            onSelectHandler(id, timeslot[0].time_hash)
          }
        }
      )
    }
  }

  render() {
    const { children, isRange, id, disabled, loading } = this.props
    const { from, to, selectedTime, selected, showPicker } = this.state
    return (
      <Fragment>
        <button
          id={`${id}-btn`}
          disabled={disabled}
          type={'button'}
          ref={(el) => (this._targetElem = el)}
          onClick={this.handleOpenPicker}
          className="btn--oa lm--button lm--button-picker timepicker"
        >
          <Icons.time width={13} height={13} />
          {loading ? (
            <Icons.spinloader
              className="loader"
              width={20}
              height={20}
              color={['#333333']}
            />
          ) : children && typeof children === 'function' ? (
            children({
              to,
              from,
              selectedTime,
              selected,
            })
          ) : (
            `${selected !== '' ? selected : 'Time'}`
          )}
        </button>
        {showPicker && (
          <MenuComp.Menu
            id={`${id}-tp`}
            className="app-timepicker"
            show={true}
            onCloseHandler={this.handleClosePicker}
            onEscapeKeyDownHandler={this.handleClosePicker}
            targetNode={this._targetElem}
            arrow={false}
          >
            <MenuComp.MenuGroup>
              {isRange ? (
                this._renderTimeslot()
              ) : (
                <Fragment>{this._renderTimeslot()}</Fragment>
              )}
            </MenuComp.MenuGroup>
          </MenuComp.Menu>
        )}
      </Fragment>
    )
  }

  _renderTimeslot() {
    const { timeslot, id } = this.props
    const { selectedTime } = this.state
    if (timeslot.length === 0) return null
    return timeslot.map((time, i) => {
      const timeLabel = this._getRangeValue({
        start: time.start,
        end: time.end,
      })
      const timeVal = time.time_hash ? time.time_hash : timeLabel
      return (
        <MenuComp.MenuItem
          key={`time-slot__${i}`}
          onClickHandler={this._timeSelectHandler}
          group={id}
          type={'button'}
          value={timeVal}
          label={timeLabel}
          active={timeVal === selectedTime}
        >
          {timeLabel}
        </MenuComp.MenuItem>
      )
    })
  }

  handleOpenPicker = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPicker: true,
    }))
  }
  handleClosePicker = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPicker: false,
    }))
  }

  _timeSelectHandler = ({ value, group, label }) => {
    const { onSelectHandler, isRange } = this.props
    const payload = {}
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedTime: value,
        selected: label,
      }),
      () => {
        if (onSelectHandler) {
          onSelectHandler(group, value)
        }
      }
    )
    this.handleClosePicker()
  }

  _getFormatRangeValue = ({ start, end, format = 'HH:mm' }) => {
    const { timezone } = this.props
    return {
      from: moment(start)
        .tz(timezone)
        .format(format),
      to: moment(end)
        .tz(timezone)
        .format(format),
    }
  }

  _getRangeValue = ({ start, end }) => {
    // let s = moment(start).format('HH:mm')
    // let e = moment(end).format('HH:mm')
    let time = this._getFormatRangeValue({ start, end })
    let s = time.from
    let e = time.to
    return `${s} - ${e}`
  }
}

TimePicker.defaultProps = {
  timeslot: [],
  timezone: 'Asia/Singapore',
}

export default TimePicker

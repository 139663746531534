import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'

const Loader = ({ text, icon }) => {
  return (
    <Modal withBackdrop={false}>
      <div className="app-loader">
        {icon && <figure className="loader-icon">{icon}</figure>}
        {text && text !== '' && <span className="loader-text">{text}</span>}
      </div>
    </Modal>
  )
}

Loader.defaultProps = {
  text: 'Loading...',
}

Loader.propTypes = {
  /**
   * Message to display on the loader eg. 'Loading..', 'Processing..'
   */
  text: PropTypes.string,
  /**
   * Graphic when loader is mounted, can be animated or static visual.
   */
  icon: PropTypes.object,
}

export default Loader

import React, { Fragment, PureComponent } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import moment from 'moment'
import debounce from 'lodash/debounce'

import {
  Button,
  Tooltip,
  ConfirmationDialog,
  Icons,
} from 'spd-oa/components/common'

import {
  FormCheckBox,
  FormDate,
  FormDateTime,
  FormInput,
  FormOtpInput,
  FormField,
  FormUpload,
} from 'spd-oa/components/FormElement'
import Utils from 'spd-oa/utils'
import { API } from 'spd-oa/services'
import {
  openLink,
  getFormFields,
  getElectCapacityRangeIdx,
  parseDate,
} from 'spd-oa/helpers'
import KioskUpload from './KioskUpload/KioskUpload'

require('moment-timezone')

// const publicHoliday = [new Date('2019-05-20'), new Date('2019-05-22')]

class TurnOnServices extends PureComponent {
  state = {
    dateslot: [],
    timeslot: [],
    turnOnAvailableDates: [],
    turnOnAvailableTimeslot: [],
    turnOnAvailableTimeslotFetching: false,
    turnOnAvailableTimeslotError: null,
    publicHolidays: [],
    confirmDialog: false,
    confirmEeg: false,
    ebillOtpRequesting: false,
    ebillOtpSent: false,
    ebillOtpAttempts: 0,
    ebillOtpMaxAttempts: 3,
    ebillVerifying: false,
    ebillVerified: false,
    errors: {
      ebill_otp_max: false,
      ebill_otp_error: null,
    },
    confirmExtLink: false,
    extLink: {
      path: '',
      target: '',
    },
    securityDeposit: {
      fields: [],
      isShowDependField: false,
      isLoading: false,
    },
    uploadIsDone: false,
  }

  componentDidMount() {
    const { contextActions } = this.props
    this.mounted = true
    this._fetchCalendarTime()
    this._fetchSDOptions()
    contextActions.presave({
      preferred_date: {},
      turn_on_datetime: '',
      turn_on_date: '',
      turn_on_time: '',
      turn_on_date_hash: '',
      turn_on_time_hash: '',
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { contextShared, contextData, formProps } = this.props
    const {
      contextShared: prevContextShared,
      contextData: prevContextData,
      formProps: prevFormProps,
    } = prevProps
    if (contextShared.refetch_calendar !== prevContextShared.refetch_calendar) {
      if (contextShared.refetch_calendar) {
        this._fetchCalendarTime()
      }
    }
    if (
      formProps.values['turn_on_date'] !==
        prevFormProps.values['turn_on_date'] ||
      contextData.zcGasDate !== prevContextData.zcGasDate
    ) {
      this._checkByPassGas()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const {
      stateId,
      contextData,
      contextConfig,
      contextShared,
      homeContent,
      formProps,
      handleSubmitMeterCheck,
      handleEegCheck,
      requiredDocs,
    } = this.props
    const {
      turnOnAvailableDates,
      turnOnAvailableTimeslot,
      turnOnAvailableTimeslotFetching,
      turnOnAvailableTimeslotError,
      publicHolidays,
      dateslot,
      timeslot,
      confirmDialog,
      confirmEeg,
      ebillOtpRequesting,
      ebillOtpSent,
      ebillVerifying,
      ebillVerified,
      errors,
      errCalendar,
      confirmExtLink,
      securityDeposit: { isLoading },
    } = this.state
    const { mode } = Utils.getOAMode()
    const shouldRenderGstCertUpload = () => {
      if (contextConfig.application_type === 'company') {
        if (contextData.use_myinfo) {
          return true
        }
      } else {
        // if type is Personal
        return true
      }
      return false
    }

    const isTurnOnAndTerminateSame = this._checkTurnOnAndTerminate()
    const terminationDate = this._getTerminateDate('DD MMM YYYY')

    const isGasSelectedFromPremises = this._checkGasSelectedFromPremises()

    let printArr = [...contextData.turnOnServices.services]
    if (contextData.turnOnServices && contextData.turnOnServices.bypassZCGas) {
      printArr = printArr.filter((serv) => serv !== 'gas')
    }
    if (contextData.premises.chilled_water)
      printArr.push('Centralised Cooling Services')

    return (
      <>
        <div className="detail_section--group-box">
          <div className="node-item--block">
            <h4>{`You have applied for`}</h4>
            <div>
              {contextData.turnOnServices &&
                contextData.turnOnServices.services &&
                Utils.printArray(printArr)}
            </div>
          </div>

          {contextConfig.application_domain === 'commercial' && (
            <div className="node-item--block">
              <h4>{`Type of usage`}</h4>
              <div>
                {contextData.turnOnServices &&
                  contextData.turnOnServices.services &&
                  this._getTypeOfUsage(
                    contextData.turnOnServices.type_of_usage
                  )}
              </div>
            </div>
          )}

          <div className="node-item--block">
            <h4>
              {!contextData.premises.nanl
                ? 'When would you like to turn on your utilities?'
                : 'For new/modified service connection(s), you are required to engage a Licensed Electrical Worker / Licensed Plumber / Licensed Gas Worker to take charge of your application. Your supply turn on date is managed by your Licensed Electrical Worker / Licensed Plumber / Licensed Gas Worker.'}
            </h4>
            <div>
              <FormDateTime
                id="turn_on_datetime"
                dateId="turn_on_date"
                timeId="turn_on_time"
                label={
                  <>
                    Choose your preferred
                    <Tooltip>
                      Please select your preferred date. (Subject to
                      availability)
                    </Tooltip>
                  </>
                }
                setFieldValue={this._setDateValue}
                disablePrev={true}
                availableDays={turnOnAvailableDates}
                dateProps={{
                  value: formProps.values['turn_on_date'],
                  disabled: turnOnAvailableDates.length === 0,
                }}
                publicHolidays={publicHolidays}
                timeslotProps={{
                  timeslot: turnOnAvailableTimeslot,
                  fetching: turnOnAvailableTimeslotFetching,
                  error: turnOnAvailableTimeslotError,
                }}
                // dateTimeValue={contextData['turn_on_datetime']}
                isRenderNote={!this._renderCalendarMessage(formProps)}
                retrieveTimeslotHandler={this._fetchTimeslot}
                retrieveTimeslotAgain={contextShared.refetch_timeslot}
                dateslot={dateslot}
                timeslot={timeslot}
                useHash={true}
                hashConfig={{
                  dateId: 'turn_on_date_hash',
                  timeId: 'turn_on_time_hash',
                }}
              >
                {this._renderCalendarMessage(formProps)}
              </FormDateTime>
            </div>
          </div>

          {mode !== 'kiosk' && formProps.values.isPooRequired && (
            <div className="node-item--block">
              <h4>{`You are required to upload additional document:`}</h4>

              {formProps.values.isPooRequired && (
                <div>
                  <FormUpload.Doc
                    max={4000000}
                    id="poo"
                    label={
                      contextConfig.application_domain === 'commercial' ||
                      contextData.gasPreSelected
                        ? 'Proof of Occupancy'
                        : 'Proof of Ownership'
                    }
                    setFieldValue={formProps.setFieldValue}
                    value={
                      formProps.values['poo'] && formProps.values['poo'] !== ''
                        ? [formProps.values['poo']]
                        : []
                    }
                    upload={true}
                    params={{
                      transaction_id: stateId,
                      type: 'proof_of_ownership',
                    }}
                    useFilename={true}
                    errors={formProps.errors}
                  />
                </div>
              )}
            </div>
          )}

          {/* {formProps.values.meter_reading_required === true &&
            contextConfig.application_domain !== 'commercial' &&
            (contextConfig.application_type !== 'company' ||
              contextData.use_myinfo) && (
              <div className="node-item--block">
                <h4>
                  {`Would you like to submit your meter readings?`}
                  <Tooltip>
                    <span>
                      An appointment is not required if clear photographs
                      showing the meter serial numbers and meter readings are
                      uploaded. A SMS will be sent to you if your meter readings
                      are accepted for the account opening.
                    </span>
                  </Tooltip>
                </h4>
                <div>
                  <FormCheckBox
                    onlyElem={true}
                    id="meter_readings"
                    text={`Yes, I would like to submit my meter readings`}
                    error={
                      formProps.touched.meter_readings &&
                      formProps.errors.meter_readings
                    }
                    checked={formProps.values.meter_readings}
                    handleChange={handleSubmitMeterCheck}
                  />
                  {formProps.values.meter_readings && (
                    <div className="check-grouped">
                      {mode !== 'kiosk' ? (
                        <Fragment>
                          {contextData.turnOnServices.services &&
                            contextData.turnOnServices.services.length > 0 && (
                              <Fragment>
                                <small>{`Please attach photographs for ${
                                  contextData.turnOnServices.services.length > 1
                                    ? `all your ${contextData.turnOnServices.services.length} supplies`
                                    : `your supply`
                                }.`}</small>
                                {contextData.turnOnServices.services.indexOf(
                                  'electricity'
                                ) !== -1 && (
                                  <FormUpload.Doc
                                    id="electric_meter_reading"
                                    label="Electricity"
                                    setFieldValue={formProps.setFieldValue}
                                    value={
                                      formProps.values[
                                        'electric_meter_reading'
                                      ] &&
                                      formProps.values[
                                        'electric_meter_reading'
                                      ] !== '' && [
                                        formProps.values[
                                          'electric_meter_reading'
                                        ],
                                      ]
                                    }
                                    upload={true}
                                    params={{
                                      transaction_id: stateId,
                                      type: 'meter_electric',
                                    }}
                                    useFilename={true}
                                    max={4000000}
                                    errors={formProps.errors}
                                  />
                                )}
                                {contextData.turnOnServices.services.indexOf(
                                  'water'
                                ) !== -1 && (
                                  <FormUpload.Doc
                                    id="water_meter_reading"
                                    label="Water"
                                    setFieldValue={formProps.setFieldValue}
                                    value={
                                      formProps.values.water_meter_reading &&
                                      formProps.values.water_meter_reading !==
                                        '' && [
                                        formProps.values.water_meter_reading,
                                      ]
                                    }
                                    upload={true}
                                    params={{
                                      transaction_id: stateId,
                                      type: 'meter_water',
                                    }}
                                    useFilename={true}
                                    max={4000000}
                                    errors={formProps.errors}
                                  />
                                )}
                                {contextData.turnOnServices.services.indexOf(
                                  'gas'
                                ) !== -1 && (
                                  <FormUpload.Doc
                                    id="gas_meter_reading"
                                    label="Gas"
                                    setFieldValue={formProps.setFieldValue}
                                    value={
                                      formProps.values.gas_meter_reading &&
                                      formProps.values.gas_meter_reading !==
                                        '' && [
                                        formProps.values.gas_meter_reading,
                                      ]
                                    }
                                    upload={true}
                                    params={{
                                      transaction_id: stateId,
                                      type: 'meter_gas',
                                    }}
                                    useFilename={true}
                                    max={4000000}
                                    errors={formProps.errors}
                                  />
                                )}
                              </Fragment>
                            )}
                        </Fragment>
                      ) : (
                        <small>
                          Please scan QR code at Required Documents Section to
                          submit your meter readings.
                        </small>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )} */}

          {!contextData.premises.nanl && (
            <div className="node-item--block">
              <h4>
                {`Are you GST registered with an embedded electricity generator (e.g. Solar panels) at your premises? Ignore if not applicable`}
              </h4>
              <div>
                <FormCheckBox
                  onlyElem={true}
                  id="check_eeg"
                  name="check_eeg"
                  text={`Yes, I/we am/are GST registered and have solar panel with generation capacity less than 1MW.`}
                  error={
                    formProps.touched.check_eeg && formProps.errors.check_eeg
                  }
                  checked={formProps.values.check_eeg}
                  handleChange={handleEegCheck}
                />
                {formProps.values.check_eeg && (
                  <div className="check-grouped">
                    <FormInput
                      label="GST Reg. No."
                      id="gst_reg_no"
                      error={
                        formProps.touched.gst_reg_no &&
                        formProps.errors.gst_reg_no
                      }
                      value={formProps.values.gst_reg_no}
                      handleBlur={this._handleBlurInput}
                      capsOnChange={true}
                      setFieldValue={this._setValue}
                    />
                    <FormDate
                      id="gst_reg_date"
                      label="GST Reg. Date"
                      error={
                        (formProps.touched.gst_reg_date &&
                          formProps.errors.gst_reg_date) ||
                        formProps.errors.gst_reg_date
                      }
                      setFieldValue={this._setValue}
                      value={formProps.values.gst_reg_date}
                      showLegend={false}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {!contextData.premises.nanl && homeContent && (
            <div className="node-item--block">
              <h4>{`Free for SP Services customers:`}</h4>
              <p>
                Up to $5,000 Free{' '}
                {Utils.getOAMode().mode !== 'kiosk' ? (
                  <>
                    <a
                      href="http://www.hlas.com.sg/home-starter-kit"
                      target="_blank"
                    >
                      Home Content Protection
                    </a>{' '}
                  </>
                ) : (
                  'Home Content Protection'
                )}{' '}
                and 2 months Electricity Bill Protector provided by HL Assurance
                Pte Ltd.
              </p>
              <div>
                <FormCheckBox
                  onlyElem={true}
                  id="hla"
                  error={formProps.touched.hla && formProps.errors.hla}
                  checked={formProps.values.hla}
                  handleChange={formProps.handleChange}
                >
                  Yes, please contact me for the sign-up*
                </FormCheckBox>
              </div>
              <small>
                * HL Assurance Pte Ltd will be in touch with you to assist with
                the sign-up. By checking this box, you consent to have your
                name, mobile number and email sent to HL Assurance Pte Ltd for
                the sign-up. Terms and conditions apply.
              </small>
            </div>
          )}
        </div>
        {contextConfig.application_domain === 'commercial' && (
          <>
            <div className="detail_section--group-box">
              <div className="node-item--block">
                <h4>{`Security Deposit Calculation`}</h4>
                <div>
                  {this.renderTypeOfPremisesFields({
                    ...formProps,
                    handleChange: this._handleChangeTypeOfPremises,
                  })}
                  <FormField
                    key={`security-dep`}
                    field="field-item--content"
                    label="Security Deposit"
                  >
                    {isLoading ? (
                      <Icons.spinloader
                        width={50}
                        height={50}
                        color={['#00b0b2']}
                      />
                    ) : (
                      <span>{`S$ ${formProps.values[
                        'security_deposit'
                      ].toLocaleString()}`}</span>
                    )}
                  </FormField>
                </div>
              </div>
            </div>
            {mode === 'kiosk' && requiredDocs.length > 0 ? (
              <KioskUpload
                stateId={stateId}
                requiredDocs={requiredDocs}
                preSave={this.props.preSave}
                setFormValue={formProps.setFieldValue}
                setUploadIsDone={this._handleUploadStatusChange}
              />
            ) : null}
            {Utils.getOAMode().mode !== 'kiosk' &&
              ((contextConfig.application_domain === 'commercial' &&
                contextConfig.application_type === 'company' &&
                contextData.use_myinfo) ||
                (contextConfig.application_domain === 'commercial' &&
                  contextConfig.application_type === 'personal')) && (
                <div className="detail_section--group-box">
                  <div className="node-item--block">
                    <>
                      <h4>{`You are required to upload additional documents:`}</h4>
                      <div className="additional-documents">
                        {contextData.turnOnServices.type_of_usage ===
                          'permanent' && (
                          <div>
                            <h5>
                              Documentary Proof of Occupancy of Premises (e.g.
                              Latest Property Tax or Tenancy Agreement)
                            </h5>
                            <FormUpload.Doc
                              max={4000000}
                              id="pooop"
                              label={null}
                              setFieldValue={formProps.setFieldValue}
                              value={
                                formProps.values['pooop'] &&
                                formProps.values['pooop'] !== ''
                                  ? [formProps.values['pooop']]
                                  : []
                              }
                              upload={true}
                              params={{
                                transaction_id: stateId,
                                type: 'proof_of_occupancy_of_premises',
                              }}
                              useFilename={true}
                              errors={formProps.errors}
                            />
                          </div>
                        )}
                        {contextData.premises.nanl && (
                          <div>
                            <Fragment>
                              {`Proof of Premises`}
                              <Tooltip>
                                <span>
                                  {`e.g. Certificate of Numbering
                              from IRAS, Certificate of Factory Registration
                              from MOM`}
                                </span>
                              </Tooltip>
                            </Fragment>
                            <FormUpload.Doc
                              max={4000000}
                              id="npop"
                              label={null}
                              setFieldValue={formProps.setFieldValue}
                              value={
                                formProps.values['npop'] &&
                                formProps.values['npop'] !== ''
                                  ? [formProps.values['npop']]
                                  : []
                              }
                              upload={true}
                              params={{
                                transaction_id: stateId,
                                type: 'nanl_proof_of_premise',
                              }}
                              useFilename={true}
                              errors={formProps.errors}
                            />
                          </div>
                        )}
                        {contextData.turnOnServices.type_of_usage ===
                          'permanent' &&
                          contextData.turnOnServices.services &&
                          contextData.turnOnServices.services.indexOf(
                            'electricity'
                          ) !== -1 && (
                            <div>
                              <h5>
                                <span>
                                  Signed Electrical Installation License Form
                                  (EIL) (Download from{' '}
                                  <Button
                                    link
                                    onClickHandler={this._linkHandler(
                                      'https://www.spgroup.com.sg/resources',
                                      '_blank',
                                      false
                                    )}
                                  >
                                    HERE
                                  </Button>
                                  ){' '}
                                  {!formProps.values['isEilRequired'] &&
                                    '(Optional)'}
                                </span>
                              </h5>
                              <FormUpload.Doc
                                max={4000000}
                                id="eil"
                                label={null}
                                setFieldValue={formProps.setFieldValue}
                                value={
                                  formProps.values['eil'] &&
                                  formProps.values['eil'] !== ''
                                    ? [formProps.values['eil']]
                                    : []
                                }
                                upload={true}
                                params={{
                                  transaction_id: stateId,
                                  type: 'electrical_installation_license_form',
                                }}
                                useFilename={true}
                                errors={formProps.errors}
                              />
                            </div>
                          )}
                        {contextData.premises.nanl && (
                          <div>
                            <Fragment>
                              {`Additional Supporting Document (Optional)`}
                              <Tooltip>
                                <span>
                                  {`Any other supporting documents for this premises`}
                                </span>
                              </Tooltip>
                            </Fragment>
                            <FormUpload.Doc
                              max={4000000}
                              id="nasd"
                              label={null}
                              setFieldValue={formProps.setFieldValue}
                              value={
                                formProps.values['nasd'] &&
                                formProps.values['nasd'] !== ''
                                  ? [formProps.values['nasd']]
                                  : []
                              }
                              upload={true}
                              params={{
                                transaction_id: stateId,
                                type: 'nanl_additional_supporting_documents',
                              }}
                              useFilename={true}
                              errors={formProps.errors}
                            />
                          </div>
                        )}
                        {contextData.turnOnServices.type_of_usage ===
                          'temporary' && (
                          <>
                            {contextData.turnOnServices.services &&
                              contextData.turnOnServices.services.length >
                                0 && (
                                <>
                                  <div>
                                    <h5>Letter of Award</h5>
                                    <FormUpload.Doc
                                      max={4000000}
                                      id="loawrd"
                                      label={null}
                                      setFieldValue={formProps.setFieldValue}
                                      value={
                                        formProps.values['loawrd'] &&
                                        formProps.values['loawrd'] !== ''
                                          ? [formProps.values['loawrd']]
                                          : []
                                      }
                                      upload={true}
                                      params={{
                                        transaction_id: stateId,
                                        type: 'letter_of_award',
                                      }}
                                      useFilename={true}
                                      errors={formProps.errors}
                                    />
                                  </div>
                                  {contextData.turnOnServices.services.indexOf(
                                    'electricity'
                                  ) !== -1 && (
                                    <div>
                                      <h5>
                                        {' '}
                                        SP PowerGrid Quotation (for temporary
                                        electricity supply){' '}
                                      </h5>
                                      <FormUpload.Doc
                                        max={4000000}
                                        id="sp_pwrgrd_quot"
                                        label={null}
                                        setFieldValue={formProps.setFieldValue}
                                        value={
                                          formProps.values['sp_pwrgrd_quot'] &&
                                          formProps.values['sp_pwrgrd_quot'] !==
                                            ''
                                            ? [
                                                formProps.values[
                                                  'sp_pwrgrd_quot'
                                                ],
                                              ]
                                            : []
                                        }
                                        upload={true}
                                        params={{
                                          transaction_id: stateId,
                                          type: 'sp_powergrid_quotation',
                                        }}
                                        useFilename={true}
                                        errors={formProps.errors}
                                      />
                                    </div>
                                  )}
                                  {contextData.turnOnServices.services.indexOf(
                                    'water'
                                  ) !== -1 && (
                                    <div>
                                      <Fragment>
                                        Water Quotation and Acknowledgement for
                                        Declaration on Temporary Sanitary
                                        Facilities/Toilets from PUB (for
                                        temporary water supply)
                                        <Tooltip>
                                          <span>
                                            Email acknowledgement from PUB for
                                            the Declaration on Temporary
                                            Sanitary Facilities/Toilets. Refer
                                            to PUB{' '}
                                            <Link
                                              to="#"
                                              onClick={this._linkHandler(
                                                'https://form.gov.sg/65adcc577d4e4a0012d46f80',
                                                '_blank'
                                              )}
                                            >
                                              website
                                            </Link>{' '}
                                            for more information.
                                          </span>
                                        </Tooltip>
                                      </Fragment>
                                      <FormUpload.Doc
                                        max={4000000}
                                        id="pub_wat_quot"
                                        label={null}
                                        setFieldValue={formProps.setFieldValue}
                                        value={
                                          formProps.values['pub_wat_quot'] &&
                                          formProps.values['pub_wat_quot'] !==
                                            ''
                                            ? [formProps.values['pub_wat_quot']]
                                            : []
                                        }
                                        upload={true}
                                        params={{
                                          transaction_id: stateId,
                                          type: 'pub_water_quotation',
                                        }}
                                        useFilename={true}
                                        errors={formProps.errors}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                          </>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              )}
          </>
        )}

        {mode === 'kiosk' &&
        contextConfig.application_domain === 'residential' &&
        requiredDocs.length > 0 ? (
          <KioskUpload
            stateId={stateId}
            requiredDocs={requiredDocs}
            preSave={this.props.preSave}
            setFormValue={formProps.setFieldValue}
          />
        ) : null}

        {mode !== 'kiosk' &&
          contextConfig.application_domain === 'residential' &&
          contextConfig.application_type !== 'company' && (
            <>
              {!contextData.premises.nanl && (
                <div className="detail_section--group-box">
                  <div className="node-item--block">
                    <h4>{`You will receive your bill summary via email.${
                      !contextData.linked_email
                        ? ' Your detailed bill will be sent to your mailing address.'
                        : ''
                    }`}</h4>
                    <FormOtpInput
                      label={''}
                      id="ebill_otp"
                      text={
                        contextData &&
                        contextData.personal_contact_information ? (
                          <small>
                            {!contextData.linked_email &&
                              contextData.ebillEligible && (
                                <>
                                  An online login account will be created for
                                  you to view your bills on SP app and SP
                                  Utilities Portal.
                                  <br />
                                </>
                              )}
                            {`Please request for OTP to verify your email address (${contextData.personal_contact_information.email}). `}
                            {!contextData.linked_email &&
                              contextData.ebillEligible && (
                                <>
                                  If the email address is incorrect, please{' '}
                                  <Button
                                    link
                                    onClickHandler={this._changeEmailHandler}
                                  >
                                    change the email
                                  </Button>
                                  .
                                </>
                              )}
                          </small>
                        ) : null
                      }
                      value={formProps.values.ebill_otp}
                      numberOfInputs={6}
                      handleChange={formProps.handleChange}
                      setFieldValue={formProps.setFieldValue}
                      error={
                        (errors.ebill_otp_max &&
                          'You have reached the maximum number of incorrect attempts for OTP verification. Please request for OTP again') ||
                        errors.ebill_otp_error ||
                        (!formProps.values.ebill_verified &&
                          formProps.touched.ebill_otp &&
                          ((!contextData.ebillEligible &&
                            !contextData.linked_email &&
                            'Please click on "Change Email" to enter new email address') ||
                            formProps.errors.ebill_otp))
                      }
                      autoComplete={'no'}
                      autoFocus
                      autoVerify
                      verifying={ebillVerifying}
                      requesting={ebillOtpRequesting}
                      verified={ebillVerified}
                      verifyHandler={this._verifyOtpEbill}
                      withRequestBtn
                      expiredTime={300}
                      requestTimeout={60}
                      requestHandler={this._requestOtpEbill}
                      ready={ebillOtpSent}
                      preRender={
                        contextData &&
                        contextData.personal_contact_information &&
                        !contextData.linked_email
                          ? !contextData.ebillEligible && (
                              <small>
                                {`An online login account will be created for you to view your bills on SP app and SP Utilities Portal.`}
                                <br />
                                {`${contextData.personal_contact_information.email} has been used in another utilities account. Please change your email. `}
                                <Button
                                  link
                                  onClickHandler={this._changeEmailHandler}
                                >
                                  Change Email
                                </Button>
                                .
                                <br />
                              </small>
                            )
                          : null
                      }
                      otpLabel={
                        contextData &&
                        contextData.personal_contact_information &&
                        ebillOtpSent &&
                        !ebillVerified &&
                        !errors.ebill_otp_max &&
                        !errors.ebill_otp_error && (
                          <small>{`Please enter the OTP sent to your email (${contextData.personal_contact_information.email})`}</small>
                        )
                      }
                    />
                    {!contextData.isNewCustomer && contextData.linked_email && (
                      <div>
                        <FormCheckBox
                          label={false}
                          onlyElem={true}
                          id="bill_mode_paper"
                          name="bill_mode_paper"
                          error={
                            formProps.touched.bill_mode_paper &&
                            formProps.errors.bill_mode_paper
                          }
                          disabled={!ebillVerified}
                          text={`I want to continue to receive paper bill`}
                          checked={formProps.values.bill_mode_paper}
                          handleChange={this._ebillCheckHandler}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {contextData.premises.nanl && (
                <div className="detail_section--group-box">
                  <div className="node-item--block">
                    <>
                      <h4>{`You are required to upload additional documents:`}</h4>
                      <>
                        <div>
                          <Fragment>
                            {`Proof of Premises`}
                            <Tooltip>
                              <span>
                                {`e.g. Certificate of Numbering
                              from IRAS, Certificate of Factory Registration
                              from MOM`}
                              </span>
                            </Tooltip>
                          </Fragment>
                          <FormUpload.Doc
                            max={4000000}
                            id="npop"
                            label={null}
                            setFieldValue={formProps.setFieldValue}
                            value={
                              formProps.values['npop'] &&
                              formProps.values['npop'] !== ''
                                ? [formProps.values['npop']]
                                : []
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'nanl_proof_of_premise',
                            }}
                            useFilename={true}
                            errors={formProps.errors}
                          />
                        </div>
                        <div>
                          <Fragment>
                            {`Additional Supporting Document (Optional)`}
                            <Tooltip>
                              <span>
                                {`Any other supporting documents for this premises`}
                              </span>
                            </Tooltip>
                          </Fragment>
                          <FormUpload.Doc
                            max={4000000}
                            id="nasd"
                            label={null}
                            setFieldValue={formProps.setFieldValue}
                            value={
                              formProps.values['nasd'] &&
                              formProps.values['nasd'] !== ''
                                ? [formProps.values['nasd']]
                                : []
                            }
                            upload={true}
                            params={{
                              transaction_id: stateId,
                              type: 'nanl_additional_supporting_documents',
                            }}
                            useFilename={true}
                            errors={formProps.errors}
                          />
                        </div>
                      </>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        {mode !== 'kiosk' &&
          contextConfig.application_domain === 'residential' &&
          contextConfig.application_type === 'company' &&
          contextData.use_myinfo &&
          contextData.premises.nanl && (
            <div className="detail_section--group-box">
              <div className="node-item--block">
                <>
                  <h4>{`You are required to upload additional documents:`}</h4>
                  <>
                    <div>
                      <Fragment>
                        {`Proof of Premises`}
                        <Tooltip>
                          <span>
                            {`e.g. Certificate of Numbering
                              from IRAS, Certificate of Factory Registration
                              from MOM`}
                          </span>
                        </Tooltip>
                      </Fragment>
                      <FormUpload.Doc
                        max={4000000}
                        id="npop"
                        label={null}
                        setFieldValue={formProps.setFieldValue}
                        value={
                          formProps.values['npop'] &&
                          formProps.values['npop'] !== ''
                            ? [formProps.values['npop']]
                            : []
                        }
                        upload={true}
                        params={{
                          transaction_id: stateId,
                          type: 'nanl_proof_of_premise',
                        }}
                        useFilename={true}
                        errors={formProps.errors}
                      />
                    </div>
                    <div>
                      <Fragment>
                        {`Additional Supporting Document (Optional)`}
                        <Tooltip>
                          <span>
                            {`Any other supporting documents for this premises`}
                          </span>
                        </Tooltip>
                      </Fragment>
                      <FormUpload.Doc
                        max={4000000}
                        id="nasd"
                        label={null}
                        setFieldValue={formProps.setFieldValue}
                        value={
                          formProps.values['nasd'] &&
                          formProps.values['nasd'] !== ''
                            ? [formProps.values['nasd']]
                            : []
                        }
                        upload={true}
                        params={{
                          transaction_id: stateId,
                          type: 'nanl_additional_supporting_documents',
                        }}
                        useFilename={true}
                        errors={formProps.errors}
                      />
                    </div>
                  </>
                </>
              </div>
            </div>
          )}

        {confirmExtLink && (
          <ConfirmationDialog
            confirmOkHandler={this._linkConfirmHandler}
            confirmCancelHandler={this._linkConfirmationCloseHandler}
            onCloseHandler={this._linkConfirmationCloseHandler}
            onEscapeKeyDownHandler={this._linkConfirmationCloseHandler}
            content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
            actionConfig={{
              cancel: {
                label: 'Cancel',
              },
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}
        {confirmDialog && (
          <ConfirmationDialog
            confirmOkHandler={this._homeStarterLinkConfirmHandler}
            confirmCancelHandler={this._homeStarterLinkConfirmationCloseHandler}
            onCloseHandler={this._homeStarterLinkConfirmationCloseHandler}
            onEscapeKeyDownHandler={
              this._homeStarterLinkConfirmationCloseHandler
            }
            content={`You are now leaving this website for a third party website.  Please review the new site's privacy and security policies as they may differ from those of this site.`}
            actionConfig={{
              cancel: {
                label: 'Cancel',
              },
              ok: {
                label: 'OK',
              },
            }}
          />
        )}
        {errCalendar && (
          <ConfirmationDialog
            id="general-err-prompt"
            confirmOkHandler={this._confirmCalendarErrorHandler}
            title={`Error`}
            content={errCalendar}
            processing={false}
            actionConfig={{
              cancel: false,
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}

        {confirmEeg && (
          <ConfirmationDialog
            confirmOkHandler={this._eegLinkConfirmHandler}
            confirmCancelHandler={this._eegLinkConfirmationCloseHandler}
            onCloseHandler={this._eegLinkConfirmationCloseHandler}
            onEscapeKeyDownHandler={this._eegLinkConfirmationCloseHandler}
            content={`You are now leaving this website for a third party website.  Please review the new site's privacy and security policies as they may differ from those of this site.`}
            actionConfig={{
              cancel: {
                label: 'Cancel',
              },
              ok: {
                label: 'OK',
              },
            }}
          />
        )}
      </>
    )
  }

  renderTypeOfPremisesFields = (formProps) => {
    const { securityDeposit } = this.state
    const { fields, isShowDependField, isLoading } = securityDeposit
    const lisfOfFields = fields
    const { contextData } = this.props
    const additionalCriteria = {
      ELECT_CAPACITY: [
        {
          parentID: 'services',
          parentCondition: 'VALUES_EITHER',
          parentValues: ['electricity'],
        },
      ],
      WATER_CAPACITY: [
        {
          parentID: 'services',
          parentCondition: 'VALUES_EITHER',
          parentValues: ['water'],
        },
      ],
    }
    const tooltips = {
      ELECT_CAPACITY:
        'Please check with your Licensed Electrical Worker (LEW) on the Supply Capacity.',
      WATER_CAPACITY:
        'Please refer to your water quotation from PUB for temporary water supply. For permanent water supply, please enter numeric “1”.',
      ...(contextData.turnOnServices.type_of_usage === 'permanent' && {
        USE_OF_PREMISES_LIST:
          'Please select “All Others” if your premises type is not in the list.',
      }),
    }

    if (
      lisfOfFields &&
      lisfOfFields.find((field) => field.id === 'USE_OF_PREMISES_LIST')
    ) {
      lisfOfFields.find(
        (field) => field.id === 'USE_OF_PREMISES_LIST'
      ).disabled = Utils.getOAMode().mode === 'kiosk' && this.state.uploadIsDone
    }

    const formFields = getFormFields(
      lisfOfFields,
      formProps,
      {
        additionalCriteria,
        isShowDependField,
        isLoading,
        defaultValue: null,
      },
      tooltips
    )
    return formFields
  }

  _renderCalendarMessage = (formProps) => {
    const { contextData } = this.props
    const { zcGasDate, zczzDate } = contextData
    const turnOnDate = moment(formProps.values['turn_on_date']).format(
      'YYYY-MM-DD'
    )
    const turnOnDateFormat = moment(turnOnDate).format('DD MMM YYYY')
    const zczzDateFormat = moment(zczzDate)
      .tz('Asia/Singapore')
      .format('DD MMM YYYY')
    const isGasSelectedFromPremises =
      contextData.turnOnServices &&
      contextData.turnOnServices.services.some((s) => s === 'gas')

    if (formProps.values['turn_on_date']) {
      if (formProps.values['turn_on_date'] && zcGasDate) {
        if (isGasSelectedFromPremises && turnOnDate > zcGasDate) {
          return `You have selected ${turnOnDateFormat}. For activation of your gas supply, please contact City Energy after receiving your new account number confirmation. City Energy fees apply.`
        }
      }

      if (zczzDate && turnOnDate >= zczzDate) {
        return `Any ongoing utilities at the premises will be discontinued on ${zczzDateFormat}. To prevent any disruption, please select ${zczzDateFormat} to take over the utilities.`
      }
    }

    return ''
  }

  _checkByPassGas = () => {
    const { formProps, contextActions, contextData } = this.props
    const turnOnDate = moment(formProps.values['turn_on_date']).format(
      'YYYY-MM-DD'
    )
    const isGasSelectedFromPremises =
      contextData.turnOnServices &&
      contextData.turnOnServices.services.some((s) => s === 'gas')

    const zcGasDate = contextData.zcGasDate

    if (isGasSelectedFromPremises && zcGasDate && turnOnDate > zcGasDate) {
      contextActions.presave({
        turnOnServices: {
          ...contextData.turnOnServices,
          bypassZCGas: true,
        },
      })
    } else {
      contextActions.presave({
        turnOnServices: {
          ...contextData.turnOnServices,
          bypassZCGas: false,
        },
      })
    }
  }
  _setValue = (group, value) => {
    const { formProps, contextActions } = this.props
    const { setFieldValue } = formProps
    contextActions.presave({
      [group]: value,
    })
    setFieldValue(group, value)
  }

  _handleBlurInput = (e) => {
    this.props.formProps.setFieldTouched(e.target.id, true)
  }

  _handleSetRequiredDocs = (value) => {
    const { contextData, contextConfig, setRequiredDocs } = this.props

    if (
      contextConfig.application_type === 'personal' ||
      contextData.use_myinfo
    ) {
      setRequiredDocs(value)
    }
  }

  _handleChangeTypeOfPremises = (e) => {
    const { id, value } = e.target
    const { formProps, contextData } = this.props
    const { mode } = Utils.getOAMode()

    const prevEilRequired = formProps.values.isEilRequired

    if (id === 'USE_OF_PREMISES_LIST') {
      if (prevEilRequired) {
        this._handleSetRequiredDocs(false)
      }
      formProps.setFieldValue('ELECT_CAPACITY', '')
      formProps.setFieldValue('WATER_CAPACITY', '')
      formProps.setFieldValue('NO_DAYS', '')
      formProps.setFieldValue('isEilRequired', false)
    }

    if (
      contextData.turnOnServices &&
      contextData.turnOnServices.type_of_usage === 'permanent'
    ) {
      if (id === 'ELECT_CAPACITY') {
        const eCRangeIdx = getElectCapacityRangeIdx({
          ELECT_CAPACITY: value,
        })
        const isEilRequired = eCRangeIdx && eCRangeIdx > 2
        if (!isEilRequired && mode !== 'kiosk') {
          this._deleteAttachments(['electrical_installation_license_form'])
        }
        setTimeout(() => {
          if (prevEilRequired !== isEilRequired) {
            this._handleSetRequiredDocs(isEilRequired)
          }
          formProps.setFieldValue('isEilRequired', isEilRequired)
        }, 0)
      }
    }
    formProps.handleChange(e)
    this._processSDDebounce()
  }

  _checkTurnOnAndTerminate() {
    const { formProps, contextData } = this.props

    const to = formProps.values['turn_on_date']
    const terminate = contextData.terminateDate
    if (terminate) {
      const moDate = moment(terminate).tz('Asia/Singapore')
      return moDate.isSame(to, 'day')
    }
    return false
  }

  _checkGasSelectedFromPremises() {
    const { contextData, formProps } = this.props
    const { gasPreSelected } = contextData
    const { values } = formProps
    if (
      gasPreSelected &&
      contextData.turnOnServices &&
      contextData.turnOnServices.services.some((s) => s === 'gas')
    ) {
      return true
    }
    return false
  }

  _getTerminateDate = (format) => {
    const { contextData } = this.props
    const terminate = contextData.terminateDate
    if (terminate) {
      const moDate = moment(terminate).tz('Asia/Singapore')
      return moDate.format(format)
    }
    return null
  }

  _fetchSDOptions = () => {
    const { contextActions, contextData, formProps } = this.props
    const {
      turnOnServices: { type_of_usage } = {},
      security_deposit_calculation,
    } = contextData

    API.payment.fetchSecurityDepositOptions().then((fields) => {
      const isPremisesIncluded =
        security_deposit_calculation &&
        fields[type_of_usage] &&
        !!fields[type_of_usage].options.find(
          (item) =>
            item.id === security_deposit_calculation.USE_OF_PREMISES_LIST
        )

      if (!isPremisesIncluded) {
        formProps.setFieldValue('USE_OF_PREMISES_LIST', '')
        formProps.setFieldValue('NO_DAYS', '')
        formProps.setFieldValue('ELECT_CAPACITY', '')
        formProps.setFieldValue('WATER_CAPACITY', '')
        formProps.setFieldValue('selected_elec_cap', '')
        formProps.setFieldValue('selected_premises', '')
        formProps.setFieldValue('security_deposit', 0)
      }

      contextActions.presave({
        security_deposit_calculation: {
          ...contextData.security_deposit_calculation,
          security_deposit:
            (isPremisesIncluded &&
              contextData.security_deposit_calculation &&
              contextData.security_deposit_calculation.security_deposit) ||
            0,
          type_of_premises:
            fields[type_of_usage] && fields[type_of_usage].options,
          USE_OF_PREMISES_LIST: isPremisesIncluded
            ? contextData.security_deposit_calculation.USE_OF_PREMISES_LIST
            : '',
          NO_DAYS: isPremisesIncluded
            ? contextData.security_deposit_calculation.NO_DAYS
            : '',
          ELECT_CAPACITY: isPremisesIncluded
            ? contextData.security_deposit_calculation.ELECT_CAPACITY
            : '',
          WATER_CAPACITY: isPremisesIncluded
            ? contextData.security_deposit_calculation.WATER_CAPACITY
            : '',
          selected_elec_cap: isPremisesIncluded
            ? contextData.security_deposit_calculation.selected_elec_cap
            : '',
          selected_premises: isPremisesIncluded
            ? contextData.security_deposit_calculation.selected_premises
            : '',
          electricity_capacity_options: fields.elect_capacity.options,
          no_days: fields.no_days,
        },
      })
      this.setState((prevState) => ({
        ...prevState,
        securityDeposit: {
          ...prevState.securityDeposit,
          fields: [...this._getListOfFields(fields)],
        },
      }))
    })
  }

  _processSDDebounce = debounce(
    () => {
      this._processSD()
    },
    300,
    {
      trailing: true,
      leading: false,
    }
  )

  _processSD = () => {
    if (!this.props.formProps) return null
    const { contextData, contextConfig, formProps, contextActions } = this.props
    const { values, setFieldValue } = formProps
    const { securityDeposit } = this.state
    if (values.USE_OF_PREMISES_LIST === '') {
      setFieldValue('security_deposit', 0)
      return null
    }
    const premises = values.USE_OF_PREMISES_LIST

    const isElectricitySelected = values.services.some(
      (s) => s === 'electricity'
    )
    const isWaterSelected = values.services.some((s) => s === 'water')
    const isGasSelected = values.services.some((s) => s === 'gas')

    const shouldHaveElectSupply = [
      'PREMISES_PUBLIC_LIGHTING',
      'PREMISES_OTHERS',
      'PREMISES_CONSTRUCTION_SITE',
    ]
    const shouldHaveWaterSupply = [
      'PREMISES_OTHERS',
      'PREMISES_CONSTRUCTION_SITE',
    ]
    const shouldHaveNoOfDays = ['PREMISES_WAYANG']
    // Check for the fields before calculating the SD
    // If they required fields are not yet being filled or empty dont proceed with calculation
    // But return first from the caller

    if (
      isElectricitySelected &&
      shouldHaveElectSupply.some((es) => es === premises)
    ) {
      setFieldValue('security_deposit', 0)
      if (values.ELECT_CAPACITY === '') return null
    }

    if (
      isWaterSelected &&
      shouldHaveWaterSupply.some((es) => es === premises)
    ) {
      setFieldValue('security_deposit', 0)
      if (values.WATER_CAPACITY === '') return null
    }

    if (shouldHaveNoOfDays.some((es) => es === premises)) {
      setFieldValue('security_deposit', 0)
      if (values.NO_DAYS === '') return null
    }

    const payload = {
      accHolderType: contextConfig.application_type,
      usageType: contextData.turnOnServices.type_of_usage,
      isElectricitySelected,
      isWaterSelected,
      isGasSelected,
      useOfPremises: premises,
      waterCapacityRequired: values.WATER_CAPACITY,
      electricitySupplyRange: values.ELECT_CAPACITY,
      noOfdays: values.NO_DAYS,
    }

    const selectedPremises =
      contextData.security_deposit_calculation.type_of_premises.find(
        (option) => option.id === values.USE_OF_PREMISES_LIST
      ) || ''

    const selectedElecCap =
      contextData.security_deposit_calculation.electricity_capacity_options.find(
        (option) => option.id === values.ELECT_CAPACITY
      ) || ''

    this.setState({
      securityDeposit: { ...securityDeposit, isLoading: true },
    })

    API.payment
      .calculateSecurityDeposit(payload)
      .then((res) => {
        setFieldValue('security_deposit', res.securityDeposit || '0')
        const contextValues = {
          payment_method: res.payment_method,
          security_deposit_calculation: {
            ...contextData.security_deposit_calculation,
            security_deposit: res.securityDeposit,
            selected_premises: selectedPremises.text,
            selected_elec_cap: selectedElecCap.text,
            WATER_CAPACITY: values.WATER_CAPACITY,
            NO_DAYS: values.NO_DAYS,
            USE_OF_PREMISES_LIST: values.USE_OF_PREMISES_LIST,
            ELECT_CAPACITY: values.ELECT_CAPACITY,
          },
        }
        if (res.payment_method.options.length === 1) {
          if (contextData.payment) {
            if (contextData.payment.chosen_payment) {
              contextValues.payment = {
                ...contextData.payment,
                security_deposit: 0,
                admin_fees: 0,
                admin_fees_wo_gst: 0,
                amount_payable: 0,
                amount_payable_wo_gst: 0,
                gst: 0,
                chosen_payment: res.payment_method.defaults,
              }
            }
          } else {
            contextValues.payment = {
              PAYMENT_METHOD: '',
              security_deposit: 0,
              admin_fees: 0,
              admin_fees_wo_gst: 0,
              amount_payable: 0,
              amount_payable_wo_gst: 0,
              gst: 0,
              bypass_dup_check: 'false',
              chosen_payment: res.payment_method.defaults,
            }
          }
        } else if (res.payment_method.options.length > 1) {
          contextValues.payment = {
            ...(contextData.payment || {}),
            bypass_dup_check:
              (contextData.payment && contextData.payment.bypass_dup_check) ||
              'false',
            security_deposit: 0,
            admin_fees: 0,
            admin_fees_wo_gst: 0,
            amount_payable: 0,
            amount_payable_wo_gst: 0,
            gst: 0,
            chosen_payment:
              (contextData.payment && contextData.payment.chosen_payment) ||
              res.payment_method.defaults,
          }
        }
        contextActions.presave(contextValues)
        this.setState({
          securityDeposit: { ...securityDeposit, isLoading: false },
        })
      })
      .catch((err) => {
        if (err) {
          this.setState({
            securityDeposit: {
              ...securityDeposit,
              isShowDependField: false,
              isLoading: false,
            },
          })
        }
      })
  }

  _setDateValue = (group, value) => {
    const {
      formProps,
      contextActions,
      contextData,
      fetchTimeslotCallBack,
    } = this.props
    const { setFieldValue } = formProps
    const preferred_date = {
      ...contextData.preferred_date,
      [group]: value,
    }
    contextActions.presave({
      preferred_date,
      [group]: value,
    })
    if (group === 'turn_on_time' && fetchTimeslotCallBack) {
      fetchTimeslotCallBack()
    }
    setFieldValue(group, value)
  }

  _getListOfFields = (fields) => {
    const { contextData: data, formProps } = this.props
    const { values } = formProps
    const isElectricitySelected = data.turnOnServices.services.some(
      (s) => s === 'electricity'
    )
    const result = []
    if (data.turnOnServices && data.turnOnServices.type_of_usage) {
      const typeOfUsage = data.turnOnServices.type_of_usage
      const premisesListField = fields[typeOfUsage]
      const premisesOptions = premisesListField.options.filter((option) =>
        !isElectricitySelected
          ? option.id !== 'PREMISES_PUBLIC_LIGHTING'
          : isElectricitySelected
      )
      const filteredPremisesField = {
        ...premisesListField,
        options: premisesOptions,
      }

      if (fields[typeOfUsage]) {
        result.push(filteredPremisesField)
      }
      if (fields.elect_capacity) {
        result.push(fields.elect_capacity)
      }
      if (fields.water_capacity) {
        result.push(fields.water_capacity)
      }
      if (fields.no_days) {
        result.push(fields.no_days)
      }
      if (fields.payment_method) {
        result.push(fields.payment_method)
      }
    }
    return result
  }

  _requestOtpEbill = () => {
    const {
      contextData: { personal_contact_information },
      formProps,
      stateId,
    } = this.props

    this.setState((prevState) => ({
      ...prevState,
      ebillOtpSent: false,
      ebillVerified: false,
      ebillVerifying: false,
      ebillOtpRequesting: true,
      errors: {
        ...prevState.errors,
        ebill_otp_max: false,
        ebill_otp_error: null,
      },
    }))

    formProps.setFieldTouched('ebill_otp', false)
    formProps.setFieldValue('ebill_otp', '')

    API.ebill
      .verifyEmail({
        email: personal_contact_information.email,
        transaction_id: stateId,
      })
      .then((resp) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            ebillOtpSent: resp.success,
            ebillOtpRequesting: false,
            ebillOtpAttempts: 0,
            errors: {
              ...prevState.errors,
              ebill_otp_error: !resp.success ? resp.message : null,
            },
          }),
          () => {
            formProps.setFieldValue('ebill_requested', true)
          }
        )
      })
      .catch((err) => {
        if (err.error) {
          this.setState((prevState) => ({
            ...prevState,
            ebillOtpRequesting: false,
            ebillOtpAttempts: 0,
            errors: {
              ...prevState.errors,
              ebill_otp_error: err.error_description,
            },
          }))
        }
      })
  }

  _verifyOtpEbill = (otp) => {
    const {
      formProps: { setFieldValue },
      contextData: { personal_contact_information },
      stateId,
    } = this.props

    setFieldValue('ebill_verified', false)

    this.setState((prevState) => ({
      ...prevState,
      ebillVerifying: true,
      ebillVerified: false,
      ebillOtpRequesting: false,
      errors: {
        ...prevState.errors,
        ebill_otp_max: false,
        ebill_otp_error: null,
      },
    }))
    API.ebill
      .verifyOtp({
        email: personal_contact_information.email,
        otp: otp,
        transaction_id: stateId,
      })
      .then((resp) => {
        this.setState((prevState) => {
          const attempts = prevState.ebillOtpAttempts + 1
          const newState = {
            ...prevState,
            ebillVerifying: false,
            ebillVerified: resp.verified,
            ebillOtpAttempts: attempts,
            errors: {
              ...prevState.errors,
              ebill_otp_max: false,
              ebill_otp_error: null,
            },
          }
          if (!resp.verified) {
            setFieldValue('ebill_otp', '')
            setFieldValue('ebill_verified', false)
            newState.errors = {
              ebill_otp_error: resp.message,
            }
            if (attempts >= prevState.ebillOtpMaxAttempts) {
              newState.ebillOtpSent = false
              newState.errors = {
                ...newState.errors,
                ebill_otp_max: true,
              }
            }
          } else {
            setFieldValue('ebill_verified', true)
          }
          return newState
        })
      })
      .catch((err) => {
        if (err.error) {
          setFieldValue('ebill_otp', '')
          setFieldValue('ebill_verified', false)
          this.setState((prevState) => ({
            ...prevState,
            ebillVerifying: false,
            ebillOtpAttempts: 0,
            errors: {
              ...prevState.errors,
              ebill_otp_error: err.error_description,
            },
          }))
        }
      })
  }

  _fetchCalendarTime = () => {
    const { contextData, contextConfig, contextActions, formProps } = this.props
    if (
      contextData.premises &&
      contextData.premises.premise_address &&
      contextData.premises.premise_address.postal_code !== ''
    ) {
      const premises_address = contextData.premises.premise_address
      const payload = {
        postal_code: premises_address.premise_postal_code || undefined,
        block: premises_address.premise_block || undefined,
        unit: premises_address.premise_unit || undefined,
        floor: premises_address.premise_floor || undefined,
        timeslot: contextData.timeslot_allowed,
        electricity: contextData.turnOnServices.services.includes(
          'electricity'
        ),
        gas: contextData.turnOnServices.services.includes('gas'),
        water: contextData.turnOnServices.services.includes('water'),
        nanl: contextData.premises.nanl,
        source: Utils.getSource(),
        account_type: contextData.account_type,
        personal: contextConfig.application_type === 'personal',
      }
      formProps.setFieldValue('turn_on_date', '')
      formProps.setFieldValue('turn_on_datetime', '')
      formProps.setFieldValue('turn_on_time', '')
      formProps.setFieldValue('turn_on_date_hash', '')
      formProps.setFieldValue('turn_on_time_hash', '')
      formProps.setFieldValue('timeslot_required', true)
      this.setState((prevState) => ({
        ...prevState,
        turnOnAvailableTimeslot: [],
        turnOnAvailableTimeslotError: null,
      }))
      if (this.mounted) {
        API.retrieveCalendar(payload)
          .then((data) => {
            const availableDates = this._getAvailableDates(data.available)
            if (availableDates.length > 0) {
              this.setState({
                dateslot: data.available,
                turnOnAvailableDates: availableDates,
                publicHolidays: data.public_holidays,
              })
              if (data.oc_mo_date) {
                const isMODateAvailable = data.available.some(
                  (date) =>
                    moment(date.date)
                      .tz('Asia/Singapore')
                      .format('YYYY-MM-DD') ===
                    moment(data.oc_mo_date)
                      .tz('Asia/Singapore')
                      .format('YYYY-MM-DD')
                )
                if (isMODateAvailable) {
                  const _dt = moment(data.oc_mo_date)
                    .add(8, 'hours')
                    .tz('Asia/Singapore')
                    .format()
                  this._setDateValue('turn_on_date', _dt)
                  this._setDateValue('turn_on_datetime', _dt)
                  const hash = this._getHash(_dt, data.available)
                  this._setDateValue('turn_on_date_hash', hash)
                  const date = parseDate(_dt, 'YYYYMMDD')
                  this._fetchTimeslot(date, _dt, hash)
                  contextActions.presave({ terminateDate: data.oc_mo_date })
                }
              } else {
                contextActions.presave({ terminateDate: '' })
              }
              contextActions.presave({
                zcGasDate: data.zc_gas_date || '',
                zczzDate: data.zc_zz_date || '',
              })
            } else {
              contextActions.showEndSessionError()
            }
          })
          .catch(() => {
            this.setState((prevState) => ({
              ...prevState,
              errCalendar:
                'Sorry, we encountered an issue when processing your request. Please try again later.',
            }))
          })
      }
    }
  }

  _getHash(value, datesAvailable) {
    // get hash for date
    if (datesAvailable.length > 0) {
      let dHash = ''
      let d1 = moment(value).tz('Asia/Singapore')
      let date = datesAvailable.find((ds) => {
        let d2 = moment(ds.date).tz('Asia/Singapore')
        if (d1.format('YYYY MM DD') === d2.format('YYYY MM DD')) {
          return true
        }
        return false
      })
      if (date && date.date_hash) {
        dHash = date.date_hash
      }
      return dHash
    }
    return null
  }

  _fetchTimeslot = (selected_date, date, selected_date_hash) => {
    const {
      contextData,
      contextConfig,
      contextActions,
      formProps,
      fetchTimeslotCallBack,
    } = this.props
    // this._setValue('turn_on_datetime', {})
    this._setValue('turn_on_time', {})
    this._setValue('turn_on_time_hash', '')
    this.setState((prevState) => ({
      ...prevState,
      turnOnAvailableTimeslot: [],
    }))

    if (
      contextData.premises &&
      !contextData.premises.nanl &&
      contextData.premises.premise_address &&
      contextData.premises.premise_address.postal_code !== ''
    ) {
      const premises_address = contextData.premises.premise_address
      const services =
        contextData.turnOnServices && contextData.turnOnServices.services
      let timeslot = contextData.timeslot_allowed
      if (contextData.bankruptcy && contextData.bankruptcy.bankruptcy) {
        timeslot = false
      }
      if (services) {
        const payload = {
          postal_code: premises_address.premise_postal_code,
          block: premises_address.premise_block,
          unit: premises_address.premise_unit,
          floor: premises_address.premise_floor,
          electricity: services.indexOf('electricity') !== -1,
          water: services.indexOf('water') !== -1,
          gas: services.indexOf('gas') !== -1,
          selected_date,
          date,
          notification_number: contextData.notification_no,
          timeslot,
          selected_date_hash,
          resi: contextConfig.application_domain === 'residential',
          personal: contextConfig.application_type === 'personal',
        }
        this.setState({
          turnOnAvailableTimeslotFetching: true,
          turnOnAvailableTimeslot: [],
          turnOnAvailableTimeslotError: null,
        })
        formProps.setFieldValue('notification_type', '')

        if (this.mounted) {
          API.retrieveTimeslot(payload)
            .then((data) => {
              if (data.pre_turn_on) {
                contextActions.presave({
                  preTurnOn: data.pre_turn_on,
                })
              }

              // Check if type is under company name then just set meter_reading_required only
              if (contextData.bankruptcy && contextData.bankruptcy.bankruptcy) {
                formProps.setFieldValue(
                  'notification_type',
                  data.notification_type
                )
                formProps.setFieldValue('timeslot_required', false)
                formProps.setFieldValue(
                  'meter_reading_required',
                  data.meter_reading_required
                )
                contextActions.presave({
                  notification_type: data.notification_type,
                })
                this.setState({
                  turnOnAvailableTimeslotFetching: false,
                  turnOnAvailableTimeslot: [],
                  turnOnAvailableTimeslotError: null,
                })
              } else if (
                contextConfig &&
                contextConfig.application_domain === 'residential'
              ) {
                formProps.setFieldValue(
                  'notification_type',
                  data.notification_type
                )
                formProps.setFieldValue(
                  'meter_reading_required',
                  data.meter_reading_required
                )
                formProps.setFieldValue(
                  'timeslot_required',
                  data.timeslot_required
                )
                contextActions.presave({
                  notification_type: data.notification_type,
                })
                if (data.timeslots && data.timeslots.length > 0) {
                  formProps.setFieldValue('timeslot_required', true)
                  this.setState({
                    turnOnAvailableTimeslotFetching: false,
                    turnOnAvailableTimeslot: data.timeslots,
                    turnOnAvailableTimeslotError: null,
                  })
                }
                if (data.timeslots.length === 0) {
                  const timeslotErrMsg = 'Please select a new date'
                  if (contextData.timeslot_allowed && data.timeslot_required) {
                    this.setState({
                      turnOnAvailableTimeslotFetching: false,
                      turnOnAvailableTimeslotError: timeslotErrMsg,
                    })
                  } else if (data.timeslot_required) {
                    this.setState({
                      turnOnAvailableTimeslotFetching: false,
                      turnOnAvailableTimeslotError: timeslotErrMsg,
                    })
                  } else {
                    this.setState({
                      turnOnAvailableTimeslotFetching: false,
                      turnOnAvailableTimeslotError: null,
                    })
                  }
                }
              } else {
                this.setState({
                  turnOnAvailableTimeslotFetching: false,
                  turnOnAvailableTimeslotError: null,
                })
              }
            })
            .catch((err) => {
              if (
                contextConfig &&
                contextConfig.application_type !== 'company'
              ) {
                formProps.setFieldValue('timeslot_required', false)
                this.setState({
                  _err: err,
                  turnOnAvailableTimeslotFetching: false,
                  turnOnAvailableTimeslotError:
                    'There was a problem retrieving timeslot, Please try again',
                })
              }
            })
        }
      }
    }
    if (fetchTimeslotCallBack) {
      fetchTimeslotCallBack()
    }
  }

  _resetEbill = () => {
    const { formProps } = this.props

    this.setState((prevState) => ({
      ...prevState,
      ebillOtpSent: false,
      ebillVerifying: false,
      ebillVerified: false,
      errors: {
        ...prevState.errors,
        ebill_otp_max: false,
        ebill_otp_error: null,
      },
    }))
    formProps.setFieldValue('ebill_otp', '')
    formProps.setFieldValue('ebill_verified', false)
  }

  _ebillCheckHandler = (e) => {
    const { formProps } = this.props
    formProps.setFieldTouched('ebill_otp', false)
    formProps.setFieldValue('enable_paper_bill', e.target.checked)
    this._setValue('enable_paper_bill', e.target.checked)
  }

  _confirmTimeslotHandler = (date, id, hash, cb, errCb) => {
    const { contextData, formProps } = this.props
    API.confirmTimeslot({
      notification_number: contextData.notification_no,
      notification_type: contextData.notification_type,
      selected_date: moment(date.start)
        .tz('Asia/Singapore')
        .format('YYYYMMDD'),
      selected_time: moment(date.start)
        .tz('Asia/Singapore')
        .format('HHmmss'),
      time_hash: hash,
    })
      .then((res) => {
        if (res.success) {
          if (cb) {
            this.setState((prevState) => ({
              ...prevState,
              turnOnAvailableTimeslotError: null,
            }))
            cb()
          }
        }
      })
      .catch((err) => {
        formProps.setFieldError('turn_on_time', 'Please select new timeslot')
        this.setState((prevState) => ({
          ...prevState,
          turnOnAvailableTimeslotError: 'Please select new timeslot',
          _error: err,
        }))
        if (errCb) {
          errCb()
        }
      })
  }

  _changeEmailHandler = () => {
    const { contextActions, history, contextData } = this.props

    contextActions.presave(
      {
        promptMyInfo: false,
        isChangedEmailLinked: !contextData.ebillEligible,
      },
      false,
      () => {
        history.push({
          pathname: '/open-account/residential/personal/particulars',
          search: '?target=email',
        })
      }
    )
  }

  _getAvailableDates = (available) => {
    const { contextData } = this.props
    const { terminateDate } = contextData
    let available_dates = available.map((d) => d.date)
    if (terminateDate) {
      const moDate = moment(terminateDate)
        .tz('Asia/Singapore')
        .format('YYYY-MM-DD')
      available_dates = available_dates.filter((date) => {
        return (
          moDate <=
          moment(date)
            .tz('Asia/Singapore')
            .format('YYYY-MM-DD')
        )
      })
    }
    if (contextData.premises.nanl) {
      const next7Days = moment()
        .add(7, 'days')
        .tz('Asia/Singapore')
        .format()
      available_dates = available_dates.filter((date) => {
        return date > next7Days
      })
    }
    return available_dates
  }

  _getTypeOfUsage = (type) => {
    return `${Utils.capitalize(type)} Supply`
  }

  _confirmCalendarErrorHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      errCalendar: null,
    }))
  }

  _homeStarterLinkClickHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmDialog: true,
    }))
  }
  _homeStarterLinkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmDialog: false,
    }))
  }
  _homeStarterLinkConfirmHandler = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmDialog: false,
      }),
      () => {
        openLink('https://www.hlas.com.sg/home-starter-kit', '_blank')
      }
    )
  }

  _eegLinkClickHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmEeg: true,
    }))
  }
  _eegLinkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmEeg: false,
    }))
  }

  _eegLinkConfirmHandler = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmEeg: false,
      }),
      () => {
        openLink(
          'https://www.ema.gov.sg/Solar_Photovoltaic_Systems.aspx',
          '_blank'
        )
      }
    )
  }

  _linkHandler = (path, target, external = true) => () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmExtLink: external,
        extLink: {
          path,
          target,
        },
      }),
      () => {
        if (!external) {
          this._linkConfirmHandler()
        }
      }
    )
  }

  _linkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmExtLink: false,
      extLink: {
        path: '',
        target: '',
      },
    }))
  }

  _linkConfirmHandler = () => {
    const { extLink } = this.state
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmExtLink: false,
        extLink: {
          path: '',
          target: '',
        },
      }),
      () => {
        openLink(extLink.path, extLink.target)
      }
    )
  }

  _deleteAttachments = (attachments) => {
    const { formProps } = this.props
    const stateId = Utils.getStateId()
    if (
      attachments &&
      attachments.includes('electrical_installation_license_form')
    ) {
      API.deleteAttachment({
        transaction_id: stateId,
        type: 'electrical_installation_license_form',
      }).then((data) => {
        formProps.setFieldValue('eil', '')
      })
    }
  }

  _handleUploadStatusChange = (done) => {
    this.setState({ uploadIsDone: done })
  }
}

TurnOnServices.defaultProps = {
  homeContent: true,
  contextConfig: {
    application_type: 'personal',
  },
}

export default withRouter(TurnOnServices)

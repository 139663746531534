import { has, isEmpty } from 'lodash'
import { specs } from 'spd-oa/attachmentHelpers'
import { API } from 'spd-oa/services'

const kioskConfig = {
  SESSION_STATES_KEY: {
    uploadStatus: 'uploadStatus',
    kioskStreamId: 'kioskStreamId',
  },
  UPLOAD_STATUS: {
    INIT: 'INIT',
    DONE: 'DONE',
  },
}

/**
 * @param {string} uploadUrl
 * @return {string} kiosk upload sessionId
 */
function getKioskSessionId(uploadUrl) {
  const url = new URL(uploadUrl)
  return url.searchParams.get('stateId')
}

/**
 * @typedef {{ id: string, type: string, filename: string, data: { fileId: string } | null }} FileInfo
 * @typedef {{ id: string, type: string, value: string[] }} FileInfoMap
 */

/**
 * Reduce array of fileInfoList of same id to an object.
 * @param fileInfoList {FileInfo[]}
 * @returns {FileInfoMap}
 */
function normalizeFileInfoList(fileInfoList = []) {
  return fileInfoList.reduce(
    (prevValue, fileInfo) => {
      return {
        id: prevValue.id || fileInfo.id,
        type: prevValue.type || fileInfo.type,
        value: getValues(prevValue.value, fileInfo.filename),
        fileId: prevValue.fileId
          ? [...prevValue.fileId, fileInfo.fileId]
          : [fileInfo.fileId],
      }
    },
    { value: [] }
  )
}

function getValues(values = [], filename: string) {
  if (values.includes(filename)) {
    const name = filename.split('.')
    const duplicates = values.filter(
      (value) => value.includes(name[0]) && value.includes(name[1])
    )
    filename = name[0] + duplicates.length + '.' + name[1]
  }
  return [...values, filename]
}

function buildSpec(transactionId, requiredDocs) {
  return {
    app_id: 'OA',
    app_state: transactionId,
    fields: requiredDocs
      .filter((doc) => has(specs, doc.id) && isEmpty(doc.value))
      .map((doc) => {
        return {
          ...specs[doc.id],
          is_required: doc.hasOwnProperty('is_required')
            ? doc.is_required
            : specs[doc.id].is_required,
        }
      }),
  }
}

function handleNext() {
  const kioskStreamId = sessionStorage.getItem(
    kioskConfig.SESSION_STATES_KEY.kioskStreamId
  )
  const msg = encodeURIComponent(
    JSON.stringify({ UPLOAD_STATUS: kioskConfig.UPLOAD_STATUS.DONE })
  )
  if (kioskStreamId) {
    return API.sendMsgToKiosk(kioskStreamId, msg)
  }
}

export {
  getKioskSessionId,
  buildSpec,
  normalizeFileInfoList,
  kioskConfig,
  handleNext,
}

import React, { createContext } from 'react'

export const KioskSideBarContext = createContext({})

export const KioskSideBarProvider = KioskSideBarContext.Provider
export const KioskSideBarConsumer = KioskSideBarContext.Consumer

export const withKioskSideBarContext = (Component, otherProps) => {
  return (props) => (
    <KioskSideBarConsumer>
      {(context) => (
        <div className="page-content">
          <Component {...props} {...otherProps} kioskContext={context} />
        </div>
      )}
    </KioskSideBarConsumer>
  )
}

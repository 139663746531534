import React, { Component, Fragment } from 'react'
import {
  CardList,
  CardListItem,
  GridItem,
  Options,
} from 'spd-oa/components/common'

export default class AppList extends Component {
  _resolveData(data) {
    const { resolver } = this.props
    if (resolver) {
      if (Array.isArray(data) && data.length > 0) {
        return data.map((item, index) => {
          let _resolvedItem = resolver(item, index)
          return {
            ...item,
            ..._resolvedItem,
          }
        })
      }
      return data
    }
    return data
  }

  _renderItemContent(key, content, contextData = {}) {
    const { fieldsToBeIconize } = this.props
    if (fieldsToBeIconize) {
      if (fieldsToBeIconize[key]) {
        let iconSet = fieldsToBeIconize[key]
        if (iconSet[content]) {
          let iconStyle = {}
          if (iconSet[content].background) {
            iconStyle.background = iconSet[content].background
          }
          return (
            <i className="icon" style={iconStyle}>
              {iconSet[content].icon}
            </i>
          )
        }
      }
    }
    if (key === 'action') {
      return <Options handlers={content} data={contextData} />
    }
    return content
  }

  _renderList() {
    const { data, actionItem } = this.props
    const resolvedData = this._resolveData(data)
    return resolvedData.map((item, itemIndex) => {
      if (actionItem.type === 'button' && actionItem.option) {
        item.action = actionItem.optionHandlers
      }
      return (
        <CardListItem
          key={itemIndex}
          onClick={actionItem.type === 'row' ? actionItem.handler : null}
        >
          {Object.keys(item).map((key, keyIndex) => {
            return (
              <GridItem
                key={keyIndex}
                className="card-list--node"
                data-field={key.toLowerCase()}
              >
                {this._renderItemContent(key, item[key], item)}
              </GridItem>
            )
          })}
        </CardListItem>
      )
    })
  }
  render() {
    let { dataHeader, data, renderEmpty } = this.props
    return (
      <Fragment>
        {data.length === 0 && <div className="empty-list">{renderEmpty}</div>}
        {data.length > 0 && (
          <CardList
            header={{
              className: 'grid-head',
              list: dataHeader,
            }}
          >
            {this._renderList()}
          </CardList>
        )}
      </Fragment>
    )
  }
}

AppList.defaultProps = {
  dataHeader: [],
  data: [],
  resolver(item, index) {
    return item
  },
  fieldsToBeIconize: {},
  actionItem: {
    type: 'row',
    option: false,
    handler: () => {},
  },
  renderEmpty: <p>List is empty</p>,
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

function getIcon(currentStep, index) {
  if (currentStep === index) {
    return 'is-active'
  } else if (currentStep > index) {
    return 'is-complete'
  } else {
    return ''
  }
}

// const WizStepsx = ({
//   currentStep,

// }) => {
//   return (
//     <ul className='lm--timeline'>
//       <li className={getIcon(currentStep, 0)}>Select Plan</li>
//       <li className={getIcon(currentStep, 1)}>Premises Info</li>
//       <li className={getIcon(currentStep, 2)}>Your Particulars</li>
//       <li className={getIcon(currentStep, 3)}>Confirmation</li>
//     </ul>
//   )
// }

class WizSteps extends PureComponent {
  render() {
    const { items, currentStep, id } = this.props
    if (items.length === 0) {
      return null
    }
    return (
      <ul
        id={id}
        className="lm--timeline"
        data-total={items.length}
        style={{
          '--total': items.length,
        }}
      >
        {items.map((item, i) => (
          <li
            key={`wizsteps--${i}`}
            className={getIcon(currentStep, i)}
            data-total={items.length}
            data-step={i + 1}
          >
            {item.title}
          </li>
        ))}
      </ul>
    )
  }
}

WizSteps.defaultProps = {
  items: [],
  id: '',
}

WizSteps.propTypes = {
  items: PropTypes.array,
}

export default WizSteps

import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import { object, array, number, boolean } from 'yup'

import * as Helpers from 'spd-oa/helpers'
import Utils from 'spd-oa/utils'
import { API } from 'spd-oa/services'

import {
  Button,
  Detail,
  TermsControl,
  Icons,
  TermsConditions,
  Modal,
  ConfirmationDialog,
} from 'spd-oa/components/common'
import { FormField, FormCTA } from 'spd-oa/components/FormElement'
import { dollarToCents, formatSGD } from '../../helpers'

const khaosPaymentMode = {
  PYMT_ENETS: 'NETS_DEBIT',
  PYMT_CREDIT_CARD: 'CREDIT_CARD',
}
const kioskSDPaymentMax = 80000

const schema = object().shape({
  services: array(),
  admin_fees: number(),
  admin_fees_wo_gst: number(),
  amount_payable_wo_gst: number(),
  gst: number(),
  amount_payable: number()
    .min(1)
    .required(),
  hla: boolean(),
  check_i_confirm: boolean()
    .required()
    .oneOf([true], 'Must agree to the Terms and Conditions'),
})

class Payment extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      payment: {
        PAYMENT_METHOD: '',
        security_deposit: 0,
        admin_fees: 0,
        admin_fees_wo_gst: 0,
        amount_payable: 0,
        amount_payable_wo_gst: 0,
        gst: 0,
        chosen_payment: [],
        bypass_dup_check: 'false',
      },
      hla: false,
    })
    context.actions.setFields(data, false)
    this.state = {
      data,
      fields: [],
      showTerms: false,
      submitting: false,
      processingPayment: false,
      isLoading: false,
      isShowDependField: false,
      errDialogData: null,
    }

    this._handlePaymentError = this._handlePaymentError.bind(this)
  }

  componentDidMount() {
    this._getPaymentProps()
    this._getQueries()
    this._reValidate()
  }

  render() {
    const {
      context: { data: contextData, config: contextConfig },
    } = this.props
    const {
      data,
      showTerms,
      submitting,
      processingPayment,
      errDialogData,
    } = this.state

    return (
      <div className={`signup--payment signup--form`}>
        {Utils.getOAMode().mode === 'kiosk' && processingPayment ? (
          <section className="form-cover kiosk-mode">
            <div className="cover-content kiosk-mode">
              <span>Please wait...</span>
              <Icons.spinloader width={100} height={100} color={['#00b0b2']} />
            </div>
          </section>
        ) : (
          <>
            <header className="signup--head">
              <h4>{`Payment`}</h4>
            </header>

            <Formik
              ref={(el) => (this._form = el)}
              validationSchema={schema}
              enableReinitialize={true}
              onSubmit={this._handleConfirm}
              initialValues={{
                ...data.payment,
                services:
                  (contextData.turnOnServices &&
                    contextData.turnOnServices.services) ||
                  [],
              }}
              render={(formProps) => (
                <form
                  className="form-container"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault()
                  }}
                  onSubmit={formProps.handleSubmit}
                >
                  {processingPayment && (
                    <section className="form-cover">
                      <div className="cover-content">
                        <Icons.spinloader
                          width={50}
                          height={50}
                          color={['#333333']}
                        />
                        <span>Processing Payment..</span>
                      </div>
                    </section>
                  )}
                  {this.renderFields({
                    ...formProps,
                    handleChange: this._handleChange,
                    setFieldValue: this._setFieldValue,
                  })}

                  <FormField
                    key={`security-deposit`}
                    field="field-item--content"
                    label="Security Deposit"
                  >
                    <span>
                      {formatSGD(formProps.values['security_deposit'])}
                    </span>
                  </FormField>

                  <Detail.DetailNode>
                    <div className="detail_section--checks">
                      <div className="u-grid">
                        <div className="col-md-12 col-sm-12 u-cell">
                          <TermsControl
                            id="check_i_confirm"
                            name="check_i_confirm"
                            handleChange={formProps.handleChange}
                            value={formProps.values['check_i_confirm']}
                            byClickMessage={`By checking this box and submitting this application, ${
                              contextConfig.application_type === 'personal'
                                ? 'I:'
                                : 'I represent that I am duly authorised by the company to act on its behalf in respect of this application. On behalf of the company, I:'
                            }`}
                          >
                            {contextData.use_myinfo ? (
                              <ol>
                                <li>
                                  agree to the{' '}
                                  <Button
                                    link={true}
                                    onClickHandler={this._termsShowHandler}
                                  >{`Terms and Conditions`}</Button>
                                  .
                                </li>
                                {contextConfig.application_type ===
                                'personal' ? (
                                  <>
                                    <li>
                                      {`confirm that the information and/or data that I provided or made available to SP Services Limited (“SPS”) in this application (including any information or data from Myinfo database (“Myinfo Data”) are true, accurate and complete, and that I am the owner or tenant of the premises.`}
                                    </li>
                                    <li>
                                      {`undertake  to  indemnify  and  hold  harmless  SPS  and/or  its  affiliates  from  any  losses, damages,  claims,  costs  or  penalties  arising  from  or  in  connection  with  any  information and/or  data  that  I  provided  or  made  available  to  SPS  in  this  application  (including  any Myinfo Data).`}
                                    </li>
                                    <li>
                                      {`acknowledge that I am required to engage a licensed electrical worker (“LEW”) to inspect and test my electrical installation if I have made any alteration or rewiring to it. Thereafter the  LEW  will  lodge  a  copy  of  the  Certificate  of  Compliance  (CoC)  together  with  this application  to  SPS  to  check  my  electrical  installation  to  determine  if  the  electrical installation is exempted from an Electrical Installation Licence.`}
                                    </li>
                                    <li>
                                      {`acknowledge  that  I  am  required  to  engage  a  licensed  plumber  (LP)  to  submit  the notification for water service installation (WSI) work if I have made any addition or alteration to existing piping and ensure the water meter is not removed or lost during the works.`}
                                    </li>
                                    <li>
                                      {`am aware that it is an offence under the Public Utilities Act (Cap. 261) to make any false statement,  representation  or  declaration  in  connection  with  the  application  for  water supplied by the Public Utilities Board.`}
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      {`confirm that the information and/or data that I provided or made available to SP Services Limited  (“SPS”)  in  this  application  (including  any  information  or  data  from  the  Myinfo database (“Myinfo Data”) are true, accurate and complete, and the company is the owner or tenant of the premises.`}
                                    </li>
                                    <li>
                                      {`undertake  to  indemnify  and  hold  harmless  SPS  and/or  its  affiliates  from  any  losses, damages,  claims,  costs  or  penalties  arising  from  or  in  connection  with  any  information and/or  data  that  I  provided  or  made  available  to  SPS  in  this  application  (including  any Myinfo Data).`}
                                    </li>
                                    <li>
                                      {`acknowledge that the Company is required to engage a licensed electrical worker (“LEW”) to inspect and test the electrical installation of the premises and if any alteration or rewiring to it has been made. Thereafter the LEW will lodge a copy of the Certificate of Compliance (CoC) together with this application to SPS to check the electrical installation to determine if the electrical installation is exempted from an Electrical Installation Licence.`}
                                    </li>
                                    <li>
                                      {`acknowledge that the Company is required to engage a licensed plumber (LP) to submit the  notification  for  water  service  installation  (WSI)  work  if  any  addition  or  alteration  to existing piping has been made and ensure the water meter is not removed or lost during the works.`}
                                    </li>
                                    <li>
                                      {`am aware that it is an offence under the Public Utilities Act (Cap. 261) to make any false statement,  representation  or  declaration  in  connection  with  the  application  for  water supplied by the Public Utilities Board.`}
                                    </li>
                                  </>
                                )}
                              </ol>
                            ) : (
                              <ol>
                                <li>
                                  agree to the{' '}
                                  <Button
                                    link={true}
                                    onClickHandler={this._termsShowHandler}
                                  >{`Terms and Conditions`}</Button>
                                  .
                                </li>
                                {contextConfig.application_type ===
                                'personal' ? (
                                  <>
                                    <li>
                                      {`confirm that the information and/or data that I provided or made available to SP Services Limited (“SPS”) in this application are true, accurate and complete, and that I am the owner or tenant of the premises.`}
                                    </li>
                                    <li>
                                      {`accept that SPS does not endorse and is not responsible for any information and/or data that I provided or made available to SPS in this application, and shall not be liable to me for any errors, inaccuracies or omissions in such information and/or data.`}
                                    </li>
                                    <li>
                                      {`undertake  to  indemnify  and  hold  harmless  SPS  and/or  its  affiliates  from  any  losses, damages,  claims,  costs  or  penalties  arising  from  or  in  connection  with  any  information and/or data that I provided or made available to SPS in this application.`}
                                    </li>
                                    <li>
                                      {`consent and agree to SPS collecting and using any information or data that I provide or make available to SPS for the following purposes:`}
                                      <ol>
                                        <li>
                                          {`to sign me up as its customer and set up my utilities account;`}
                                        </li>
                                        <li>{`to verify my identity;`}</li>
                                        <li>{`to provide me with services/ goods;`}</li>
                                        <li>
                                          {`to contact me for any of the above purposes; and`}
                                        </li>
                                        <li>
                                          {`any other purpose that is incidental and/or ancillary to the above stated purposes.`}
                                        </li>
                                      </ol>
                                    </li>
                                    <li>
                                      {`acknowledge that I am required to engage a licensed electrical worker (“LEW”) to inspect and test my electrical installation if I have made any alteration or rewiring to it. Thereafter the  LEW  will  lodge  a  copy  of  the  Certificate  of  Compliance  (CoC)  together  with  this application  to  SPS  to  check  my  electrical  installation  to  determine  if  the  electrical installation is exempted from an Electrical Installation Licence.`}
                                    </li>
                                    <li>
                                      {`acknowledge  that  I  am  required  to  engage  a  licensed  plumber  (LP)  to  submit  the notification  for  water  service  installation  (WSI)  work    if  I  have  made  any  addition  or alteration to existing piping and ensure the water meter is not removed or lost during the works.`}
                                    </li>
                                    <li>
                                      {`am aware that it is an offence under the Public Utilities Act (Cap. 261) to make any false statement,  representation  or  declaration  in  connection  with  the  application  for  water supplied by the Public Utilities Board.`}
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      {`confirm that the information and/or data that I provided or made available to SP Services Limited (“SPS”) in this application are true, accurate and complete, and that the company is the owner or tenant of the premises.`}
                                    </li>
                                    <li>
                                      {`accept that SPS does not endorse and is not responsible for any information and/or data provided  or  made  available  to  SPS  in  this  application,  and  shall  not  be  liable  to  the company for any errors, inaccuracies or omissions in such information and/or data.`}
                                    </li>
                                    <li>
                                      {`undertake  to  indemnify  and  hold  harmless  SPS  and/or  its  affiliates  from  any  losses, damages,  claims,  costs  or  penalties  arising  from  or  in  connection  with  any  information and/or data provided or made available to SPS in this application.`}
                                    </li>
                                    <li>
                                      {`consent and agree to SPS collecting and using any information or data that I provide or make available to SPS for the following purposes:`}
                                      <ol>
                                        <li>
                                          {`to sign the company up as its customer and set up the company’s utilities account;`}
                                        </li>
                                        <li>{`to verify my identity;`}</li>
                                        <li>{`to provide the company with services/goods;`}</li>
                                        <li>
                                          {`to contact me and the company for any of the above purposes; and`}
                                        </li>
                                        <li>
                                          {`for any other purpose that is incidental and/or ancillary to the above stated purposes.`}
                                        </li>
                                      </ol>
                                    </li>
                                    <li>
                                      {`acknowledge that the Company is required to engage a licensed electrical worker (“LEW”) to inspect and test the electrical installation of the premises and if any alteration or rewiring to it has been made. Thereafter the LEW will lodge a copy of the Certificate of Compliance (CoC) together with this application to SPS to check the electrical installation to determine if the electrical installation is exempted from an Electrical Installation Licence.`}
                                    </li>
                                    <li>
                                      {`acknowledge that the Company is required to engage a licensed plumber (LP) to submit the  notification  for  water  service  installation  (WSI)  work  if  any  addition  or  alteration  to existing piping has been made and ensure the water meter is not removed or lost during the works.`}
                                    </li>
                                    <li>
                                      {`am aware that it is an offence under the Public Utilities Act (Cap. 261) to make any false statement,  representation  or  declaration  in  connection  with  the  application  for  water supplied by the Public Utilities Board.`}
                                    </li>
                                  </>
                                )}
                              </ol>
                            )}
                          </TermsControl>
                        </div>
                      </div>
                    </div>
                  </Detail.DetailNode>

                  <FormCTA>
                    <Button
                      variant="secondary"
                      onClickHandler={this._handleBackBtn}
                      disabled={submitting}
                    >
                      {`Back`}
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        !Helpers.isEmpty(formProps.errors) ||
                        !formProps.isValid ||
                        submitting
                      }
                      loading={submitting}
                    >
                      {`Pay`}
                    </Button>
                  </FormCTA>
                </form>
              )}
            />
          </>
        )}

        {showTerms && (
          <Modal
            onClose={this._termsOkHandler}
            onEscapeKeyDown={this._termsOkHandler}
            closeBtn={Utils.getOAMode().mode === 'kiosk'}
            title={`Terms and Conditions`}
            className="modal-terms"
          >
            <section className="terms-container">
              <TermsConditions />
            </section>
          </Modal>
        )}

        {errDialogData && (
          <ConfirmationDialog
            id="submit-pending-prompt"
            confirmOkHandler={errDialogData.okHandler}
            confirmCancelHandler={errDialogData.cancelHandler}
            content={errDialogData.content}
            actionConfig={errDialogData.actionConfig}
            processing={false}
          />
        )}
      </div>
    )
  }

  renderFields = (formProps) => {
    const { fields } = this.state
    const lisfOfFields = fields
    const { data: ContextData } = this.props.context
    const additionalCriteria = {
      ELECT_CAPACITY: [
        {
          parentID: 'services',
          parentCondition: 'VALUES_EITHER',
          parentValues: ['electricity'],
        },
      ],
      WATER_CAPACITY: [
        {
          parentID: 'services',
          parentCondition: 'VALUES_EITHER',
          parentValues: ['water'],
        },
      ],
    }

    const formFields = Helpers.getFormFields(lisfOfFields, formProps, {
      additionalCriteria,
      defaultValue: ContextData.payment
        ? ContextData.payment.chosen_payment
        : null,
    })
    return formFields
  }

  _reValidate = () => {
    this._form.validateForm(this._form.state.values)
    setTimeout(() => {
      if (this._form && this._form.state) {
        if (this._form.state.errors) {
          Object.keys(this._form.state.errors).forEach((field) => {
            if (this._form.state.values[field] !== '') {
              this._form.setFieldTouched(field, true)
            }
          })
        }
      }
    })
  }

  _handleConfirm = (values) => {
    const { context } = this.props
    const {
      actions,
      data: contextData,
      config: contextConfig,
      stateId,
    } = context

    const kioskPaymentError = this._getKioskPaymentValidationErrorDialogData(
      contextConfig,
      contextData
    )
    if (kioskPaymentError) {
      this.setState({ errDialogData: kioskPaymentError })
      return
    }

    this.setState((prevState) => ({
      ...prevState,
      submitting: true,
    }))

    if (actions.next) {
      const contact = {
        email: '',
        number: '',
      }
      if (contextConfig.application_type === 'personal') {
        contact.email = `${contextData.personal_information.email}`
        contact.number = `${contextData.personal_information.mobile_number}`
      } else if (contextConfig.application_type === 'company') {
        contact.email = `${contextData.representative.representative_email}`
        contact.number = `${contextData.representative.representative_mobile_number}`
      }
      actions.next(
        values,
        (res) => {
          this.setState((prevState) => ({
            ...prevState,
            submitting: false,
            errDialogData: null,
          }))

          if (res.error === 'pending') {
            const errorDialogData = {
              content: `${res.error_description} Please click CONTINUE if you wish to proceed with payment or CANCEL to terminate the application.`,
              actionConfig: {
                cancel: {
                  label: 'Cancel',
                },
                ok: {
                  label: 'Continue',
                },
              },
              okHandler: this._handlePendingContinue,
              cancelHandler: this._handlePendingCancel,
            }
            this.setState((prevState) => ({
              ...prevState,
              errDialogData: errorDialogData,
            }))
          }

          if (res && res.app_id && res.payment_details && res.hmac !== '') {
            // REDIRECT TO Kiosk Payment for commercial case
            if (this._shouldUseKioskPayment(contextConfig)) {
              const redirectUrl = btoa(
                window.location.href +
                  (window.location.href.includes('?') ? '&' : '?') +
                  new URLSearchParams({ appId: res.app_id }).toString()
              )

              const searchString = new URLSearchParams({
                paymentMode:
                  khaosPaymentMode[contextData.payment.chosen_payment[0]],
                amount: dollarToCents(
                  contextData.security_deposit_calculation.security_deposit
                ),
                clientId: 'OA',
                redirectUrl,
                clientState: res.app_id,
              }).toString()

              window.location.href = `${process.env.REACT_APP_KHAOS_URL}/payment/client-payment?${searchString}`
              return
            }
            // REDIRECT TO ENETS
            const { enets_url, ...data } = res.payment_details
            const dataPayload = JSON.parse(data.payload)
            data.payload = JSON.stringify(dataPayload)

            const form = document.createElement('form')
            form.action = enets_url
            form.method = 'POST'
            const payloadField = document.createElement('input')
            payloadField.name = 'payload'
            payloadField.value = data.payload
            const apiKeyField = document.createElement('input')
            apiKeyField.name = 'apiKey'
            apiKeyField.value = data.api_key
            const hmacField = document.createElement('input')
            hmacField.name = 'hmac'
            hmacField.value = data.hmac

            form.append(payloadField)
            form.append(apiKeyField)
            form.append(hmacField)
            document.body.append(form)
            form.submit()
          }
        },
        {},
        false
      )
    }
  }

  _getKioskPaymentValidationErrorDialogData = (contextConfig, contextData) => {
    if (!this._shouldUseKioskPayment(contextConfig)) {
      return
    }

    if (
      contextData.security_deposit_calculation.security_deposit >
      kioskSDPaymentMax
    ) {
      return {
        content: `Maximum of ${formatSGD(
          kioskSDPaymentMax
        )} are allowed for Kiosk Payment.`,
        actionConfig: {
          ok: null,
          cancel: {
            label: 'Cancel',
          },
        },
        cancelHandler: () => {
          this.setState({ errDialogData: null })
        },
      }
    }
  }

  _handleChange = (e) => {
    this._form.handleChange(e)
  }

  _setFieldValue = (target) => {
    const { fields } = this.state

    if (target.id === 'PAYMENT_METHOD') {
      const paymentField = fields.find((field) => field.id === 'PAYMENT_METHOD')

      const chosenPaymentMethod =
        paymentField.options &&
        paymentField.options.find((item) => item.id === target.chosen[0])

      this._form.setFieldValue(target.id, target.chosen[0])
      this._form.setFieldValue('admin_fees', chosenPaymentMethod.adminFee)
      this._form.setFieldValue('amount_payable', chosenPaymentMethod.total)
      this._form.setFieldValue('chosen_payment', target.chosen)
      this._form.setFieldValue(
        'admin_fees_wo_gst',
        chosenPaymentMethod.adminFeeWithoutTax || 0
      )
      this._form.setFieldValue(
        'amount_payable_wo_gst',
        chosenPaymentMethod.amountWithoutTax || 0
      )
      this._form.setFieldValue('gst', chosenPaymentMethod.tax || 0)
      this._preSave({
        admin_fees: chosenPaymentMethod.adminFee,
        admin_fees_wo_gst: chosenPaymentMethod.adminFeeWithoutTax,
        amount_payable: chosenPaymentMethod.total,
        amount_payable_wo_gst: chosenPaymentMethod.amountWithoutTax,
        gst: chosenPaymentMethod.tax,
        chosen_payment: target.chosen,
        security_deposit: this.props.context.data.security_deposit_calculation
          .security_deposit,
      })
    }
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (this._form.state && this._form.state.values) {
      this._preSave(this._form.state.values)
    }
    actions.prev()
  }

  _handlePendingContinue = () => {
    if (this._form) {
      const {
        state: { values },
      } = this._form
      this.setState((prevState) => ({
        ...prevState,
        errDialogData: null,
      }))
      this._handleConfirm({ ...values, bypass_dup_check: 'true' })
    }
  }

  _handlePendingCancel = () => {
    const { history } = this.props
    history.push('/open-account')
  }

  _termsOkHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: false,
    }))
  }

  _termsShowHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showTerms: true,
    }))
  }

  _getPaymentProps = () => {
    const { data: contextData } = this.props.context
    const { setFieldValue } = this._form
    const defaultPaymentMethod = contextData.payment_method.options.find(
      (item) =>
        contextData.payment && contextData.payment.chosen_payment.length > 0
          ? item.id === contextData.payment.chosen_payment[0]
          : item.id === contextData.payment_method.defaults[0]
    )

    setFieldValue('bypass_dup_check', 'false')

    setFieldValue(
      'PAYMENT_METHOD',
      contextData.payment && contextData.payment.chosen_payment
        ? contextData.payment.chosen_payment[0]
        : contextData.payment_method.defaults[0]
    )
    setFieldValue(
      'USE_OF_PREMISES_LIST',
      contextData.security_deposit_calculation.USE_OF_PREMISES_LIST
    )
    setFieldValue('admin_fees', defaultPaymentMethod.adminFee)
    setFieldValue(
      'admin_fees_wo_gst',
      defaultPaymentMethod.adminFeeWithoutTax || 0
    )
    setFieldValue('amount_payable', defaultPaymentMethod.total)
    setFieldValue(
      'amount_payable_wo_gst',
      defaultPaymentMethod.amountWithoutTax || 0
    )
    setFieldValue('gst', defaultPaymentMethod.tax || 0)
    setFieldValue(
      'ELECT_CAPACITY',
      contextData.security_deposit_calculation.ELECT_CAPACITY
    )
    setFieldValue(
      'WATER_CAPACITY',
      contextData.security_deposit_calculation.WATER_CAPACITY
    )
    setFieldValue(
      'security_deposit',
      contextData.security_deposit_calculation.security_deposit
    )
    setFieldValue('NO_DAYS', contextData.security_deposit_calculation.NO_DAYS)
    this.setState((prevState) => ({
      ...prevState,
      fields: [contextData.payment_method],
    }))
  }

  _getListOfFields = (fields) => {
    const {
      context: { data },
    } = this.props
    const {
      state: { values },
    } = this._form
    const isElectricitySelected = values.services.some(
      (s) => s === 'electricity'
    )
    const result = []
    if (data.turnOnServices && data.turnOnServices.type_of_usage) {
      const typeOfUsage = data.turnOnServices.type_of_usage
      const premisesListField = fields[typeOfUsage]
      const premisesOptions = premisesListField.options.filter((option) =>
        !isElectricitySelected
          ? option.id !== 'PREMISES_PUBLIC_LIGHTING'
          : isElectricitySelected
      )
      const filteredPremisesField = {
        ...premisesListField,
        options: premisesOptions,
      }

      if (fields[typeOfUsage]) {
        result.push(filteredPremisesField)
      }
      if (fields.elect_capacity) {
        result.push(fields.elect_capacity)
      }
      if (fields.water_capacity) {
        result.push(fields.water_capacity)
      }
      if (fields.no_days) {
        result.push(fields.no_days)
      }
      if (fields.payment_method) {
        result.push(fields.payment_method)
      }
    }
    return result
  }

  _preSave = (values) => {
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {
        payment: {
          admin_fees: values.admin_fees,
          admin_fees_wo_gst: values.admin_fees_wo_gst,
          amount_payable: values.amount_payable,
          amount_payable_wo_gst: values.amount_payable_wo_gst,
          gst: values.gst,
          chosen_payment: values.chosen_payment,
          services: values.services,
          security_deposit: values.security_deposit,
          bypass_dup_check: values.bypass_dup_check,
        },
      }
      actions.presave(payload)
    }
  }

  _processPaymentThankYou = (queries) => {
    this._updateProcessingPayment(false)
    // this.setState((prevState) => ({
    //   ...prevState,
    //   processingPayment: false,
    // }))
    const { history, context } = this.props
    const { data: contextData, config: contextConfig } = context
    const stateId = Utils.getStateId()
    const contact = {
      email: '',
      number: '',
    }
    if (contextConfig.application_type === 'personal') {
      contact.email = `${contextData.personal_information.email}`
      contact.number = `${contextData.personal_information.mobile_number}`
    } else if (contextConfig.application_type === 'company') {
      contact.email = `${contextData.representative.representative_email}`
      contact.number = `${contextData.representative.representative_mobile_number}`
    }
    history.push({
      pathname: `/thank-you`,
      state: {
        success: true,
        ebill: false,
        contact,
        application: {
          transaction_id: stateId,
          domain: contextConfig.application_domain,
          type: contextConfig.application_type,
          occupancy: contextData.premises.premise_occupancy,
        },
        appId: queries.appId,
      },
    })
  }

  _processPaymentFail = ({ error }) => {
    this._updateProcessingPayment(false)
    // this.setState((prevState) => ({
    //   ...prevState,
    //   processingPayment: false,
    // }))
    const { context } = this.props
    const { actions } = context
    actions.showPaymentError(decodeURIComponent(error.replace(/\+/g, ' ')))
  }

  _getQueries = () => {
    const {
      location: { search },
      context: { actions },
    } = this.props
    if (search) {
      Helpers.processQueries(search, [
        {
          param: 'status',
          resolver: (res, queries) => {
            this._updateProcessingPayment(true)
            // this.setState((prevState) => ({
            //   ...prevState,
            //   processingPayment: true,
            // }))
            actions.presave({ shouldTimerSuspense: false })
            if (res === 'success') {
              if (Utils.getOAMode().mode === 'kiosk' && queries.appId) {
                this._updateOASDPaymentStatus(
                  Number(queries.appId),
                  queries.txnRef
                )
              } else {
                this._checkPaymentStatusByTxnRef(queries)
              }
            } else {
              this._processPaymentFail(queries)
            }
          },
        },
      ])
    }
  }

  _updateOASDPaymentStatus(appId, txnId) {
    return API.payment
      .updatePaymentStatus({
        appId,
        txnId: txnId,
      })
      .then(() => {
        return this._checkPaymentStatusByAppId(appId)
      })
      .catch(() => this._updateOASDPaymentStatus(appId, txnId))
  }

  _checkPaymentStatusByAppId = (appId) => {
    return API.payment
      .getStatusByAppId(appId)
      .then((status) => {
        if (status === 'PAYMENT_SUCCESS') {
          this._processPaymentThankYou({ appId })
        }
      })
      .catch(() => this._checkPaymentStatusByAppId(appId))
  }

  _checkPaymentStatusByTxnRef = (queries) => {
    return API.payment
      .getStatus(queries.txnRef)
      .then((status) => {
        if (status === 'PAYMENT_SUCCESS') {
          this._processPaymentThankYou(queries)
        }
      })
      .catch(this._handlePaymentError)
  }

  _handlePaymentError(err) {
    this._processPaymentFail({
      error: err.error_description,
    })
  }

  _shouldUseKioskPayment = (contextConfig) => {
    return (
      contextConfig.application_domain === 'commercial' &&
      Utils.isPaymentKioskDevice()
    )
  }

  _updateProcessingPayment = (processingPayment) => {
    this.setState((prevState) => ({
      ...prevState,
      processingPayment: processingPayment,
    }))
    if (Utils.getOAMode().mode === 'kiosk') {
      const { kioskContext } = this.props
      const { updateShouldHideEndSession } = kioskContext
      updateShouldHideEndSession(processingPayment)
    }
  }
}

export default Payment

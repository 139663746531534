import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Button,
  Tooltip,
  ConfirmationDialog,
  Icons,
} from 'spd-oa/components/common'
import Utils from 'spd-oa/utils'
import { openLink } from 'spd-oa/helpers'
import MyinfoLogoBiz from 'spd-oa/images/logos/myinfo-biz.svg'

class Myinfo extends PureComponent {
  state = {
    confirm: false,
    confirmMyinfo: false,
  }

  _confirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirm: false,
    }))
  }
  _confirmHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirm: true,
    }))
  }

  componentDidMount() {
    const { isField } = this.props
    if (!isField) {
      window.dataLayer.push({
        pageDataLayer: '/open-account/residential/company/particulars',
      })
    }
  }

  componentDidUpdate() {
    const { isField } = this.props
    if (!isField) {
      window.dataLayer.push({
        pageDataLayer: '/open-account/residential/company/particulars',
      })
    }
  }

  render() {
    const { props } = this
    const { contextConfig } = props
    const { confirm, confirmMyinfo } = this.state
    const {
      skipHandler,
      backHandler,
      isField,
      label,
      processing,
      business,
    } = this.props
    const { mode } = Utils.getOAMode()
    if (isField) {
      return (
        <div className={`lm--formItem lm--formItem--inline form-myinfo`}>
          <div className="lm--formItem-control myinfo-prompt-box">
            <div className="u-grid">
              <div>{`Change your mind?`}&nbsp;</div>
              <div>
                {'Click'}&nbsp;
                <Button
                  className={`outline`}
                  onClickHandler={this._confirm}
                  disabled={processing}
                  loading={processing}
                  link={true}
                >
                  {` here `}
                </Button>
                &nbsp;{`to use`}&nbsp;
                <span className="myinfo-text">{'Myinfo'}</span>
              </div>
            </div>
            <figure className="myinfo-logo">
              <img src={MyinfoLogoBiz} alt="Myinfo" />
            </figure>
            <Tooltip className="myinfo-tooltip">
              {`Myinfo business is a secure Government service that allows Corppass users to manage their Company data and pre-fill forms for online transactions. It was built by the Government Technology Agency (GovTech) to support Singapore's Smart Nation Initiatives and help residents to save time on online form filling.`}
            </Tooltip>
          </div>
        </div>
      )
    }
    return (
      <section className="myinfo-prompt">
        <div>
          <section className="myinfo-header">
            <div className="myinfo-content">
              <h2>{`Fill your company information faster`}</h2>
              <p>
                {`You can use Myinfo business to fill up your company information. Myinfo business helps you save time and provide convenience for online transactions.`}
              </p>
            </div>
            <figure className="myinfo-logo">
              <img src={MyinfoLogoBiz} alt="Myinfo" />
            </figure>
          </section>
          <section className="myinfo-features">
            <div className="item">
              <figure className="item-icon">
                <Icons.noic width={70} height={70} />
              </figure>
              <span className="item-title">{`No more document copies`}</span>
              <ul>
                <li>ACRA Business Profile</li>
                <li>Signed Application Form</li>
                <li>Letter of Authorisation</li>
              </ul>
            </div>
            <div className="item">
              <figure className="item-icon">
                <Icons.fast width={70} height={70} />
              </figure>
              <span className="item-title">{`Faster form filling`}</span>
            </div>
            <div className="item">
              <figure className="item-icon">
                <Icons.accurate width={70} height={70} />
              </figure>
              <span className="item-title">{`Reduce error inputs`}</span>
            </div>
          </section>

          <section className="myinfo-steps">
            <h3>{`How do you use Myinfo business?`}</h3>
            <ol>
              <li>
                {`You can access it by logging in to your Corppass login.`}
              </li>

              <li>
                {`Once you log in, you give consent to retrieve company data from your Myinfo business profile.`}
              </li>
              <li>
                {`Your company information will be automatically filled up and you do not need to upload documents about your company.`}
              </li>
            </ol>
          </section>

          <section className="myinfo-about">
            <h3>{`What is Myinfo business?`}</h3>
            <p>
              {`Myinfo business is a secure Government service that allows Corppass users to manage their company information data and pre-fill forms for online transactions. It was built by the Government Technology Agency (GovTech) to support Singapore's Smart Nation Initiatives and help businesses to save time on online form filling.`}{' '}
              {Utils.getOAMode().mode !== 'kiosk' && (
                <>
                  {`Find out `}
                  <Link to="#" onClick={this._myInfoLinkClickHandler}>
                    more about Myinfo
                  </Link>
                  .
                </>
              )}
            </p>
          </section>
        </div>

        <div className="myinfo-ctas">
          <div>
            <Button
              id="btn--use-myinfo"
              onClickHandler={this._confirm}
              disabled={processing}
              loading={processing}
            >
              {`I want to use Myinfo business`}
            </Button>
          </div>
          {Utils.getParticularsOptions(contextConfig.application_domain).company
            .manual && (
            <div>
              <Button
                id="btn--use-manual"
                link={true}
                onClickHandler={skipHandler}
              >
                {`Fill up form`}
              </Button>
            </div>
          )}
          <div>{`or`}</div>
          <div>
            <Button
              id="btn--back-to-premises"
              link={true}
              onClickHandler={backHandler}
            >
              {`Back to Premises`}
            </Button>
          </div>
        </div>
        {confirmMyinfo && (
          <ConfirmationDialog
            confirmOkHandler={this._myInfoLinkConfirmHandler}
            confirmCancelHandler={this._myInfoLinkConfirmationCloseHandler}
            onCloseHandler={this._myInfoLinkConfirmationCloseHandler}
            onEscapeKeyDownHandler={this._myInfoLinkConfirmationCloseHandler}
            content={`You are now leaving this website for a third party website.  Please review the new site's privacy and security policies as they may differ from those of this site.`}
            actionConfig={{
              cancel: {
                label: 'Cancel',
              },
              ok: {
                label: 'OK',
              },
            }}
          />
        )}
      </section>
    )
  }

  _confirm = () => {
    const { confirmHandler } = this.props
    if (confirmHandler) {
      confirmHandler()
    }
  }

  _myInfoLinkClickHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyinfo: true,
    }))
  }
  _myInfoLinkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmMyinfo: false,
    }))
  }
  _myInfoLinkConfirmHandler = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmMyinfo: false,
      }),
      () => {
        openLink('https://www.singpass.gov.sg/myinfobusiness', '_blank')
      }
    )
  }
}

Myinfo.defaultProps = {
  confirmHandler: () => {},
  skipHandler: () => {},
  backHandler: () => {},
  business: true,
}

Myinfo.propTypes = {
  confirmHandler: PropTypes.func,
  skipHandler: PropTypes.func,
  backHandler: PropTypes.func,
  business: PropTypes.bool,
}

export default Myinfo

import React, { PureComponent } from 'react'

export default class CardList extends PureComponent {
  _renderHeader() {
    const { header } = this.props
    if (header && (header.list.length === 0 || !header.list)) {
      return
    }
    return (
      <div
        className={`card-list--row card-list--header ${header.className || ''}`}
      >
        {header.list.map((item, itemIndex) => {
          if (item.hasOwnProperty('render') && !item.render) {
            return null
          }
          return (
            <div
              className="card-list--node"
              key={`cardlist_head-${itemIndex}`}
              data-field={item.key ? item.key.toLowerCase() : null}
            >
              <button onClick={item.clickHandler}>{item.label}</button>
            </div>
          )
        })}
      </div>
    )
  }
  render() {
    const { children, className } = this.props
    return (
      <section className={`card-list ${className}`}>
        {this._renderHeader()}
        {children}
      </section>
    )
  }
}

CardList.defaultProps = {
  className: '',
}

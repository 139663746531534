import React from 'react'

const ContentNode = (props) => {
  return (
    <div className="col-sm-12 col-md-6 u-pb3">
      <div className={`${props.className}`}>
        <div className="label-info">{props.label}</div>
        <div className="value">{props.value}</div>
      </div>
    </div>
  )
}

ContentNode.defaultProps = {
  className: '',
  label: 'Label',
  value: '-',
}

export default ContentNode

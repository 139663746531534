import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FormSwitchRadio, FormErrorBlock } from 'spd-oa/components/FormElement'

class FormChoose extends PureComponent {
  state = {
    error: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error !== state.error) {
      return {
        error: props.error,
      }
    }
    return null
  }
  render() {
    const { title, children, multiple, notes, onlyElem, choices } = this.props
    const { error: stateError } = this.state

    const _FormChoose = classnames('form-choose', {
      'lm--formItem': !onlyElem,
      'lm--formItem--inline': !onlyElem,
      'form-choose--2': choices.length % 2 === 0,
      'form-choose--3': choices.length % 3 === 0,
    })

    if (onlyElem) {
      return (
        <div className={_FormChoose}>
          {children ? (
            typeof children === 'function' ? (
              children()
            ) : (
              children
            )
          ) : (
            <div className="switches">{this._renderChoices()}</div>
          )}
          {stateError && (
            <FormErrorBlock
              id={`${multiple ? 'checks' : 'rads'}-field_error`}
              error={stateError}
            />
          )}
          {notes.length > 0 && this._renderNotes()}
        </div>
      )
    }
    return (
      <Fragment>
        <div className={_FormChoose}>
          <div className="lm--formItem-label">{title}</div>
          <div className="lm--formItem-control">
            {children ? (
              typeof children === 'function' ? (
                children()
              ) : (
                children
              )
            ) : (
              <div className="switches">{this._renderChoices()}</div>
            )}
            {stateError && (
              <FormErrorBlock
                id={`${multiple ? 'checks' : 'rads'}-field_error`}
                error={stateError}
              />
            )}
            {notes.length > 0 && this._renderNotes()}
          </div>
        </div>
      </Fragment>
    )
  }
  _renderChoices() {
    const { choices, multiple, selected } = this.props

    if (choices.length > 0) {
      if (!multiple) {
        return choices.map((c, i) => {
          return (
            <FormSwitchRadio
              key={`rad--${c.name}-${i}`}
              label={c.label}
              id={c.id}
              name={c.name}
              error={c.error}
              value={c.value}
              checked={c.value === selected[0]}
              onChange={this._onChangeHandler}
              onBlur={this._blurHandler}
              disabled={c.disabled}
            />
          )
        })
      }

      return choices.map((c, i) => {
        return (
          <div
            key={`check--${c.name}-${i}`}
            className={`lm--switch ${c.error ? 'is-error' : ''} ${
              c.disabled ? 'is-disabled' : ''
            }`}
          >
            <input
              type="checkbox"
              className="btn--oa lm--switch-input"
              value={c.value}
              id={c.id}
              name={c.name}
              onChange={this._onChangeHandler}
              onBlur={this._blurHandler}
              checked={selected.includes(c.value)}
              disabled={c.disabled}
            />
            <label htmlFor={c.id} className="lm--switch-label">
              <div dangerouslySetInnerHTML={{ __html: c.label }} />
            </label>
          </div>
        )
      })
    }
    return null
  }

  _renderNotes = () => {
    const { id, selected, notes } = this.props
    let filteredNotes = notes.filter((note, i) => {
      let _values = JSON.stringify(note.values.sort())
      let _selected = JSON.stringify([...selected].sort())
      return _values === _selected
    })
    if (filteredNotes && filteredNotes.length > 0) {
      return (
        <div className="notes">
          {filteredNotes[0].notes.map((note, i) => {
            return <div key={`${id}-choose--note--${i}`}>{note}</div>
          })}
        </div>
      )
    }
    return null
  }
  _onChangeHandler = (e) => {
    const {
      setFieldValue,
      setFieldTouched,
      multiple,
      selected,
      handleChange,
    } = this.props
    const { value, name, checked } = e.target
    let fieldValue = value
    setFieldTouched(e.target.id, true)

    if (multiple) {
      fieldValue = [...selected] || []
      if (checked) {
        fieldValue.push(value)
      } else {
        fieldValue.splice(fieldValue.indexOf(value), 1)
      }
    }
    if (setFieldValue) {
      setFieldValue(name, fieldValue)
    }
    if (handleChange) {
      handleChange(e)
    }
  }

  _blurHandler = (e) => {
    const { handleBlur, error } = this.props

    if (handleBlur) {
      handleBlur(e)
    }
    if (error) {
      this.setState((prevState) => ({
        ...prevState,
        error,
      }))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: null,
      }))
    }
  }
}

FormChoose.defaultProps = {
  id: '',
  title: '',
  choices: [],
  multiple: false,
  selected: [],
  notes: [],
  onlyElem: false,
}

FormChoose.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.object),
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  notes: PropTypes.array,
  onlyElem: PropTypes.bool,
}

export default FormChoose

/**
 * @typedef {{
 * event: string,
 * eventCategory: string,
 * eventAction: EVENT_ACTION,
 * eventLabel: EVENT_LABEL,
 * errorMessage: string }} GATemplate
 */

export default function eSignatureGAServices(request) {
  const EVENT_ACTION = Object.freeze({
    uploadEsig: 'Upload eSignature',
    generatePdf: 'Generate PDF Form',
    tieEsigToAppId: 'Tie eSignature to App',
  })

  const EVENT_LABEL = Object.freeze({
    success: 'Success',
    fail: 'Fail',
  })

  const TEMPLATE = Object.freeze({
    event: 'API Response',
    eventCategory: `Open Account - eSignature`,
    errorMessage: '',
  })

  /**
   * send Google Analytics
   * @param gaInfo {Partial<GATemplate>}
   */
  function push(gaInfo) {
    window.dataLayer.push({
      ...TEMPLATE,
      ...gaInfo,
    })
  }

  /**
   *
   * @param axiosConfig {{ method: 'POST' | 'GET', url: string, data: any }}
   * @param actionType { EVENT_ACTION }
   * @return {Promise}
   */
  function promise(axiosConfig, actionType) {
    return request(axiosConfig)
      .then((res) => {
        push({
          eventAction: actionType,
          eventLabel: EVENT_LABEL.success,
        })
        return res.data
      })
      .catch((err) => {
        push({
          eventAction: actionType,
          eventLabel: EVENT_LABEL.fail,
        })
        return err
      })
  }

  return { promise, EVENT_ACTION, EVENT_LABEL }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import keycode from 'keycode'
import classnames from 'classnames'

import Portal from './Portal'
import Backdrop from './Backdrop'
import Utils from 'spd-oa/utils'
import { Icons } from '.'

export default class Modal extends Component {
  static _modals = []
  state = {
    position: {
      x: 0,
      y: 0,
    },
    topOffset: 10,
    minHeightOffset: 200,
  }

  _calculatePosition(target) {
    const { offset, arrow, useMinHeight } = this.props
    const heightOffset = useMinHeight ? this.state.minHeightOffset : 0
    /**
     * Calculate position from the target element
     */
    let position = {}
    /**
     * get window size - width and height, and half of it
     * this is to check whether the target is at what quadrant of the screen
     * and render the modal on that quadrant
     */
    let windowSize = Utils.getWindowSize()
    let halfWidthWindow = windowSize.width / 2
    let halfHeightWindow = windowSize.height / 2

    if (this._modalElement) {
      let label = ''

      /**
       * get modal's bounding client rect to get both size and position
       */
      let modalElementClientRect = this._modalElement.getBoundingClientRect()

      if (target) {
        /**
         * if target is available get its client rect
         */
        let targetRect = target.getBoundingClientRect()

        /**
         * if targetRect doesnt have x and y properies but instead has left and top,
         * set x and y with the left and top respectively
         */
        if (!targetRect.x && targetRect.left) {
          targetRect.x = targetRect.left
        }

        if (!targetRect.y && targetRect.top) {
          targetRect.y = targetRect.top
        }

        if (targetRect.x > halfWidthWindow) {
          /**
           * if target x position is more than the half of window's width
           * then modal's x position would be on the right and
           * get the value base on the full width of the screen and target's position x and width
           */
          if (targetRect.x + targetRect.width > modalElementClientRect.width) {
            position.right = windowSize.width - targetRect.x - targetRect.width
          } else {
            position.left = 5
          }
          if (arrow === 'relative') {
            position.right += -(targetRect.width / 2 + 10)
          }
          if (offset && offset.x) {
            position.right += offset.x
          }
          label = 'right'
        } else {
          /**
           * if target x position is less than the half of window's width
           * then modal's x position would be on the left and
           * get the value base on target's position x
           */
          if (targetRect.x + modalElementClientRect.width < windowSize.width) {
            position.left = targetRect.x
          } else {
            position.right = 5
          }
          if (arrow === 'relative') {
            position.left += -(targetRect.width / 2 + 10)
          }
          if (offset && offset.x) {
            position.left += offset.x
          }
          label = 'left'
        }

        if (targetRect.y > halfHeightWindow + heightOffset) {
          /**
           * if target y position is more than the half of window's height
           * then modal's y position would be on the bottom and
           * get the value base on the full height of the screen and target's position y and height
           * together with the topOffset
           */
          position.bottom =
            windowSize.height - targetRect.y + this.state.topOffset
          if (offset && offset.y) {
            position.bottom += offset.y
          }
          label += '_bottom'
        } else if (targetRect.y < halfHeightWindow - heightOffset) {
          /**
           * if target y position is less than the half of window's height
           * then modal's y position would be on the top and
           * get the value base on the full height of the screen and target's position y and height
           * together with the topOffset
           */
          position.top = targetRect.height + targetRect.y + this.state.topOffset
          if (offset && offset.y) {
            position.top += offset.y
          }
          label += '_top'
        } else {
          /**
           * else, position y on the top with the half of the window's height and topOffset
           */
          position.top = halfHeightWindow - modalElementClientRect.height / 2
          label += '_center'
        }
      } else {
        /**
         * if there's no target available,
         * get the half of the screen both width and height
         * then set the position both for left and top respectively with half of the window's sizes
         */
        position.top = halfHeightWindow - modalElementClientRect.height / 2
        position.left = halfWidthWindow - modalElementClientRect.width / 2
      }

      this.setState((prevState) => ({
        ...prevState,
        position,
        label,
      }))
    }
  }

  _backdropClickHandler = () => {
    /**
     * on backdrop being clicked, invoke the onClose handler from props
     */
    if (!this.props.disableBackdropClick) {
      this._handleClose()
    }
    this.props.onClose()
  }

  _closeBtnClickHandler = () => {
    /**
     * on close button being clicked, invoke the onClose handler from props
     */
    this.props.onClose()
  }

  _keyDownHandler = (event) => {
    /**
     * if the key is 'esc' call this onEscapeKeyDown() handler
     * from the props if available and disableEscapeKey is false
     */
    if (keycode(event) === 'esc') {
      if (this.props.onEscapeKeyDown && !this.props.disableEscapeKey) {
        this.props.onEscapeKeyDown(event)
      }
    }
  }
  _handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose()
    }
  }

  _restoreLastFocus() {
    /**
     * Last modal in the list will be focused, by calling the _enforceFocus() method
     */
    const modal = Modal._modals[Modal._modals.length - 1]
    if (modal) {
      this._enforceFocus(modal)
    }
  }

  _enforceFocus = (modal) => {
    /**
     * enforce focus on modal by focus() API
     */
    if (modal) {
      modal.focus({
        preventScroll: true,
      })
    }
  }

  _setBodyOverflow() {
    /**
     * sets body overflow to hidden and
     * checks if browser is in windows
     * then force to mimic scrollbar's width
     */

    this._bodyElement = document.querySelector('body')
    this._bodyElement.classList.add('overflow-hidden')

    if (window.navigator) {
      const _nav = window.navigator
      const { platform } = _nav
      const regex = /win/g
      const res = platform.toLowerCase().match(regex)
      if (res) {
        this._bodyElement.classList.add('overflow-offset')
      }
    }
  }

  componentWillUnmount() {
    /**
     * if component is unmounting, remove any event/listeners on it
     * and keep track of the modals available
     * and restore the focus to the last modal
     */
    if (this._modalElement) {
      this._modalElement.removeEventListener('keydown', this._keyDownHandler)
      Modal._modals.splice(Modal._modals.length - 1, 1)
      this._restoreLastFocus()
    }

    if (this._bodyElement) {
      this._bodyElement.classList.remove('overflow-hidden', 'overflow-offset')
    }
  }

  componentDidMount() {
    /**
     * When mounted, check and calculate position of modal based on target node.
     * Target is usually the one who invoked this Modal
     */
    const { target } = this.props
    if (target) {
      this._calculatePosition(target)
    }

    if (this._modalElement) {
      /**
       * force modal to scroll to the top most
       */
      this._modalElement.scrollTop = 0

      /**
       * force the focus in the modal
       */
      this._enforceFocus(this._modalElement)

      /**
       * add a listener to every keydown event
       * this is to handle the esc key - close modal feature
       */
      this._modalElement.addEventListener('keydown', this._keyDownHandler)
    }

    /**
     * keep track of all the modals being mounted
     * this component should be able to render nested modals
     */
    Modal._modals.push(this._modalElement)

    this._setBodyOverflow()
  }

  render() {
    const {
      children,
      style,
      className,
      target,
      withBackdrop,
      arrow,
      closeBtn,
      disableEscapeKey,
      onEscapeKeyDown,
      useMinHeight,
      ...rest
    } = this.props
    let { position, label: positionLabel } = this.state
    let _styles = target
      ? {
          top: `${position.top ? `${position.top}px` : null}`,
          right: `${position.right ? `${position.right}px` : null}`,
          bottom: `${position.bottom ? `${position.bottom}px` : null}`,
          left: `${position.left ? `${position.left}px` : null}`,
          zIndex: 4000 + Modal._modals.length,
          ...style,
        }
      : {
          top: `50%`,
          left: `50%`,
          bottom: null,
          right: null,
          transform: `translate(-50%, -50%)`,
          zIndex: 4000 + Modal._modals.length,
          ...style,
        }
    const __modal__ = classnames(`app-modal ${className}`, {
      'modal-arrow': arrow !== null,
      'kiosk-mode': Utils.getOAMode().mode === 'kiosk',
    })
    return (
      <Portal>
        {
          <Backdrop
            className={`${
              Utils.getOAMode().mode === 'kiosk' ? 'kiosk-mode' : ''
            }`}
            onClickHandler={this._backdropClickHandler}
            style={{
              zIndex: 4000 + Modal._modals.length,
              opacity: withBackdrop ? 1 : 0,
            }}
          />
        }
        <div
          id={`app_modal_${Modal._modals.length}`}
          tabIndex={-1}
          ref={(el) => (this._modalElement = el)}
          className={__modal__}
          style={_styles}
          {...rest}
        >
          {closeBtn && (
            <button
              className="modal-close"
              onClick={this._closeBtnClickHandler}
            >
              <Icons.close width={12} height={12} />
            </button>
          )}
          {arrow && (
            <div
              className={`modal-arrow ${
                arrow === 'relative' ? positionLabel : ''
              }`}
              style={typeof arrow === 'object' ? arrow : {}}
            ></div>
          )}
          {children}
        </div>
      </Portal>
    )
  }
}

Modal.propTypes = {
  /**
   * Handler for closing the Modal, being called on esc key pressed/backdrop click or custom event
   */
  onClose: PropTypes.func,
  /**
   * class names to be added on the modal, list will be in a string format
   */
  className: PropTypes.string,
  /**
   * if `true`, disabling closing event on esc key press
   */
  disableEscapeKey: PropTypes.bool,
  withBackdrop: PropTypes.bool,
  offset: PropTypes.object,
  useMinHeight: PropTypes.bool,
}

Modal.defaultProps = {
  onClose: () => {},
  className: 'default',
  disableEscapeKey: false,
  withBackdrop: true,
  arrow: null,
  offset: null,
  closeBtn: false,
  useMinHeight: true,
}

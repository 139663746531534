import React from 'react'

const MyinfoTerms = ({ business = false }) => {
  if (business) {
    // business
    return (
      <div className="myinfo-terms">
        <h4>By clicking on the “I want to use Myinfo business” button, I:</h4>
        <div>
          <ol>
            <li>
              accept that I will be leaving SP Services Limited (“SPS”) website
              and I will be accessing websites, platforms, applications and/or
              portals that are not operated by SPS and/or its affiliates and my
              access and/or use of such websites, platforms, applications and/or
              portals is at my own risk and discretion and SPS shall not be
              liable in any way whatsoever for any damage and/or losses arising
              from and/or in connection with such access and/or use.
            </li>
            <li>
              consent and agree to SPS collecting and using any information or
              data that I provide or make available to SPS (including
              information or data from the Myinfo database (“Myinfo Data”) or
              any third party websites, platforms, applications or portals) for
              the following purposes:
              <ol>
                <li>
                  to autofill information required by SPS to sign the company up
                  as its customer and set up the company’s utilities account;
                </li>
                <li>to verify my identity;</li>
                <li>to provide the company with services/ goods;</li>
                <li>
                  to contact me and the company for any of the above purposes;
                  and
                </li>
                <li>
                  any other purpose that is incidental and/or ancillary to the
                  above stated purposes.
                </li>
              </ol>
            </li>
            <li>
              accept that SPS does not endorse and is not responsible for any
              Myinfo Data and/or any other information and/or data that I
              provided or made available to SPS in this application, and shall
              not be liable to the company for any errors, inaccuracies or
              omissions in such Myinfo Data and/or any other information and/or
              data that I provided or made available to SPS.
            </li>
          </ol>
        </div>
      </div>
    )
  }
  return (
    <div className="myinfo-terms">
      <h4>By clicking on the “I want to use Myinfo” button, I:</h4>
      <div>
        <ol>
          <li>
            accept that I will be leaving SP Services Limited (“SPS”) website
            and I will be accessing websites, platforms, applications and/or
            portals that are not operated by SPS and/or its affiliates and my
            access and/or use of such websites, platforms, applications and/or
            portals is at my own risk and discretion and SPS shall not be liable
            in any way whatsoever for any damage and/or losses arising from
            and/or in connection with such access and/or use.
          </li>
          <li>
            consent and agree to SPS collecting and using any information or
            data that I provide or make available to SPS (including information
            or data from the Myinfo database (“Myinfo Data”) or any third party
            websites, platforms, applications or portals) for the following
            purposes:
            <ol>
              <li>
                to autofill information required by SPS to sign me up as its
                customer and set up my utilities account;
              </li>
              <li>to verify my identity;</li>
              <li>to provide me with services/ goods;</li>
              <li>to contact me for any of the above purposes; and</li>
              <li>
                any other purpose that is incidental and/or ancillary to the
                above stated purposes.
              </li>
            </ol>
          </li>
          <li>
            accept that SPS does not endorse and is not responsible for any
            Myinfo Data and/or any other information and/or data that I provided
            or made available to SPS in this application, and shall not be
            liable to me for any errors, inaccuracies or omissions in such
            Myinfo Data and/or any other information and/or data that I provided
            or made available to SPS.
          </li>
        </ol>
      </div>
    </div>
  )
}

export default MyinfoTerms
